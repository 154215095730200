import React from "react";
import { Dropdown } from "react-bootstrap";

const AVAILABLE_ROWS_PER_PAGE = [10, 25, 50, 100];

interface Props {
  value: number;
  onChange: (value: number) => void;
}
const RowsPerPageSelector = ({ value, onChange }: Props) => {
  const onSelect = (value: string | null) => {
    const numValue = Number(value);
    onChange(numValue);
  };

  const getDropdown = () => (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        {value}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {AVAILABLE_ROWS_PER_PAGE.map((value, index) =>
          getDropdownItem(value, AVAILABLE_ROWS_PER_PAGE.length - 1 === index)
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
  const getDropdownItem = (value: number, isLast?: boolean) => (
    <React.Fragment>
      <Dropdown.Item key={value} eventKey={String(value)}>
        {value}
      </Dropdown.Item>
      {!isLast && <Dropdown.Divider />}
    </React.Fragment>
  );
  return (
    <div className="row-gap-12px align-items-center">
      <p>Show</p>
      {getDropdown()}
      <p>per page</p>
    </div>
  );
};

export default RowsPerPageSelector;
