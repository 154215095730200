import {
  getCoachingPlan,
  selectActiveCoachingPlan,
  selectAllChatbotConversations,
  selectGetCoachingPlanStatus,
  setChatbotMessages,
  setCoachBotPrompt,
  setIsCoachbotOpen,
  setThreadId,
  selectGetRecurringChatStatus,
  getRecurringChat,
  selectRecurringChat,
  selectProcessConversationStatus,
} from "app/containers/Chatbot/slice";
import Robot from "resources/images/robot.svg";
import Button from "app/storybookComponents/Button";
import Loading from "app/storybookComponents/Loading";
import { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScheduleChatbotRemindersModal from "app/components/Modals/ScheduleChatbotRemindersModal";
import { useNavigate } from "react-router-dom";
import EditSkillFocusModal from "app/components/Modals/EditSkillFocusModal";
import { isUTCTimeBeforeOrAfterLocal } from "utils/helperFunctions";
import {
  selectCurrentUserInfo,
  selectIsCurrentUserAdmin,
} from "app/containers/Global/slice";
import ExpandableLineClamp from "app/storybookComponents/CollapsibleCards/ExpandableLineClamp";
import { selectHasCoachBoConversationHistoryEnabled } from "app/containers/AdminConsole/slice";

interface CoachBoGreeterCardProps {
  name?: string;
}

export default function CoachBoGreeterCard({
  name,
}: Readonly<CoachBoGreeterCardProps>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeCoachingPlan = useAppSelector(selectActiveCoachingPlan);
  const getCoachingPlanStatus = useAppSelector(selectGetCoachingPlanStatus);
  const savedChatBoConversations = useAppSelector(
    selectAllChatbotConversations
  );
  const getRecurringChatStatus = useAppSelector(selectGetRecurringChatStatus);
  const activeRecurringChat = useAppSelector(selectRecurringChat);
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const isConversationHistoryEnabledCompanyWide = useAppSelector(
    selectHasCoachBoConversationHistoryEnabled
  );
  const processConversationStatus = useAppSelector(
    selectProcessConversationStatus
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);

  const [showScheduleReminderModal, setShowScheduleReminderModal] =
    useState(false);
  const [showEditSkillFocusModal, setShowEditSkillFocusModal] = useState(false);

  useEffect(() => {
    if (getCoachingPlanStatus !== "idle") {
      return;
    }
    dispatch(getCoachingPlan());
  }, [dispatch, getCoachingPlanStatus]);

  useEffect(() => {
    if (getRecurringChatStatus !== "idle") {
      return;
    }
    dispatch(getRecurringChat());
  }, [dispatch, getRecurringChatStatus]);

  const isConversationHistoryEnabled =
    userInfo?.hasCoachBoConversationHistoryEnabled &&
    isConversationHistoryEnabledCompanyWide;

  // --------------- Render Functions ---------------
  const getIntroMessage = () => {
    if (!activeCoachingPlan || !isConversationHistoryEnabled) {
      return `Welcome ${
        name ?? ""
      }! I'm Coach Bo, your personal development coach.`;
    }

    return `Hello ${name ?? ""}! Here's what you're currently working on:`;
  };

  const getCoachBoGreeting = () => {
    return (
      <div className="coach-bo-greetings">
        <div className="assistant-avatar-container">
          <img src={Robot} alt="Criteria" className="assistant-avatar me-0" />
        </div>

        <div className="coach-bo-greetings-message">
          <p className="coach-bo-greetings-message-text">{getIntroMessage()}</p>
        </div>
      </div>
    );
  };

  const getNextChatDateBanner = () => {
    if (!activeRecurringChat) {
      return null;
    }
    const daysRemaining = getDaysRemaining();
    const date = new Date();
    date.setDate(date.getDate() + daysRemaining);
    let dateText = date.toLocaleDateString([], {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
    let isSoon = false;

    if (daysRemaining < 2) {
      const day = daysRemaining === 0 ? "Today" : "Tomorrow";
      dateText = `${day}, ${date.toLocaleDateString([], {
        month: "long",
        day: "numeric",
      })}`;
      isSoon = true;
    }

    return (
      <div
        className={`warning-banner ${
          isSoon ? "gold" : "light-blue"
        } column-gap-8px`}
        style={{
          padding: "20px",
          border: 0,
          height: "100%",
        }}
      >
        <p className="font-family-montserrat fw-bold sapphire-150-text">
          {dateText}
        </p>
        <div className="row-gap-16px">
          <p className="grey-text">
            at {getLocaleTimeString(activeRecurringChat.timeOfDayUTC)} repeats{" "}
            {activeRecurringChat.cadence}
          </p>
          <button
            className="no-style-button grey-text"
            onClick={() => setShowScheduleReminderModal(true)}
          >
            <FontAwesomeIcon icon={["far", "edit"]} />
          </button>
        </div>
      </div>
    );
  };

  const getLocaleTimeString = (timeInUTC: string) => {
    // Assuming the date is today, we need to get the current date in UTC to combine with the time
    const now = new Date();
    const currentDateUTC = now.toISOString().split("T")[0];

    // Combine the current date with the time string
    const dateTimeString = `${currentDateUTC}T${timeInUTC}Z`; // 'Z' denotes UTC time

    // Create a Date object
    const date = new Date(dateTimeString);

    // Convert to local time string
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getDaysRemaining = () => {
    if (!activeRecurringChat) return 0;
    if (activeRecurringChat.cadence === "daily") {
      return isUTCTimeBeforeOrAfterLocal(activeRecurringChat.timeOfDayUTC) ===
        "before"
        ? 1
        : 0;
    }
    if (!activeRecurringChat.dayOfWeek) {
      return 0;
    }
    const daysOfTheWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const today = new Date().getDay();
    const chatDay = daysOfTheWeek.indexOf(activeRecurringChat.dayOfWeek);
    let daysUntilNextChat = chatDay - today;
    // If daysUntilNextChat is negative, add 7 to wrap around to the next week
    if (
      daysUntilNextChat < 0 ||
      (daysUntilNextChat === 0 &&
        isUTCTimeBeforeOrAfterLocal(activeRecurringChat.timeOfDayUTC) ===
          "before")
    ) {
      daysUntilNextChat += 7;
    }
    return daysUntilNextChat;
  };

  const getDaysUntilNextChatCard = () => {
    if (!activeRecurringChat) {
      return (
        <div className="column-gap-16px h-100">
          <div className="column-gap-8px">
            <p className="sapphire-150-text fw-bold">
              Recurring chat has not been set yet
            </p>
            <p>
              Schedule a recurring chat with Coach Bo and receive reminders to
              check back in on your goals.
            </p>
          </div>
          <div>
            <Button
              variant="secondary-blue"
              onClick={() => setShowScheduleReminderModal(true)}
            >
              Schedule recurring chat
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="column-gap-8px h-100">
        <p className="sapphire-150-text fw-bold">
          Your next chat is scheduled for:
        </p>
        {getNextChatDateBanner()}
      </div>
    );
  };

  const onStartFistChatClick = () => {
    if (userInfo?.hasCoachBoConversationHistoryEnabled) {
      dispatch(
        setCoachBotPrompt({
          chatType: "welcomeChat",
          userPrompts: [
            "Explore how chatting together regularly can help",
            "Envision my ideal future",
            "Create a 1-2 month growth goal",
          ],
          fullBotIntro: `Hi ${name}, I’m looking forward to getting to know you and helping you achieve your goals. What can I help you with today?`,
        })
      );
    }
    dispatch(setIsCoachbotOpen(true));
  };

  const onCheckInClick = () => {
    if (!savedChatBoConversations[0]) {
      return onStartFistChatClick();
    }
    dispatch(setThreadId(savedChatBoConversations[0].threadId));
    dispatch(setChatbotMessages(savedChatBoConversations[0].messages));
    dispatch(setIsCoachbotOpen(true));
  };

  const safeParse = (jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return jsonString;
    }
  };

  const onDropdownSelect = (eventKey: string | null) => {
    if (eventKey === "viewPastChats") {
      return navigate("/UserGuide?tab=Chat+History");
    }
    if (eventKey === "notificationConfiguration") {
      // if the user is an admin and the conversation history is turned off companywide redirect to admin console settings
      return navigate(
        isAdmin && !isConversationHistoryEnabledCompanyWide
          ? "/AdminConsole/Settings"
          : "/UserSettings"
      );
    }
    if (eventKey === "editSkillFocus") {
      return setShowEditSkillFocusModal(true);
    }
  };

  const getDropdown = (hasActiveCoachingPlan?: boolean) => {
    // If the conversation history is disabled company wide and the user is not an admin no need to show the dropdown
    if (!isConversationHistoryEnabledCompanyWide && !isAdmin) {
      return null;
    }

    return (
      <Dropdown onSelect={onDropdownSelect} placement="auto">
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-basic"
          className="no-caret"
        >
          <FontAwesomeIcon icon="ellipsis" size="lg" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {isConversationHistoryEnabled ? (
            <Dropdown.Item eventKey="viewPastChats">
              View recent chats
            </Dropdown.Item>
          ) : null}
          {hasActiveCoachingPlan && isConversationHistoryEnabled ? (
            <>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="editSkillFocus">
                Edit current focus
              </Dropdown.Item>
            </>
          ) : null}
          <Dropdown.Divider />
          {isConversationHistoryEnabledCompanyWide || isAdmin ? (
            <Dropdown.Item eventKey="notificationConfiguration">
              Configure chat settings
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getYourFocusCard = () => {
    const dropdown = getDropdown(!!activeCoachingPlan);
    const lineClampStyle = {
      "--line-clamp": 2,
    } as React.CSSProperties;

    if (processConversationStatus === "loading") {
      return (
        <Card className="coach-bo-greeter-card main-card">
          <div className="vertical-line blue" />
          <div className="warning-banner blue border-0 w-100 row-gap-20px align-items-center">
            <div>
              <Loading color="#202d63" />
            </div>
            <div className="column-gap-8px">
              <p className="sapphire-150-text fw-bold">
                Your conversation is processing...
              </p>
              <p>
                This will only take about 15 seconds. If it takes more than 30
                seconds, please refresh the page.
              </p>
            </div>
          </div>
        </Card>
      );
    }

    if (!activeCoachingPlan || !isConversationHistoryEnabled) {
      return (
        <Card className="coach-bo-greeter-card main-card">
          <div className="vertical-line blue" />
          <div className="column-gap-16px w-100">
            <div className="column-gap-8px">
              <p className="sapphire-150-text fw-bold">
                Ready to get started on your goals?
              </p>
              <p className="dynamic-line-clamp" style={lineClampStyle}>
                Hey there! I'm Coach Bo, an AI-driven coaching assistant, and
                I'm here to help you be your best at work. Help me understand
                your career goals so I can create a personalized development
                plan for you. Start on your goals now?
              </p>
            </div>
            <div className="action-buttons mt-auto">
              <div className="row-gap-12px">
                <Button onClick={onStartFistChatClick}>Start chat</Button>
                <Button
                  variant="secondary-blue"
                  onClick={() => navigate("/CoachBoPrompts")}
                >
                  See chat prompts
                </Button>
              </div>
              <div className="row-gap-8px align-items-center">
                {!isConversationHistoryEnabled ? (
                  <p className="grey-text">
                    <FontAwesomeIcon
                      icon={["far", "exclamation-triangle"]}
                      className="me-2"
                    />
                    Conversation history is not being saved.
                  </p>
                ) : null}
                {dropdown}
              </div>
            </div>
          </div>
        </Card>
      );
    }

    return (
      <Card className="coach-bo-greeter-card main-card">
        <div className="vertical-line  blue" />
        <div className="column-gap-16px w-100">
          <div className="column-gap-8px">
            <p className="sapphire-150-text fw-bold">Your focus right now</p>
            <ExpandableLineClamp
              text={safeParse(activeCoachingPlan?.currentFocus)}
              lines={2}
            />
          </div>
          <div className="action-buttons mt-auto">
            <div className="row-gap-12px">
              <Button onClick={onCheckInClick}>Check in</Button>
              {isConversationHistoryEnabled ? (
                <Button
                  variant="secondary-blue"
                  onClick={() => navigate("/UserGuide?tab=Chat+History")}
                >
                  See past chats
                </Button>
              ) : null}
            </div>
            {dropdown}
          </div>
        </div>
      </Card>
    );
  };

  if (
    getCoachingPlanStatus === "loading" &&
    processConversationStatus !== "loading"
  ) {
    return <Loading />;
  }

  return (
    <div className="coach-bo-greeter-card-container">
      <ScheduleChatbotRemindersModal
        show={showScheduleReminderModal}
        onHide={() => setShowScheduleReminderModal(false)}
        recurringChat={activeRecurringChat}
      />
      <EditSkillFocusModal
        show={showEditSkillFocusModal}
        onHide={() => setShowEditSkillFocusModal(false)}
      />
      {getCoachBoGreeting()}
      <div className="coach-bo-greeter-cards">
        {getYourFocusCard()}
        <Card className="coach-bo-greeter-card days-remaining-card">
          {getDaysUntilNextChatCard()}
        </Card>
      </div>
    </div>
  );
}
