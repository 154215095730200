import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MOOD_SCORE_DESCRIPTIONS,
  PROGRESS_SCORE_DESCRIPTIONS,
  READINESS_SCORE_DESCRIPTIONS,
} from "./helpers";
import { getOutOfFiveColor } from "app/containers/Dashboard/helpers";
import SimplePopover from "./SimplePopover";

interface Props {
  type: string;
  showColors?: boolean;
  overallType?: string;
}
const CheckinScorePopover = ({
  type,
  showColors = true,
  overallType = "user",
}: Readonly<Props>) => {
  const getCheckInScoreDescriptionArray = (type: string): string[] => {
    switch (type) {
      case "mood":
        return MOOD_SCORE_DESCRIPTIONS;
      case "progress":
        return PROGRESS_SCORE_DESCRIPTIONS;
      case "readiness":
        return READINESS_SCORE_DESCRIPTIONS;
      case "overall":
        return [
          `This is the average of this ${overallType}'s mood, progress, and readiness scores this week.`,
        ];
      default:
        return [];
    }
  };

  const getCheckinScoreDescription = (score: number, description: string) => (
    <div>
      <div>
        {showColors ? (
          <>
            {" "}
            <p style={{ display: "inline" }}>
              <b>{score}</b> -
            </p>{" "}
            <div
              className={`status-dot ${getOutOfFiveColor(score)}`}
              style={{ display: "inline-block" }}
            />{" "}
          </>
        ) : null}
        <p style={{ display: "inline" }}>{description}</p>
      </div>
    </div>
  );

  const getCheckInScoreDescriptionPopover = (type: string) =>
    getCheckInScoreDescriptionArray(type).map((description, index) => (
      <div key={index}>
        {getCheckinScoreDescription(5 - index, description)}
      </div>
    ));

  const getPopoverTitle = (type: string) => {
    switch (type) {
      case "mood":
        return "Mood Score";
      case "progress":
        return "Progress Score";
      case "readiness":
        return "Readiness Score";
      case "overall":
        return "Overall Score";
      default:
        return "";
    }
  };

  return (
    <SimplePopover
      popoverContent={
        <div className="column-gap-16px">
          {getCheckInScoreDescriptionPopover(type)}
        </div>
      }
      trigger="hover"
      popoverTitle={getPopoverTitle(type)}
      popoverClassName="checkin-score-popover"
    >
      <FontAwesomeIcon
        icon={["far", "circle-info"]}
        style={{ paddingLeft: "5px" }}
      />
    </SimplePopover>
  );
};

export default CheckinScorePopover;
