// This array determines the color of the text inside of the radar chart
export const RADAR_COLOR_ARRAY = [
  "#425CC7",
  "#425CC7",
  "#425CC7",
  "#ffa300",
  "#ffa300",
  "#ffa300",
  "#FF5C39",
  "#FF5C39",
  "#2dccd3",
  "#2dccd3",
];

export const RADAR_LABEL_ARRAY = [
  "Achievement",
  "Motivation",
  "Conscientiousness",
  "Assertiveness",
  "Extroversion",
  "Cooperativeness",
  "Competitiveness",
  "Patience",
  "Self-Confidence",
  "Openness",
] as const;

// This is the constant for when just comparing one data set
export const SINGLE_POPULATION_RADAR_CHART_DATASET = {
  fill: true,
  backgroundColor: "rgba(0, 31, 78, 0.2)",
  borderColor: "rgb(0, 31, 78)",
  pointBackgroundColor: "rgb(0, 31, 78)",
  pointBorderColor: "#fff",
  pointHoverBackgroundColor: "#fff",
  pointHoverBorderColor: "rgb(0, 31, 78)",
};

// This is the constant to show circle at the base of the radar chart
export const BASE_RADAR_CHART_DATASET = {
  data: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25],
  fill: true,
  borderColor: "rgb(183.6, 191.25, 201.45)",
  backgroundColor: "rgba(204, 209, 219, 1)",
  borderWidth: 1,
  pointBorderWidth: 0,
  pointHitRadius: 0,
  label: "",
};

// This is the constant to show the general population
export const GENERAL_POPULATION_RADAR_CHART_DATASET = {
  data: [75, 75, 75, 75, 75, 75, 75, 75, 75, 75],
  fill: true,
  backgroundColor: "rgba(255, 255, 255, 0)",
  borderColor: "rgb(100, 100, 100)",
  borderDash: [3, 3],
  borderDashOffset: 3,
  borderWidth: 2,
  pointBorderWidth: 0,
  pointHitRadius: 0,
  pointRadius: 0,
  label: "General Population",
};

export const COLLABORATION_COMPARE_BY_RADAR_CHART_DATASET = {
  fill: true,
  backgroundColor: "rgba(236, 238, 249, 0.50)",
  borderColor: "#425CC7",
  borderDash: [3, 3],
  borderDashOffset: 3,
  borderWidth: 2,
  pointBorderWidth: 0,
  pointHitRadius: 0,
  pointRadius: 0,
};

export const COLLABORATION_COMPARE_FOR_RADAR_CHART_DATASET = {
  fill: true,
  backgroundColor: "rgba(255, 163, 0, 0.20)",
  borderColor: "#FFA300",
  pointBorderColor: "#fff",
  pointHoverBackgroundColor: "#fff",
  pointBorderWidth: 0,
  pointHitRadius: 0,
  pointRadius: 0,
};

export const LINE_CHART_DEFAULT_COLORS = [
  "#425CC7",
  "#ff5c39",
  "#2dccd3",
  "#ffa300",
  "#00a9e0",
  "#d50057",
];
