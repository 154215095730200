import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getSubordinatesWeeklyCheckinTableData,
  selectSubordinatesWeeklyCheckinTableData,
  selectGetSubordinatesWeeklyCheckinTableDataStatus,
  selectRegradeWeeklyCheckinStatus,
  selectUsersSubordinatesIds,
  getUserSpanOfControl,
  selectGetSpanOfControlStatus,
} from "../slice";
import CheckInActivityTable from "./CheckInActivityTable";
import { useCallback, useEffect, useMemo } from "react";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { getPeopleOrPerson, getS } from "utils/helperFunctions";
import { trackFeatureViewed } from "utils/trackingFunctions";
import { selectTeamAndDepartmentLeadIdsForLoggedInUser } from "app/containers/AdminConsole/slice";

interface Props {
  fallbackBackButton?: React.ReactNode;
}

const SubordinatesWeeklyCheckinTable = ({ fallbackBackButton }: Props) => {
  const dispatch = useAppDispatch();
  const allWeeklyCheckInTableData = useAppSelector(
    selectSubordinatesWeeklyCheckinTableData
  );
  const regradingWeeklyCheckinStatus = useAppSelector(
    selectRegradeWeeklyCheckinStatus
  );
  const fetchingWeeklyCheckInTableDataStatus = useAppSelector(
    selectGetSubordinatesWeeklyCheckinTableDataStatus
  );
  const teams = useAppSelector(selectTeamsByTeamId);
  const users = useAppSelector(selectAllCompanyUsersById);
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const usersSubordinatesIds = useAppSelector(selectUsersSubordinatesIds);
  const getSpanOfControlStatus = useAppSelector(selectGetSpanOfControlStatus);

  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );

  useEffect(() => {
    dispatch(getUserSpanOfControl());
  }, [dispatch]);

  useEffect(() => {
    trackFeatureViewed("Weekly Check-In Activity Table (Direct Report)");
  }, []);

  const { subordinatesCount, teamCount } = useMemo((): {
    subordinatesCount: number;
    teamCount: number;
  } => {
    if (!currentUserAccountId) {
      return { subordinatesCount: 0, teamCount: 0 };
    }
    const subordinatesCount = usersSubordinatesIds?.length ?? 0;

    let teamCount = 0;

    const user = users[currentUserAccountId];
    user?.teamIds?.forEach((teamId) => {
      const team = teams[teamId];
      if (team?.teamLeadUserAccountIds?.includes(currentUserAccountId)) {
        teamCount++;
      }
    });

    return { subordinatesCount, teamCount };
  }, [currentUserAccountId, usersSubordinatesIds, users, teams]);

  const fetchTableData = useCallback(
    (params: { entityType: string; timeInterval: string }) =>
      dispatch(getSubordinatesWeeklyCheckinTableData(params)),
    [dispatch]
  );

  const getHeaderDescription = () => {
    if (
      (!subordinatesCount && !teamCount) ||
      getSpanOfControlStatus === "loading"
    ) {
      return "";
    }
    const base =
      "Showing Check-In activity for everyone in your reporting structure, including";
    if (!subordinatesCount) {
      return `${base} ${teamCount} team${getS(teamCount)} you lead.`;
    }
    if (!teamCount) {
      return `${base} ${subordinatesCount} ${getPeopleOrPerson(
        subordinatesCount
      )} reporting to you.`;
    }
    return `${base} ${subordinatesCount} ${getPeopleOrPerson(
      subordinatesCount
    )} reporting to you and ${teamCount} team${getS(teamCount)} you lead.`;
  };

  return (
    <CheckInActivityTable
      getWeeklyCheckInTableDataStatus={fetchingWeeklyCheckInTableDataStatus}
      allWeeklyCheckInTableData={allWeeklyCheckInTableData}
      regradingWeeklyCheckinStatus={regradingWeeklyCheckinStatus}
      fetchTableData={fetchTableData}
      headerDescription={getHeaderDescription()}
      fallbackBackButton={fallbackBackButton}
      hideDepartmentDropdown={!teamAndDepartmentLeaderOf?.departments?.length}
    />
  );
};

export default SubordinatesWeeklyCheckinTable;
