import SortableTable from "app/components/SortableTable";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectTeamSurveyInstancesByTeamId,
  updateTeam360DateRange,
} from "../slice";
import { memo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendReminder } from "app/containers/Dashboard/slice";
import { setTeam360ActionByTeamId } from "app/components/Team360Assessment/slice";
import { useNavigate } from "react-router-dom";
import { TeamAssessmentInstance } from "app/containers/Assessment/types";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import {
  getDaysRemaining,
  getScoreClassName,
  getStatus,
  getTeamIdsFromSurvey,
} from "app/containers/Assessment/helpers";
import { deactivateTeamScan } from "app/containers/Assessment/slice";
import { showScheduleAssessmentModalForTeamId } from "app/components/LaunchAssessmentModal/slice";
import TableDropdownMenu from "app/components/Dropdowns/TableDropdownMenu";

interface Props {
  teamId: number;
}

const SurveysTable = ({ teamId }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const availableSurveyInstances = useAppSelector(
    selectTeamSurveyInstancesByTeamId(teamId)
  );
  const [
    showDeactivateSurveyWarningModal,
    setShowDeactivateSurveyWarningModal,
  ] = useState<{
    isModalOpen: boolean;
    assessmentInstanceId: number | null;
  }>({
    isModalOpen: false,
    assessmentInstanceId: null,
  });
  const [showRemindWarningModal, setShowRemindWarningModal] =
    useState<boolean>(false);

  const onTableDropdownSelect = (
    e: string | null,
    surveyInstance: TeamAssessmentInstance
  ) => {
    switch (e) {
      case "viewResults": {
        const { startDate, endDate } = surveyInstance;
        dispatch(
          updateTeam360DateRange({
            teamId,
            instance: startDate,
            startDate,
            endDate,
          })
        );
        navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
        return;
      }
      case "remindMembers": {
        setShowRemindWarningModal(true);
        break;
      }
      case "editSurveySettings": {
        // TODO: Create modal to edit existing survey settings
        break;
      }
      case "deactivateSurvey": {
        setShowDeactivateSurveyWarningModal({
          isModalOpen: true,
          assessmentInstanceId: surveyInstance.assessmentInstanceId,
        });

        break;
      }
      default:
    }
  };

  const getCompletionRateCell = (
    invitedCount: number = 0,
    completedCount: number = 0
  ) => {
    const getClassName = (score: number) => {
      if (score > 75) {
        return "green";
      } else if (score > 50) {
        return "yellow";
      } else if (score > 25) {
        return "poppy";
      } else {
        return "red";
      }
    };
    const completedPercentage = Math.round(
      (completedCount / invitedCount) * 100
    );

    const className = `status-dot ${getClassName(completedPercentage || 1)}`;

    return {
      displayValue: (
        <div className="row-gap-8px align-items-center">
          <p>
            {completedPercentage}% ({completedCount}/{invitedCount})
          </p>
          <div className={className} />
        </div>
      ),
      sortValue: completedPercentage,
    };
  };

  const rows: any[] = [];
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
  } as const;

  availableSurveyInstances?.forEach((surveyInstance) => {
    const teamIds = getTeamIdsFromSurvey(surveyInstance);
    const overallInstanceScore =
      (surveyInstance.teamScanScores?.overall ?? 0) * 20;
    const daysRemaining = getDaysRemaining(surveyInstance.endDate);
    const isPending = !!(daysRemaining && daysRemaining > 0);
    const isActive = !!(surveyInstance.isActive && isPending);
    const emptyScoreString = isActive ? "Results Pending" : "No Results";
    rows.push({
      surveyType: "TEAMscan",
      launchDate: {
        displayValue: new Date(surveyInstance.startDate).toLocaleDateString(
          "en-US",
          options
        ),
        sortValue: new Date(surveyInstance.startDate),
      },
      endDate: {
        displayValue: new Date(surveyInstance.endDate).toLocaleDateString(
          "en-US",
          options
        ),
        sortValue: new Date(surveyInstance.endDate),
      },
      status: {
        displayValue: (
          <div>
            <div className={`label-tag ${isActive ? "green" : "grey"}`}>
              {getStatus(
                !!surveyInstance.isActive,
                surveyInstance.endDate,
                surveyInstance.startDate
              )}
            </div>
          </div>
        ),
        sortValue: isActive,
      },
      completionRate: getCompletionRateCell(
        surveyInstance.totalInvited,
        surveyInstance.totalCompleted
      ),
      score: {
        displayValue: (
          <div
            className={`verbal-tag bigger ${getScoreClassName(
              overallInstanceScore
            )}`}
            style={{ width: "fit-content" }}
          >
            {Math.round(overallInstanceScore) || emptyScoreString}
          </div>
        ),
        sortValue: overallInstanceScore,
      },

      dropdown: {
        displayValue: (
          <Dropdown onSelect={(e) => onTableDropdownSelect(e, surveyInstance)}>
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="no-caret"
            >
              <FontAwesomeIcon icon="ellipsis" />
            </Dropdown.Toggle>
            <TableDropdownMenu>
              {isActive ? (
                <>
                  <Dropdown.Item eventKey="viewResults">
                    View Results
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="remindMembers">
                    Remind members
                  </Dropdown.Item>
                  {teamIds.length === 1 ? (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        eventKey="deactivateSurvey"
                        className="danger"
                      >
                        Deactivate survey
                      </Dropdown.Item>
                    </>
                  ) : null}
                  {/* Commented Out until I get the logic working */}
                  {/* <Dropdown.Item eventKey="editSurveySettings">
                    Edit survey settings
                  </Dropdown.Item>
                  <Dropdown.Divider />
                */}
                </>
              ) : (
                <Dropdown.Item eventKey="viewResults">
                  View Results
                </Dropdown.Item>
              )}
            </TableDropdownMenu>
          </Dropdown>
        ),
        sortValue: isActive ? 1 : 0,
      },
    });
  });

  const getDeactivateWarningModalText = () => {
    if (!showDeactivateSurveyWarningModal.assessmentInstanceId) return "";
    const appendedInstanceText =
      "Deactivating this survey will prevent any more members from completing this TEAMscan. If an insight report is available, results will include any responses collected so far.";

    const currentAssessmentInfo = availableSurveyInstances?.find(
      (instance) =>
        instance.assessmentInstanceId ===
        showDeactivateSurveyWarningModal.assessmentInstanceId
    );

    const daysRemaining = getDaysRemaining(currentAssessmentInfo?.endDate);
    const preText =
      daysRemaining && daysRemaining > 0
        ? `This survey closes in ${daysRemaining} days. `
        : "";

    return preText + appendedInstanceText;
  };
  const hideWarningModal = () => {
    setShowDeactivateSurveyWarningModal({
      isModalOpen: false,
      assessmentInstanceId: null,
    });
  };

  const getTable = () => {
    if (rows.length === 0) {
      return (
        <div
          className="empty-card"
          style={{
            padding: "32px",
          }}
        >
          <div className="column-gap-12px">
            <span>
              <b>No TEAMscan surveys have been launched for this team yet.</b>
            </span>
            <p>
              Active, past, and upcoming instances will be shown here once
              launched.
            </p>
          </div>
          <div className="action-buttons">
            <Button
              onClick={() => {
                dispatch(showScheduleAssessmentModalForTeamId(teamId));
              }}
            >
              Launch TEAMscan
            </Button>
            <Button
              onClick={() => {
                navigate("/SkillsGuide");
              }}
              variant="secondary-blue"
              className="white-button"
            >
              See teamwork skills
            </Button>
          </div>
        </div>
      );
    }
    return (
      <SortableTable
        columnHeaders={[
          {
            key: "score",
            label: "Overall Score",
          },
          {
            key: "completionRate",
            label: "Completion Rate",
          },
          {
            key: "launchDate",
            label: "Launch Date",
          },
          {
            key: "endDate",
            label: "End Date",
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "dropdown",
            label: "",
          },
        ]}
        rows={rows}
      />
    );
  };

  return (
    <>
      <WarningModal
        modalTitle="Deactivate Survey Instance"
        hideModal={hideWarningModal}
        onConfirmClick={() => {
          if (!showDeactivateSurveyWarningModal.assessmentInstanceId) return;
          const teamIds = getTeamIdsFromSurvey(
            availableSurveyInstances?.find(
              (instance) =>
                instance.assessmentInstanceId ===
                showDeactivateSurveyWarningModal.assessmentInstanceId
            )
          );
          dispatch(
            deactivateTeamScan({
              seriesOrInstance: "Instance",
              id: showDeactivateSurveyWarningModal.assessmentInstanceId,
              teamIds,
            })
          );
          hideWarningModal();
        }}
        isOpen={showDeactivateSurveyWarningModal.isModalOpen}
        warningTitle={`Are you sure you want to deactivate this TEAMscan survey?`}
        warningMessage={getDeactivateWarningModalText()}
        customButtonText={`Deactivate Survey Instance`}
        isDanger
      />
      <WarningModal
        modalTitle="Remind Team Members"
        warningTitle="Remind all team members who have not completed the TEAMscan?"
        warningMessage="Send out an email reminder to an team members who have not completed the TEAMscan survey for this instance."
        isOpen={showRemindWarningModal}
        onConfirmClick={async () => {
          if (!showRemindWarningModal) return;
          await dispatch(
            sendReminder({
              reminderType: "remindUserToTakeAssessment",
              teamId,
            })
          );
          dispatch(setTeam360ActionByTeamId({ teamId, action: "reminded" }));
          setShowRemindWarningModal(false);
        }}
        hideModal={() => {
          setShowRemindWarningModal(false);
        }}
        customButtonText="Send Reminder"
        isDanger={false}
      />
      {getTable()}
    </>
  );
};
export default memo(SurveysTable);
