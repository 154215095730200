import ActivityMonitorContainer from "app/components/ActivityMonitor/ActivityMonitorContainer";
import Button from "app/storybookComponents/Button";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Loading from "app/storybookComponents/Loading";
import {
  getOverallActivitySnapshot,
  selectGetOverallActivitySnapshotStatus,
  selectOverallActivitySnapshotByDateInterval,
} from "../slice";
import { OVERALL_ACTIVITY_TIME_RANGE_OPTIONS } from "../constants";
import { getMonitorPropsFromOverallActivitySnapshot } from "../helpers";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  selectAllCompanyUsersById,
  selectCompanyInfo,
} from "app/containers/Global/slice";

const OverallActivityCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const getOverallActivitySnapshotStatus = useAppSelector(
    selectGetOverallActivitySnapshotStatus
  );
  const overallActivitySnapshotByDateInterval = useAppSelector(
    selectOverallActivitySnapshotByDateInterval
  );
  const companyInfo = useAppSelector(selectCompanyInfo);
  const [overallActivityTimeInterval, setOverallActivityTimeInterval] =
    useState("Past 30 days");

  const overallActivitySnapShot = useMemo(() => {
    const activeKey =
      OVERALL_ACTIVITY_TIME_RANGE_OPTIONS[overallActivityTimeInterval];
    return activeKey ? overallActivitySnapshotByDateInterval[activeKey] : null;
  }, [overallActivitySnapshotByDateInterval, overallActivityTimeInterval]);

  useEffect(() => {
    dispatch(getOverallActivitySnapshot());
  }, [dispatch]);

  const onTagClick = (tag: string) => {
    setOverallActivityTimeInterval(tag);
  };

  const getEmptyState = (): {
    isEmpty: boolean;
    emptyStateContent: React.ReactElement | null;
  } => {
    if (getOverallActivitySnapshotStatus === "loading") {
      return {
        isEmpty: true,
        emptyStateContent: <Loading />,
      };
    }
    return {
      isEmpty: false,
      emptyStateContent: null,
    };
  };

  const { isEmpty, emptyStateContent } = getEmptyState();

  const weeklyCheckInTimeInterval = overallActivityTimeInterval
    .split("Past")[1]
    .trim()
    .toLowerCase();

  const userCount = Object.keys(usersById).length;

  return (
    <ActivityMonitorContainer
      title="Overall Activity"
      labelTags={Object.keys(OVERALL_ACTIVITY_TIME_RANGE_OPTIONS)}
      activeTag={overallActivityTimeInterval}
      onTagClick={onTagClick}
      boxProps={getMonitorPropsFromOverallActivitySnapshot(
        overallActivitySnapShot,
        weeklyCheckInTimeInterval,
        userCount,
        companyInfo?.companyAccountId
      )}
      footer={
        <Button
          variant="tertiary-blue"
          onClick={() => {
            navigate("/AdminConsole/Overall Activity");
          }}
          className="activity-monitor-container__footer-button"
        >
          View activity <FontAwesomeIcon icon={["fas", "arrow-right"]} />
        </Button>
      }
      timeInterval={weeklyCheckInTimeInterval}
      isEmpty={isEmpty}
      emptyStateContent={emptyStateContent}
    />
  );
};

export default OverallActivityCard;
