import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import Select, { components } from "react-select";
import { Team } from "app/containers/Global/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getAssessmentInformation,
  selectAssessmentInformation,
  selectDepartments,
} from "app/containers/AdminConsole/slice";
import { getOrganizationDepartments } from "app/containers/AdminConsole/helpers";
import ToggleBox from "app/storybookComponents/Forms/ToggleBox";

interface Props {
  teamInfo?: { teamName: string; departmentId?: number };
  modalShowing: null | "Name" | "Department" | "Who Can Invite";
  hideModal: () => void;
  onSave: (
    payload: Partial<Team> & { sendDepartmentTEAMScanInvitations?: boolean }
  ) => void;
  loading?: boolean;
}

export default function TeamSettingsModal({
  hideModal,
  modalShowing,
  teamInfo,
  onSave,
  loading,
}: Props) {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const filteredDepartments = getOrganizationDepartments(departments);
  const [teamName, setTeamName] = useState(teamInfo?.teamName || "");
  const [departmentId, setDepartmentId] = useState(teamInfo?.departmentId);
  const [
    sendDepartmentTEAMScanInvitations,
    setSendDepartmentTEAMScanInvitations,
  ] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (modalShowing) {
      dispatch(getAssessmentInformation());
    } else {
      setTeamName(teamInfo?.teamName || "");
      setDepartmentId(teamInfo?.departmentId);
    }
  }, [dispatch, modalShowing, teamInfo]);

  useEffect(() => {
    if (!assessmentInformation || !departmentId || !teamInfo) {
      return;
    }
    const newDepartmentAssessmentInformation =
      assessmentInformation?.departments?.[departmentId];
    if (!newDepartmentAssessmentInformation?.activeAssessment) {
      // If the department has no active assessment, we don't need to show the toggle box
      return setSendDepartmentTEAMScanInvitations(undefined);
    }

    setSendDepartmentTEAMScanInvitations(
      !!newDepartmentAssessmentInformation?.emailSentOnInitialInvite
    );
  }, [teamInfo, departmentId, assessmentInformation]);

  const getModalTitle = () => {
    switch (modalShowing) {
      case "Who Can Invite":
        return "Who can invite";
      case "Name":
      case "Department":
      default:
        return `Edit ${modalShowing}`;
    }
  };
  const getModalContent = () => {
    switch (modalShowing) {
      case "Name":
        return getTeamNameForm();
      case "Department":
        return getDepartmentForm();
      case "Who Can Invite":
        return getWhoCanInviteUserForm();
      default:
        return null;
    }
  };

  const getTeamNameForm = () => (
    <>
      <Form.Group>
        <Form.Label>Team Name</Form.Label>
        <Form.Control
          placeholder="Team's name"
          name="teamName"
          required
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </Form.Group>
    </>
  );

  const getDepartmentForm = () => {
    let value = null;
    const options = Object.values(filteredDepartments).map((department) => {
      if (departmentId === department.departmentId) {
        value = {
          value: departmentId,
          label: department.name,
        };
      }

      return {
        value: department.departmentId,
        label: department.name,
      };
    });

    return (
      <>
        <Form.Group>
          <Form.Label>Department</Form.Label>
          <Select
            components={{
              DropdownIndicator: (props: any) => (
                <components.DropdownIndicator {...props}>
                  <FontAwesomeIcon icon="caret-down" />
                </components.DropdownIndicator>
              ),
              IndicatorSeparator: () => null,
            }}
            value={value}
            options={options}
            isSearchable={false}
            onChange={(newValue) => {
              setDepartmentId(newValue?.value);
            }}
          />
        </Form.Group>
        {getDepartmentChangeToggleBox()}
      </>
    );
  };

  const getWhoCanInviteUserForm = () => {
    return (
      <Select
        noOptionsMessage={() => null}
        components={{
          DropdownIndicator: null,
        }}
        // inputValue={inputText}
        isClearable
        isMulti
        isSearchable
        // options={options}
        // onChange={(newValue) => setSelectValues(newValue)}
        // onInputChange={(newValue) => setInputText(newValue)}
        // onKeyDown={selectKeyDown}
        placeholder="Search by name or email"
        // value={selectValues}
        styles={{
          menu: (base: any) => ({
            ...base,
            marginTop: 0,
          }),
        }}
        // formatOptionLabel={(member) => {
        //   const memberInfo = memberMap.get(member.value);
        //   if (memberInfo) {
        //     return (
        //       <div className="member-option">
        //         {memberInfo.avatarCircle}
        //         <div className="member-info">
        //           <span className="member-name">{memberInfo.fullName}</span>
        //           <span className="member-email">
        //             {memberInfo.emailAddress}
        //           </span>
        //         </div>
        //       </div>
        //     );
        //   }
        //   return (
        //     <div>
        //       <span>{member.label}</span>
        //     </div>
        //   );
        // }}
      />
    );
  };

  const handleSave = () => {
    switch (modalShowing) {
      case "Name":
        return onSave({ teamName });
      case "Department":
        return onSave({ departmentId, sendDepartmentTEAMScanInvitations });
      default:
        return null;
    }
  };

  const getDepartmentChangeToggleBox = () => {
    // If the department is hidden, the department did not change we don't need to show the toggle box
    if (teamInfo?.departmentId === departmentId || !departmentId) {
      return null;
    }

    const newDepartmentAssessmentInformation =
      assessmentInformation?.departments?.[departmentId];

    if (newDepartmentAssessmentInformation?.activeAssessment) {
      return (
        <ToggleBox
          setToggle={() => {
            setSendDepartmentTEAMScanInvitations(
              !sendDepartmentTEAMScanInvitations
            );
          }}
          toggleLabel={
            <p>
              <b>This department currently has an active TEAMscan.</b> Send
              invitation emails to this team to complete the TEAMscan?
            </p>
          }
          toggleMessage={
            sendDepartmentTEAMScanInvitations
              ? "No invitations emails will be sent"
              : "An invitation email will be sent requesting team members to complete the TEAMscan survey."
          }
          toggleValue={!!sendDepartmentTEAMScanInvitations}
        />
      );
    }

    return null;
  };

  return (
    <Modal
      show={modalShowing !== null}
      onHide={hideModal}
      className="simple-modal"
      size="lg"
    >
      <div className="modal-title-row">
        <h2>{getModalTitle()}</h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none" }}
          xIcon
        />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        {getModalContent()}
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button type="submit" disabled={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
