import NavigateBackButton from "app/components/NavigateBackButton";
import SurveyBreakdownTable from "app/containers/AdminConsole/Tables/SurveyBreakdownTable";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const SurveyBreakdown = () => {
  const location = useLocation();

  const getBackButton = () => {
    if (location.key === "default") {
      return null;
    }
    return (
      <div style={{ marginBottom: "20px" }}>
        <NavigateBackButton />
      </div>
    );
  };

  return (
    <div className="page dashboard">
      {getBackButton()}
      <Card className="padding-20px">
        <SurveyBreakdownTable />
      </Card>
    </div>
  );
};

export default SurveyBreakdown;
