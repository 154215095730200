import { useCallback, useMemo, useState } from "react";
import Button from "app/storybookComponents/Button";
import { useAppSelector } from "utils/redux/hooks";
import { selectConfigCatFlag } from "app/containers/Global/slice";
import Loading from "app/storybookComponents/Loading";
import ActivityMonitorContainerEmptyContent from "app/components/ActivityMonitor/ActivityMonitorContainerEmptyContent";
import ActivityMonitorContainer from "app/components/ActivityMonitor/ActivityMonitorContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CHECK_IN_TIME_RANGE_OPTIONS } from "../constants";
import {
  getCoachBoWeeklyCheckInActivityReportToActivityMonitorBoxProps,
  getWeeklyCheckInActivityTimeIntervalKey,
  getWeeklyCheckinActivityLineChartData,
  getWeeklyCheckinActivityLineEmptyState,
} from "../helpers";
import { useNavigate } from "react-router-dom";
import SimpleLineChart from "app/storybookComponents/Charts/SimpleLineChart";
import ChartLegend from "app/storybookComponents/Charts/ChartLegend";
import { TooltipItem } from "chart.js";
import SimpleCollapsibleCard from "app/storybookComponents/CollapsibleCards/SimpleCollapsibleCard";
import { trackFeatureViewed } from "utils/trackingFunctions";
import CircularTabs from "app/storybookComponents/Tabs/CircularTabs";
import { CONFIG_CAT_FLAGS } from "app/components/GlobalNavbar/constants";
import { responseStatus } from "utils/types";
import {
  CoachBoWeeklyCheckInActivityReports,
  WeeklyCheckInLineChartState,
} from "../types";

interface Props {
  coachBoWeeklyCheckInActivityReports: CoachBoWeeklyCheckInActivityReports;
  getCoachBoWeeklyCheckInActivityReportStatus: responseStatus;
  showWeeklyCheckInActivityEmptyState: boolean;
  weeklyCheckInLineChartData: WeeklyCheckInLineChartState;
  fetchingWeeklyCheckInLineChartDataStatus: responseStatus;
  getShowWeeklyCheckInActivityEmptyStateStatus: responseStatus;
  viewActivityPath: string;
  weeklyCheckInActivityTimeInterval?: string;
  setWeeklyCheckInActivityTimeInterval?: (timeInterval: string) => void;
  lineChartTimeInterval?: string;
  setLineChartTimeInterval?: (timeInterval: string) => void;
  lineChartNavigationIndex?: number;
  setLineChartNavigationIndex?: (index: number) => void;
  onGetWeeklyCheckInLineChartData: ({
    timeInterval,
    index,
  }: {
    timeInterval: string;
    index: number;
  }) => void;
  headerCornerContent?: React.ReactNode;
}

const WeeklyCheckInActivitySnapshot = ({
  coachBoWeeklyCheckInActivityReports,
  getCoachBoWeeklyCheckInActivityReportStatus,
  showWeeklyCheckInActivityEmptyState,
  weeklyCheckInLineChartData,
  fetchingWeeklyCheckInLineChartDataStatus,
  getShowWeeklyCheckInActivityEmptyStateStatus,
  viewActivityPath,
  weeklyCheckInActivityTimeInterval = "Past 7 days",
  setWeeklyCheckInActivityTimeInterval = () => {},
  lineChartTimeInterval = "Past 30 days",
  setLineChartTimeInterval,
  lineChartNavigationIndex = 1,
  setLineChartNavigationIndex,
  onGetWeeklyCheckInLineChartData,
  headerCornerContent = null,
}: Readonly<Props>) => {
  const navigate = useNavigate();
  // ------------------------------- Selectors --------------------------------------------- //
  const weeklyCheckInConfigCatFlag = useAppSelector(
    selectConfigCatFlag(CONFIG_CAT_FLAGS.weeklyCheckIn.savedFlagName)
  );
  // ------------------------------------- Component States ------------------------------------- //
  const [isLineChartCollapsed, setIsLineChartCollapsed] = useState(false);

  // ------------------------------------- Memo States ------------------------------------- //
  const activeCoachBoWeeklyCheckInActivityReport = useMemo(() => {
    const activeKey =
      CHECK_IN_TIME_RANGE_OPTIONS[weeklyCheckInActivityTimeInterval];

    return coachBoWeeklyCheckInActivityReports[activeKey];
  }, [weeklyCheckInActivityTimeInterval, coachBoWeeklyCheckInActivityReports]);

  const activeCoachBoCheckInLineChartData = useMemo(() => {
    const dataKey = getWeeklyCheckInActivityTimeIntervalKey(
      lineChartTimeInterval
    );
    return (
      dataKey && weeklyCheckInLineChartData[dataKey]?.[lineChartNavigationIndex]
    );
  }, [
    weeklyCheckInLineChartData,
    lineChartTimeInterval,
    lineChartNavigationIndex,
  ]);

  // ------------------------------------- Render ------------------------------------- //

  const getActionButton = () => {
    const { completionInfo } = activeCoachBoWeeklyCheckInActivityReport ?? {};
    if (!completionInfo?.completedCount && !completionInfo?.totalCount) {
      return (
        <div className="warning-banner light-blue border-0">
          <p>
            No Check-Ins received yet. Please check back in <b>5 days.</b> Once
            responses are received, score averages will be available here.
          </p>
        </div>
      );
    }

    return (
      <Button
        variant="tertiary-blue"
        onClick={() => {
          navigate(viewActivityPath);
        }}
        className="activity-monitor-container__footer-button"
      >
        View activity <FontAwesomeIcon icon={["fas", "arrow-right"]} />
      </Button>
    );
  };

  const getToolTipBody = useCallback(
    (tooltipItems: TooltipItem<"line">[]) => {
      const index = tooltipItems[0].dataIndex;
      const countArray = activeCoachBoCheckInLineChartData?.counts ?? [];
      const count = countArray[index] ?? 0;
      return `Number of responses: ${count}\nScore: ${tooltipItems[0].formattedValue}`;
    },
    [activeCoachBoCheckInLineChartData]
  );

  const onLineChartNavigate = useCallback(
    (newIndex: number) => {
      const index = Math.max(1, newIndex);
      setLineChartNavigationIndex?.(index);
      const dataKey = getWeeklyCheckInActivityTimeIntervalKey(
        lineChartTimeInterval
      );

      if (dataKey) {
        onGetWeeklyCheckInLineChartData({
          timeInterval: dataKey,
          index,
        });
      }
    },
    [
      lineChartTimeInterval,
      onGetWeeklyCheckInLineChartData,
      setLineChartNavigationIndex,
    ]
  );

  const getLineChartNavigation = useCallback(() => {
    const labelTags = ["Past 30 days", "Past 365 days"];
    const activeTag = lineChartTimeInterval;
    return (
      <div style={{ marginTop: "-20px" }}>
        <CircularTabs
          tabs={labelTags.map((tag) => ({ label: tag }))}
          selectedTab={activeTag ?? undefined}
          onTabSelected={(tag) => {
            setLineChartTimeInterval?.(tag);
            setLineChartNavigationIndex?.(1);
            const dataKey = getWeeklyCheckInActivityTimeIntervalKey(tag);
            if (dataKey) {
              onGetWeeklyCheckInLineChartData({
                timeInterval: dataKey,
                index: 1,
              });
            }
          }}
        />
      </div>
    );
  }, [
    lineChartTimeInterval,
    setLineChartNavigationIndex,
    setLineChartTimeInterval,
    onGetWeeklyCheckInLineChartData,
  ]);

  const lineChart = useMemo(() => {
    if (fetchingWeeklyCheckInLineChartDataStatus === "loading") {
      return <Loading />;
    }
    const lineChartData = getWeeklyCheckinActivityLineChartData(
      activeCoachBoCheckInLineChartData
    );

    if (!lineChartData || lineChartData.hasAtLeastTwoDataPoints === false) {
      return (
        <>
          {getLineChartNavigation()}
          {getWeeklyCheckinActivityLineEmptyState(
            lineChartTimeInterval,
            lineChartData?.hasAtLeastTwoDataPoints === false
          )}
        </>
      );
    }
    const { dataValues, labels } = lineChartData;
    const { hasNextTimePeriodData, hasPreviousTimePeriodData } =
      activeCoachBoCheckInLineChartData ?? {};

    return (
      <>
        {getLineChartNavigation()}
        <SimpleLineChart
          dataValues={dataValues}
          labels={labels}
          canvasId="weekly-check-in-activity-line-chart"
          maxYScale={5.1}
          stepSize={1}
          tooltipCallbacks={{
            title: () => "",
            label: () => "",
            afterBody: getToolTipBody,
          }}
        />
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary-blue"
            disabled={!hasPreviousTimePeriodData}
            onClick={() => {
              onLineChartNavigate(lineChartNavigationIndex + 1);
            }}
          >
            <FontAwesomeIcon icon="arrow-left" className="me-2" />
            Previous
          </Button>
          <ChartLegend
            labels={["Average Mood", "Average Progress", "Average Readiness"]}
          />
          <Button
            variant="secondary-blue"
            disabled={!hasNextTimePeriodData}
            onClick={() => {
              onLineChartNavigate(lineChartNavigationIndex - 1);
            }}
          >
            Next
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </div>
      </>
    );
  }, [
    lineChartNavigationIndex,
    lineChartTimeInterval,
    activeCoachBoCheckInLineChartData,
    fetchingWeeklyCheckInLineChartDataStatus,
    getLineChartNavigation,
    getToolTipBody,
    onLineChartNavigate,
  ]);

  const getEmptyState = (): {
    isEmpty: boolean;
    emptyStateContent: React.ReactElement;
  } => {
    if (
      getCoachBoWeeklyCheckInActivityReportStatus === "loading" ||
      getShowWeeklyCheckInActivityEmptyStateStatus === "loading"
    ) {
      return {
        isEmpty: true,
        emptyStateContent: <Loading />,
      };
    }

    return {
      isEmpty: showWeeklyCheckInActivityEmptyState,
      emptyStateContent: (
        <ActivityMonitorContainerEmptyContent
          title="Start Weekly Check-Ins"
          button={
            <Button variant="secondary-blue" className="white-button">
              See how it works
            </Button>
          }
          description="AI-powered weekly check-ins enable managers to get summarized Monday reports on team mood, status, and blockers while tracking trends over time and highlighting key conversations to have."
        />
      ),
    };
  };

  const getFooter = () => (
    <>
      <SimpleCollapsibleCard
        className="column-gap-20px"
        title={
          <p className="grey-text fw-bold">
            Mood, Progress, and Readiness Over Time
          </p>
        }
        isOpen={!isLineChartCollapsed}
        setIsOpen={() => {
          setIsLineChartCollapsed(!isLineChartCollapsed);
          trackFeatureViewed("Weekly Check-In Activity Line Chart", {
            timeInterval: weeklyCheckInActivityTimeInterval,
            location: "dashboard",
          });
        }}
        collapseText="Hide chart"
        expandText="Show chart"
      >
        <div className="column-gap-20px">{lineChart}</div>
      </SimpleCollapsibleCard>
      {getActionButton()}
    </>
  );

  const onTagClick = (tag: string) => {
    setWeeklyCheckInActivityTimeInterval(tag);
    setLineChartNavigationIndex?.(1);
    const dataKey = getWeeklyCheckInActivityTimeIntervalKey(tag);
    if (dataKey) {
      onGetWeeklyCheckInLineChartData({ timeInterval: dataKey, index: 1 });
    }
  };

  const weeklyCheckInTimeInterval = weeklyCheckInActivityTimeInterval
    .split("Past")[1]
    .trim()
    .toLowerCase();

  const { isEmpty, emptyStateContent } = getEmptyState();

  if (!weeklyCheckInConfigCatFlag) {
    return null;
  }

  return (
    <ActivityMonitorContainer
      title="Weekly Check-In Activity"
      labelTags={Object.keys(CHECK_IN_TIME_RANGE_OPTIONS)}
      activeTag={weeklyCheckInActivityTimeInterval}
      onTagClick={onTagClick}
      boxProps={getCoachBoWeeklyCheckInActivityReportToActivityMonitorBoxProps(
        activeCoachBoWeeklyCheckInActivityReport,
        weeklyCheckInTimeInterval
      )}
      footer={getFooter()}
      timeInterval={weeklyCheckInTimeInterval}
      titleTag={
        <div>
          <FontAwesomeIcon icon={["far", "sparkles"]} />
          <span className="label-tag info-sapphire">Powered by AI</span>
        </div>
      }
      isEmpty={isEmpty}
      emptyStateContent={emptyStateContent}
      headerCornerContent={headerCornerContent}
    />
  );
};

export default WeeklyCheckInActivitySnapshot;
