import { FreeTextModuleTemplate } from "app/components/Modules/types";
import { useState, ReactElement } from "react";
import { Form, Card } from "react-bootstrap";
import Toggle from "app/components/Toggle";

interface Props extends FreeTextModuleTemplate {
  onSave: (
    section: string,
    title: string,
    moduleType: string,
    moduleContent: string,
    moduleTemplateId: number,
    link: string
  ) => void;
  getSubmitButton: (
    talentInsightsModuleId?: number,
    isDisabled?: boolean
  ) => ReactElement;
  moduleContent?: string;
  talentInsightsModuleId?: number;
  link?: string;
}

export default function FreeTextModule({
  placeholder,
  description,
  shortDescription,
  characterLimit,
  onSave,
  section,
  title,
  moduleTemplateId,
  moduleContent,
  getSubmitButton,
  talentInsightsModuleId,
  link,
}: Readonly<Props>) {
  const [inputText, setInputText] = useState(moduleContent || "");
  const [hyperLink, setHyperLink] = useState(link || "");
  const [linkToggled, setLinkToggled] = useState(!!link);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSave(
          section,
          title,
          "Free Text",
          inputText,
          moduleTemplateId,
          linkToggled ? hyperLink : ""
        );
      }}
    >
      <Card className="module-form-card column-gap-16px">
        <Form.Group>
          <Form.Label
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            <strong>{description || shortDescription}</strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={`e.g. ${placeholder || "Placeholder"}`}
            value={inputText}
            onChange={(event) =>
              setInputText(event.target.value.slice(0, Number(characterLimit)))
            }
          />
          <Form.Text className="text-muted">
            {inputText.length}/{characterLimit} Characters
          </Form.Text>
        </Form.Group>
        <Form.Group className="column-gap-16px">
          <div className="d-flex">
            <span
              className="me-2"
              style={{ color: "#53565A", fontSize: "14px", fontWeight: "bold" }}
            >
              Add Link
            </span>
            <Toggle
              isOn={linkToggled}
              handleToggle={() => setLinkToggled(!linkToggled)}
            />
          </div>
          {linkToggled ? (
            <Form.Control
              placeholder="https://www."
              value={hyperLink}
              onChange={(e) => setHyperLink(e.target.value)}
            />
          ) : null}
        </Form.Group>
      </Card>
      {getSubmitButton(talentInsightsModuleId, !inputText)}
    </Form>
  );
}
