import Button from "app/storybookComponents/Button";
import TextInput from "app/storybookComponents/Forms/TextInput";
import WarningBanner from "app/storybookComponents/WarningBanner";
import LoginHeader from "./LoginHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { faKey } from "@fortawesome/pro-regular-svg-icons";
import { Form, InputGroup } from "react-bootstrap";
import { getEyeSlashed, getInputPlaceholder, getInputType } from "./helpers";
import { responseStatus } from "utils/types";
import { LOGIN_ERRORS } from "./constants";

interface LoginV2Props {
  emailError?: string;
  identityProviderStatus?: responseStatus;
  loginUserStatus?: responseStatus;
  onEmailSubmit: (email: string) => void;
  onPasswordSubmit: (email: string, password: string) => void;
  onResetPassword: (email: string) => void;
  onResetPasswordResetStatus: () => void;
  onSSOSubmit: (email: string) => void;
  onUnCheckEmail: () => void;
  resetPasswordError: () => void;
  resetPasswordStatus?: responseStatus;
  ssoError?: string;
  ssoProvider?: string;
  ssoSetting?: "hard" | "soft" | "none";
  loginError?: number | null;
}

export default function LoginV2({
  emailError,
  identityProviderStatus,
  loginUserStatus,
  onEmailSubmit,
  onPasswordSubmit,
  onResetPassword,
  onResetPasswordResetStatus,
  onSSOSubmit,
  onUnCheckEmail,
  resetPasswordError,
  resetPasswordStatus,
  ssoProvider = "Okta",
  ssoSetting = "none",
  loginError,
}: Readonly<LoginV2Props>) {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onShowInitialLogin = (defaultEmail: string = "") => {
    setEmailAddress(defaultEmail);
    setPassword("");
    resetPasswordError();
    onUnCheckEmail();
  };

  const getEmailField = (editable?: boolean) => {
    if (editable) {
      return (
        <>
          <TextInput
            controlId="emailAddress"
            inputText={emailAddress}
            onTextChange={setEmailAddress}
            inputLabel="Email"
            placeholder="name@example.com"
            hideLength
            type="email"
            className="login-input-field"
          />
          {emailError ? (
            <WarningBanner>
              <p>{emailError}</p>
            </WarningBanner>
          ) : null}
        </>
      );
    }

    // if on step two we should return the field but should not allow the user to change it
    // on the right there will be abutton that says Log in as someone else which will reset the state
    return (
      <div className="email-field-filled">
        <p>{emailAddress}</p>
        <Button
          variant="link"
          onClick={() => {
            onShowInitialLogin();
          }}
        >
          Log in as someone else
        </Button>
      </div>
    );
  };

  const getWarningBanner = () => {
    if (loginUserStatus !== "failed") {
      return null;
    }
    if (loginError === 1) {
      return (
        <WarningBanner type="light-red" className="border-0">
          <p>
            Sorry, that password isn't right. We can help you{" "}
            <u
              onClick={() => {
                setPasswordReset(true);
              }}
              style={{ cursor: "pointer" }}
            >
              reset your password
            </u>
            .
          </p>
        </WarningBanner>
      );
    }

    return (
      <WarningBanner type="light-red" className="border-0">
        <p>{LOGIN_ERRORS[loginError ?? 0] ?? LOGIN_ERRORS[0]}</p>
      </WarningBanner>
    );
  };

  const getPasswordField = () => {
    const isUserLoginLoading = loginUserStatus === "loading";
    const loginFailed = loginUserStatus === "failed";
    return (
      <>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              placeholder={getInputPlaceholder(showPassword)}
              type={getInputType(showPassword)}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                resetPasswordError();
              }}
              disabled={isUserLoginLoading}
              className={`login-input-field ${loginFailed ? "has-error" : ""} ${
                password ? "" : "empty"
              }`}
            />
            <InputGroup.Text>
              <button
                onClick={() => setShowPassword(!showPassword)}
                className="no-style-button"
              >
                <FontAwesomeIcon icon={["far", getEyeSlashed(showPassword)]} />
              </button>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <div style={{ marginTop: "-20px" }}>
          <Button variant="link" onClick={() => setPasswordReset(true)}>
            <p>Forgot my password</p>
          </Button>
        </div>
        {getWarningBanner()}
        <Button
          onClick={() => onPasswordSubmit(emailAddress, password)}
          disabled={!password || isUserLoginLoading}
        >
          <p>
            Log in <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </p>
        </Button>
      </>
    );
  };

  const getForgotPasswordContent = () => (
    <>
      <p className="forgot-password-description-text">
        Enter your email address below to receive an email containing a link to
        reset your password.
      </p>
      {getEmailField(true)}
      {resetPasswordStatus === "succeeded" ? (
        <div
          className="warning-banner light-blue border-0"
          style={{ marginBottom: "10px", padding: "12px 16px" }}
        >
          <p>
            If your account exist in our database, we'll email you a reset link.
          </p>
        </div>
      ) : (
        <Button
          disabled={!emailAddress || resetPasswordStatus === "loading"}
          onClick={() => {
            setPassword("");
            onResetPassword(emailAddress);
          }}
        >
          Reset Password
        </Button>
      )}
      <Button
        variant="secondary-blue"
        className="border-0"
        style={{ marginTop: "-10px" }}
        onClick={() => {
          setPasswordReset(false);
          onResetPasswordResetStatus();
          onShowInitialLogin(emailAddress);
        }}
      >
        Back to Login
      </Button>
    </>
  );

  const getSSOContent = () => (
    <>
      {getEmailField()}
      <Button onClick={() => onSSOSubmit(emailAddress)}>
        <p>Log in with {ssoProvider}</p>
      </Button>
      <p className="grey-text text-center">
        <FontAwesomeIcon icon={faKey} className="me-2" />
        Your company uses Single Sign On (SSO) via {ssoProvider}
      </p>

      {ssoSetting === "soft" ? (
        <>
          <div className="OR-section">
            <hr className="m-0" />
            <p>OR</p>
            <hr className="m-0" />
          </div>
          {getPasswordField()}
        </>
      ) : null}
    </>
  );

  const getNoEmailCheckedContent = () => (
    <>
      {getEmailField(true)}
      <Button
        disabled={!emailAddress || identityProviderStatus === "loading"}
        onClick={() => {
          onEmailSubmit(emailAddress);
        }}
      >
        Continue
        <FontAwesomeIcon icon="arrow-right" className="ms-2" />
      </Button>
    </>
  );

  const getContent = () => {
    if (passwordReset) {
      return getForgotPasswordContent();
    }

    // First we are going to look if the email has already been checked, if not then we should only be showing the email field
    if (identityProviderStatus !== "succeeded") {
      return getNoEmailCheckedContent();
    }

    if (ssoSetting !== "none") {
      return getSSOContent();
    }

    // If email already check, then we will check the sso setting
    // If SSO is not enabled then we just show the email and password fields
    return (
      <>
        {getEmailField()}
        {getPasswordField()}
      </>
    );
  };

  const getLoginHeader = () => {
    if (passwordReset) {
      return <LoginHeader title="Forgot your password?" />;
    }
    return (
      <LoginHeader
        title="Unlock your team's potential"
        subTitle={
          <span>
            <strong>with Develop</strong> by Criteria
          </span>
        }
      />
    );
  };

  return (
    <>
      {getLoginHeader()}
      {getContent()}
    </>
  );
}
