import React, { useEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import { NavProp, SideNavigationProps } from "./types";
import { getNavIcon } from "./helpers";

interface Props extends SideNavigationProps {
  isResponsive?: boolean;
}
interface NestedClicked {
  [nestedCount: number]: {
    [navTitle: string]: boolean;
  };
}

const SimpleSideNavigation: React.FC<Props> = ({
  footer = null,
  header = null,
  navs = [],
  isResponsive,
  setNavSelected,
  navSelected = [],
  navStartIndex = 0,
}) => {
  const [isNested, setIsNested] = useState(false);
  const [nestedClicked, setNestedClicked] = useState<NestedClicked>({});

  useEffect(() => {
    const nested = navs.some((nav) => nav.nestedNavs);
    setIsNested(nested);
  }, [navs]);

  useEffect(() => {
    if (navSelected.length === 0) {
      setNestedClicked({});
    }
    const nestedClickedObject: NestedClicked = {};
    navSelected.forEach((nav, idx) => {
      nestedClickedObject[idx] = {
        [nav]: true,
      };
    });
    setNestedClicked(nestedClickedObject);
  }, [navSelected]);

  // ----------------------------- Functions -----------------------------------

  const onNavClick = (
    nav: NavProp,
    nestedCount: number,
    isSelected: boolean
  ) => {
    setNavSelected?.(nav.title);
    nav.onClick?.();
    if (nav.nestedNavs) {
      setNestedClicked((prev) => ({
        ...prev,
        [nestedCount]: {
          ...prev[nestedCount],
          [nav.title]: isSelected
            ? !nestedClicked[nestedCount]?.[nav.title]
            : true,
        },
      }));
    }
  };

  const getNavsElms = (navs: NavProp[], nestedCount = 0) => (
    <div className={`nested-container nested-count-${nestedCount}`}>
      {navs.map((nav, idx) => {
        const isNavSelected = navSelected[nestedCount] === nav.title;
        const hasNested = !!nav.nestedNavs;

        return (
          <>
            <Button
              key={nav.title}
              className={`nav-item ${
                isNavSelected && navSelected.length === nestedCount + 1
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                onNavClick(nav, nestedCount, isNavSelected);
              }}
              variant="secondary-blue"
            >
              <p>{nav.title}</p>
              {getNavIcon(
                nestedCount,
                hasNested,
                isNavSelected && nestedClicked[nestedCount]?.[nav.title]
              )}
            </Button>
            {isNavSelected &&
              nav.nestedNavs &&
              nestedClicked[nestedCount]?.[nav.title] && (
                <div className="nested-navs">
                  {getNavsElms(nav.nestedNavs, nestedCount + 1)}
                </div>
              )}
            {isNested && nestedCount === 0 && idx !== navs.length - 1 && (
              <hr className="m-0" />
            )}
          </>
        );
      })}
    </div>
  );

  const getClassName = () => {
    let baseClassName = "simple-side-nav";

    // this is used to hide the side navigation on mobile
    if (isResponsive) {
      baseClassName += " responsive";
    }

    if (isNested) {
      baseClassName += " nested";
    }

    return baseClassName;
  };

  // ----------------------------- Render -----------------------------------
  return (
    <div className={getClassName()}>
      {header}
      <div className="nav-container">{getNavsElms(navs, navStartIndex)}</div>
      {footer}
    </div>
  );
};

export default SimpleSideNavigation;
