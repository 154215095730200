import { Dropdown } from "react-bootstrap";

interface Props {
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

const TableDropdownMenu = ({ children, styles }: Readonly<Props>) => {
  return (
    <Dropdown.Menu
      renderOnMount
      popperConfig={{
        strategy: "fixed",
      }}
      style={styles}
    >
      {children}
    </Dropdown.Menu>
  );
};

export default TableDropdownMenu;
