import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request, simpleFetch } from "utils/request";
import { getUser } from "app/containers/Global/slice";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { trackEvent, segmentIdentifyUser } from "utils/trackingFunctions";
import { IdentityProviderResponse } from "./types";
import { setUserToken } from "utils/helperFunctions";

// ------------------ State Type/Structure ------------------
export interface LoginState {
  identityProvidedResponse: IdentityProviderResponse | null;
  gettingLoginStatus: responseStatus;
  resetPasswordLinkStatus: responseStatus;
  updatingUserPasswordStatus?: responseStatus;
  identityProviderStatus?: responseStatus;
  hideInviteViaLinkOption?: boolean;
  loginError: null | number;
}

// ------------------ InitialState ------------------
const initialState: LoginState = {
  identityProvidedResponse: null,
  gettingLoginStatus: "idle",
  resetPasswordLinkStatus: "idle",
  updatingUserPasswordStatus: "idle",
  identityProviderStatus: "idle",
  hideInviteViaLinkOption: false,
  loginError: null,
};

// ------------------ Asynchronous API calls ------------------
export const logUserIn = createAsyncThunk(
  "login/logUserIn",
  async (
    payload: { emailAddress: string; password: string },
    { dispatch, rejectWithValue }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/login`;
    const updateUserInfoResponse = (await simpleFetch(
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
      null
    )) as
      | {
          data: {
            userAccountId: number;
          };
          jwt: string;
        }
      | {
          error: number;
        };

    if ("error" in updateUserInfoResponse) {
      return rejectWithValue(updateUserInfoResponse.error);
    }

    setUserToken(updateUserInfoResponse.jwt);
    dispatch(getUser(updateUserInfoResponse?.data?.userAccountId));
    segmentIdentifyUser(updateUserInfoResponse?.data?.userAccountId);
    trackEvent("User Logged In");
    dispatch(trackLogin());
    return updateUserInfoResponse;
  }
);

export const trackLogin = createAsyncThunk("login/trackLogin", async () => {
  const requestUrl = `${REACT_APP_API_URL}/talentInsights/trackDevelopLogon`;
  return await request(requestUrl);
});

export const resetPasswordLink = createAsyncThunk(
  "login/resetPasswordLink",
  async (emailAddress: string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/resetPasswordLink`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        emailAddress,
        action: "reset",
      }),
    })) as string;
  }
);

export const updateUsersPassword = createAsyncThunk(
  "login/updateUsersPassword",
  async (payload: { tk1: string; tk2: string; password: string }) => {
    const requestUrl = `${REACT_APP_API_URL}/doResetPasswordLink`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as string;
  }
);

export const getIdentityProvider = createAsyncThunk(
  "login/getIdentityProvider",
  async (payload: { emailAddress: string }) => {
    const requestUrl = `${REACT_APP_API_URL}/identityProvider`;

    const response = (await simpleFetch(
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
      null
    )) as IdentityProviderResponse;

    return response;
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const loginReducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetPasswordLinkStatus: (state) => {
      state.resetPasswordLinkStatus = "idle";
    },
    resetIdentityProviderStatus: (state) => {
      state.identityProviderStatus = "idle";
      state.identityProvidedResponse = null;
    },
    resetLoginStatus: (state) => {
      state.gettingLoginStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logUserIn.pending, (state) => {
        state.gettingLoginStatus = "loading";
      })
      .addCase(logUserIn.fulfilled, (state) => {
        return {
          ...state,
          gettingLoginStatus: "succeeded",
        };
      })
      .addCase(logUserIn.rejected, (state, action) => {
        const error = action.payload as number;
        state.loginError = error;
        state.gettingLoginStatus = "failed";
      })
      .addCase(resetPasswordLink.pending, (state) => {
        state.resetPasswordLinkStatus = "loading";
      })
      .addCase(resetPasswordLink.fulfilled, (state) => {
        state.resetPasswordLinkStatus = "succeeded";
      })
      .addCase(resetPasswordLink.rejected, (state) => {
        state.resetPasswordLinkStatus = "failed";
      })
      .addCase(updateUsersPassword.pending, (state) => {
        state.updatingUserPasswordStatus = "loading";
      })
      .addCase(updateUsersPassword.fulfilled, (state) => {
        state.updatingUserPasswordStatus = "succeeded";
      })
      .addCase(updateUsersPassword.rejected, (state) => {
        state.updatingUserPasswordStatus = "failed";
      })
      .addCase(getIdentityProvider.pending, (state) => {
        state.identityProviderStatus = "loading";
      })
      .addCase(
        getIdentityProvider.fulfilled,
        (state, { payload }: PayloadAction<IdentityProviderResponse>) => {
          state.identityProviderStatus = "succeeded";
          state.identityProvidedResponse = payload;
          state.hideInviteViaLinkOption = payload?.identityProviderMode === 2;
        }
      )
      .addCase(getIdentityProvider.rejected, (state) => {
        state.identityProviderStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectGetLoginStatus = (state: RootState) =>
  state.login.gettingLoginStatus;
export const selectResetPasswordLinkStatus = (state: RootState) =>
  state.login.resetPasswordLinkStatus;
export const selectUpdatePasswordStatus = (state: RootState) =>
  state.login.updatingUserPasswordStatus;
export const selectIdentityProviderStatus = (state: RootState) =>
  state.login.identityProviderStatus;
export const selectIdentityProviderResponse = (state: RootState) =>
  state.login.identityProvidedResponse;
export const selectHideInviteViaLinkOption = (state: RootState) =>
  state.login.hideInviteViaLinkOption;
export const selectLoginError = (state: RootState) => state.login.loginError;

// ------------------ Actions ------------------
export const {
  resetState,
  resetPasswordLinkStatus,
  resetIdentityProviderStatus,
  resetLoginStatus,
} = loginReducer.actions;

export default loginReducer.reducer;
