import SimpleModal from "app/components/Modals/SimpleModal";
import {
  selectAllWeeklyCheckInTableData,
  selectCheckInResponseSummaries,
  getCheckInResponseSummary,
  selectGetCheckInResponseSummaryStatus,
} from "./slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import AvatarCircle from "app/components/AvatarCircle";
import { useEffect, useMemo } from "react";
import Loading from "app/storybookComponents/Loading";
import { getOutOfFiveColor } from "app/containers/Dashboard/helpers";
import { isWeeklyCheckInRowUser, UserWeeklyCheckInTableRowData } from "./types";
import { getWeeklyCheckInTableRowEntityId } from "./helpers";
import { trackModalOpened } from "utils/trackingFunctions";
import { Card } from "react-bootstrap";

interface CheckInResponseSummaryModalProps {
  onHide: () => void;
  show: boolean;
  threadId?: string;
  userAccountId?: number | null;
}

const CheckInResponseSummaryModal = ({
  onHide,
  show,
  threadId,
  userAccountId,
}: Readonly<CheckInResponseSummaryModalProps>) => {
  const dispatch = useAppDispatch();
  const allWeeklyCheckInTableData = useAppSelector(
    selectAllWeeklyCheckInTableData
  );
  const getCheckInResponseSummaryStatus = useAppSelector(
    selectGetCheckInResponseSummaryStatus
  );
  const checkInResponseSummaries = useAppSelector(
    selectCheckInResponseSummaries
  );
  const usersById = useAppSelector(selectAllCompanyUsersById);

  const foundCheckInData = useMemo(
    () =>
      allWeeklyCheckInTableData?.allTime?.user?.find(
        (checkIn) =>
          isWeeklyCheckInRowUser(checkIn) && checkIn?.threadId === threadId
      ) as UserWeeklyCheckInTableRowData | undefined,
    [allWeeklyCheckInTableData, threadId]
  );
  const foundSummary = threadId ? checkInResponseSummaries[threadId] : null;

  useEffect(() => {
    if (threadId) {
      dispatch(getCheckInResponseSummary(threadId));
    }
  }, [dispatch, threadId]);

  useEffect(() => {
    if (show) {
      trackModalOpened("Check-In Response Summary");
    }
  }, [show]);

  const getFormattedDate = (date?: string) => {
    if (!date) {
      return "";
    }

    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  const getUserInfo = () => {
    const entityId =
      foundCheckInData && getWeeklyCheckInTableRowEntityId(foundCheckInData);
    const foundUser = entityId ? usersById[entityId] : null;

    if (!foundUser) {
      return null;
    }
    const { userAccountId, firstName, lastName, jobTitle } = foundUser;
    const fullName = `${firstName} ${lastName}`.trim();
    return (
      <div className="row-gap-8px">
        <AvatarCircle userAccountId={userAccountId} />
        <div>
          <p className="fw-bold">{fullName}</p>
          <p className="small-grey-text">{jobTitle}</p>
        </div>
      </div>
    );
  };

  const getScoreSpan = (label: string, score?: number | null) => {
    const color = getOutOfFiveColor(score ?? 0);
    return (
      <div className={`out-of-5-span-tag ${color}`}>
        <p>
          {label}: {score ?? "N/A"}
        </p>
      </div>
    );
  };

  const getAllScores = () => {
    if (!foundCheckInData) {
      return null;
    }
    return (
      <div className="row-gap-16px">
        {getScoreSpan("Mood score", foundCheckInData.moodScore)}
        {getScoreSpan("Progress score", foundCheckInData.progressScore)}
        {getScoreSpan("Readiness score", foundCheckInData.readinessScore)}
      </div>
    );
  };

  const getMoreInsights = (moreInsights?: string | null) => {
    if (!moreInsights) {
      return null;
    }

    return (
      <div className="column-gap-4px">
        <p className="grey-text">More Insights:</p>
        <p>{moreInsights}</p>
      </div>
    );
  };

  const getWinSpotlight = () => {
    if (
      !foundSummary?.winSpotlight ||
      !(
        foundSummary?.winSpotlight?.achievement &&
        foundSummary?.winSpotlight?.impact &&
        foundSummary?.winSpotlight?.recognition
      )
    ) {
      return null;
    }
    const magnitude = foundSummary?.winSpotlight?.magnitude ?? 0;
    const color = getOutOfFiveColor(magnitude / 2);

    return (
      <div className="column-gap-4px">
        <p className="grey-text">Win Spotlight:</p>
        <ul className="check-in-response-list">
          <li>Achievement: {foundSummary?.winSpotlight?.achievement}</li>
          <li>Impact: {foundSummary?.winSpotlight?.impact}</li>
          <li>Recognition: {foundSummary?.winSpotlight?.recognition}</li>
        </ul>
        <div className="d-flex">
          <div className={`out-of-5-span-tag ${color}`}>
            <p>Magnitude: {magnitude} out of 10</p>
          </div>
        </div>
      </div>
    );
  };

  const getIssueSpotlight = () => {
    if (
      !foundSummary?.issueSpotlight ||
      !(
        foundSummary?.issueSpotlight?.issue &&
        foundSummary?.issueSpotlight?.impact &&
        foundSummary?.issueSpotlight?.action
      )
    ) {
      return null;
    }
    const severityScore = foundSummary?.issueSpotlight?.severity ?? 0;
    const color = getOutOfFiveColor(severityScore / 2, true);

    return (
      <div className="column-gap-4px">
        <p className="grey-text">Issue Spotlight:</p>
        <ul className="check-in-response-list">
          <li>Issue: {foundSummary?.issueSpotlight?.issue}</li>
          <li>Impact: {foundSummary?.issueSpotlight?.impact}</li>
          <li>Action: {foundSummary?.issueSpotlight?.action}</li>
        </ul>
        <div className="d-flex">
          <div className={`out-of-5-span-tag ${color}`}>
            <p>Severity: {severityScore} out of 10</p>
          </div>
        </div>
      </div>
    );
  };

  const getConversationStarters = () => {
    if (!foundSummary?.conversationStarters?.length) {
      return null;
    }

    return (
      <Card
        className="column-gap-4px"
        style={{
          backgroundColor: "#F5F7FC",
          padding: "16px 24px",
        }}
      >
        <p className="grey-text">Conversation Starters:</p>
        <ul className="check-in-response-list">
          {foundSummary?.conversationStarters.map((starter) => (
            <li key={starter}>{starter}</li>
          ))}
        </ul>
      </Card>
    );
  };

  const getContent = () => {
    if (getCheckInResponseSummaryStatus === "loading") {
      return <Loading />;
    }
    const { completedDate } =
      (foundCheckInData as UserWeeklyCheckInTableRowData) ?? {};

    return (
      <>
        <hr className="m-0" />
        <p>Check-In completed by</p>
        {getUserInfo()}
        {getAllScores()}
        <div className="column-gap-16px">
          <div className="column-gap-4px">
            <p className="grey-text">Response Summary:</p>
            <p>{foundSummary?.primaryTheme}</p>
          </div>
          {getMoreInsights(foundSummary?.secondaryTheme)}
          {getWinSpotlight()}
          {getIssueSpotlight()}
          {getConversationStarters()}
        </div>
        <hr className="m-0" />
        <p className="grey-text">
          This Weekly Check-In was completed on{" "}
          {getFormattedDate(completedDate)}
        </p>
      </>
    );
  };

  const getModalTitle = () => {
    const user = userAccountId ? usersById[userAccountId] : null;
    if (!user) {
      return "Check-In Response Summary";
    }

    const { firstName } = user;
    const completedDate = foundCheckInData?.completedDate ?? "";
    const dateObj = new Date(completedDate);
    const date = dateObj.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
    return `${firstName}'s ${date} Check-in`;
  };

  return (
    <SimpleModal show={show} onHide={onHide} title={getModalTitle()}>
      {getContent()}
    </SimpleModal>
  );
};

export default CheckInResponseSummaryModal;
