import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getWeeklyCheckInTableData,
  selectAllWeeklyCheckInTableData,
  selectGetWeeklyCheckInTableDataStatus,
  selectRegradeWeeklyCheckinStatus,
} from "../slice";
import { useCallback, useEffect } from "react";
import { trackFeatureViewed } from "utils/trackingFunctions";
import CheckInActivityTable from "./CheckInActivityTable";

const AdminCheckinActivityTable = () => {
  const dispatch = useAppDispatch();
  const fetchingWeeklyCheckInTableDataStatus = useAppSelector(
    selectGetWeeklyCheckInTableDataStatus
  );

  const allWeeklyCheckInTableData = useAppSelector(
    selectAllWeeklyCheckInTableData
  );
  const regradingWeeklyCheckinStatus = useAppSelector(
    selectRegradeWeeklyCheckinStatus
  );

  useEffect(() => {
    trackFeatureViewed("Weekly Check-In Activity Table (Admin)");
  }, []);

  const fetchTableData = useCallback(
    (params: { entityType: string; timeInterval: string }) =>
      dispatch(getWeeklyCheckInTableData(params)),
    [dispatch]
  );

  return (
    <CheckInActivityTable
      getWeeklyCheckInTableDataStatus={fetchingWeeklyCheckInTableDataStatus}
      allWeeklyCheckInTableData={allWeeklyCheckInTableData}
      regradingWeeklyCheckinStatus={regradingWeeklyCheckinStatus}
      fetchTableData={fetchTableData}
      headerDescription="Showing check-in activity for everyone in your organization"
    />
  );
};

export default AdminCheckinActivityTable;
