import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SurveyIllustration from "resources/images/survey-illustration.png";
import Button from "app/storybookComponents/Button";
import { Team, TeamMember } from "app/containers/Global/types";
import { useState, useEffect, useLayoutEffect } from "react";
import {
  selectDepartments,
  selectCompanySettings,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
  selectAiFeaturesEnabled,
} from "app/containers/AdminConsole/slice";
import { Card, Nav, Form, Dropdown } from "react-bootstrap";
import TeamTable from "./TeamTable";
import TeamSettingsModal from "../Modals/TeamSettingsModal";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { responseStatus } from "utils/types";
import Toggle from "app/components/Toggle";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import {
  selectUpdateTeamStatus,
  editTeamMemberFromTeam,
  removeTeamMemberFromTeam,
  selectUpdateTeamMemberByTeamMemberIdStatus,
  selectRemoveTeamMemberFromTeamStatus,
  selectIsCurrentUserAdmin,
  selectConfigCatFlag,
} from "app/containers/Global/slice";
import SurveysTable from "./SurveysTable";
import {
  getTeamAssessmentInstances,
  selectTeamVisibilitySettings,
  updateTeamVisibility,
} from "../slice";
import { TeamVisibilitySettingsLabelMap } from "../constants";
import { showScheduleAssessmentModalForTeamId } from "app/components/LaunchAssessmentModal/slice";
import AdminTablesHeader from "app/containers/AdminConsole/AdminTablesHeader";
import ChecklistDropdown from "app/components/Dropdowns/ChecklistDropdown";
import { TeamVisibilitySetting, TeamVisibilitySettings } from "../types";
import { useDebounceCallback } from "utils/hooks";
import {
  getAutomaticTeamsSettings,
  selectAutomaticTeamsEditSetting,
  selectGetAutomaticTeamsSettingsStatus,
} from "app/containers/AutomationSettings/slice";
import { AUTOMATIC_TEAMS_EDIT_SETTINGS } from "app/containers/AutomationSettings/constants";
import { getS } from "utils/helperFunctions";
import OverflowContainer from "app/storybookComponents/OverflowContainer/OverflowContainer";
import { CONFIG_CAT_FLAGS } from "app/components/GlobalNavbar/constants";

interface Props {
  teamInfo?: Team;
  teamMembers?: TeamMember[];
  showInviteModal: () => void;
  onDeleteTeam: () => void;
  deleteTeamStatus: responseStatus;
  onUpdateTeam: (
    team: Partial<Team> & { sendDepartmentTEAMScanInvitations?: boolean }
  ) => void;
  isAutomatic?: 0 | 1;
}

// TODO: Need to create modal to show who can invite people to the team and display those members who already have access.
export default function TeamSettings({
  teamInfo,
  teamMembers,
  showInviteModal,
  onDeleteTeam,
  deleteTeamStatus,
  onUpdateTeam,
  isAutomatic,
}: Readonly<Props>) {
  // --------------------- Selectors ---------------------
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateTeamStatus = useAppSelector(selectUpdateTeamStatus);
  const updateTeamMemberStatus = useAppSelector(
    selectUpdateTeamMemberByTeamMemberIdStatus
  );
  const removeTeamMemberStatus = useAppSelector(
    selectRemoveTeamMemberFromTeamStatus
  );
  const areAIFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const departments = useAppSelector(selectDepartments);
  const companySettings = useAppSelector(selectCompanySettings);
  const teamVisibilitySettings = useAppSelector(
    selectTeamVisibilitySettings(teamInfo?.teamId ?? 0)
  );
  const automaticTeamsEditSetting = useAppSelector(
    selectAutomaticTeamsEditSetting
  );
  const getAutomaticTeamsSettingsStatus = useAppSelector(
    selectGetAutomaticTeamsSettingsStatus
  );

  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const weeklyCheckInConfigCatFlag = useAppSelector(
    selectConfigCatFlag(CONFIG_CAT_FLAGS.weeklyCheckIn.savedFlagName)
  );

  // --------------------- UseState ---------------------
  const [editTeamModal, setEditTeamModal] = useState<
    null | "Name" | "Department" | "Who Can Invite"
  >(null);
  const [tabShowing, setTabShowing] = useState<
    "members" | "general" | "surveys"
  >("general");

  const [showWarningModal, setShowWarningModal] = useState<
    | false
    | ({
        warningTitle: string;
        warningMessage: string;
        extraBody?: React.ReactNode;
      } & (
        | {
            modalTitle: "Change Permissions";
            isDanger: false;
            customButtonText: "Confirm Changes";
            teamMemberId: number;
            isTeamLead: 0 | 1;
            userAccountId: number;
          }
        | {
            modalTitle: "Remove Team Member";
            isDanger: true;
            customButtonText: "Remove";
            teamMemberId: number;
            userAccountId: number;
          }
        | {
            modalTitle: "Deactivate Team";
            isDanger: true;
            customButtonText: "Deactivate team";
          }
      ))
  >(false);
  const [value, setValue] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState<boolean>(!!teamInfo?.private);
  const [membersInTeam, setMembersInTeam] = useState<
    (TeamMember & { isPending?: boolean })[]
  >([]);
  const [team360ResultsVisibility, setTeam360ResultsVisibility] =
    useState<TeamVisibilitySetting>({});
  const [
    teamPersonalityVisibilitySetting,
    setTeamPersonalityVisibilitySetting,
  ] = useState<TeamVisibilitySetting>({});

  // --------------------- UseEffect ---------------------

  useLayoutEffect(() => {
    if (!teamInfo) {
      return;
    }

    const newMembers: {
      [key: string]: TeamMember & { isPending?: boolean };
    } = {};

    teamInfo.teamMembers?.forEach((teamMember) => {
      newMembers[teamMember.userAccountId] = teamMember;
    });

    teamInfo.pendingTeamMemberIds?.forEach((userAccountId) => {
      if (!newMembers[userAccountId]) {
        return;
      }
      newMembers[userAccountId] = {
        ...newMembers[userAccountId],
        isPending: true,
      };
    });
    setIsPrivate(!!teamInfo.private);
    setMembersInTeam(Object.values(newMembers));
  }, [teamInfo]);

  useEffect(() => {
    if (updateTeamStatus === "succeeded") {
      setEditTeamModal(null);
    }
  }, [updateTeamStatus]);

  useEffect(() => {
    if (updateTeamMemberStatus === "succeeded") {
      setShowWarningModal(false);
    }
  }, [updateTeamMemberStatus]);

  useEffect(() => {
    if (removeTeamMemberStatus === "succeeded") {
      setShowWarningModal(false);
    }
  }, [removeTeamMemberStatus]);

  useLayoutEffect(() => {
    const eventKey = searchParams.get("teamSettingsActiveTab");
    if (
      eventKey === "members" ||
      eventKey === "general" ||
      eventKey === "surveys"
    ) {
      setTabShowing(eventKey);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!teamVisibilitySettings) return;
    setTeam360ResultsVisibility(teamVisibilitySettings["Team TEAMscan"]);
    setTeamPersonalityVisibilitySetting(
      teamVisibilitySettings["Team Personality"]
    );
  }, [teamVisibilitySettings]);

  useEffect(() => {
    if (!teamInfo?.teamId) return;
    dispatch(getTeamAssessmentInstances({ teamId: teamInfo?.teamId }));
  }, [dispatch, teamInfo]);

  useEffect(() => {
    if (getAutomaticTeamsSettingsStatus === "idle") {
      dispatch(getAutomaticTeamsSettings());
    }
  }, [getAutomaticTeamsSettingsStatus, dispatch]);
  // --------------------- Functions ---------------------

  const userCanEditAutomaticTeam = () => {
    if (!automaticTeamsEditSetting) {
      return false;
    }
    const setting = AUTOMATIC_TEAMS_EDIT_SETTINGS[automaticTeamsEditSetting];
    if (
      (setting === "Only admins" ||
        setting === "Only admins and team leaders") &&
      isAdmin
    ) {
      return true;
    }
    if (setting === "Only admins and team leaders" && teamInfo?.teamId) {
      return teamAndDepartmentLeaderOf?.teams?.includes(teamInfo?.teamId);
    }
    return false;
  };

  const teamInfoEditDisabled = !!isAutomatic && !userCanEditAutomaticTeam();

  const teamInfoLockIcon = teamInfoEditDisabled ? (
    <FontAwesomeIcon
      icon={["far", "lock-keyhole"]}
      style={{ color: "#805100" }}
    />
  ) : null;

  const onSaveTeamScanVisibilitySetting = useDebounceCallback(
    (payload: TeamVisibilitySettings["Team TEAMscan"]) => {
      dispatch(
        updateTeamVisibility({
          teamId: teamInfo?.teamId ?? 0,
          visibilitySetting: {
            "Team TEAMscan": payload,
          },
        })
      );
    },
    1000
  );

  const onSaveTeamPersonalityVisibilitySetting = useDebounceCallback(
    (payload: TeamVisibilitySettings["Team Personality"]) => {
      dispatch(
        updateTeamVisibility({
          teamId: teamInfo?.teamId ?? 0,
          visibilitySetting: {
            "Team Personality": payload,
          },
        })
      );
    },
    1000
  );

  const getTabContent = () => {
    switch (tabShowing) {
      case "members":
        return getMemberTabContent();
      case "general":
        return getGeneralSettingsContent();
      case "surveys":
        return getSurveyTabContent();
    }
  };

  const getDepartmentName = () =>
    teamInfo?.departmentId
      ? departments[teamInfo?.departmentId ?? 0]?.name ?? "No Department"
      : "No Department";

  const getVisibilitySettingValue = (setting: TeamVisibilitySetting) => {
    if (setting["Organization Member"]) {
      return "Everyone in this organization";
    }
    const keys: string[] = [];
    Object.entries(setting).forEach(([key, value]) => {
      if (value) {
        keys.push(TeamVisibilitySettingsLabelMap[key] ?? key);
      }
    });

    return keys.join(", ");
  };

  const getChangePermissionWarningMessageAndTitle = (
    isTeamLead: boolean
  ): {
    warningTitle: string;
    warningMessage: string;
  } => {
    const teamMemberMessage =
      "This person will no longer be able to invite/remove members, change permissions, and configure team settings";
    const teamLeaderMessage =
      "Team leaders have the ability to invite/remove members, change permissions, and configure team settings";

    const warningTitle = `Are you sure you want to assign this person as a team ${
      isTeamLead ? "leader" : "member"
    }?`;
    const warningMessage = isTeamLead ? teamLeaderMessage : teamMemberMessage;

    return {
      warningTitle,
      warningMessage,
    };
  };

  const getMemberTabContent = () => {
    if (!teamInfo) return null;
    return (
      <>
        {isAutomatic ? (
          <div className="warning-banner" style={{ fontSize: "14px" }}>
            <FontAwesomeIcon
              icon={["far", "lock-keyhole"]}
              style={{ fontSize: "16px", paddingRight: "10px" }}
            />
            <strong>This is an Automatic team:</strong> This team will be
            automatically updated based on manager-employee relationships. Any
            direct report of this team’s manager will be added.
          </div>
        ) : null}
        <Form.Group className="row-gap-20px">
          <Form.Control
            placeholder={"Search by name or email..."}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {isAutomatic ? null : (
            <Button onClick={showInviteModal} style={{ whiteSpace: "nowrap" }}>
              <FontAwesomeIcon icon="user-plus" className="me-2" />
              Add
            </Button>
          )}
        </Form.Group>
        <OverflowContainer>
          <TeamTable
            teamId={teamInfo?.teamId}
            teamMembers={membersInTeam ?? []}
            searchValue={value}
            updateTeamMember={(teamMemberId, payload) => {
              const memberInfo = teamMembers?.find(
                (teamMember) => teamMember.teamMemberId === teamMemberId
              );
              const { warningTitle, warningMessage } =
                getChangePermissionWarningMessageAndTitle(!!payload.isTeamLead);
              setShowWarningModal({
                warningTitle,
                warningMessage,
                modalTitle: "Change Permissions",
                isDanger: false,
                customButtonText: "Confirm Changes",
                teamMemberId,
                isTeamLead: payload.isTeamLead,
                userAccountId: memberInfo?.userAccountId ?? 0,
              });
            }}
            removeTeamMember={(teamMemberId) => {
              const memberInfo = teamMembers?.find(
                (teamMember) => teamMember.teamMemberId === teamMemberId
              );
              setShowWarningModal({
                warningTitle: `Are you sure you want to remove this person from ${teamInfo?.teamName}?`,
                warningMessage:
                  "This person will no longer be able to access information viewable only to the team or take this team's assessments.",
                modalTitle: "Remove Team Member",
                isDanger: true,
                customButtonText: "Remove",
                teamMemberId,
                userAccountId: memberInfo?.userAccountId || 0,
              });
            }}
            isAutomatic={isAutomatic}
          />
        </OverflowContainer>
      </>
    );
  };

  const getSurveyTabContent = () => {
    if (!teamInfo?.teamId) return null;
    return (
      <>
        <AdminTablesHeader
          bannerDescription="Send the TEAMscan survey to your team."
          bannerTitle="Launch the TEAMscan survey"
          iconImageSrc={SurveyIllustration}
          iconImageAlt="Launch TEAMscan"
          actionButton={
            <div className="row-gap-12px">
              <Button
                onClick={() => {
                  dispatch(
                    showScheduleAssessmentModalForTeamId(teamInfo?.teamId)
                  );
                }}
              >
                Launch TEAMscan
              </Button>
              <Button
                onClick={() => {
                  navigate("/SkillsGuide");
                }}
                variant="secondary-blue"
                className="white-button"
              >
                See teamwork skills
              </Button>
            </div>
          }
        />
        <p
          style={{
            margin: "4px 0",
          }}
        >
          Monitor completion and edit survey settings for TEAMscans here. To
          view results or edit settings, click the menu icon to find more
          actions for each instance.
        </p>
        <SurveysTable teamId={teamInfo?.teamId} />
      </>
    );
  };

  const getDepartmentRow = () => {
    if (companySettings?.areDepartmentsHidden) {
      return null;
    }
    return (
      <div className="setting-row">
        <div className="setting-row-description">
          <h3>Department</h3>
          <p>{getDepartmentName()}</p>
        </div>
        <div className="setting-row-action">
          {teamInfoLockIcon}
          <Button
            variant="secondary-blue"
            onClick={() => setEditTeamModal("Department")}
            disabled={teamInfoEditDisabled}
          >
            Edit department
          </Button>
        </div>
      </div>
    );
  };

  const onVisibilityChecklistDropdownSelect = (
    selectedItems: TeamVisibilitySetting,
    setter: (value: TeamVisibilitySetting) => void,
    selectedKey?: string | number,
    alwaysOnSettings?: TeamVisibilitySetting
  ) => {
    // If the user selects "Organization Member" and its selected then we need to select all the other options
    if (selectedKey === "Organization Member" && selectedItems[selectedKey]) {
      return setter({
        "Organization Member": 1,
        Admin: 1,
        "Team Leader": 1,
        "Team Member": 1,
        "Department Leader": 1,
        ...alwaysOnSettings,
      });
    }

    // If the user deselects any of the other options then we should deselect "Organization Member"
    if (
      (selectedKey === "Team Member" ||
        selectedKey === "Team Leader" ||
        selectedKey === "Department Leader") &&
      !selectedItems[selectedKey]
    ) {
      return setter({
        ...selectedItems,
        [selectedKey]: selectedItems[selectedKey],
        "Organization Member": 0,
      });
    }

    setter(selectedItems);
  };

  const getGeneralSettingsContent = () => {
    const isOverrideDisabled =
      !companySettings?.allowVisibilityPermissionOverride;
    const lockIcon = isOverrideDisabled ? (
      <FontAwesomeIcon
        icon={["far", "lock-keyhole"]}
        style={{ color: "#805100" }}
      />
    ) : null;

    const alwaysOnSettings = {
      Admin: 1,
      "Team Leader": 1,
    } as TeamVisibilitySetting;

    const getAutomaticTeamsWarningBanner = () => {
      let text = "";
      if (!automaticTeamsEditSetting) {
        return null;
      }
      const setting = AUTOMATIC_TEAMS_EDIT_SETTINGS[automaticTeamsEditSetting];

      if (setting === "Only admins and team leaders") {
        return null;
      }

      if (setting === "Only admins") {
        text = "Only Admins can edit this team's information.";
      } else {
        text = "No one can edit this team's information.";
      }
      return (
        <div className="warning-banner" style={{ fontSize: "14px" }}>
          <FontAwesomeIcon
            icon={["far", "lock-keyhole"]}
            style={{ fontSize: "16px", paddingRight: "10px" }}
          />
          <strong>This is an Automatic team:</strong> {text}{" "}
          {isAdmin ? (
            <>
              You can change this in{" "}
              <Link to="/AdminConsole/Automations" className="no-underline">
                Automation Settings
              </Link>
              .
            </>
          ) : (
            <>Please reach out to an admin to change this setting.</>
          )}
        </div>
      );
    };

    return (
      <div className="column-gap-20px">
        {isAutomatic ? getAutomaticTeamsWarningBanner() : null}
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Team Name</h3>
            <p>{teamInfo?.teamName}</p>
          </div>
          <div className="setting-row-action">
            {teamInfoLockIcon}
            <Button
              variant="secondary-blue"
              onClick={() => setEditTeamModal("Name")}
              disabled={teamInfoEditDisabled}
            >
              Edit team name
            </Button>
          </div>
        </div>
        {getDepartmentRow()}
        <hr className="m-0" />
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Team visibility</h3>
            <p>
              Either allow everyone in this organization (public) or only allow
              admins and this team's members to view this team guide (private).
            </p>
          </div>
          <div className="setting-row-action">
            {teamInfoLockIcon}
            <Dropdown
              onSelect={(e) => {
                if (!e) return;
                setIsPrivate(e === "1");
                onUpdateTeam({
                  private: e === "1" ? 1 : 0,
                });
              }}
            >
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                disabled={teamInfoEditDisabled}
              >
                {isPrivate
                  ? "Admins and team members on this team"
                  : "Everyone in this organization"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="0">
                  Everyone in this organization
                </Dropdown.Item>
                <Dropdown.Item eventKey="1">
                  Admins and team members on this team
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can view Team Personality?</h3>
            <p>
              Specify which users can view the Team Personality for this team.
            </p>
          </div>
          <div className="setting-row-action">
            {lockIcon}

            <ChecklistDropdown
              checklistItems={[
                { id: "Admin", label: "Admin", disabled: true },
                {
                  id: "Team Leader",
                  label: "This team's leader(s)",
                  disabled: true,
                },
                { id: "Team Member", label: "Team members on this team" },
                {
                  id: "Department Leader",
                  label: "This team's department leader",
                },
                {
                  id: "Organization Member",
                  label: "Everyone in this organization (including above)",
                },
              ]}
              selectedItems={{
                ...teamPersonalityVisibilitySetting,
                ...alwaysOnSettings,
              }}
              setSelectedItems={(si, ck) =>
                onVisibilityChecklistDropdownSelect(
                  si,
                  setTeamPersonalityVisibilitySetting,
                  ck,
                  alwaysOnSettings
                )
              }
              disabled={isOverrideDisabled}
              forcedDropdownText={getVisibilitySettingValue({
                ...teamPersonalityVisibilitySetting,
                ...alwaysOnSettings,
              })}
              onUnFocus={(pl) => onSaveTeamPersonalityVisibilitySetting(pl)}
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see TEAMscan results?</h3>
            <p>
              Specify which users can view the TEAMscan results for this team.
            </p>
          </div>
          <div className="setting-row-action">
            {lockIcon}
            <ChecklistDropdown
              checklistItems={[
                { id: "Admin", label: "Admin", disabled: true },
                {
                  id: "Team Leader",
                  label: "This team's leader(s)",
                  disabled: true,
                },
                { id: "Team Member", label: "Team members on this team" },
                {
                  id: "Department Leader",
                  label: "This team's department leader",
                },
                {
                  id: "Organization Member",
                  label: "Everyone in this organization (including above)",
                },
              ]}
              selectedItems={{
                ...team360ResultsVisibility,
                ...alwaysOnSettings,
              }}
              setSelectedItems={(si, ck) =>
                onVisibilityChecklistDropdownSelect(
                  si,
                  setTeam360ResultsVisibility,
                  ck,
                  alwaysOnSettings
                )
              }
              disabled={isOverrideDisabled}
              forcedDropdownText={getVisibilitySettingValue({
                ...team360ResultsVisibility,
                ...alwaysOnSettings,
              })}
              onUnFocus={(pl) => onSaveTeamScanVisibilitySetting(pl)}
            />
          </div>
        </div>
        {isOverrideDisabled ? (
          <div className="warning-banner gold row-gap-8px align-items-center">
            {lockIcon}
            <p>
              An admin has turned off the ability to override visibility
              defaults set for this organization. Please reach out to an admin
              to change visibility settings.
            </p>
          </div>
        ) : null}
        {getCoachBoCheckInSetting()}

        <hr className="m-0" />
        <div className="column-gap-16px">
          <div>
            <h3
              style={{
                color: "black",
              }}
            >
              Deactivate Team
            </h3>
            <p>Remove all team members and permanently delete team guide.</p>
          </div>
          <div className="row-gap-16px align-items-center">
            <Button
              variant="secondary-danger"
              onClick={() =>
                setShowWarningModal({
                  warningTitle: `Are you sure you want to deactivate "${teamInfo?.teamName}"?`,
                  warningMessage:
                    "This will permanently delete all information on this team guide and remove this team from the company directory. This action cannot be undone.",
                  modalTitle: "Deactivate Team",
                  isDanger: true,
                  customButtonText: "Deactivate team",
                })
              }
              disabled={deleteTeamStatus === "loading" || !!isAutomatic}
            >
              Deactivate team
            </Button>
            {!!isAutomatic && (
              <div style={{ color: "#805100", display: "flex", gap: "8px" }}>
                <FontAwesomeIcon icon={["far", "lock-keyhole"]} />
                <p className="fw-bold">
                  Automatic teams cannot be deactivated.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getCoachBoCheckInSetting = () => {
    if (!areAIFeaturesEnabled || !weeklyCheckInConfigCatFlag) return null;
    const isCompanySettingsEnabled =
      !!companySettings?.hasCoachBoWeeklyCheckInsEnabled;
    const departmentInfo =
      !companySettings?.areDepartmentsHidden && teamInfo?.departmentId
        ? departments[teamInfo?.departmentId]
        : null;
    const teamHasCoachBoCheckInsEnabled =
      teamInfo?.hasCoachBoWeeklyCheckInsEnabled ??
      departmentInfo?.hasCoachBoWeeklyCheckInsEnabled ??
      companySettings?.hasCoachBoWeeklyCheckInsEnabled; // first check if team has its own setting, if not, use company setting
    const isTeamSettingEnabled = !!teamHasCoachBoCheckInsEnabled;
    return (
      <>
        <hr className="m-0" />
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Enable Weekly Check-Ins</h3>
            <p>
              All team members on this team will receive an email from Coach Bo
              to check on work progress, share how they’re feeling, and report
              any successes or challenges at the end of each week. Team leaders
              will receive a weekly report with key patterns, recommended
              actions, and a completion analysis based on weekly check-ins
              completed by members on this team.
            </p>
          </div>
          <div className="setting-row-action">
            <Toggle
              isOn={isTeamSettingEnabled}
              handleToggle={() => {
                onUpdateTeam({
                  hasCoachBoWeeklyCheckInsEnabled: isTeamSettingEnabled ? 0 : 1,
                });
              }}
            />
          </div>
        </div>
        {isCompanySettingsEnabled ||
        departmentInfo?.hasCoachBoWeeklyCheckInsEnabled ? (
          <div className="light-blue warning-banner">
            <p style={{ color: "black" }}>
              Weekly Check-Ins have been <b>enabled</b> for either this team’s
              department or this entire organization. If you choose to disable
              this feature, members on this team will not receive the Weekly
              Check-In email from Coach Bo. Note: Team members may still receive
              the Weekly Check-In if this feature is enabled by their other
              team(s).
            </p>
          </div>
        ) : null}
      </>
    );
  };

  const getWarningModalInProgress = () => {
    if (showWarningModal === false) {
      return false;
    }

    switch (showWarningModal.modalTitle) {
      case "Deactivate Team":
        return deleteTeamStatus === "loading";
      case "Change Permissions":
        return updateTeamMemberStatus === "loading";
      case "Remove Team Member":
        return removeTeamMemberStatus === "loading";
      default:
        return false;
    }
  };

  // ------------ "On Action" Functions ------------
  const onWarningConfirmClick = () => {
    if (showWarningModal === false || !teamInfo) {
      return false;
    }

    switch (showWarningModal.modalTitle) {
      case "Deactivate Team":
        return onDeleteTeam();
      case "Change Permissions":
        return dispatch(
          editTeamMemberFromTeam({
            payload: {
              isTeamLead: showWarningModal.isTeamLead,
            },
            teamMemberId: showWarningModal.teamMemberId,
            teamId: teamInfo.teamId,
            userAccountId: showWarningModal.userAccountId,
          })
        );
      case "Remove Team Member":
        return dispatch(
          removeTeamMemberFromTeam({
            teamMemberId: showWarningModal.teamMemberId,
            teamId: teamInfo.teamId,
            userAccountId: showWarningModal.userAccountId,
          })
        );
      default:
        return false;
    }
  };

  const teamMemberCount = teamMembers?.length || 0;

  return (
    <>
      <TeamSettingsModal
        teamInfo={teamInfo}
        modalShowing={editTeamModal}
        onSave={(payload) => onUpdateTeam(payload)}
        hideModal={() => setEditTeamModal(null)}
        loading={updateTeamStatus === "loading"}
      />
      {showWarningModal !== false ? (
        <WarningModal
          modalTitle={showWarningModal.modalTitle}
          warningTitle={showWarningModal.warningTitle}
          warningMessage={showWarningModal.warningMessage}
          hideModal={() => setShowWarningModal(false)}
          onConfirmClick={onWarningConfirmClick}
          isInProgress={getWarningModalInProgress()}
          isDanger={showWarningModal.isDanger}
          customButtonText={showWarningModal.customButtonText}
          extraBody={showWarningModal.extraBody}
          isOpen
        />
      ) : null}
      <div style={{ marginBottom: "20px" }}>
        <Button
          onClick={() => navigate(-1)}
          variant="secondary-blue"
          style={{ border: "none" }}
        >
          <FontAwesomeIcon icon="arrow-left" className="me-2" />
          Back
        </Button>
      </div>
      <div className="column-gap-20px">
        <Card className="team-settings column-gap-20px">
          <div className="d-flex justify-content-between align-items-center">
            <div className="team-settings-header row-gap-20px">
              <div className="row-gap-12px align-items-center">
                <h1>Team Settings</h1>
                {isAutomatic ? (
                  <span className="automatic-tag">Automatic Team</span>
                ) : null}
                <span className="team-member-count">
                  {teamMemberCount} Member{getS(teamMemberCount)}
                </span>
              </div>
            </div>
            <div>
              <Button
                variant="secondary-blue"
                onClick={() => {
                  navigate(`/TeamGuide/${teamInfo?.teamId}`);
                }}
              >
                Go to team profile
              </Button>
            </div>
          </div>
          <Nav
            className="simple-nav"
            activeKey={tabShowing || ""}
            onSelect={(e) => {
              if (!e) return;
              setTabShowing(e as "members" | "general" | "surveys");
              setSearchParams((searchParams) => {
                searchParams.set("teamSettingsActiveTab", e);
                return searchParams;
              });
            }}
            style={{
              marginBottom: "-20px",
            }}
          >
            <Nav.Item>
              <Nav.Link eventKey="members">Members</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="surveys">Surveys</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="general">General</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card>
        <Card className="team-settings column-gap-20px">{getTabContent()}</Card>
      </div>
    </>
  );
}
