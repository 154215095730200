import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { request } from "utils/request";
import { AutomaticTeamsSettings } from "./types";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";

// ------------------ State Type/Structure ------------------
export interface AutomationSettingsState {
  hasAutomaticTeamsEnabled: boolean | null;
  automaticTeamsEditSetting: 1 | 2 | 3 | null;
  getAutomaticTeamsSettingsStatus: responseStatus;
  turnOnAutomaticTeamsSettingStatus: responseStatus;
  turnOffAutomaticTeamsSettingStatus: responseStatus;
  updateAutomaticTeamsEditSettingStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: AutomationSettingsState = {
  hasAutomaticTeamsEnabled: null,
  automaticTeamsEditSetting: null,
  getAutomaticTeamsSettingsStatus: "idle",
  turnOnAutomaticTeamsSettingStatus: "idle",
  turnOffAutomaticTeamsSettingStatus: "idle",
  updateAutomaticTeamsEditSettingStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------

export const getAutomaticTeamsSettings = createAsyncThunk(
  "getAutomaticTeamsSettings",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/automaticTeamsSettings`;
    const getAutomaticTeamsSettingsResponse = await request(requestUrl);
    return getAutomaticTeamsSettingsResponse as AutomaticTeamsSettings;
  }
);

export const turnAutomaticTeamsSettingOn = createAsyncThunk(
  "turnAutomaticTeamsSettingOn",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/turnAutomaticTeamsSettingOn`;
    const turnAutomaticTeamsSettingOnResponse = await request(requestUrl, {
      method: "PUT",
    });
    return turnAutomaticTeamsSettingOnResponse;
  }
);

export const turnAutomaticTeamsSettingOff = createAsyncThunk(
  "turnAutomaticTeamsSettingOff",
  async (payload: { deleteAutomaticTeams: boolean }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/turnAutomaticTeamsSettingOff`;
    const turnAutomaticTeamsSettingOffResponse = await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    });
    return turnAutomaticTeamsSettingOffResponse;
  }
);

export const updateAutomaticTeamsEditSetting = createAsyncThunk(
  "automaticTeamsEditSetting",
  async (automaticTeamsEditSetting: 1 | 2 | 3) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/automaticTeamsEditSetting`;
    const updateAutomaticTeamsEditSettingResponse = await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ automaticTeamsEditSetting }),
    });
    return updateAutomaticTeamsEditSettingResponse as AutomaticTeamsSettings;
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const automationSettingsSlice = createSlice({
  name: "automationSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAutomaticTeamsEditSetting.pending, (state) => {
        state.updateAutomaticTeamsEditSettingStatus = "loading";
      })
      .addCase(updateAutomaticTeamsEditSetting.fulfilled, (state, action) => {
        state.updateAutomaticTeamsEditSettingStatus = "succeeded";
        state.automaticTeamsEditSetting =
          action.payload.automaticTeamsEditSetting;
        toast.success(
          "Setting Updated Successfully",
          successNotificationOptions
        );
      })
      .addCase(updateAutomaticTeamsEditSetting.rejected, (state) => {
        state.updateAutomaticTeamsEditSettingStatus = "failed";
      })
      .addCase(getAutomaticTeamsSettings.pending, (state) => {
        state.getAutomaticTeamsSettingsStatus = "loading";
      })
      .addCase(getAutomaticTeamsSettings.fulfilled, (state, action) => {
        state.getAutomaticTeamsSettingsStatus = "succeeded";
        state.hasAutomaticTeamsEnabled =
          action.payload.hasAutomaticTeamsEnabled;
        state.automaticTeamsEditSetting =
          action.payload.automaticTeamsEditSetting;
      })
      .addCase(getAutomaticTeamsSettings.rejected, (state) => {
        state.getAutomaticTeamsSettingsStatus = "failed";
      })
      .addCase(turnAutomaticTeamsSettingOn.pending, (state) => {
        state.turnOnAutomaticTeamsSettingStatus = "loading";
      })
      .addCase(turnAutomaticTeamsSettingOn.fulfilled, (state) => {
        state.turnOnAutomaticTeamsSettingStatus = "succeeded";
        state.hasAutomaticTeamsEnabled = true;
        toast.success(
          "Setting Updated Successfully",
          successNotificationOptions
        );
      })
      .addCase(turnAutomaticTeamsSettingOn.rejected, (state) => {
        state.turnOnAutomaticTeamsSettingStatus = "failed";
      })
      .addCase(turnAutomaticTeamsSettingOff.pending, (state) => {
        state.turnOffAutomaticTeamsSettingStatus = "loading";
      })
      .addCase(turnAutomaticTeamsSettingOff.fulfilled, (state) => {
        state.turnOffAutomaticTeamsSettingStatus = "succeeded";
        state.hasAutomaticTeamsEnabled = false;
        toast.success(
          "Setting Updated Successfully",
          successNotificationOptions
        );
      })
      .addCase(turnAutomaticTeamsSettingOff.rejected, (state) => {
        state.turnOffAutomaticTeamsSettingStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectHasAutomaticTeamsEnabled = (state: RootState) =>
  state.automaticTeams.hasAutomaticTeamsEnabled;

export const selectAutomaticTeamsEditSetting = (state: RootState) =>
  state.automaticTeams.automaticTeamsEditSetting;

export const selectGetAutomaticTeamsSettingsStatus = (state: RootState) =>
  state.automaticTeams.getAutomaticTeamsSettingsStatus;

export const selectTurnOnAutomaticTeamsSettingStatus = (state: RootState) =>
  state.automaticTeams.turnOnAutomaticTeamsSettingStatus;

export const selectTurnOffAutomaticTeamsSettingStatus = (state: RootState) =>
  state.automaticTeams.turnOffAutomaticTeamsSettingStatus;

export default automationSettingsSlice.reducer;
