import { Chart, TooltipCallbacks, TooltipItem, TooltipModel } from "chart.js";
import { memo, useEffect, useLayoutEffect, useRef } from "react";
import { LINE_CHART_DEFAULT_COLORS } from "./constants";
import { getColorFromArray } from "./helpers";
import { _DeepPartialObject } from "chart.js/dist/types/utils";

interface Props {
  dataValues: (number | null)[][];
  labels: string[];
  canvasId: string;
  minYScale?: number;
  maxYScale?: number;
  stepSize?: number;
  colors?: string[];
  tooltipCallbacks?: _DeepPartialObject<
    TooltipCallbacks<"line", TooltipModel<"line">, TooltipItem<"line">>
  >;
}

const SimpleLineChart = ({
  dataValues,
  labels,
  canvasId,
  minYScale = 0,
  maxYScale = 100,
  stepSize,
  colors = LINE_CHART_DEFAULT_COLORS,
  tooltipCallbacks,
}: Readonly<Props>) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartRefInstance = useRef<{ chart: Chart | null }>({ chart: null });

  // add a useEffect to redraw the chart when the window is resized
  useEffect(() => {
    const onResize = () => {
      if (chartRefInstance.current.chart) {
        chartRefInstance.current.chart.resize();
      }
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (!chartRef?.current) {
      return;
    }
    const dataSets = dataValues.map((dataValue, index) => {
      return {
        label: `Line ${index + 1}`,
        data: dataValue,
        borderColor: getColorFromArray(colors, index),
      };
    });

    chartRefInstance.current.chart = new Chart(chartRef.current, {
      type: "line",
      data: {
        labels: labels,
        datasets: dataSets,
      },
      options: {
        plugins: {
          legend: {
            labels: {
              filter: () => false,
            },
          },
          tooltip: {
            callbacks: tooltipCallbacks,
            backgroundColor: "#F7F7F7",
            titleColor: "#53565A",
            bodyColor: "#53565A",
            yAlign: "top",
          },
        },
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
            },
          },
          y: {
            beginAtZero: true,
            max: maxYScale,
            min: minYScale,
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              stepSize: stepSize,
              includeBounds: false,
            },
          },
        },
        elements: {
          line: {
            borderColor: colors,
            spanGaps: true,
          },
          point: {
            radius: 5,
            backgroundColor: "white",
          },
        },
        maintainAspectRatio: false,
      },
    });

    const chart = chartRefInstance.current.chart;

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [
    labels,
    minYScale,
    maxYScale,
    stepSize,
    colors,
    dataValues,
    tooltipCallbacks,
  ]);

  return (
    <div className="simple-line-chart-container">
      <canvas id={canvasId} ref={chartRef} />
    </div>
  );
};

export default memo(SimpleLineChart);
