import { ActivityMonitorBoxProps } from "app/components/ActivityMonitor/types";
import {
  getChangeSinceLast,
  getOutOfFiveColor,
  percentageColor,
} from "../Dashboard/helpers";
import {
  AllWeeklyCheckInTableData,
  CoachBoWeeklyCheckInActivityReport,
  isWeeklyCheckInRowUser,
  SelectedCheckInBreakdownEntity,
  WeeklyCheckInLineChartResponse,
  WeeklyCheckInTableRowData,
  WeeklyCheckInTableRowDataBase,
} from "./types";
import NoResultsIcon from "resources/images/no-results-transparent-back.png";

export const getWeeklyCheckInTableRowEntityId = (
  data: WeeklyCheckInTableRowData
) => ("userAccountId" in data ? data.userAccountId : data.entityId);

export const getDateForSorting = (row: WeeklyCheckInTableRowData): Date => {
  if ("completedDate" in row) {
    return new Date(row.completedDate);
  } else if ("startDate" in row) {
    return new Date(row.startDate);
  }
  // Default to a very early date if no date is available
  return new Date(0);
};

export const sortWeeklyCheckInTableRowData = (
  rows: WeeklyCheckInTableRowData[]
) => {
  return rows.sort((a, b) => {
    const dateA = getDateForSorting(a);
    const dateB = getDateForSorting(b);

    // Sort by date in descending order
    return dateB.getTime() - dateA.getTime();
  });
};

export const getTableDataFromSelectedRow = (
  allWeeklyCheckInTableData: AllWeeklyCheckInTableData,
  selectedRow: SelectedCheckInBreakdownEntity,
  timeInterval: string,
  entitySelected: string
) => {
  if (!selectedRow) {
    return allWeeklyCheckInTableData[timeInterval]?.[entitySelected];
  }

  const allTimeIntervalTableData = allWeeklyCheckInTableData[timeInterval];
  if (!allTimeIntervalTableData) {
    return [];
  }

  if (selectedRow.type === "department") {
    const allTeamRowsForTimeInterval = allTimeIntervalTableData.team;

    // filter out all rows where the entity id is not one of the teamIds in selectedRow
    // and filter out any rows where the start and end date is not within the selectedRow start and end date
    return filterWeeklyCheckInTableDataByEntityIds(
      allTeamRowsForTimeInterval,
      selectedRow.teamIds,
      selectedRow.startDate,
      selectedRow.endDate
    );
  }

  const allUserRowsForTimeInterval = allTimeIntervalTableData?.user ?? [];
  return allUserRowsForTimeInterval.filter((row) => {
    if (!isWeeklyCheckInRowUser(row)) {
      return false;
    }

    if (!selectedRow.threadIds.includes(row.threadId)) {
      return false;
    }

    return true;
  });
};

const filterWeeklyCheckInTableDataByEntityIds = (
  rows: WeeklyCheckInTableRowData[] = [],
  entityIds: number[] = [],
  startDate: string = "",
  endDate: string = ""
) => {
  const selectedStartDate = new Date(startDate);
  const selectedEndDate = new Date(endDate);
  return rows?.filter((row) => {
    if (isWeeklyCheckInRowUser(row)) {
      return false;
    }

    if (!entityIds.includes(row.entityId)) {
      return false;
    }

    const rowStartDate = new Date(row.startDate);
    const rowEndDate = new Date(row.endDate);

    if (
      rowStartDate.getTime() < selectedStartDate.getTime() ||
      rowEndDate.getTime() > selectedEndDate.getTime()
    ) {
      return false;
    }

    return true;
  });
};

export const updateUserWeeklyCheckInTableData = (
  array: WeeklyCheckInTableRowData[],
  updatedData: WeeklyCheckInTableRowDataBase,
  threadId: string
) =>
  array.map((entry) => {
    if (isWeeklyCheckInRowUser(entry) && entry.threadId === threadId) {
      return {
        ...entry,
        ...updatedData,
      };
    }
    return entry;
  });

export const getWeeklyCheckInActivityTimeIntervalKey = (
  selectedKey?: string
) => {
  switch (selectedKey) {
    case "Past 30 days":
      return "last30Days";
    case "Past 365 days":
      return "last365Days";
    default:
      return null;
  }
};

export const getWeeklyCheckinActivityLineChartData = (
  weeklyCheckInLineChartResponse?: WeeklyCheckInLineChartResponse | null
): {
  dataValues: (number | null)[][];
  labels: string[];
  hasAtLeastTwoDataPoints: boolean;
} | null => {
  if (!weeklyCheckInLineChartResponse) {
    return null;
  }
  const {
    labels,
    averageEnergy = [],
    averageProgress = [],
    averageReadiness = [],
  } = weeklyCheckInLineChartResponse;
  const dataValues: (number | null)[][] = [];
  dataValues.push(averageEnergy);
  dataValues.push(averageProgress);
  dataValues.push(averageReadiness);

  const hasAtLeastTwoDataPoints =
    averageEnergy.filter((value) => value !== null).length > 1;

  return {
    dataValues,
    labels,
    hasAtLeastTwoDataPoints,
  };
};

export const getWeeklyCheckinActivityLineEmptyState = (
  lineChartTimeInterval: string,
  notEnoughDataPoints?: boolean
) => {
  const title = `Chart Unavailable for ‘${lineChartTimeInterval}’`;
  let description =
    "Once there is comparison data, this chart will be shown here.";

  if (notEnoughDataPoints) {
    description =
      "There's not enough data in this time period to build a trend chart. Trend charts require data in at least 2 time periods. Try another time period.";
  }

  return (
    <div className="line-chart-empty-state">
      <img
        src={NoResultsIcon}
        alt="no results"
        className="line-chart-empty-state__illustration"
      />
      <div>
        <div className="line-chart-empty-state__title">
          <p>{title}</p>
        </div>
        <div className="line-chart-empty-state__description">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export const getCoachBoWeeklyCheckInActivityReportToActivityMonitorBoxProps = (
  coachBoWeeklyCheckInActivityReport?: CoachBoWeeklyCheckInActivityReport,
  timeInterval: string = "week"
): ActivityMonitorBoxProps[] => {
  const activityMonitorBoxProps: ActivityMonitorBoxProps[] = [];
  if (!coachBoWeeklyCheckInActivityReport) return activityMonitorBoxProps;

  const { completionInfo, energy, progress, readiness } =
    coachBoWeeklyCheckInActivityReport;

  const hasSomeData =
    !!completionInfo.completedCount || !!completionInfo.totalCount;

  activityMonitorBoxProps.push({
    title: "Check-in Completion",
    mainValue: `${completionInfo.percentage}%`,
    changeSinceLast: getChangeSinceLast(
      completionInfo.trendAmount,
      completionInfo.trendDirection,
      true
    ),
    descriptionText: hasSomeData
      ? `${completionInfo.completedCount ?? 0} / ${completionInfo.totalCount}`
      : null,
    timeInterval,
    dotColor: percentageColor(completionInfo.percentage),
    popoverText:
      "This is the percentage of Weekly Check-Ins completed out of all those sent in this organization.",
  });

  const averageScoreDescriptionText = hasSomeData ? "out of 5" : null;

  activityMonitorBoxProps.push({
    title: "Average Mood Score",
    mainValue: energy.score,
    changeSinceLast: getChangeSinceLast(
      energy.trendAmount,
      energy.trendDirection
    ),
    descriptionText: averageScoreDescriptionText,
    timeInterval,
    dotColor: energy.score ? getOutOfFiveColor(energy.score) : null,
    popoverText:
      "In the Weekly Check-In, Coach Bo asks members how they’re feeling. Their answers are scored by AI on a scale of 1 (lowest) to 5 (highest). This is the average of all mood scores from the selected time frame.",
  });

  activityMonitorBoxProps.push({
    title: "Average Progress Score",
    mainValue: progress.score,
    changeSinceLast: getChangeSinceLast(
      progress.trendAmount,
      progress.trendDirection
    ),
    descriptionText: averageScoreDescriptionText,
    timeInterval,
    dotColor: progress.score ? getOutOfFiveColor(progress.score) : null,
    popoverText:
      "In the Weekly Check-In, Coach Bo asks members what they’ve been working on and their progress each week. Their answers are scored by AI on a scale of 1 (no progress) to 5 (great progress). This is the average of all progress scores from the selected time frame.",
  });

  activityMonitorBoxProps.push({
    title: "Average Readiness Score",
    mainValue: readiness.score,
    changeSinceLast: getChangeSinceLast(
      readiness.trendAmount,
      readiness.trendDirection
    ),
    descriptionText: averageScoreDescriptionText,
    timeInterval,
    dotColor: readiness.score ? getOutOfFiveColor(readiness.score) : null,
    popoverText:
      "In the Weekly Check-In, Coach Bo asks members if anything is preventing them from making progress in their work. Their answers are scored by AI on a scale of 1 (major blockers) to 5 (no blockers). This is the average of all readiness scores from the selected time frame.",
  });

  return activityMonitorBoxProps;
};
