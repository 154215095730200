import Toggle from "app/components/Toggle";

interface Props {
  setToggle: (value: boolean) => void;
  toggleLabel: string | JSX.Element;
  toggleMessage: string;
  toggleValue?: boolean;
}

const ToggleBox = ({
  setToggle,
  toggleLabel,
  toggleMessage,
  toggleValue,
}: Readonly<Props>) => {
  const getLabel = () => {
    if (typeof toggleLabel === "string") {
      return <p style={{ color: "black" }}>{toggleLabel}</p>;
    }

    return toggleLabel;
  };

  return (
    <div className="warning-banner lighter-blue column-gap-12px border-0">
      <div className="row-gap-12px align-items-center">
        <Toggle
          handleToggle={() => {
            setToggle(!toggleValue);
          }}
          isOn={!!toggleValue}
        />
        {getLabel()}
      </div>
      <p>{toggleMessage}</p>
    </div>
  );
};

export default ToggleBox;
