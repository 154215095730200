import { ReactNode } from "react";
import { RowContent, SortDirection, TableHeader } from "./types";

export const renderTableRows = ({
  columnHeaders,
  rows,
  toggledSort,
  startIndex,
  endIndex,
}: {
  columnHeaders: TableHeader[];
  rows: RowContent[];
  toggledSort: {
    headerName: string;
    sortDirection: SortDirection;
    sortInverse?: boolean;
    isDate?: boolean;
  } | null;
  startIndex?: number;
  endIndex?: number;
}) => {
  const startIdx = startIndex || 0;
  const endIdx = endIndex || rows.length;

  // If the toggle sort is null, just render the rows as is
  if (!toggledSort) {
    return rows
      .slice(startIdx, endIdx)
      .map((row, idx) => renderRowContent(columnHeaders, row, idx));
  }

  const sortedRows = [...rows].sort((a, b) => sortRows(a, b, toggledSort));

  return sortedRows
    .slice(startIdx, endIdx)
    .map((row, idx) => renderRowContent(columnHeaders, row, idx));
};

const sortRows = (
  a: RowContent,
  b: RowContent,
  {
    headerName,
    sortDirection,
    sortInverse,
    isDate,
  }: {
    headerName: string;
    sortDirection: SortDirection;
    sortInverse?: boolean;
    isDate?: boolean;
  }
) => {
  const aVal = getRowCellValue(a[headerName], true);
  const bVal = getRowCellValue(b[headerName], true);

  const aIsDefined = !!(aVal || aVal === 0);
  const bIsDefined = !!(bVal || bVal === 0);

  let returnVal = 0;

  // If a is defined and b is not, a should always come first regardless of sort direction
  if (!aIsDefined && bIsDefined) {
    returnVal = -1;
  }

  // If a is not defined and b is, b should always come first regardless of sort direction
  if (aIsDefined && !bIsDefined) {
    returnVal = 1;
  }

  // if its a date, compare dates and skip the rest
  if (isDate) {
    return (
      compareDates(aVal as string, bVal as string) *
      (sortInverse ? -1 : 1) *
      (sortDirection === "up" ? -1 : 1)
    );
  }

  // If a and b are both defined, sort them
  if (aIsDefined && bIsDefined) {
    returnVal = aVal < bVal ? -1 : 1;
  }

  if (sortInverse) {
    returnVal *= -1;
  }

  if (sortDirection === "down") {
    returnVal *= -1;
  }

  // Catch all
  return returnVal;
};

const compareDates = (a: string, b: string) => {
  try {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate < bDate ? -1 : 1;
  } catch (error) {
    return 0;
  }
};

const renderRowContent = (
  columnHeaders: TableHeader[],
  row: RowContent,
  idx: number
) => {
  return (
    <tr
      key={idx}
      className={`${row.onRowClick ? "clickable " : " "}${
        row.className ? row.className : ""
      }`}
      onClick={row.onRowClick}
    >
      {columnHeaders.map(({ key }, idx) => {
        const onCellClick = getCellOnClick(row[key]);
        return (
          <td
            key={`${key}${idx}`}
            onClick={onCellClick ? onCellClick : undefined}
            className={onCellClick ? "clickable" : undefined}
          >
            {getRowCellValue(row[key])}
          </td>
        );
      })}
    </tr>
  );
};

const getCellOnClick = (cellValue: RowContent[keyof RowContent]) => {
  if (typeof cellValue === "object" && cellValue !== null) {
    return cellValue?.onClick;
  }

  return null;
};

const getRowCellValue = (
  cellValue:
    | {
        displayValue: ReactNode;
        sortValue: string | number | boolean | null;
      }
    | string
    | number
    | boolean
    | null
    | (() => void)
    | undefined,
  sortValue?: true
) => {
  // If the value is a function, it's a button
  if (typeof cellValue === "function") {
    return null;
  }

  // If the value is an object, it's a custom object with a displayValue and sortValue
  if (typeof cellValue === "object" && cellValue !== null) {
    return sortValue ? cellValue?.sortValue : cellValue?.displayValue;
  }

  // If we are asking for the sort value, just return the cellValue otherwise returning N/A will mess up the sorting.
  if (sortValue) {
    return cellValue;
  }

  // If the value is a number, return it as is
  if (typeof cellValue === "number") {
    return cellValue;
  }

  return cellValue || "";
};
