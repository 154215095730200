import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import ActivityMonitorContainer from "app/components/ActivityMonitor/ActivityMonitorContainer";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setShowModal as setShowOnboardingModal } from "app/components/Onboarding/slice";
import { getTEAMscanActivityReportToActivityMonitorBoxProps } from "../helpers";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getTEAMscanActivitySnapshot,
  selectGetTEAMscanActivitySnapshotStatus,
  selectTEAMscanActivitySnapshotByDateInterval,
} from "../slice";
import Loading from "app/storybookComponents/Loading";
import ActivityMonitorContainerEmptyContent from "app/components/ActivityMonitor/ActivityMonitorContainerEmptyContent";
import { showScheduleAssessmentModal } from "app/components/LaunchAssessmentModal/slice";

export const RANGE_OPTIONS: Record<string, "past90Days" | "past365Days"> = {
  "Past 90 days": "past90Days",
  "Past 365 days": "past365Days",
};

const TeamScanActivityCard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [timeInterval, setTimeInterval] = useState("Past 90 days");
  const teamScanActivitySnapshotByDateInterval = useAppSelector(
    selectTEAMscanActivitySnapshotByDateInterval
  );
  const getTEAMscanActivitySnapshotStatus = useAppSelector(
    selectGetTEAMscanActivitySnapshotStatus
  );

  useEffect(() => {
    dispatch(getTEAMscanActivitySnapshot(RANGE_OPTIONS[timeInterval]));
  }, [dispatch, timeInterval]);

  const teamScanActivitySnapshot = useMemo(() => {
    const activeKey = RANGE_OPTIONS[timeInterval];
    return activeKey ? teamScanActivitySnapshotByDateInterval[activeKey] : null;
  }, [teamScanActivitySnapshotByDateInterval, timeInterval]);

  const onTagClick = (tag: string) => {
    setTimeInterval(tag);
  };

  const getEmptyState = (): {
    isEmpty: boolean;
    emptyStateContent: React.ReactElement;
  } => {
    if (getTEAMscanActivitySnapshotStatus === "loading") {
      return {
        isEmpty: true,
        emptyStateContent: <Loading />,
      };
    }
    const showWeeklyCheckInActivityEmptyState = !teamScanActivitySnapshot;

    return {
      isEmpty: showWeeklyCheckInActivityEmptyState,
      emptyStateContent: (
        <ActivityMonitorContainerEmptyContent
          title="Measure Teamwork"
          button={
            <div className="column-gap-8px">
              <Button
                onClick={() => {
                  dispatch(showScheduleAssessmentModal());
                }}
              >
                Launch TEAMscan
              </Button>
              <Button
                variant="secondary-blue"
                className="border-0"
                onClick={() => {
                  dispatch(
                    setShowOnboardingModal({
                      eventType: "team360LeadViewNotScheduledYet",
                    })
                  );
                }}
              >
                Learn more
              </Button>
            </div>
          }
          description="Launch the TEAMscan survey for teams, departments, or your entire organization to start building team excellence."
        />
      ),
    };
  };

  const teamScanTimeInterval = timeInterval
    .split("Past")[1]
    .trim()
    .toLowerCase();

  const { isEmpty, emptyStateContent } = getEmptyState();

  return (
    <ActivityMonitorContainer
      title="TEAMscan Activity"
      labelTags={Object.keys(RANGE_OPTIONS)}
      activeTag={timeInterval}
      onTagClick={onTagClick}
      boxProps={getTEAMscanActivityReportToActivityMonitorBoxProps(
        teamScanActivitySnapshot,
        teamScanTimeInterval
      )}
      timeInterval={teamScanTimeInterval}
      footer={
        <Button
          variant="tertiary-blue"
          onClick={() => {
            navigate("/AdminConsole/TEAMscan Activity");
          }}
          className="activity-monitor-container__footer-button"
        >
          View activity <FontAwesomeIcon icon={["fas", "arrow-right"]} />
        </Button>
      }
      isEmpty={isEmpty}
      emptyStateContent={emptyStateContent}
    />
  );
};

export default TeamScanActivityCard;
