import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import Button from "app/storybookComponents/Button";
import { Card, Dropdown, Form } from "react-bootstrap";
import { getS, getSelectProps } from "utils/helperFunctions";
import {
  WeeklyCheckInEntityTypes,
  WeeklyCheckInInitialEntityType,
  WeeklyCheckInInitialTimeInterval,
  OverallActivityTimeIntervals,
  NA_IMPORTANT_COMPANY_ACCOUNTS,
} from "../constants";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getOverallActivityTableData,
  selectDepartments,
  selectGetOverallActivityTableDataStatus,
  selectAllOverallActivityTableData,
  selectAiFeaturesEnabled,
} from "../slice";
import ExportCSVButton from "app/storybookComponents/Button/ExportCSVButton";
import SortableTable from "app/components/SortableTable";
import Loading from "app/storybookComponents/Loading";
import {
  selectAllCompanyUsersById,
  selectCompanyInfo,
  selectConfigCatFlag,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { OverallActivityTableRow, SelectedActivityRow } from "../types";
import EmptyCard from "app/storybookComponents/Cards/EmptyCard";
import { trackCSVExport } from "utils/trackingFunctions";
import {
  getLoginCountWarningMessageText,
  getOutOfFiveColor,
} from "app/containers/Dashboard/helpers";
import { TableHeader } from "app/components/SortableTable/types";
import { useNavigate } from "react-router-dom";
import { getOverallActivityTableDataFromSelectedRow } from "../helpers";
import TableDropdownMenu from "app/components/Dropdowns/TableDropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimplePopover from "app/components/Popovers/SimplePopover";
import { IS_AU_INSTANCE } from "utils/environmentVariables";
import { CONFIG_CAT_FLAGS } from "app/components/GlobalNavbar/constants";

const OverallActivityTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ---------------------------- Selectors ----------------------------
  const getOverallActivityTableDataStatus = useAppSelector(
    selectGetOverallActivityTableDataStatus
  );
  const allOverallActivityTableData = useAppSelector(
    selectAllOverallActivityTableData
  );
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const areAIFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const weeklyCheckInConfigCatFlag = useAppSelector(
    selectConfigCatFlag(CONFIG_CAT_FLAGS.weeklyCheckIn.savedFlagName)
  );
  const companyInfo = useAppSelector(selectCompanyInfo);

  // ---------------------------- States ----------------------------
  const [entitySelected, setEntitySelected] = useState(
    WeeklyCheckInInitialEntityType
  );
  const [timeInterval, setTimeInterval] = useState(
    WeeklyCheckInInitialTimeInterval
  );
  const [displayedInput, setDisplayedInput] = useState<string>("");
  const [breakdownRowSelectedArray, setBreakdownRowSelectedArray] = useState<
    SelectedActivityRow[]
  >([]);
  const breakdownRowSelected = useMemo(
    () => breakdownRowSelectedArray[0] ?? null,
    [breakdownRowSelectedArray]
  ) as SelectedActivityRow;

  // ---------------------------- Effect ----------------------------

  useEffect(() => {
    dispatch(
      getOverallActivityTableData({
        entityType: entitySelected,
        timeInterval,
      })
    );
  }, [dispatch, timeInterval, entitySelected]);

  // ---------------------------- Handler Functions ----------------------------
  const onSetBreakdownState = (entityId: number, entityType: string) => {
    if (entityType === "user") {
      return;
    }

    if (entityType === "team") {
      const team = allTeamsById[entityId];
      const userAccountIds = team?.teamMemberIds ?? [];
      setEntitySelected("user");
      dispatch(
        getOverallActivityTableData({ entityType: "user", timeInterval })
      );
      return setBreakdownRowSelectedArray([
        {
          type: "team",
          teamId: entityId,
          userAccountIds,
        },
        ...breakdownRowSelectedArray,
      ]);
    }

    setEntitySelected("team");
    dispatch(getOverallActivityTableData({ entityType: "team", timeInterval }));
    const teamIds = departments[entityId]?.teams ?? [];
    const userAccountIds = teamIds
      .map((teamId) => {
        const team = allTeamsById[teamId];
        return team?.teamMemberIds ?? [];
      })
      .flat();

    setBreakdownRowSelectedArray([
      {
        type: "department",
        departmentId: entityId,
        teamIds,
        userAccountIds,
      },
      ...breakdownRowSelectedArray,
    ]);
  };

  // ---------------------------- Getter Helper Functions ----------------------------

  const overallActivityTableData = useMemo(() => {
    if (breakdownRowSelected) {
      return getOverallActivityTableDataFromSelectedRow(
        allOverallActivityTableData,
        breakdownRowSelected,
        timeInterval,
        entitySelected
      );
    }

    return Object.values(
      allOverallActivityTableData?.[timeInterval]?.[entitySelected] ?? {}
    );
  }, [
    breakdownRowSelected,
    allOverallActivityTableData,
    entitySelected,
    timeInterval,
  ]);

  const getFilteredRows = useCallback((): OverallActivityTableRow[] => {
    // First we sort the rows by date and then by user name
    const rows = [...overallActivityTableData];

    // If there is no input, return all rows
    if (!displayedInput) {
      return overallActivityTableData;
    }

    if (entitySelected === "team") {
      return rows.filter((row) => {
        const team = allTeamsById[row.entityId];
        if (!team) {
          return false;
        }
        return team.teamName
          .toLowerCase()
          .includes(displayedInput.toLowerCase());
      });
    }

    if (entitySelected === "department") {
      return rows.filter((row) => {
        const department = departments[row.entityId];
        if (!department) {
          return false;
        }
        return department.name
          ?.toLowerCase()
          .includes(displayedInput.toLowerCase());
      });
    }

    // Otherwise, filter the rows by user name or email
    return rows.filter((row) => {
      const user = usersById[row.entityId];
      if (!user) {
        return false;
      }
      const { firstName = "", lastName = "", emailAddress } = user;
      const fullName = `${firstName} ${lastName}`.trim();
      return (
        fullName.toLowerCase().includes(displayedInput.toLowerCase()) ||
        emailAddress.toLowerCase().includes(displayedInput.toLowerCase())
      );
    });
  }, [
    overallActivityTableData,
    usersById,
    displayedInput,
    entitySelected,
    departments,
    allTeamsById,
  ]);

  const getSearchPlaceholder = () => {
    if (entitySelected === "department") {
      return "Search by department";
    } else if (entitySelected === "team") {
      return "Search by team";
    }
    return "Search by user";
  };

  const getEntityDisplayValue = (
    title: {
      label: string;
      onClick: () => void;
    },
    description?: string
  ) => (
    <div>
      <div className="user-name-cell">
        <button className="button-link" onClick={title.onClick}>
          <p className="user-name-cell__name">{title.label}</p>
        </button>
        {description ? (
          <p className="user-name-cell__job-title">{description}</p>
        ) : null}
      </div>
    </div>
  );

  const getUserCell = (userAccountId: number) => {
    const user = usersById[userAccountId];
    if (!user) {
      return "";
    }
    const { firstName = "", lastName = "", emailAddress } = user;
    const jobTitle = user.jobTitle || "No job title";
    const fullName =
      `${firstName ?? ""} ${lastName ?? ""}`.trim() || emailAddress;
    return {
      sortValue: fullName,
      displayValue: getEntityDisplayValue(
        {
          label: fullName,
          onClick: () => {
            navigate(`/UserGuide/${userAccountId}`);
          },
        },
        jobTitle
      ),
    };
  };

  const getDepartmentCell = (departmentId: number) => {
    const department = departments[departmentId] ?? {};
    const userAccountIds = department.teamMembers ?? [];
    const memberCount = userAccountIds?.length ?? 0;
    const entityDescription = `${memberCount} member${getS(memberCount)}`;

    const { name = "" } = department;

    return {
      sortValue: name,
      displayValue: getEntityDisplayValue(
        {
          label: name,
          onClick: () => {
            onSetBreakdownState(departmentId, "department");
          },
        },
        entityDescription
      ),
    };
  };

  const getTeamCell = (teamId: number) => {
    const team = allTeamsById[teamId] ?? {};
    const userAccountIds = team.teamMemberIds ?? [];
    const memberCount = userAccountIds?.length ?? 0;
    const entityDescription = `${memberCount} member${getS(memberCount)}`;
    const { teamName = "" } = team;
    return {
      sortValue: teamName,
      displayValue: getEntityDisplayValue(
        {
          label: teamName,
          onClick: () => {
            onSetBreakdownState(teamId, "team");
          },
        },
        entityDescription
      ),
    };
  };

  const getSearchInput = () => {
    const { selectStyles, components } = getSelectProps();

    return (
      <Form.Group>
        <Select
          placeholder={getSearchPlaceholder()}
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={displayedInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setDisplayedInput(e);
            }
          }}
        />
      </Form.Group>
    );
  };

  const getDropdownFromRecord = (
    record: Record<string, string>,
    selected: string,
    onSelect: (str: string | null) => void,
    isDisabled: boolean = false
  ) => (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="d-flex align-items-center justify-content-between"
        style={{ width: "200px" }}
        variant="light"
        disabled={isDisabled}
      >
        {record[selected]}
      </Dropdown.Toggle>
      <TableDropdownMenu>
        {Object.entries(record).map(([key, value], idx) => (
          <>
            <Dropdown.Item eventKey={key} key={key}>
              {value}
            </Dropdown.Item>
            {idx !== Object.keys(record).length - 1 && <Dropdown.Divider />}
          </>
        ))}
      </TableDropdownMenu>
    </Dropdown>
  );

  const getMembersWithMoreThanOneChatCell = (
    memberCount: number,
    peopleWithMoreThanOneChatCount: number = 0
  ) => {
    const percentage = memberCount
      ? (peopleWithMoreThanOneChatCount / memberCount) * 100
      : 0;
    const percentageString = percentage.toFixed() + "%";
    const dotColor = getOutOfFiveColor(percentage / 20);
    return {
      sortValue: percentageString,
      displayValue: (
        <div className="row-gap-8px align-items-center">
          <p>{percentageString}</p>
          <div className={`status-dot ${dotColor}`} />
        </div>
      ),
    };
  };

  const getTableRow = ({
    entityId,
    peopleWithMoreThanOneChatCount,
    logonCount,
    coachBoChatCount,
    weeklyCheckInCount,
    completedTeamScanCount,
  }: OverallActivityTableRow) => {
    if (entitySelected === "user") {
      return {
        logonCount,
        coachBoChatCount,
        weeklyCheckInCount,
        user: getUserCell(entityId),
      };
    }

    if (entitySelected === "team") {
      const team = allTeamsById[entityId];
      const memberCount = team?.teamMemberIds?.length ?? 0;
      const peopleWithMoreThanOneChatCountCell =
        getMembersWithMoreThanOneChatCell(
          memberCount,
          peopleWithMoreThanOneChatCount
        );
      return {
        logonCount,
        coachBoChatCount,
        weeklyCheckInCount,
        completedTeamScanCount,
        team: getTeamCell(entityId),
        peopleWithMoreThanOneChatCount: peopleWithMoreThanOneChatCountCell,
      };
    }

    const department = departments[entityId];
    const memberCount = department?.teamMembers?.length ?? 0;
    const peopleWithMoreThanOneChatCountCell =
      getMembersWithMoreThanOneChatCell(
        memberCount,
        peopleWithMoreThanOneChatCount
      );
    return {
      logonCount,
      coachBoChatCount,
      weeklyCheckInCount,
      completedTeamScanCount,
      department: getDepartmentCell(entityId),
      peopleWithMoreThanOneChatCount: peopleWithMoreThanOneChatCountCell,
    };
  };

  const getNameCSVCellValue = useCallback(
    (entityId: number, entitySelected: string) => {
      if (entitySelected === "department") {
        return departments[entityId]?.name ?? "";
      }

      if (entitySelected === "team") {
        return allTeamsById[entityId]?.teamName ?? "";
      }
      const user = usersById[entityId];
      const { firstName = "", lastName = "" } = user ?? {};
      return `${firstName} ${lastName}`.trim();
    },
    [usersById, departments, allTeamsById]
  );

  const getCSVExportRows = useCallback(
    () =>
      getFilteredRows().map(
        ({
          entityId,
          logonCount,
          coachBoChatCount,
          peopleWithMoreThanOneChatCount = 0,
          completedTeamScanCount = 0,
          weeklyCheckInCount = 0,
        }) => {
          trackCSVExport("Overall Activity");

          let memberCount = 0;

          if (entitySelected === "team") {
            memberCount = allTeamsById[entityId]?.teamMemberIds?.length ?? 0;
          } else if (entitySelected === "department") {
            memberCount = departments[entityId]?.teamMembers?.length ?? 0;
          }

          const percentage = memberCount
            ? (peopleWithMoreThanOneChatCount / memberCount) * 100
            : 0;

          return {
            [entitySelected]: getNameCSVCellValue(entityId, entitySelected),
            logonCount: Number(logonCount),
            coachBoChatCount,
            peopleWithMoreThanOneChatCount: percentage.toFixed() + "%",
            completedTeamScanCount,
            weeklyCheckInCount,
          };
        }
      ),
    [
      getFilteredRows,
      getNameCSVCellValue,
      entitySelected,
      departments,
      allTeamsById,
    ]
  );

  const getHeaders = () => {
    const isAllianz =
      !IS_AU_INSTANCE &&
      NA_IMPORTANT_COMPANY_ACCOUNTS.Allianz === companyInfo?.companyAccountId;
    const nameHeaderLabel =
      entitySelected.charAt(0).toUpperCase() + entitySelected.slice(1);
    const mainHeaders: TableHeader[] = [
      {
        label: nameHeaderLabel,
        key: entitySelected,
        className: "entity-header",
      },
      {
        label: "Logins",
        key: "logonCount",
        sortInverse: true,
        additionalContent: !isAllianz ? (
          <SimplePopover
            popoverContent={
              <div>
                <p>{getLoginCountWarningMessageText()}</p>
              </div>
            }
            trigger="hover"
            popoverTitle=""
            popoverClassName="checkin-score-popover"
            hideTitleAndCloseButton
          >
            <FontAwesomeIcon
              icon={["far", "exclamation-triangle"]}
              style={{ paddingLeft: "5px" }}
            />
          </SimplePopover>
        ) : null,
      },
      { label: "Coach Bo Chats", key: "coachBoChatCount", sortInverse: true },
    ];

    if (weeklyCheckInConfigCatFlag && areAIFeaturesEnabled) {
      mainHeaders.push({
        label: "Weekly Check-Ins",
        key: "weeklyCheckInCount",
        sortInverse: true,
      });
    }

    if (entitySelected !== "user") {
      return [
        ...mainHeaders,
        {
          label: "TEAMscans Completed",
          key: "completedTeamScanCount",
          sortInverse: true,
        },
        {
          label: "% People With 1+ Coach Bo Chats",
          key: "peopleWithMoreThanOneChatCount",
          disableSort: true,
        },
      ];
    }

    return mainHeaders;
  };

  const getEmptyState = () => {
    if (getOverallActivityTableDataStatus === "loading") {
      return <Loading />;
    }

    if (overallActivityTableData.length === 0) {
      return (
        <EmptyCard
          title="No overall activity found"
          bodyText="Once there is overall activity, it will be shown here."
        />
      );
    }

    if (rows.length === 0) {
      return (
        <EmptyCard
          title={`No ${entitySelected} found matching "${displayedInput}"`}
          bodyText="Try searching for a different name."
        />
      );
    }

    return null;
  };

  const onBackNavigate = () => {
    const newBreakdownRowSelectedArray = [...breakdownRowSelectedArray];
    const removedSelectedRow = newBreakdownRowSelectedArray.shift();

    if (removedSelectedRow?.type) {
      setEntitySelected(removedSelectedRow?.type);
    }
    setBreakdownRowSelectedArray(newBreakdownRowSelectedArray);
  };

  const getBackButton = () => {
    if (!breakdownRowSelected) {
      return null;
    }

    return (
      <Button
        variant="secondary-blue"
        style={{ border: "none", marginBottom: "20px" }}
        onClick={() => {
          onBackNavigate();
        }}
      >
        <FontAwesomeIcon icon="arrow-left" className="me-2" /> Back
      </Button>
    );
  };

  const getEntitySelectedText = () => {
    if (!breakdownRowSelected) {
      return "";
    }

    if (breakdownRowSelected.type === "department") {
      const departmentName =
        departments[breakdownRowSelected.departmentId]?.name ?? "";
      return departmentName.toLowerCase().includes("department")
        ? departmentName
        : `${departmentName} Department`;
    }

    const teamName = allTeamsById[breakdownRowSelected.teamId]?.teamName;
    return teamName.toLowerCase().includes("team")
      ? teamName
      : `${teamName} Team`;
  };

  const getTitleSection = () => {
    if (!breakdownRowSelected) {
      return (
        <div className="column-gap-8px">
          <h2>Overall Activity</h2>
          <p>Showing activity in this entire organization</p>
        </div>
      );
    }

    const entitySelectedText = getEntitySelectedText();
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="column-gap-8px">
          <h2>Overall Activity Breakdown</h2>
          <div className="row-gap-4px grey-text">
            <p>
              Overall activity for {entitySelectedText} from{" "}
              {OverallActivityTimeIntervals[timeInterval]}
            </p>
          </div>
        </div>
        <ExportCSVButton
          headers={headers.filter((header) => header.key !== "dropdown")}
          getRows={getCSVExportRows}
        />
      </div>
    );
  };

  const getDropdownSection = () => {
    if (breakdownRowSelected?.type === "department") {
      return getDropdownFromRecord(
        {
          user: WeeklyCheckInEntityTypes.user,
          team: WeeklyCheckInEntityTypes.team,
        },
        entitySelected,
        (e) => {
          setEntitySelected(e ?? "user");
        },
        isDropdownDisabled
      );
    }

    if (breakdownRowSelected) {
      return null;
    }

    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="row-gap-12px">
          {getDropdownFromRecord(
            OverallActivityTimeIntervals,
            timeInterval,
            (e) => {
              setTimeInterval(e ?? "allTime");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
          {getDropdownFromRecord(
            WeeklyCheckInEntityTypes,
            entitySelected,
            (e) => {
              setEntitySelected(e ?? "user");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
        </div>
        <div>
          <ExportCSVButton
            headers={headers.filter((header) => header.key !== "dropdown")}
            getRows={getCSVExportRows}
            fileName="Overall Activity"
          />
        </div>
      </div>
    );
  };

  const isDropdownDisabled = getOverallActivityTableDataStatus === "loading";
  const headers = getHeaders();
  const rows = getFilteredRows().map(getTableRow);

  return (
    <>
      {getBackButton()}
      <Card>
        {getTitleSection()}
        {getSearchInput()}
        {getDropdownSection()}
        <SortableTable
          rows={rows}
          tableClassName="admin-console-table"
          columnHeaders={[...headers]}
        />
        {getEmptyState()}
      </Card>
    </>
  );
};

export default OverallActivityTable;
