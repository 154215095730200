import Button from "app/storybookComponents/Button";
import SurveysTable from "../AdminConsole/Tables/SurveysTable";
import SurveyIllustration from "resources/images/survey-illustration.png";
import { useAppDispatch } from "utils/redux/hooks";
import { setShowModal } from "app/components/Onboarding/slice";
import AdminTablesHeader from "app/containers/AdminConsole/AdminTablesHeader";
import { showScheduleAssessmentModalForDepartmentId } from "app/components/LaunchAssessmentModal/slice";
import { useNavigate } from "react-router-dom";

interface Props {
  departmentId: number;
  isLeader?: boolean;
  showHeader?: boolean;
}
export default function DepartmentSurveys({
  departmentId,
  isLeader,
  showHeader = true,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getEmptyStateActions = () => {
    const seeDetailsButton = (
      <Button
        variant="secondary-blue"
        className="white-button"
        onClick={() => {
          dispatch(
            setShowModal({
              eventType: "visitedTeam360TabWithResults",
            })
          );
        }}
      >
        See details
      </Button>
    );

    if (!isLeader) {
      return <div className="action-buttons">{seeDetailsButton}</div>;
    }

    return (
      <div className="action-buttons">
        <Button
          onClick={() => {
            dispatch(showScheduleAssessmentModalForDepartmentId(departmentId));
          }}
        >
          Launch Survey
        </Button>
        {seeDetailsButton}
      </div>
    );
  };

  return (
    <>
      {showHeader ? (
        <AdminTablesHeader
          bannerDescription="Send the TEAMscan survey to your department or teams in your department."
          bannerTitle="Launch the TEAMscan survey"
          iconImageSrc={SurveyIllustration}
          iconImageAlt="Launch TEAMscan"
          actionButton={
            <div className="row-gap-12px">
              <Button
                onClick={() => {
                  dispatch(
                    showScheduleAssessmentModalForDepartmentId(departmentId)
                  );
                }}
              >
                Launch TEAMscan
              </Button>
              <Button
                onClick={() => {
                  navigate("/SkillsGuide");
                }}
                variant="secondary-blue"
                className="white-button"
              >
                See teamwork skills
              </Button>
            </div>
          }
        />
      ) : null}
      <div>
        <SurveysTable
          departmentId={departmentId}
          emptyStateActions={getEmptyStateActions()}
          elementBetweenSearchAndTable={
            <p
              style={{
                margin: "24px 0",
              }}
            >
              Monitor completion and edit survey settings for TEAMscans here. To
              view results or edit settings, click the menu icon to find more
              actions for each instance.
            </p>
          }
        />
      </div>
    </>
  );
}
