// --------------- Half Donut Chart Helpers --------------- //
export const getRadiusAndOffset = (
  size: "small" | "medium" | "large" | "extra-small"
): { radius1: number; radius2: number; offset: number; cutout: number } => {
  switch (size) {
    case "extra-small":
      return {
        radius1: 8,
        radius2: 4,
        offset: 8,
        cutout: 81,
      };
    case "small":
    default:
      return {
        radius1: 10,
        radius2: 5,
        offset: 11,
        cutout: 88,
      };
  }
};

const HALF_DONUT_COLOR_MAP = {
  veryLow: {
    inactive: "#FAE5EE",
    active: "#AA0045",
  },
  low: {
    inactive: "#FFEEEB",
    active: "#FF5C39",
  },
  medium: {
    inactive: "#FFF7CC",
    active: "#CCAB00",
  },
  high: {
    inactive: "#CCF2E1",
    active: "#00c067",
  },
  veryHigh: {
    inactive: "#D9DEF4",
    active: "#425CC7",
  },
};

export const dialPlugin = {
  id: "dialPlugin",
  afterDraw: function (chart: any) {
    const {
      ctx,
      data,
      chartArea: { width },
    } = chart;
    ctx.save();
    // Code to show needle, will use to show the current value eventually
    const needleValue = data.datasets?.[0]?.needleValue as number;
    const dataTotal = data.datasets?.[0]?.data.reduce(
      (acc: number, score: number) => acc + score,
      0
    );
    const needleAngle = Math.PI + (needleValue / dataTotal) * Math.PI;
    const size = data.datasets?.[0]?.size as
      | "small"
      | "medium"
      | "large"
      | "extra-small";
    const cx = chart._metasets[0].data[0].x;
    const cy = chart._metasets[0].data[0].y;
    const { radius1, radius2, offset } = getRadiusAndOffset(size);

    ctx.translate(cx, cy);
    ctx.rotate(needleAngle);
    ctx.beginPath();
    ctx.moveTo(0, -2);
    ctx.translate(width / 2 - offset, 0);

    // border circle radius code snippet
    // ctx.beginPath();
    // const radius0 = radius1 + 2;
    // ctx.arc(0, 0, radius0, 0, 2 * Math.PI); // outer circle
    // ctx.fillStyle = 'black'; // set the fill color
    // ctx.fill();

    ctx.beginPath();
    ctx.arc(0, 0, radius1, 0, 2 * Math.PI); // outer circle

    let totalData = 0;
    data.datasets?.[0]?.data.some((scoreRange: number, idx: number) => {
      if (scoreRange + totalData >= needleValue) {
        ctx.fillStyle = Object.values(HALF_DONUT_COLOR_MAP)[idx].active; // set the fill color
        return true;
      }
      totalData += scoreRange;
      return false;
    });
    ctx.fill();
    ctx.beginPath();
    ctx.arc(0, 0, radius2, 0, 2 * Math.PI); // inner circle
    ctx.fillStyle = "white"; // set the fill color to white
    ctx.fill();

    ctx.restore();
  },
};

// This function is used to get the colors for the dial chart, we then use the dataPoint to shade in the color that the dataPoint is in

export const getDataColors = (data: number[], dataPoint: number): string[] => {
  let totalData = 0;
  const colors: string[] = [];
  Object.values(HALF_DONUT_COLOR_MAP).forEach((elm, idx) => {
    if (!data[idx]) {
      return;
    }
    const minRange = totalData;
    const maxRange = totalData + data[idx];
    totalData += data[idx];

    if (dataPoint > minRange && dataPoint <= maxRange) {
      return colors.push(elm.active);
    }

    return colors.push(elm.inactive);
  });
  return colors;
};

export const getWordCloudLabelsAndData = (
  data: {
    text: string;
    value: number;
  }[]
) => {
  const labels: string[] = [];
  const dataArr: number[] = [];
  data.forEach((elm) => {
    labels.push(elm.text);
    dataArr.push(elm.value);
  });
  return { labels, dataArr };
};

export const getColorFromArray = (colors: string[], index: number) =>
  colors[index % colors.length];
