import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "app/storybookComponents/Button";
import { Card, Dropdown, Form } from "react-bootstrap";
import Select from "react-select";
import { getS, getSelectProps } from "utils/helperFunctions";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getTEAMscanActivityTableData,
  selectAllTEAMscanActivityTableData,
  selectDepartments,
  selectGetTEAMscanActivityTableDataStatus,
} from "../slice";
import SortableTable from "app/components/SortableTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExportCSVButton from "app/storybookComponents/Button/ExportCSVButton";
import { updateTeam360DateRange as updateTeamsTeam360DateRange } from "app/containers/TeamGuide/slice";
import { updateTeam360DateRange as updateDepartmentsTeam360DateRange } from "app/containers/DepartmentInsightReport/slice";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { trackCSVExport } from "utils/trackingFunctions";
import { TableHeader } from "app/components/SortableTable/types";
import Loading from "app/storybookComponents/Loading";
import {
  GetTEAMscanActivityTablePayload,
  SelectedTEAMscanBreakdownEntity,
  TEAMscanActivityTableRow,
} from "../types";
import EmptyCard from "app/storybookComponents/Cards/EmptyCard";
import TableDropdownMenu from "app/components/Dropdowns/TableDropdownMenu";
import { TEAMscanTimeIntervals } from "../constants";
import { useNavigate } from "react-router-dom";
import {
  getStartAndEndDateForInstanceId,
  getTEAMscanTableDataFromSelectedRow,
} from "../helpers";
import { percentageColor } from "app/containers/Dashboard/helpers";
import {
  getDaysRemaining,
  getScoreClassName,
} from "app/containers/Assessment/helpers";
import { selectAllTeamAssessmentsInstances } from "app/containers/Assessment/slice";

const TEAMscanActivityTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ---------------------------- Selectors ----------------------------
  const departments = useAppSelector(selectDepartments);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const allTEAMscanActivityTableData = useAppSelector(
    selectAllTEAMscanActivityTableData
  );
  const getTEAMscanActivityTableDataStatus = useAppSelector(
    selectGetTEAMscanActivityTableDataStatus
  );
  const allTeamAssessmentInstances = useAppSelector(
    selectAllTeamAssessmentsInstances
  );

  // ---------------------------- States ----------------------------
  const [displayedInput, setDisplayedInput] = useState<string>("");
  const [entitySelected, setEntitySelected] = useState<"team" | "department">(
    "team"
  );
  const [timeInterval, setTimeInterval] = useState("allTime");
  const [breakdownRowSelectedArray, setBreakdownRowSelectedArray] = useState<
    SelectedTEAMscanBreakdownEntity[] // Even though an array is not needed, will keep incase we want to nest one level deeper
  >([]);

  const breakdownRowSelected = useMemo(
    () => breakdownRowSelectedArray[0] ?? null,
    [breakdownRowSelectedArray]
  ) as SelectedTEAMscanBreakdownEntity | null;

  // ---------------------------- Effect ----------------------------

  useEffect(() => {
    dispatch(
      getTEAMscanActivityTableData({
        timeFrame: timeInterval as GetTEAMscanActivityTablePayload["timeFrame"],
      })
    );
  }, [dispatch, timeInterval, entitySelected]);

  // ---------------------------- Handler Functions ----------------------------

  const onSetDepartmentBreakdown = (row: TEAMscanActivityTableRow) => {
    const {
      id: departmentId,
      startDate,
      endDate = "",
      teamsInAssessmentInstance,
    } = row;
    setEntitySelected("team");
    const teamIds =
      teamsInAssessmentInstance ?? departments[departmentId]?.teams ?? [];

    setBreakdownRowSelectedArray([
      {
        type: "department",
        departmentId,
        teamIds,
        startDate,
        endDate,
      },
      ...breakdownRowSelectedArray,
    ]);
  };

  const onSeeTeamScanResultsForRow = (
    { id, assessmentInstanceId }: TEAMscanActivityTableRow,
    isDepartmentRow?: boolean
  ) => {
    const startAndEndDate = getStartAndEndDateForInstanceId(
      assessmentInstanceId,
      allTeamAssessmentInstances
    );

    if (!startAndEndDate) {
      return;
    }

    if (isDepartmentRow) {
      dispatch(
        updateDepartmentsTeam360DateRange({
          departmentId: id,
          startDate: startAndEndDate.startDate,
          endDate: startAndEndDate.endDate,
          instance: startAndEndDate.startDate,
        })
      );
    } else {
      dispatch(
        updateTeamsTeam360DateRange({
          teamId: id,
          startDate: startAndEndDate.startDate,
          endDate: startAndEndDate.endDate,
          instance: startAndEndDate.startDate,
        })
      );
    }
    navigate(
      `/${isDepartmentRow ? "Department" : "Team"}Guide/${id}?tab=TEAMscan`
    );
  };

  const onRowDropdownSelect = (
    e: string | null,
    row: TEAMscanActivityTableRow
  ) => {
    if (e === "teamScan") {
      onSeeTeamScanResultsForRow(row, entitySelected === "department");
    } else if (e === "breakdown" && entitySelected === "department") {
      onSetDepartmentBreakdown(row);
    }
  };

  // ---------------------------- Getter Functions ----------------------------
  const activityTableData = useMemo(() => {
    if (breakdownRowSelected) {
      return getTEAMscanTableDataFromSelectedRow(
        allTEAMscanActivityTableData,
        breakdownRowSelected,
        timeInterval,
        entitySelected
      );
    }

    const entitySelectedText = `${entitySelected}s`;
    return Object.values(
      allTEAMscanActivityTableData?.[timeInterval]?.[entitySelectedText] ?? {}
    );
  }, [
    breakdownRowSelected,
    allTEAMscanActivityTableData,
    entitySelected,
    timeInterval,
  ]);

  const getFilteredRows = useCallback((): TEAMscanActivityTableRow[] => {
    const rows = [...activityTableData];
    // If there is no input, return all rows
    if (!displayedInput) {
      return activityTableData;
    }

    if (entitySelected === "team") {
      return rows.filter((row) => {
        const team = allTeamsById[row.id];
        if (!team) {
          return false;
        }
        return team.teamName
          .toLowerCase()
          .includes(displayedInput.toLowerCase());
      });
    }

    if (entitySelected === "department") {
      return rows.filter((row) => {
        const department = departments[row.id];
        if (!department) {
          return false;
        }
        return department.name
          ?.toLowerCase()
          .includes(displayedInput.toLowerCase());
      });
    }

    // Otherwise, filter the rows by user name or email
    return rows.filter((row) => {
      const user = usersById[row.id];
      if (!user) {
        return false;
      }
      const { firstName = "", lastName = "", emailAddress } = user;
      const fullName = `${firstName} ${lastName}`.trim();
      return (
        fullName.toLowerCase().includes(displayedInput.toLowerCase()) ||
        emailAddress.toLowerCase().includes(displayedInput.toLowerCase())
      );
    });
  }, [
    displayedInput,
    entitySelected,
    activityTableData,
    allTeamsById,
    departments,
    usersById,
  ]);

  const getSearchPlaceholder = () => {
    if (breakdownRowSelected) {
      return "Search for a user...";
    }
    switch (entitySelected) {
      case "team":
        return "Search for a team...";
      case "department":
        return "Search for a department...";
    }
  };

  const getSearchInput = () => {
    const { selectStyles, components } = getSelectProps();

    return (
      <Form.Group>
        <Select
          placeholder={getSearchPlaceholder()}
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={displayedInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setDisplayedInput(e);
            }
          }}
        />
      </Form.Group>
    );
  };

  const getNameCSVCellValue = useCallback(
    (entityId: number, entitySelected: string) => {
      if (entitySelected === "department") {
        return departments[entityId]?.name ?? "";
      }

      if (entitySelected === "team") {
        return allTeamsById[entityId]?.teamName ?? "";
      }
      const user = usersById[entityId];
      const { firstName = "", lastName = "" } = user ?? {};
      return `${firstName} ${lastName}`.trim();
    },
    [usersById, departments, allTeamsById]
  );

  const getCSVExportRows = useCallback(
    () =>
      getFilteredRows().map((data) => {
        const completedAssessmentEventsCount =
          data.completedAssessmentEventsCount ?? 0;
        const assessmentEventsCount = data.assessmentEventsCount ?? 0;
        const completionRatePercentage = Math.round(
          (completedAssessmentEventsCount / assessmentEventsCount) * 100
        );
        const { id } = data;
        const baseRow = {
          [entitySelected]: getNameCSVCellValue(id, entitySelected),
          overallScore: data.overallScore ?? "N/A",
          peopleOnAverageAndBelow: data.peopleOnAverageOrBelowTeams ?? "0",
          peopleNotLikelyToStayOnTeam: data.peopleNotLikelyToStayOnTeam ?? "0",
          completionRate: `${completionRatePercentage}% (${completedAssessmentEventsCount}/${assessmentEventsCount})`,
          startDate: new Date(data.startDate).toLocaleDateString("en-US"),
        };

        trackCSVExport("TEAMscan Activity");
        return baseRow;
      }),
    [getFilteredRows, getNameCSVCellValue, entitySelected]
  );

  const getDropdownFromRecord = (
    record: Record<string, string>,
    selected: string,
    onSelect: (str: string | null) => void,
    isDisabled: boolean = false
  ) => (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="d-flex align-items-center justify-content-between"
        style={{ width: "200px" }}
        variant="light"
        disabled={isDisabled}
      >
        {record[selected]}
      </Dropdown.Toggle>
      <TableDropdownMenu>
        {Object.entries(record).map(([key, value], index) => (
          <>
            <Dropdown.Item eventKey={key} key={key}>
              {value}
            </Dropdown.Item>
            {index !== Object.keys(record).length - 1 && <Dropdown.Divider />}
          </>
        ))}
      </TableDropdownMenu>
    </Dropdown>
  );

  const getDropdownSection = () => {
    if (breakdownRowSelected) {
      return null;
    }
    const isDropdownDisabled = getTEAMscanActivityTableDataStatus === "loading";
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="row-gap-12px">
          {getDropdownFromRecord(
            TEAMscanTimeIntervals,
            timeInterval,
            (e) => {
              setTimeInterval(e ?? "allTime");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
          {getDropdownFromRecord(
            {
              team: "Team",
              department: "Department",
            },
            entitySelected,
            (e) => {
              setEntitySelected((e ?? "team") as "team" | "department");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
        </div>
        <div>
          <ExportCSVButton
            headers={headers.filter((header) => header.key !== "dropdown")}
            getRows={getCSVExportRows}
          />
        </div>
      </div>
    );
  };

  const getScoreCell = (
    totalCount: number = 0,
    denominator: number = 0,
    {
      showFraction,
      inverseColor,
      hasResults,
    }: {
      showFraction?: boolean;
      inverseColor?: boolean;
      hasResults?: boolean;
    } = {
      showFraction: false,
      inverseColor: false,
      hasResults: false,
    }
  ) => {
    const percentage = Math.round((totalCount / denominator) * 100);
    const dotColor = percentageColor(percentage, inverseColor);

    const displayText = showFraction
      ? `${percentage}% (${totalCount}/${denominator})`
      : `${totalCount} (${percentage}%)`;

    const displayValue = hasResults ? (
      <div className="row-gap-8px align-items-center">
        {displayText}
        <div className={`status-dot ${dotColor}`} />
      </div>
    ) : (
      <div className="d-flex">
        <p>-</p>
      </div>
    );

    return {
      sortValue: percentage,
      displayValue,
    };
  };

  const getOverallScore = (score: number = 0, endDate: string = "") => {
    const daysRemaining = getDaysRemaining(endDate);
    const emptyScoreString =
      daysRemaining && daysRemaining > 0 ? "Results Pending" : "No Results";
    return {
      displayValue: (
        <div
          className={`verbal-tag bigger ${getScoreClassName(score)}`}
          style={{ width: "fit-content" }}
        >
          {score || emptyScoreString}
        </div>
      ),
      sortValue: score,
    };
  };

  const getEntityDisplayValue = (
    title: {
      label: string;
      onClick: () => void;
    },
    description?: string
  ) => (
    <div>
      <button className="button-link" onClick={title.onClick}>
        <p className="user-name-cell__name">{title.label}</p>
      </button>
      {description ? <p className="small-grey-text">{description}</p> : null}
    </div>
  );

  const getDepartmentCell = (row: TEAMscanActivityTableRow) => {
    const { id: departmentId } = row;
    const department = departments[departmentId] ?? {};

    const { teamsInAssessmentInstance = [] } = row;
    const teamInvitedCount = teamsInAssessmentInstance.length;

    const entityDescription = `${teamInvitedCount} team${getS(
      teamInvitedCount ?? 0
    )} invited`;

    const { name = "" } = department;

    return {
      sortValue: name,
      displayValue: getEntityDisplayValue(
        {
          label: name,
          onClick: () => {
            onSetDepartmentBreakdown(row);
          },
        },
        entityDescription
      ),
    };
  };

  const getTeamCell = (row: TEAMscanActivityTableRow) => {
    const { id: teamId } = row;
    const team = allTeamsById[teamId] ?? {};
    const userAccountIds = team.teamMemberIds ?? [];
    const memberCount = userAccountIds?.length ?? 0;
    const entityDescription = `${memberCount} member${getS(memberCount)}`;
    const { teamName = "" } = team;
    return {
      sortValue: teamName,
      displayValue: getEntityDisplayValue(
        {
          label: teamName,
          onClick: () => {
            onSeeTeamScanResultsForRow(row);
          },
        },
        entityDescription
      ),
    };
  };

  const getRowDropdown = (row: TEAMscanActivityTableRow) => ({
    displayValue: (
      <Dropdown
        onSelect={(e) => {
          onRowDropdownSelect(e, row);
        }}
      >
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-basic"
          className="no-caret"
        >
          <FontAwesomeIcon icon="ellipsis" />
        </Dropdown.Toggle>
        <TableDropdownMenu>
          <Dropdown.Item eventKey="teamScan">View TEAMscan</Dropdown.Item>
          {entitySelected === "department" ? (
            <>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="breakdown">View Breakdown</Dropdown.Item>
            </>
          ) : null}
        </TableDropdownMenu>
      </Dropdown>
    ),
    sortValue: "",
  });

  const getTableRow = (row: TEAMscanActivityTableRow) => {
    const {
      peopleOnAverageOrBelowTeams,
      peopleNotLikelyToStayOnTeam,
      overallScore,
      assessmentEventsCount,
      completedAssessmentEventsCount,
      peopleWithCompletedEvents,
      startDate,
      endDate,
    } = row;

    const hasResults = overallScore !== null && overallScore !== undefined;

    return {
      [entitySelected]:
        entitySelected === "team" ? getTeamCell(row) : getDepartmentCell(row),
      startDate: new Date(startDate).toLocaleDateString("en-US"),
      completionRate: getScoreCell(
        completedAssessmentEventsCount,
        assessmentEventsCount,
        { showFraction: true, hasResults: true }
      ),
      overallScore: getOverallScore(overallScore, endDate),
      peopleOnAverageAndBelow: getScoreCell(
        peopleOnAverageOrBelowTeams ?? 0,
        peopleWithCompletedEvents,
        { inverseColor: true, hasResults }
      ),

      peopleNotLikelyToStayOnTeam: getScoreCell(
        peopleNotLikelyToStayOnTeam ?? 0,
        peopleWithCompletedEvents,
        { inverseColor: true, hasResults }
      ),
      dropdown: getRowDropdown(row),
    };
  };

  const getTitleSection = () => {
    if (!breakdownRowSelected) {
      return (
        <div className="column-gap-8px">
          <h2>TEAMscan Activity</h2>
          <p>Showing activity in this entire organization</p>
        </div>
      );
    }

    const dataRangeString = "Aug 6 - Aug 12, 2021";

    const entitySelectedText = getEntitySelectedText();
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="column-gap-8px">
          <h2>Completion Activity Breakdown</h2>
          <div className="row-gap-4px grey-text">
            <p>
              Check-In activity for {entitySelectedText} from {dataRangeString}
            </p>
          </div>
        </div>
        <ExportCSVButton
          headers={headers.filter((header) => header.key !== "dropdown")}
          getRows={getCSVExportRows}
        />
      </div>
    );
  };

  const getEntitySelectedText = () => {
    if (!breakdownRowSelected) {
      return "";
    }

    if (breakdownRowSelected.type === "department") {
      const departmentName =
        departments[breakdownRowSelected.departmentId]?.name ?? "";
      return departmentName.toLowerCase().includes("department")
        ? departmentName
        : `${departmentName} Department`;
    }
    return "";
  };

  const getBackButton = () => {
    if (!breakdownRowSelected) {
      return null;
    }
    const onBackNavigate = () => {
      const newBreakdownRowSelectedArray = [...breakdownRowSelectedArray];
      const removedSelectedRow = newBreakdownRowSelectedArray.shift();

      if (removedSelectedRow?.type) {
        setEntitySelected(removedSelectedRow?.type);
      }
      setBreakdownRowSelectedArray(newBreakdownRowSelectedArray);
    };

    return (
      <Button
        variant="secondary-blue"
        style={{ border: "none", marginBottom: "20px" }}
        onClick={() => {
          onBackNavigate();
        }}
      >
        <FontAwesomeIcon icon="arrow-left" className="me-2" /> Back
      </Button>
    );
  };

  const getEmptyState = () => {
    if (getTEAMscanActivityTableDataStatus === "loading") {
      return <Loading />;
    }

    if (activityTableData.length === 0) {
      return (
        <EmptyCard
          title="No TEAMscan activity found"
          bodyText="Once there is TEAMscan activity, it will be shown here."
        />
      );
    }

    if (rows.length === 0) {
      return (
        <EmptyCard
          title={`No ${entitySelected} found matching "${displayedInput}"`}
          bodyText="Try searching for a different name."
        />
      );
    }

    return null;
  };

  const getHeaders = () => {
    const nameHeaderLabel =
      entitySelected.charAt(0).toUpperCase() + entitySelected.slice(1);
    const mainHeaders: TableHeader[] = [
      { label: nameHeaderLabel, key: entitySelected },
      { label: "Start Date", key: "startDate", isDate: true },
      { label: "Completion Rate", key: "completionRate", sortInverse: true },
      { label: "Teamwork Score", key: "overallScore", sortInverse: true },
      {
        label: "# People on Avg. And Below Teams",
        key: "peopleOnAverageAndBelow",
      },
      {
        label: "# People Not Likely To Stay On Team",
        key: "peopleNotLikelyToStayOnTeam",
      },
      {
        key: "dropdown",
        label: "",
        className: "table-dropdown-header",
      },
    ];

    return mainHeaders;
  };

  const rows = getFilteredRows().map(getTableRow);
  const headers = getHeaders();

  return (
    <>
      {getBackButton()}

      <Card>
        {getTitleSection()}
        {getSearchInput()}
        {getDropdownSection()}
        <SortableTable
          rows={rows}
          tableClassName="admin-console-table"
          columnHeaders={[...headers]}
        />
        {getEmptyState()}
      </Card>
    </>
  );
};

export default TEAMscanActivityTable;
