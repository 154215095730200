import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { downloadCSV } from "utils/helperFunctions";
interface Props {
  headers: {
    key?: string;
    label: string;
  }[];
  getRows: () => {
    [headerKey: string]: string | number;
  }[];
  fileName?: string;
  buttonText?: React.ReactNode;
}

const ExportCSVButton = ({
  headers,
  getRows,
  fileName,
  buttonText,
}: Readonly<Props>) => {
  const onClick = () => {
    const rows = getRows();
    const data = [headers.map((header) => header.label)];
    rows.forEach((row) => {
      data.push(headers.map((header) => `${row[header.key ?? header.label]}`));
    });
    downloadCSV(fileName ?? "export", data);
  };

  return (
    <Button onClick={onClick} variant="secondary-blue" className="border-0">
      {buttonText ?? (
        <>
          <FontAwesomeIcon icon="upload" className="me-2" />
          Export CSV
        </>
      )}
    </Button>
  );
};

export default ExportCSVButton;
