export type CoachBoWeeklyCheckInActivityReports = Record<
  string,
  CoachBoWeeklyCheckInActivityReport
>;

export interface CoachBoWeeklyCheckInActivityReport {
  completionInfo: {
    percentage: number;
    completedCount: number | null;
    totalCount: number | null;
    trendDirection: string | null;
    trendAmount: number | null;
  };
  energy: {
    score: number | null;
    trendDirection: string | null;
    trendAmount: number | null;
  };
  progress: {
    score: number | null;
    trendDirection: string | null;
    trendAmount: number | null;
  };
  readiness: {
    score: number | null;
    trendDirection: string | null;
    trendAmount: number | null;
  };
}

export interface WeeklyCheckInTableRowDataBase {
  moodScore: number | null;
  progressScore: number | null;
  readinessScore: number | null;
}

export interface UserWeeklyCheckInTableRowData
  extends WeeklyCheckInTableRowDataBase {
  threadId: string;
  completedDate: string;
  userAccountId: number;
  errorGeneratingScores: boolean | 0 | 1;
  gradingCount?: number;
}

export interface GroupWeeklyCheckInTableRowData
  extends WeeklyCheckInTableRowDataBase {
  startDate: string;
  endDate: string;
  completedUserAccountIds: number[];
  invitedUserAccountIds: {
    [id: number]: number;
  };
  threadIds: string[];
  entityId: number;
}

export type WeeklyCheckInTableRowData =
  | UserWeeklyCheckInTableRowData
  | GroupWeeklyCheckInTableRowData;

export type WeeklyCheckInTableDataByEntityType = {
  [entityType: string]: WeeklyCheckInTableRowData[] | undefined;
};

export type AllWeeklyCheckInTableData = {
  [timeInterval: string]: WeeklyCheckInTableDataByEntityType | undefined;
};

export const isWeeklyCheckInRowUser = (
  row: WeeklyCheckInTableRowData
): row is UserWeeklyCheckInTableRowData => {
  return (row as UserWeeklyCheckInTableRowData).threadId !== undefined;
};

export interface CheckInResponseSummaryResponse {
  primaryTheme?: string | null;
  secondaryTheme?: string | null;
  conversationStarters?: string[] | null;
  issueSpotlight?: {
    action?: string | null;
    impact?: string | null;
    issue?: string | null;
    severity?: number;
  } | null;
  winSpotlight?: {
    impact?: string | null;
    magnitude?: number;
    achievement?: string | null;
    recognition?: string | null;
  } | null;
}

export interface WeeklyCheckInLineChartResponse {
  labels: string[];
  averageEnergy: (number | null)[];
  averageProgress: (number | null)[];
  averageReadiness: (number | null)[];
  counts: number[];
  hasPreviousTimePeriodData?: boolean;
  hasNextTimePeriodData?: boolean;
}

export interface WeeklyCheckInLineChartState {
  [timeInterval: string]: {
    [index: number]: WeeklyCheckInLineChartResponse | null;
  };
}

export interface StartAndEndDate {
  startDate: string;
  endDate: string;
}

export type SelectedCheckInBreakdownEntity =
  | ({
      type: "department";
      teamIds: number[];
      departmentId: number;
    } & StartAndEndDate)
  | ({
      type: "team";
      teamId: number;
      threadIds: string[];
      userAccountIds: number[];
    } & StartAndEndDate)
  | null;
