import { Team360AssessmentResult } from "app/components/Team360Assessment/types";
import { DepartmentInsightReport } from "./types";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import { AnalyticsText, AnalyticsTextTeamScore } from "../AdminConsole/types";

export const prepareDepartmentInsightReportForCoachBo = (
  departmentInsightReport: DepartmentInsightReport | null
) => {
  if (!departmentInsightReport) {
    return null;
  }
  const copy = { ...departmentInsightReport };
  copy.departmentScores = prepareTeam360AssessmentResultForCoachBo(
    departmentInsightReport.departmentScores
  );

  if (copy.teamSummary) {
    copy.teamSummary = {
      ...copy.teamSummary,
      ...getRefactoredTeamSummary(copy.teamSummary),
    };
  }

  if (copy.departmentManagerAndMemberAnalysis) {
    copy.departmentManagerAndMemberAnalysis = {
      ...copy.departmentManagerAndMemberAnalysis,
      departmentMemberScores: prepareTeam360AssessmentResultForCoachBo(
        copy.departmentManagerAndMemberAnalysis.departmentMemberScores
      ),
      departmentTeamLeaderScores: prepareTeam360AssessmentResultForCoachBo(
        copy.departmentManagerAndMemberAnalysis.departmentTeamLeaderScores
      ),
    };
  }
  return copy;
};

export const prepareOrganizationTeamScanReportForCoachBo = (
  organizationTeamScanReport: AnalyticsText | null
) => {
  if (!organizationTeamScanReport) {
    return null;
  }
  const copy = { ...organizationTeamScanReport };

  if (organizationTeamScanReport.teamLeaderScores) {
    copy.teamLeaderScores = prepareTeam360AssessmentResultForCoachBo(
      organizationTeamScanReport.teamLeaderScores
    );
  }
  if (organizationTeamScanReport.teamMemberScores) {
    copy.teamMemberScores = prepareTeam360AssessmentResultForCoachBo(
      organizationTeamScanReport.teamMemberScores
    );
  }
  if (copy.teamSummary) {
    copy.teamSummary = {
      ...copy.teamSummary,
      ...getRefactoredTeamSummary(copy.teamSummary),
    };
  }
  return copy;
};

export const prepareTeam360AssessmentResultForCoachBo = (
  team360AssessmentResult?: Team360AssessmentResult | null
) => {
  if (!team360AssessmentResult) {
    return {
      dimensions: {},
      factors: {},
      overall: null,
    };
  }
  const factors = { ...(team360AssessmentResult.factors ?? {}) };
  const dimensions = { ...(team360AssessmentResult.dimensions ?? {}) };
  const overall = getTeam360Score(team360AssessmentResult.overall ?? 0);

  Object.entries(team360AssessmentResult.factors ?? {}).forEach(
    ([factorName, factorScore]) => {
      factors[factorName] = getTeam360Score(factorScore);
    }
  );

  Object.entries(team360AssessmentResult.dimensions ?? {}).forEach(
    ([dimensionName, dimensionScore]) => {
      dimensions[dimensionName] = getTeam360Score(dimensionScore);
    }
  );
  return { factors, dimensions, overall };
};

const prepareAnalyticsTextTeamScoreForCoachBo = (
  analyticsTextTeamScore: AnalyticsTextTeamScore
) => {
  if (!analyticsTextTeamScore) {
    return analyticsTextTeamScore;
  }

  const copy = { ...analyticsTextTeamScore };
  copy.scores = prepareTeam360AssessmentResultForCoachBo(copy.scores);
  copy.teamLeaderScores = prepareTeam360AssessmentResultForCoachBo(
    copy.teamLeaderScores
  );
  copy.teamMemberScores = prepareTeam360AssessmentResultForCoachBo(
    copy.teamMemberScores
  );
  return copy;
};

const getRefactoredTeamSummary = (
  payload: {
    highestScoringTeam: AnalyticsTextTeamScore;
    lowestScoringTeam: AnalyticsTextTeamScore;
    percentiles?: {
      percentile25: Team360AssessmentResult;
      percentile50: Team360AssessmentResult;
      percentile75: Team360AssessmentResult;
    } | null;
  } | null
) => {
  if (!payload) {
    return {};
  }
  const copy = { ...payload };
  copy.highestScoringTeam = prepareAnalyticsTextTeamScoreForCoachBo(
    payload.highestScoringTeam
  );
  copy.lowestScoringTeam = prepareAnalyticsTextTeamScoreForCoachBo(
    payload.lowestScoringTeam
  );
  if (payload.percentiles) {
    const percentiles = { ...payload.percentiles };
    percentiles.percentile25 = prepareTeam360AssessmentResultForCoachBo(
      payload.percentiles.percentile25
    );
    percentiles.percentile50 = prepareTeam360AssessmentResultForCoachBo(
      payload.percentiles.percentile50
    );
    percentiles.percentile75 = prepareTeam360AssessmentResultForCoachBo(
      payload.percentiles.percentile75
    );
    copy.percentiles = { ...percentiles };
  }
  return copy;
};
