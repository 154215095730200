import { useState, useRef, useEffect } from "react";

interface Props {
  text: string;
  lines?: number;
  className?: string;
}
export default function ExpandableLineClamp({
  text,
  lines = 3,
  className,
}: Readonly<Props>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const pRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!pRef.current) {
      return;
    }
    const newIsClamped = pRef.current.scrollHeight > pRef.current.clientHeight;
    setIsClamped(newIsClamped);
    // if not clamped clear expanded state
    if (!newIsClamped) {
      setIsExpanded(false);
    }
  }, [text, lines]);

  const lineClampStyle = {
    "--line-clamp": lines,
  } as React.CSSProperties;

  const getClassName = () => {
    let trueClassName = "";
    if (!isExpanded) {
      trueClassName = "dynamic-line-clamp";
    }

    if (className) {
      trueClassName += ` ${className}`;
    }

    return trueClassName;
  };

  return (
    <div className="d-flex align-items-end">
      <p className={getClassName()} style={lineClampStyle} ref={pRef}>
        {text}
      </p>
      {isClamped || isExpanded ? (
        <button
          className="button-link"
          onClick={(e) => {
            e.preventDefault();
            setIsExpanded((isExpanded) => !isExpanded);
          }}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <p>{isExpanded ? "Read less" : "Read more"}</p>
        </button>
      ) : null}
    </div>
  );
}
