import { createEntityAdapter } from "@reduxjs/toolkit";
import {
  TeamNotification,
  PendingAssessmentNotification,
  AssessmentResultsNotification,
  NewDirectReportNotification,
  NotificationWithOptionalTeamId,
  Notification,
  ChatNotification,
} from "./types";

export const teamInvitationAdaptor = createEntityAdapter<TeamNotification>({
  selectId: (e) => e.teamId,
  sortComparer: (a, b) => a.notificationId - b.notificationId, // This is what decides the order of entites in the array
});

export const pendingAssessmentNotificationAdaptor =
  createEntityAdapter<PendingAssessmentNotification>({
    selectId: (e) => e.teamId,
    sortComparer: (a, b) => b.assessmentEventId - a.assessmentEventId, // This is what decides the order of entites in the array
  });

export const pendingAssessmentResultsNotificationAdaptor =
  createEntityAdapter<AssessmentResultsNotification>({
    selectId: (e) => e.notificationId,
  });

export const teamCreationNotificationAdaptor =
  createEntityAdapter<Notification>({
    selectId: (e) => e.notificationId,
  });

export const addTeamMemberNotificationAdaptor =
  createEntityAdapter<NotificationWithOptionalTeamId>({
    selectId: (e) => e.notificationId,
  });

export const sendTeamScanReminderNotificationAdaptor =
  createEntityAdapter<TeamNotification>({
    selectId: (e) => e.notificationId,
  });

export const launchTeamScanNotificationAdaptor =
  createEntityAdapter<NotificationWithOptionalTeamId>({
    selectId: (e) => e.notificationId,
  });

export const newDirectReportAdaptor =
  createEntityAdapter<NewDirectReportNotification>({
    selectId: (e) => e.notificationId,
  });

export const chatWithDirectReportNotificationAdaptor =
  createEntityAdapter<NewDirectReportNotification>({
    selectId: (e) => e.notificationId,
  });

export const coachBoCheckInNotificationAdaptor =
  createEntityAdapter<ChatNotification>({
    selectId: (e) => e.notificationId,
  });
