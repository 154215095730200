import { getUserToken, logUserOut } from "utils/helperFunctions";
import { store } from "utils/redux/store";
import { clearCurrentUserAccountId } from "app/containers/Global/slice";

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

/**
 * Attaches JWT Token to all requests
 *
 * @param  {object} [options] The original options we receive from the redux-saga
 *
 * @return {object}           the new options containing the Authorization header
 */
function authenticateRequest(options: any) {
  const token = getUserToken();
  const newOptions = { ...options };
  newOptions.headers = {
    Authorization: token,
  };
  return newOptions;
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export const checkStatus = async (
  response: Response,
  returnStatus?: boolean
) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  // If the user no longer has access then we remove the currentUserAccountId from the redux state essentially logging them out.
  if (response.status === 401) {
    logUserOut(false); // Sending false so that when the user is logged back in then they should be on the same page they were in when they get logged out
    store.dispatch(clearCurrentUserAccountId());
  }

  if (returnStatus) {
    return response;
  }

  const error = new ResponseError(response);
  throw error;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit,
  returnStatus?: boolean
): Promise<{} | { err: ResponseError }> {
  const authenticatedOptions = authenticateRequest(options);
  const fetchResponse = await fetch(url, authenticatedOptions);
  const response = await checkStatus(fetchResponse, returnStatus);

  const parsedJSON = await parseJSON(response);

  if (returnStatus) {
    // if the response is a string then we need to wrap it in an object so that we can add the status code to it
    const jsonObject =
      typeof parsedJSON === "string" ? { response: parsedJSON } : parsedJSON;
    return { ...jsonObject, status: response.status };
  }
  return parsedJSON;
}

// This function would be use to make a request ot the api and return the status code and does not throw an error if the status code is not 200
export async function simpleFetch(
  url: string,
  options?: RequestInit,
  authenticationToken?: string | null
): Promise<{} | { err: ResponseError }> {
  const authenticatedOptions = authenticateRequest(options);
  if (authenticationToken !== undefined) {
    authenticatedOptions.headers.Authorization = authenticationToken;
  }

  const fetchResponse = await fetch(url, authenticatedOptions);
  const parsedJSON = await parseJSON(fetchResponse);

  return parsedJSON;
}
