import {
  useState,
  useEffect,
  DependencyList,
  useCallback,
  useRef,
} from "react";

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps: DependencyList
) {
  useEffect(() => {
    const t = setTimeout(() => {
      // @ts-ignore
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

// Custom debounce hook
export function useDebounceCallback<T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
  immediate: boolean = false
): T {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isInitialMount = useRef(true);

  const debouncedCallback = useCallback(
    (...args: any[]) => {
      const invokeNow = immediate && isInitialMount.current;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (invokeNow) {
        callback(...args);
        isInitialMount.current = false;
      } else {
        timerRef.current = setTimeout(() => {
          callback(...args);
        }, delay);
      }
    },
    [callback, delay, immediate]
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return debouncedCallback as T;
}

export const useIsMobile = (mobileWidthThreshold = 560) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < mobileWidthThreshold
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileWidthThreshold);
    };

    // Add the event listener
    window.addEventListener("resize", handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove the event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [mobileWidthThreshold]); // Depend on mobileWidthThreshold to re-run if it changes

  return isMobile;
};
