import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  AllWeeklyCheckInTableData,
  CheckInResponseSummaryResponse,
  CoachBoWeeklyCheckInActivityReports,
  WeeklyCheckInLineChartState,
  WeeklyCheckInTableRowData,
  WeeklyCheckInTableRowDataBase,
  WeeklyCheckInLineChartResponse,
} from "./types";
import {
  sortWeeklyCheckInTableRowData,
  updateUserWeeklyCheckInTableData,
} from "./helpers";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";

// ------------------ State Type/Structure ------------------
export interface WeeklyCheckInMonitorState {
  coachBoWeeklyCheckInActivityReports: CoachBoWeeklyCheckInActivityReports;
  subordinatesWeeklyCheckInActivityReports: CoachBoWeeklyCheckInActivityReports;
  savedCheckInResponseSummaries: Record<string, CheckInResponseSummaryResponse>;
  allWeeklyCheckInTableData: AllWeeklyCheckInTableData;
  SubordinatesWeeklyCheckinTableData: AllWeeklyCheckInTableData;
  weeklyCheckInLineChartData: WeeklyCheckInLineChartState;
  SubordinatesWeeklyCheckInLineChartData: WeeklyCheckInLineChartState;
  usersSubordinatesIds: number[];
  showWeeklyCheckInActivityEmptyState: boolean;
  getCoachBoWeeklyCheckInActivityReportStatus: responseStatus;
  getSubordinatesWeeklyCheckInActivityReportStatus: responseStatus;
  getWeeklyCheckInModuleStatus: responseStatus;
  regradeWeeklyCheckinStatus: responseStatus;
  getCheckInResponseSummaryStatus: responseStatus;
  getWeeklyCheckInTableDataStatus: responseStatus;
  getSubordinatesWeeklyCheckinTableDataStatus: responseStatus;
  getSubordinatesWeeklyCheckInLineChartDataStatus: responseStatus;
  getWeeklyCheckInLineChartDataStatus: responseStatus;
  getSpanOfControlStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: WeeklyCheckInMonitorState = {
  coachBoWeeklyCheckInActivityReports: {},
  savedCheckInResponseSummaries: {},
  allWeeklyCheckInTableData: {},
  weeklyCheckInLineChartData: {},
  SubordinatesWeeklyCheckInLineChartData: {},
  subordinatesWeeklyCheckInActivityReports: {},
  SubordinatesWeeklyCheckinTableData: {},
  usersSubordinatesIds: [],
  showWeeklyCheckInActivityEmptyState: false,
  getSubordinatesWeeklyCheckinTableDataStatus: "idle",
  getSubordinatesWeeklyCheckInActivityReportStatus: "idle",
  getSubordinatesWeeklyCheckInLineChartDataStatus: "idle",
  getCoachBoWeeklyCheckInActivityReportStatus: "idle",
  getWeeklyCheckInModuleStatus: "idle",
  regradeWeeklyCheckinStatus: "idle",
  getCheckInResponseSummaryStatus: "idle",
  getWeeklyCheckInTableDataStatus: "idle",
  getWeeklyCheckInLineChartDataStatus: "idle",
  getSpanOfControlStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
export const getCoachBoWeeklyCheckInActivityReport = createAsyncThunk(
  "weeklyCheckInMonitor/getCoachBoWeeklyCheckInActivityReport",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/checkInActivity`;
    const response = (await request(
      requestUrl
    )) as CoachBoWeeklyCheckInActivityReports;
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        weeklyCheckInMonitor: { getCoachBoWeeklyCheckInActivityReportStatus },
      } = getState() as RootState;
      if (getCoachBoWeeklyCheckInActivityReportStatus !== "idle") {
        return false;
      }
    },
  }
);

export const getSubordinatesWeeklyCheckInActivityReport = createAsyncThunk(
  "weeklyCheckInMonitor/getSubordinatesWeeklyCheckInActivityReport",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/checkInActivity?reportsOnly=true`;
    const response = (await request(
      requestUrl
    )) as CoachBoWeeklyCheckInActivityReports;
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        weeklyCheckInMonitor: {
          getSubordinatesWeeklyCheckInActivityReportStatus,
        },
      } = getState() as RootState;
      if (getSubordinatesWeeklyCheckInActivityReportStatus !== "idle") {
        return false;
      }
    },
  }
);

// This endpoint will determine if we show the empty state for the check-in activity report
export const getWeeklyCheckInModuleStatus = createAsyncThunk(
  "weeklyCheckInMonitor/getWeeklyCheckInModuleStatus",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/weeklyCheckInStatus`;
    const response = (await request(requestUrl)) as {
      atLeastOneTeamHasCheckInsEnabled: boolean;
    };
    return response?.atLeastOneTeamHasCheckInsEnabled;
  },
  {
    condition: (_, { getState }) => {
      const {
        weeklyCheckInMonitor: { getWeeklyCheckInModuleStatus },
      } = getState() as RootState;
      if (getWeeklyCheckInModuleStatus !== "idle") {
        return false;
      }
    },
  }
);

export const getWeeklyCheckInTableData = createAsyncThunk(
  "weeklyCheckInMonitor/getWeeklyCheckInTableData",
  async ({
    timeInterval,
    entityType,
  }: {
    timeInterval: string;
    entityType: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/weeklyCheckInTableData/${timeInterval}/${entityType}`;
    let response = (await request(requestUrl)) as WeeklyCheckInTableRowData[];
    response = sortWeeklyCheckInTableRowData(response);

    return { response, timeInterval, entityType };
  },
  {
    condition: ({ timeInterval, entityType }, { getState }) => {
      const {
        weeklyCheckInMonitor: {
          getWeeklyCheckInTableDataStatus,
          allWeeklyCheckInTableData,
        },
      } = getState() as RootState;
      if (
        getWeeklyCheckInTableDataStatus === "loading" ||
        allWeeklyCheckInTableData[timeInterval]?.[entityType]?.length
      ) {
        return false;
      }
    },
  }
);

export const getSubordinatesWeeklyCheckinTableData = createAsyncThunk(
  "weeklyCheckInMonitor/getSubordinatesWeeklyCheckinTableData",
  async ({
    timeInterval,
    entityType,
  }: {
    timeInterval: string;
    entityType: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/weeklyCheckInTableData/${timeInterval}/${entityType}?reportsOnly=true`;
    let response = (await request(requestUrl)) as WeeklyCheckInTableRowData[];
    response = sortWeeklyCheckInTableRowData(response);

    return { response, timeInterval, entityType };
  },
  {
    condition: ({ timeInterval, entityType }, { getState }) => {
      const {
        weeklyCheckInMonitor: {
          getSubordinatesWeeklyCheckinTableDataStatus,
          SubordinatesWeeklyCheckinTableData,
        },
      } = getState() as RootState;
      if (
        getSubordinatesWeeklyCheckinTableDataStatus === "loading" ||
        SubordinatesWeeklyCheckinTableData[timeInterval]?.[entityType]?.length
      ) {
        return false;
      }
    },
  }
);

export const getWeeklyCheckInLineChartData = createAsyncThunk(
  "weeklyCheckInMonitor/getWeeklyCheckInLineChartData",
  async ({
    timeInterval,
    index = 1,
  }: {
    timeInterval: string;
    index?: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/weeklyCheckInLineChartData/${timeInterval}/${index}`;
    const response = (await request(
      requestUrl
    )) as WeeklyCheckInLineChartResponse;

    if (!response || Object.keys(response).length === 0) {
      return { response: null, timeInterval, index };
    }
    // If the initial index is 1 that means its the most recent data
    // so hasNextTimePeriodData should be false, otherwise it should be true
    if (index !== 1) {
      response.hasNextTimePeriodData = true;
    }

    return { response, timeInterval, index };
  },
  {
    condition: ({ timeInterval, index = 1 }, { getState }) => {
      const {
        weeklyCheckInMonitor: { weeklyCheckInLineChartData },
      } = getState() as RootState;
      if (weeklyCheckInLineChartData?.[timeInterval]?.[index]) {
        // If the data is already fetched, we don't need to fetch it again
        return false;
      }
    },
  }
);

export const getSubordinatesWeeklyCheckInLineChartData = createAsyncThunk(
  "weeklyCheckInMonitor/getSubordinatesWeeklyCheckInLineChartData",
  async ({
    timeInterval,
    index = 1,
  }: {
    timeInterval: string;
    index?: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/weeklyCheckInLineChartData/${timeInterval}/${index}?reportsOnly=true`;
    const response = (await request(
      requestUrl
    )) as WeeklyCheckInLineChartResponse;

    if (!response || Object.keys(response).length === 0) {
      return { response: null, timeInterval, index };
    }
    // If the initial index is 1 that means its the most recent data
    // so hasNextTimePeriodData should be false, otherwise it should be true
    if (index !== 1) {
      response.hasNextTimePeriodData = true;
    }

    return { response, timeInterval, index };
  },
  {
    condition: ({ timeInterval, index = 1 }, { getState }) => {
      const {
        weeklyCheckInMonitor: { SubordinatesWeeklyCheckInLineChartData },
      } = getState() as RootState;
      if (SubordinatesWeeklyCheckInLineChartData?.[timeInterval]?.[index]) {
        // If the data is already fetched, we don't need to fetch it again
        return false;
      }
    },
  }
);

export const getCheckInResponseSummary = createAsyncThunk(
  "weeklyCheckInMonitor/getCheckInResponseSummary",
  async (threadId: string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/weeklyCheckIn/${threadId}`;
    const response = (await request(
      requestUrl
    )) as CheckInResponseSummaryResponse;
    return { response, threadId };
  },
  {
    condition: (threadId, { getState }) => {
      const {
        weeklyCheckInMonitor: { savedCheckInResponseSummaries },
      } = getState() as RootState;
      if (savedCheckInResponseSummaries[threadId]) {
        return false;
      }
    },
  }
);

export const regradeWeeklyCheckin = createAsyncThunk(
  "weeklyCheckInMonitor/regradeWeeklyCheckin",
  async (threadId: string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/regradeWeeklyCheckin/${threadId}`;
    const response = (await request(requestUrl, {
      method: "POST",
    })) as WeeklyCheckInTableRowDataBase;
    return { response, threadId };
  }
);

export const getUserSpanOfControl = createAsyncThunk(
  "weeklyCheckInMonitor/getUserSpanOfControl",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/spanOfControl`;
    const response = (await request(requestUrl)) as {
      spanOfControl: number[];
    };
    return response?.spanOfControl;
  },
  {
    condition: (_, { getState }) => {
      const {
        weeklyCheckInMonitor: { getSpanOfControlStatus },
      } = getState() as RootState;
      if (getSpanOfControlStatus !== "idle") {
        return false;
      }
    },
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const weeklyCheckInMonitorSlice = createSlice({
  name: "weeklyCheckInMonitor",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoachBoWeeklyCheckInActivityReport.pending, (state) => {
        state.getCoachBoWeeklyCheckInActivityReportStatus = "loading";
      })
      .addCase(
        getCoachBoWeeklyCheckInActivityReport.fulfilled,
        (state, action: PayloadAction<CoachBoWeeklyCheckInActivityReports>) => {
          state.getCoachBoWeeklyCheckInActivityReportStatus = "succeeded";
          state.coachBoWeeklyCheckInActivityReports = action.payload;
        }
      )
      .addCase(getCoachBoWeeklyCheckInActivityReport.rejected, (state) => {
        state.getCoachBoWeeklyCheckInActivityReportStatus = "failed";
      })
      .addCase(getWeeklyCheckInModuleStatus.pending, (state) => {
        state.getWeeklyCheckInModuleStatus = "loading";
      })
      .addCase(getWeeklyCheckInModuleStatus.fulfilled, (state, { payload }) => {
        state.getWeeklyCheckInModuleStatus = "succeeded";
        state.showWeeklyCheckInActivityEmptyState = !payload;
      })
      .addCase(getWeeklyCheckInModuleStatus.rejected, (state) => {
        state.getWeeklyCheckInModuleStatus = "failed";
      })
      .addCase(getWeeklyCheckInTableData.pending, (state) => {
        state.getWeeklyCheckInTableDataStatus = "loading";
      })
      .addCase(
        getWeeklyCheckInTableData.fulfilled,
        (
          state,
          {
            payload: { response, entityType, timeInterval },
          }: PayloadAction<{
            response: WeeklyCheckInTableRowData[];
            timeInterval: string;
            entityType: string;
          }>
        ) => {
          return {
            ...state,
            getWeeklyCheckInTableDataStatus: "succeeded",
            allWeeklyCheckInTableData: {
              ...state.allWeeklyCheckInTableData,
              [timeInterval]: {
                ...state.allWeeklyCheckInTableData[timeInterval],
                [entityType]: response,
              },
            },
          };
        }
      )
      .addCase(getWeeklyCheckInTableData.rejected, (state) => {
        state.getWeeklyCheckInTableDataStatus = "failed";
      })
      // Adding cases for getWeeklyCheckInLineChartData
      .addCase(getWeeklyCheckInLineChartData.pending, (state) => {
        state.getWeeklyCheckInLineChartDataStatus = "loading";
      })
      .addCase(
        getWeeklyCheckInLineChartData.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: WeeklyCheckInLineChartResponse | null;
            timeInterval: string;
            index: number;
          }>
        ) => {
          state.getWeeklyCheckInLineChartDataStatus = "succeeded";
          const { response, timeInterval, index } = action.payload;

          // Initialize the object structure if it doesn't exist
          if (!state.weeklyCheckInLineChartData[timeInterval]) {
            state.weeklyCheckInLineChartData[timeInterval] = {};
          }

          state.weeklyCheckInLineChartData[timeInterval][index] = response;
        }
      )
      .addCase(getWeeklyCheckInLineChartData.rejected, (state) => {
        state.getWeeklyCheckInLineChartDataStatus = "failed";
      })
      .addCase(getCheckInResponseSummary.pending, (state) => {
        state.getCheckInResponseSummaryStatus = "loading";
      })
      .addCase(
        getCheckInResponseSummary.fulfilled,
        (
          state,
          {
            payload: { response, threadId },
          }: PayloadAction<{
            response: CheckInResponseSummaryResponse;
            threadId: string;
          }>
        ) => {
          return {
            ...state,
            getCheckInResponseSummaryStatus: "succeeded",
            savedCheckInResponseSummaries: {
              ...state.savedCheckInResponseSummaries,
              [threadId]: response,
            },
          };
        }
      )
      .addCase(getCheckInResponseSummary.rejected, (state) => {
        state.getCheckInResponseSummaryStatus = "failed";
      })
      .addCase(regradeWeeklyCheckin.pending, (state) => {
        state.regradeWeeklyCheckinStatus = "loading";
      })
      .addCase(
        regradeWeeklyCheckin.fulfilled,
        (
          state,
          {
            payload: { response, threadId },
          }: PayloadAction<{
            response: WeeklyCheckInTableRowDataBase;
            threadId: string;
          }>
        ) => {
          toast.success("Regrading weekly check-in was successful!", {
            ...successNotificationOptions,
          });

          const updatedAllTimeUserData = updateUserWeeklyCheckInTableData(
            state.allWeeklyCheckInTableData?.allTime?.user ?? [],
            response,
            threadId
          );
          const timeInterval = "allTime";
          const entityType = "user";
          const allWeeklyCheckInTableData = {
            ...state.allWeeklyCheckInTableData,
            [timeInterval]: {
              ...state.allWeeklyCheckInTableData[timeInterval],
              [entityType]: updatedAllTimeUserData,
            },
          };

          return {
            ...state,
            regradeWeeklyCheckinStatus: "succeeded",
            allWeeklyCheckInTableData,
          };
        }
      )
      .addCase(regradeWeeklyCheckin.rejected, (state) => {
        state.regradeWeeklyCheckinStatus = "failed";
      })
      .addCase(getSubordinatesWeeklyCheckinTableData.pending, (state) => {
        state.getSubordinatesWeeklyCheckinTableDataStatus = "loading";
      })
      .addCase(
        getSubordinatesWeeklyCheckinTableData.fulfilled,
        (
          state,
          {
            payload: { response, entityType, timeInterval },
          }: PayloadAction<{
            response: WeeklyCheckInTableRowData[];
            timeInterval: string;
            entityType: string;
          }>
        ) => {
          return {
            ...state,
            getSubordinatesWeeklyCheckinTableDataStatus: "succeeded",
            SubordinatesWeeklyCheckinTableData: {
              ...state.SubordinatesWeeklyCheckinTableData,
              [timeInterval]: {
                ...state.SubordinatesWeeklyCheckinTableData[timeInterval],
                [entityType]: response,
              },
            },
          };
        }
      )
      .addCase(getSubordinatesWeeklyCheckinTableData.rejected, (state) => {
        state.getSubordinatesWeeklyCheckinTableDataStatus = "failed";
      })
      .addCase(getSubordinatesWeeklyCheckInLineChartData.pending, (state) => {
        state.getSubordinatesWeeklyCheckInLineChartDataStatus = "loading";
      })
      .addCase(
        getSubordinatesWeeklyCheckInLineChartData.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: WeeklyCheckInLineChartResponse | null;
            timeInterval: string;
            index: number;
          }>
        ) => {
          state.getSubordinatesWeeklyCheckInLineChartDataStatus = "succeeded";
          const { response, timeInterval, index } = action.payload;

          // Initialize the object structure if it doesn't exist
          if (!state.SubordinatesWeeklyCheckInLineChartData[timeInterval]) {
            state.SubordinatesWeeklyCheckInLineChartData[timeInterval] = {};
          }

          state.SubordinatesWeeklyCheckInLineChartData[timeInterval][index] =
            response;
        }
      )
      .addCase(getSubordinatesWeeklyCheckInLineChartData.rejected, (state) => {
        state.getSubordinatesWeeklyCheckInLineChartDataStatus = "failed";
      })
      .addCase(getSubordinatesWeeklyCheckInActivityReport.pending, (state) => {
        state.getSubordinatesWeeklyCheckInActivityReportStatus = "loading";
      })
      .addCase(
        getSubordinatesWeeklyCheckInActivityReport.fulfilled,
        (state, action: PayloadAction<CoachBoWeeklyCheckInActivityReports>) => {
          state.getSubordinatesWeeklyCheckInActivityReportStatus = "succeeded";
          state.subordinatesWeeklyCheckInActivityReports = action.payload;
        }
      )
      .addCase(getSubordinatesWeeklyCheckInActivityReport.rejected, (state) => {
        state.getSubordinatesWeeklyCheckInActivityReportStatus = "failed";
      })
      .addCase(getUserSpanOfControl.pending, (state) => {
        state.getSpanOfControlStatus = "loading";
      })
      .addCase(
        getUserSpanOfControl.fulfilled,
        (state, { payload }: PayloadAction<number[]>) => {
          state.getSpanOfControlStatus = "succeeded";
          state.usersSubordinatesIds = payload;
        }
      )
      .addCase(getUserSpanOfControl.rejected, (state) => {
        state.getSpanOfControlStatus = "failed";
      });
  },
});

// ------------------ Actions ------------------
export const { resetState } = weeklyCheckInMonitorSlice.actions;

// ------------------ Selectors ------------------
export const selectGetCoachBoWeeklyCheckInActivityReportStatus = (
  state: RootState
) => state.weeklyCheckInMonitor.getCoachBoWeeklyCheckInActivityReportStatus;
export const selectCoachBoWeeklyCheckInActivityReports = (state: RootState) =>
  state.weeklyCheckInMonitor.coachBoWeeklyCheckInActivityReports;
export const selectShowWeeklyCheckInActivityEmptyState = (state: RootState) =>
  state.weeklyCheckInMonitor.showWeeklyCheckInActivityEmptyState;
export const selectGetWeeklyCheckInModuleStatus = (state: RootState) =>
  state.weeklyCheckInMonitor.getWeeklyCheckInModuleStatus;
export const selectGetWeeklyCheckInTableDataStatus = (state: RootState) =>
  state.weeklyCheckInMonitor.getWeeklyCheckInTableDataStatus;
export const selectWeeklyCheckInLineChartData = (state: RootState) =>
  state.weeklyCheckInMonitor.weeklyCheckInLineChartData;
export const selectGetWeeklyCheckInLineChartDataStatus = (state: RootState) =>
  state.weeklyCheckInMonitor.getWeeklyCheckInLineChartDataStatus;
export const selectCheckInResponseSummary = (
  state: RootState,
  threadId: string
) => state.weeklyCheckInMonitor.savedCheckInResponseSummaries[threadId];
export const selectCheckInResponseSummaries = (state: RootState) =>
  state.weeklyCheckInMonitor.savedCheckInResponseSummaries;
export const selectGetCheckInResponseSummaryStatus = (state: RootState) =>
  state.weeklyCheckInMonitor.getCheckInResponseSummaryStatus;
export const selectRegradeWeeklyCheckinStatus = (state: RootState) =>
  state.weeklyCheckInMonitor.regradeWeeklyCheckinStatus;
export const selectAllWeeklyCheckInTableData = (state: RootState) =>
  state.weeklyCheckInMonitor.allWeeklyCheckInTableData;
export const selectSubordinatesWeeklyCheckinTableData = (state: RootState) =>
  state.weeklyCheckInMonitor.SubordinatesWeeklyCheckinTableData;
export const selectSubordinatesWeeklyCheckInLineChartData = (
  state: RootState
) => state.weeklyCheckInMonitor.SubordinatesWeeklyCheckInLineChartData;
export const selectSubordinatesWeeklyCheckInActivityReports = (
  state: RootState
) => state.weeklyCheckInMonitor.subordinatesWeeklyCheckInActivityReports;
export const selectGetSubordinatesWeeklyCheckinTableDataStatus = (
  state: RootState
) => state.weeklyCheckInMonitor.getSubordinatesWeeklyCheckinTableDataStatus;
export const selectGetSubordinatesWeeklyCheckInLineChartDataStatus = (
  state: RootState
) => state.weeklyCheckInMonitor.getSubordinatesWeeklyCheckInLineChartDataStatus;
export const selectGetSubordinatesWeeklyCheckInActivityReportStatus = (
  state: RootState
) =>
  state.weeklyCheckInMonitor.getSubordinatesWeeklyCheckInActivityReportStatus;
export const selectGetSpanOfControlStatus = (state: RootState) =>
  state.weeklyCheckInMonitor.getSpanOfControlStatus;
export const selectUsersSubordinatesIds = (state: RootState) =>
  state.weeklyCheckInMonitor.usersSubordinatesIds;

export default weeklyCheckInMonitorSlice.reducer;
