import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvatarCircle from "app/components/AvatarCircle";
import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { TeamMember } from "app/containers/Global/types";
import { useAppDispatch } from "utils/redux/hooks";
import { sendReminder } from "app/containers/Dashboard/slice";
import { useNavigate } from "react-router-dom";
import TableDropdownMenu from "app/components/Dropdowns/TableDropdownMenu";

interface Props {
  teamMembers: (TeamMember & { isPending?: boolean })[];
  updateTeamMember: (
    teamMemberId: number,
    payload: { isTeamLead: 0 | 1 }
  ) => void;
  removeTeamMember: (teamMemberId: number) => void;
  searchValue?: string;
  teamId: number;
  isAutomatic?: 0 | 1;
}
export default function TeamTable({
  teamMembers,
  searchValue,
  updateTeamMember,
  removeTeamMember,
  teamId,
  isAutomatic,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [filteredTeamMembers, setFilteredTeamMembers] = useState(
    teamMembers || []
  );

  useEffect(() => {
    if (!teamMembers) {
      return;
    }
    if (!searchValue) {
      setFilteredTeamMembers(teamMembers);
      return;
    }

    const timerId = setTimeout(() => {
      // handle the debounced value here
      const pattern = new RegExp(searchValue.toLowerCase());
      setFilteredTeamMembers(
        teamMembers.filter(
          ({ firstName, lastName, emailAddress }) =>
            pattern.test(firstName.toLowerCase()) ||
            pattern.test(lastName.toLowerCase()) ||
            pattern.test(emailAddress.toLowerCase())
        )
      );
    }, 500);

    // clear the timeout on each value change to reset the timer
    return () => clearTimeout(timerId);
  }, [searchValue, teamMembers]);

  const onTeamPositionCellClick = (
    e: string,
    teamMemberId: number,
    userAccountId: number
  ) => {
    switch (e) {
      case "make-team-member":
        return updateTeamMember(teamMemberId, { isTeamLead: 0 });
      case "make-team-leader":
        return updateTeamMember(teamMemberId, { isTeamLead: 1 });
      case "remove-team-member":
        return removeTeamMember(teamMemberId);
      case "remind-user-to-accept":
        return dispatch(
          sendReminder({
            reminderType: "remindTeamInvitation",
            teamId,
            userAccountId,
          })
        );
      default:
        return;
    }
  };

  const getTeamPositionCell = (isTeamLead: 0 | 1, isPending?: boolean) => {
    const position = isTeamLead === 1 ? "Team Leader" : "Team Member";
    if (isPending) {
      return `Invited ${position}`;
    }

    return position;
  };

  const getDropdown = (isTeamLead: boolean, isPending?: boolean) => {
    if (isPending) {
      return (
        <TableDropdownMenu>
          {isTeamLead ? (
            <Dropdown.Item eventKey="make-team-member">
              Add as team member
            </Dropdown.Item>
          ) : (
            <Dropdown.Item eventKey="make-team-leader">
              Add as team leader
            </Dropdown.Item>
          )}
          <Dropdown.Item eventKey="remind-user-to-accept">
            Resend invite
          </Dropdown.Item>
          <Dropdown.Item eventKey="remove-team-member" className="danger">
            Remove from team
          </Dropdown.Item>
        </TableDropdownMenu>
      );
    }

    return (
      <TableDropdownMenu>
        <Dropdown.Item eventKey="make-team-member">Team Member</Dropdown.Item>
        <Dropdown.Item eventKey="make-team-leader">Team Leader</Dropdown.Item>
        <Dropdown.Item eventKey="remove-team-member" className="danger">
          Remove from team
        </Dropdown.Item>
      </TableDropdownMenu>
    );
  };

  if (!filteredTeamMembers.length) {
    return <div>No team members found</div>;
  }

  return (
    <table className="team-members-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Permissions</th>
        </tr>
        <tr className="tr-spacer"></tr>
      </thead>
      <tbody>
        <tr className="tr-spacer"></tr>
        {filteredTeamMembers.map(
          ({
            userAccountId,
            emailAddress,
            firstName,
            lastName,
            isTeamLead,
            teamMemberId,
            isPending,
          }) => {
            const fullName = `${firstName || ""} ${lastName || ""}`;
            return (
              <tr
                key={userAccountId}
                className={isPending ? "deactivated" : undefined}
                style={{
                  fontSize: "14px",
                }}
              >
                <td
                  className="d-flex align-items-center"
                  onClick={() => {
                    navigate(`/UserGuide/${userAccountId}`);
                  }}
                  role="button"
                >
                  <AvatarCircle
                    size="small"
                    userAccountId={isPending ? undefined : userAccountId}
                    avatarColor={isPending ? "cbcccd" : undefined}
                  />
                  <div className="team-member-name">{fullName}</div>
                </td>
                <td>{emailAddress}</td>
                <td>
                  {!isAutomatic ? (
                    <Dropdown
                      onSelect={(e) =>
                        onTeamPositionCellClick(
                          e || "",
                          teamMemberId,
                          userAccountId
                        )
                      }
                    >
                      <Dropdown.Toggle
                        variant={
                          isPending ? "outline-secondary" : "outline-primary"
                        }
                        id="dropdown-basic"
                        className="no-caret"
                      >
                        {getTeamPositionCell(isTeamLead, isPending)}
                        <FontAwesomeIcon icon="caret-down" className="ms-2" />
                      </Dropdown.Toggle>

                      {getDropdown(!!isTeamLead, isPending)}
                    </Dropdown>
                  ) : (
                    getTeamPositionCell(isTeamLead, isPending)
                  )}
                </td>
              </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
}
