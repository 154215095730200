import NavigateBackButton from "app/components/NavigateBackButton";
import { useLocation } from "react-router-dom";
import SubordinatesWeeklyCheckinTable from "./Table/SubordinatesWeeklyCheckinTable";

const SubordinatesWeeklyCheckinTableIndex = () => {
  const location = useLocation();

  const getBackButton = () => {
    if (location.key === "default") {
      return null;
    }
    return (
      <div style={{ marginBottom: "20px" }}>
        <NavigateBackButton />
      </div>
    );
  };

  return (
    <div className="page dashboard">
      <SubordinatesWeeklyCheckinTable fallbackBackButton={getBackButton()} />
    </div>
  );
};

export default SubordinatesWeeklyCheckinTableIndex;
