import {
  createAsyncThunk,
  createSlice,
  EntityAdapter,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { toast } from "react-toastify";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { removeTeamIdFromUserAccountId } from "app/containers/Global/slice";
import { successNotificationOptions } from "utils/constants";
import {
  NotificationResponse,
  TeamNotification,
  PendingAssessmentNotification,
  AssessmentResultsNotification,
  NotificationWithOptionalTeamId,
  NewDirectReportNotification,
  Notification,
  ChatNotification,
} from "./types";
import {
  pendingAssessmentNotificationAdaptor,
  pendingAssessmentResultsNotificationAdaptor,
  teamInvitationAdaptor,
  teamCreationNotificationAdaptor,
  addTeamMemberNotificationAdaptor,
  sendTeamScanReminderNotificationAdaptor,
  launchTeamScanNotificationAdaptor,
  newDirectReportAdaptor,
  chatWithDirectReportNotificationAdaptor,
  coachBoCheckInNotificationAdaptor,
} from "./adaptors";

// ------------------ State Type/Structure ------------------
export interface ExampleState {
  teamNotifications: EntityState<TeamNotification>;
  pendingAssessmentNotifications: EntityState<PendingAssessmentNotification>;
  pendingAssessmentResultsNotifications: EntityState<AssessmentResultsNotification>;
  pendingTeamCreationNotifications: EntityState<Notification>;
  pendingAddTeamMemberNotifications: EntityState<NotificationWithOptionalTeamId>;
  pendingSendTeamScanReminderNotifications: EntityState<TeamNotification>;
  pendingLaunchTeamScanNotifications: EntityState<NotificationWithOptionalTeamId>;
  newDirectReportNotifications: EntityState<NewDirectReportNotification>;
  chatWithDirectReportNotifications: EntityState<NewDirectReportNotification>;
  coachBoCheckInNotifications: EntityState<ChatNotification>;
  connectedYourPersonalityReportNotification: Notification | null;
  dismissedNotificationIds: number[];
  showViewResultsReadyModal: boolean;
  getNotificationsStatus: responseStatus;
  acceptInvitationStatus: responseStatus;
  dismissNotificationStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: ExampleState = {
  teamNotifications: teamInvitationAdaptor.getInitialState(),
  pendingAssessmentNotifications:
    pendingAssessmentNotificationAdaptor.getInitialState(),
  pendingAssessmentResultsNotifications:
    pendingAssessmentResultsNotificationAdaptor.getInitialState(),
  pendingTeamCreationNotifications:
    teamCreationNotificationAdaptor.getInitialState(),
  pendingAddTeamMemberNotifications:
    addTeamMemberNotificationAdaptor.getInitialState(),
  pendingSendTeamScanReminderNotifications:
    sendTeamScanReminderNotificationAdaptor.getInitialState(),
  pendingLaunchTeamScanNotifications:
    launchTeamScanNotificationAdaptor.getInitialState(),
  newDirectReportNotifications: newDirectReportAdaptor.getInitialState(),
  chatWithDirectReportNotifications:
    chatWithDirectReportNotificationAdaptor.getInitialState(),
  coachBoCheckInNotifications:
    coachBoCheckInNotificationAdaptor.getInitialState(),
  connectedYourPersonalityReportNotification: null,
  dismissedNotificationIds: [],
  showViewResultsReadyModal: false,
  getNotificationsStatus: "idle",
  acceptInvitationStatus: "idle",
  dismissNotificationStatus: "idle",
};

// ------------------------------------ GETS ------------------------------------
export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, thunkAPI) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/notifications`;
    const response = (await request(requestUrl)) as NotificationResponse;

    // Here we filter out any notifications that do not have an eventId
    const pendingAssessmentNotifications =
      response?.pendingAssessmentNotifications.filter(
        (notification) => notification.eventId
      );

    thunkAPI.dispatch(
      setNotifications({
        ...response,
        pendingAssessmentNotifications,
      })
    );
    return response;
  }
);

// ------------------------------------ PUTS ------------------------------------
export const dismissNotification = createAsyncThunk(
  "dashboard/dismissNotification",
  async (notificationId: number, thunkAPI) => {
    thunkAPI.dispatch(setDismissedNotificationId(notificationId));
    thunkAPI.dispatch(dismissNotificationByNotificationId(notificationId));
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/notifications/${notificationId}`;
    (await request(requestUrl, {
      method: "PUT",
    })) as NotificationResponse;
  }
);

export const acceptInvitation = createAsyncThunk(
  "dashboard/acceptInvitation",
  async (
    { teamId, notificationId }: { teamId: number; notificationId: number },
    { dispatch }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/acceptInvitation`;
    const response = await request(requestUrl, {
      method: "PUT",
    });
    dispatch(dismissNotification(notificationId));
    return response;
  }
);

export const declineInvitation = createAsyncThunk(
  "dashboard/declineInvitation",
  async (
    { teamId, notificationId }: { teamId: number; notificationId: number },
    { dispatch, getState }
  ) => {
    const {
      global: { currentUserAccountId },
    } = getState() as RootState;
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/declineInvitation`;
    const response = await request(requestUrl, {
      method: "PUT",
    });
    dispatch(
      removeTeamIdFromUserAccountId({
        teamId,
        userAccountId: currentUserAccountId,
      })
    );
    dispatch(dismissNotification(notificationId));
    return response;
  }
);

// ------------------ Helpers ------------------
export const onCreateTeamDismissNotification = createAsyncThunk(
  "notifications/onCreateTeamDismissNotification",
  async (_, { dispatch, getState }) => {
    const {
      notifications: { pendingTeamCreationNotifications },
    } = getState() as RootState;
    const allPendingTeamCreationNotifications = teamCreationNotificationAdaptor
      .getSelectors()
      .selectAll(pendingTeamCreationNotifications);
    if (allPendingTeamCreationNotifications.length > 0) {
      dispatch(
        dismissNotification(
          allPendingTeamCreationNotifications[0].notificationId
        )
      );
    }
  }
);

export const onLaunchTeamScanForTeamIdsDismissNotification = createAsyncThunk(
  "notifications/onLaunchTeamScanForTeamIdsDismissNotification",
  async (teamIds: number[], { dispatch, getState }) => {
    const {
      notifications: { pendingLaunchTeamScanNotifications },
    } = getState() as RootState;

    // we should iterate through all of the pendingLaunchTeamScanNotifications and remove the ones that are in the payload
    const allPendingLaunchTeamScanNotifications =
      launchTeamScanNotificationAdaptor
        .getSelectors()
        .selectAll(pendingLaunchTeamScanNotifications);
    const foundNotifications = allPendingLaunchTeamScanNotifications.filter(
      (notification) =>
        notification.teamId && teamIds.includes(notification.teamId)
    );
    if (foundNotifications.length > 0) {
      foundNotifications.forEach((notification) => {
        dispatch(dismissNotification(notification.notificationId));
      });
    }
  }
);

export const onInviteMembersToTeamDismissNotification = createAsyncThunk(
  "notifications/onInviteMembersToTeamDismissNotification",
  async (teamId: number, { dispatch, getState }) => {
    const {
      notifications: { pendingAddTeamMemberNotifications },
    } = getState() as RootState;
    // we should iterate through all of the pendingAddTeamMemberNotifications and remove the ones that are in the payload
    const allPendingInviteMemberNotifications = addTeamMemberNotificationAdaptor
      .getSelectors()
      .selectAll(pendingAddTeamMemberNotifications);
    const foundNotification = allPendingInviteMemberNotifications.find(
      (notification) => notification.teamId === teamId
    );
    if (foundNotification) {
      dispatch(dismissNotification(foundNotification.notificationId));
    }
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (
      state,
      { payload }: PayloadAction<NotificationResponse>
    ) => {
      const resetAndAddNotifications = <T>(
        adaptor: EntityAdapter<T>,
        notifications?: T[]
      ): EntityState<T> => {
        const initialState = adaptor.getInitialState();
        return adaptor.addMany(initialState, notifications ?? []);
      };

      state.teamNotifications = resetAndAddNotifications(
        teamInvitationAdaptor,
        payload.teamInvitationNotifications
      );

      state.pendingAssessmentNotifications = resetAndAddNotifications(
        pendingAssessmentNotificationAdaptor,
        payload.pendingAssessmentNotifications
      );

      state.pendingTeamCreationNotifications = resetAndAddNotifications(
        teamCreationNotificationAdaptor,
        Object.values(payload.createATeamNotifications ?? {})
      );

      state.pendingAddTeamMemberNotifications = resetAndAddNotifications(
        addTeamMemberNotificationAdaptor,
        Object.values(payload.addTeamMemberNotifications ?? {})
      );

      state.pendingSendTeamScanReminderNotifications =
        sendTeamScanReminderNotificationAdaptor.addMany(
          state.pendingSendTeamScanReminderNotifications,
          Object.values(payload.teamScanSendReminderNotifications ?? {})
        );

      state.pendingSendTeamScanReminderNotifications = resetAndAddNotifications(
        sendTeamScanReminderNotificationAdaptor,
        Object.values(payload.teamScanSendReminderNotifications ?? {})
      );

      state.pendingAssessmentResultsNotifications = resetAndAddNotifications(
        pendingAssessmentResultsNotificationAdaptor,
        Object.values(payload.assessmentResultsNotifications ?? {})
      );

      state.pendingLaunchTeamScanNotifications = resetAndAddNotifications(
        launchTeamScanNotificationAdaptor,
        Object.values(payload.launchTEAMScanNotifications ?? {})
      );

      state.newDirectReportNotifications = resetAndAddNotifications(
        newDirectReportAdaptor,
        Object.values(payload.newDirectReportNotifications ?? {})
      );

      state.chatWithDirectReportNotifications = resetAndAddNotifications(
        chatWithDirectReportNotificationAdaptor,
        Object.values(payload.chatWithDirectReportNotifications ?? {})
      );

      state.coachBoCheckInNotifications = resetAndAddNotifications(
        coachBoCheckInNotificationAdaptor,
        Object.values(payload.weeklyCheckInNotifications ?? {})
      );

      state.connectedYourPersonalityReportNotification =
        Object.values(payload.takeEPPNotifications ?? {})?.[0] || null;
    },
    setDismissedNotificationId: (state, { payload }: PayloadAction<number>) => {
      state.dismissedNotificationIds.push(payload);
    },
    setShowViewResultsReadyModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showViewResultsReadyModal = payload;
    },
    dismissNotificationByNotificationId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      // Use the selector to get all entities as an array in either teamNotifications or pendingAssessmentNotifications
      const allTeamInvitations = teamInvitationAdaptor
        .getSelectors()
        .selectAll(state.teamNotifications);

      const allPendingAssessmentNotifications =
        pendingAssessmentNotificationAdaptor
          .getSelectors()
          .selectAll(state.pendingAssessmentNotifications);

      // Find the entity with the given notificationId in either teamNotifications or pendingAssessmentNotifications
      const teamInvitationsToDelete = allTeamInvitations.find(
        (entity) => entity.notificationId === payload
      );
      const pendingAssessmentNotificationsToDelete =
        allPendingAssessmentNotifications.find(
          (entity) => entity.notificationId === payload
        );

      // If the entity is found, use its teamId to delete it
      if (teamInvitationsToDelete) {
        teamInvitationAdaptor.removeOne(
          state.teamNotifications,
          teamInvitationsToDelete.teamId
        );
      }

      // If the entity is found, use its teamId to delete it
      if (pendingAssessmentNotificationsToDelete) {
        pendingAssessmentNotificationAdaptor.removeOne(
          state.pendingAssessmentNotifications,
          pendingAssessmentNotificationsToDelete.teamId
        );
      }

      pendingAssessmentResultsNotificationAdaptor.removeOne(
        state.pendingAssessmentResultsNotifications,
        payload
      );
      teamCreationNotificationAdaptor.removeOne(
        state.pendingTeamCreationNotifications,
        payload
      );
      addTeamMemberNotificationAdaptor.removeOne(
        state.pendingAddTeamMemberNotifications,
        payload
      );
      launchTeamScanNotificationAdaptor.removeOne(
        state.pendingLaunchTeamScanNotifications,
        payload
      );
      sendTeamScanReminderNotificationAdaptor.removeOne(
        state.pendingSendTeamScanReminderNotifications,
        payload
      );
      newDirectReportAdaptor.removeOne(
        state.newDirectReportNotifications,
        payload
      );
      chatWithDirectReportNotificationAdaptor.removeOne(
        state.chatWithDirectReportNotifications,
        payload
      );
      coachBoCheckInNotificationAdaptor.removeOne(
        state.coachBoCheckInNotifications,
        payload
      );

      if (
        state.connectedYourPersonalityReportNotification?.notificationId ===
        payload
      ) {
        state.connectedYourPersonalityReportNotification = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptInvitation.pending, (state) => {
        state.acceptInvitationStatus = "loading";
      })
      .addCase(acceptInvitation.fulfilled, (state) => {
        toast.success("Invitation accepted", successNotificationOptions);
        state.acceptInvitationStatus = "succeeded";
      })
      .addCase(acceptInvitation.rejected, (state) => {
        state.acceptInvitationStatus = "failed";
      })
      .addCase(getNotifications.pending, (state) => {
        state.getNotificationsStatus = "loading";
      })
      .addCase(getNotifications.fulfilled, (state) => {
        state.getNotificationsStatus = "succeeded";
      })
      .addCase(getNotifications.rejected, (state) => {
        state.getNotificationsStatus = "failed";
      })
      .addCase(dismissNotification.pending, (state) => {
        state.dismissNotificationStatus = "loading";
      })
      .addCase(dismissNotification.fulfilled, (state) => {
        state.dismissNotificationStatus = "succeeded";
      })
      .addCase(dismissNotification.rejected, (state) => {
        state.dismissNotificationStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const {
  setNotifications,
  setDismissedNotificationId,
  setShowViewResultsReadyModal,
  dismissNotificationByNotificationId,
} = notificationsSlice.actions;

export const {
  selectById: selectTeamInvitationById,
  selectAll: selectAllTeamInvitations,
  selectEntities: selectTeamInvitationEntities,
} = teamInvitationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.teamNotifications
);
export const {
  selectById: selectPendingAssessmentNotificationsById,
  selectAll: selectAllPendingAssessmentsNotifications,
  selectEntities: selectPendingAssessmentNotificationsEntities,
} = pendingAssessmentNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.pendingAssessmentNotifications
);

export const {
  selectById: selectAssessmentResultNotificationsById,
  selectAll: selectAllAssessmentResultsNotifications,
  selectEntities: selectAssessmentResultNotificationsEntities,
} = pendingAssessmentResultsNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.pendingAssessmentResultsNotifications
);

export const {
  selectById: selectTeamCreationNotificationById,
  selectAll: selectAllTeamCreationNotifications,
  selectEntities: selectTeamCreationNotificationEntities,
} = teamCreationNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.pendingTeamCreationNotifications
);

export const {
  selectById: selectAddTeamMemberNotificationById,
  selectAll: selectAllAddTeamMemberNotifications,
  selectEntities: selectAddTeamMemberNotificationEntities,
} = addTeamMemberNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.pendingAddTeamMemberNotifications
);

export const {
  selectById: selectSendTeamScanReminderNotificationById,
  selectAll: selectAllSendTeamScanReminderNotifications,
  selectEntities: selectSendTeamScanReminderNotificationEntities,
} = sendTeamScanReminderNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.pendingSendTeamScanReminderNotifications
);

export const {
  selectById: selectLaunchTeamScanNotificationById,
  selectAll: selectAllLaunchTeamScanNotifications,
  selectEntities: selectLaunchTeamScanNotificationEntities,
} = launchTeamScanNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.pendingLaunchTeamScanNotifications
);

export const {
  selectById: selectNewDirectReportNotificationById,
  selectAll: selectAllNewDirectReportNotifications,
  selectEntities: selectNewDirectReportNotificationEntities,
} = newDirectReportAdaptor.getSelectors<RootState>(
  (state) => state.notifications.newDirectReportNotifications
);

export const {
  selectById: selectChatWithDirectReportNotificationById,
  selectAll: selectAllChatWithDirectReportNotifications,
  selectEntities: selectChatWithDirectReportNotificationEntities,
} = chatWithDirectReportNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.chatWithDirectReportNotifications
);

export const {
  selectById: selectCoachBoCheckInNotificationById,
  selectAll: selectAllCoachBoCheckInNotifications,
  selectEntities: selectCoachBoCheckInNotificationEntities,
} = coachBoCheckInNotificationAdaptor.getSelectors<RootState>(
  (state) => state.notifications.coachBoCheckInNotifications
);

export const selectConnectedYourPersonalityReportNotification = (
  state: RootState
) => state.notifications.connectedYourPersonalityReportNotification;

export const selectDismissedNotificationIds = (state: RootState) =>
  state.notifications.dismissedNotificationIds;
export const selectAcceptInvitationStatus = (state: RootState) =>
  state.notifications.acceptInvitationStatus;
export const selectGetNotificationsStatus = (state: RootState) =>
  state.notifications.getNotificationsStatus;
export const selectDismissNotificationStatus = (state: RootState) =>
  state.notifications.dismissNotificationStatus;
export const selectShowViewResultsReadyModal = (state: RootState) =>
  state.notifications.showViewResultsReadyModal;

export default notificationsSlice.reducer;
