interface Props {
  title: string;
  description: string;
  button: React.ReactNode;
}

const ActivityMonitorContainerEmptyContent = ({
  title,
  description,
  button,
}: Readonly<Props>) => (
  <div className="activity-monitor-container__empty-content">
    <span className="title">{title}</span>
    <p className="description">{description}</p>
    <div className="action-button-container">{button}</div>
  </div>
);

export default ActivityMonitorContainerEmptyContent;
