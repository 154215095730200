import { selectCurrentUserAccountId } from "app/containers/Global/slice";
import { useEffect, useRef } from "react";
import { useAppSelector } from "utils/redux/hooks";

// The purpose of this hook is to detect a change inside of the stored Token,
//    if the token changed then we should reload the page.
// Only checking when revisiting the page, to prevent infinite loops.
const useCheckTokenChange = () => {
  const tokenRef = useRef<string | null>(null);
  const user = useAppSelector(selectCurrentUserAccountId);

  useEffect(() => {
    if (!user) return;
    const token = localStorage.getItem("critToken");
    tokenRef.current = token;
  }, [user]);

  const handleStorageChange = () => {
    const localStoredToken = localStorage.getItem("critToken");
    const currentToken = tokenRef.current;
    tokenRef.current = localStoredToken;

    // If the token is different then we need to reload the page.
    if (currentToken !== localStoredToken) {
      window.location.reload();
    }
  };

  // Set up event listener for visibility change
  useEffect(() => {
    document.addEventListener("visibilitychange", handleStorageChange);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleStorageChange);
    };
  }, []);

  return null;
};

export default useCheckTokenChange;
