import { Card } from "react-bootstrap";
import { ActivityMonitorBoxProps } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelpPopover from "../Team360Assessment/HelpPopover";

const getColor = (isPositive: boolean, isColorInverted?: boolean) => {
  if ((isPositive && !isColorInverted) || (!isPositive && isColorInverted)) {
    return "#00C067";
  }
  return "#D50057";
};

const ActivityMonitorBox = ({
  title,
  mainValue,
  changeSinceLast,
  timeInterval = "week",
  descriptionText,
  dotColor,
  popoverText,
  additionalDescriptionContent = null,
  popoverTitle,
}: Readonly<ActivityMonitorBoxProps>) => {
  const getChangeSinceLast = () => {
    if (changeSinceLast === null || changeSinceLast === undefined) {
      return <p>No comparison data yet</p>;
    }
    const { totalChange, isPositive, isColorInverted } = changeSinceLast;

    const rawTotalChange =
      typeof totalChange === "string"
        ? Number(totalChange.replace("%", ""))
        : totalChange;

    if (rawTotalChange === 0) {
      return <p>No change since last {timeInterval}</p>;
    }

    return (
      <>
        <FontAwesomeIcon
          icon={`circle-arrow-${isPositive ? "up" : "down"}`}
          style={{
            color: getColor(isPositive, isColorInverted),
          }}
        />
        <p>
          {isPositive ? "+" : "-"}
          {totalChange} since last {timeInterval}
        </p>
      </>
    );
  };

  return (
    <Card className="activity-monitor-box">
      <span className="activity-monitor-box__title">
        {title}
        {popoverText ? (
          <HelpPopover text={popoverText} title={title ?? popoverTitle} />
        ) : null}
      </span>
      <div className="activity-monitor-box__body">
        <span className="activity-monitor-box__main-value">
          {mainValue ?? "-"}
        </span>
        <div className="activity-monitor-box__description">
          {descriptionText ? <p>{descriptionText}</p> : null}
          {dotColor ? <div className={`status-dot ${dotColor}`} /> : null}
          {additionalDescriptionContent}
        </div>
      </div>
      <div className="change-since-last">{getChangeSinceLast()}</div>
    </Card>
  );
};

export default ActivityMonitorBox;
