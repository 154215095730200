import "utils/fontawesomeIcons";
import "react-toastify/dist/ReactToastify.css";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import GlobalNavbar from "app/components/GlobalNavbar";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  signUserIn,
  getAppCuesInformation,
  selectGetUserStatus,
  selectCurrentUserAccountId,
  selectGetAllTeamsStatus,
  selectGetAllUserStatus,
  selectAppCuesInformation,
  selectGetAppCuesInformationStatus,
  selectCurrentUserInfo,
  setConfigCatFlagStatus,
  selectGetConfigCatFlagsStatus,
} from "app/containers/Global/slice";
import { getUserConfigCat, getUserIdFromCookie } from "utils/helperFunctions";
import Loading from "app/storybookComponents/Loading";
import { ToastContainer } from "react-toastify";
import CreateTeamModal from "app/components/Modals/CreateTeamModal";
import {
  addBreadCrumb,
  clearHistoryArray,
} from "app/components/BreadCrumbs/slice";
import OnboardingModal from "app/components/Onboarding/OnboardingModal";
import LaunchAssessmentModal from "app/components/LaunchAssessmentModal/LaunchAssessmentModal";
import EditDepartmentModal from "app/components/Modals/EditDepartmentModal";
import Team360AssessmentModal from "app/components/Team360Assessment/Team360AssessmentModal";
import FloatingChatbotButton from "app/containers/Chatbot/FloatingChatbotButton";
import InviteTeamLeaderModal from "app/components/Modals/InviteTeamLeaderModal";
import SubscriptionModal from "app/components/Modals/SubscriptionModal";
import QuickActionDrawer from "app/components/QuickActions/QuickActionDrawer";
import { selectIsDepartmentsHidden } from "app/containers/AdminConsole/slice";
import {
  IS_AU_INSTANCE,
  REACT_APP_SEGMENT_KEY,
} from "utils/environmentVariables";
import AllRoutes from "routes";
import useCheckTokenChange from "utils/hooks/useCheckTokenChange";
import {
  selectIsSubscriptionModalOpen,
  selectIsNewDirectReportModalOpen,
  setIsNewDirectReportModalOpen,
} from "app/components/Modals/slice";
import NewDirectReportModal from "app/components/Modals/NewDirectReportModal/NewDirectReportModal";
import { getUserIdendifier } from "utils/trackingFunctions";
import {
  getIdentityProvider,
  selectIdentityProviderStatus,
} from "app/containers/Login/slice";
import { onDismissDirectReportNotifications } from "app/components/Notifications/helpers";
import { selectAllNewDirectReportNotifications } from "app/components/Notifications/slice";
import { useConfigCatClient, User } from "configcat-react";
// PLOP_IMPORT_CONTAINER

// These will be the only pages that will show the breadcrumbs, we can add more later if we want
const allowedLocations = ["UserGuide", "TeamGuide", "CompanyGuide", "Search"];

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const configCatClient = useConfigCatClient();
  const getConfigCatStatus = useAppSelector(selectGetConfigCatFlagsStatus);
  const user = useAppSelector(selectCurrentUserAccountId);
  const appCuesInformation = useAppSelector(selectAppCuesInformation);
  const getUserStatus = useAppSelector(selectGetUserStatus);
  const getAllUsersStatus = useAppSelector(selectGetAllUserStatus);
  const getAllTeamStatus = useAppSelector(selectGetAllTeamsStatus);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const getAppCuesInformationStatus = useAppSelector(
    selectGetAppCuesInformationStatus
  );
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const showSubscriptionModal = useAppSelector(selectIsSubscriptionModalOpen);
  const isNewDirectReportModalOpen = useAppSelector(
    selectIsNewDirectReportModalOpen
  );
  const identityProviderStatus = useAppSelector(selectIdentityProviderStatus);
  const allNewDirectReportNotifications = useAppSelector(
    selectAllNewDirectReportNotifications
  );

  useCheckTokenChange();
  useEffect(() => {
    window.analytics?.load(REACT_APP_SEGMENT_KEY);
  }, []);

  useEffect(() => {
    if (userInfo && identityProviderStatus === "idle") {
      dispatch(getIdentityProvider({ emailAddress: userInfo?.emailAddress }));
    }
  }, [userInfo, dispatch, identityProviderStatus]);

  useEffect(() => {
    if (checkIfURLisPublic()) {
      return;
    }

    const { userId, godMode } = getUserIdFromCookie();
    // Once the user gets to the page then we need to fetch the user data, if the user is not validated then we need to kick them out.s
    if (getUserStatus === "idle" && userId !== null) {
      dispatch(signUserIn({ userAccountId: userId, godMode }));
    }
  }, [dispatch, getUserStatus]);

  // This hook is used to add and clear the breadcrumbs
  useEffect(() => {
    if (getAllTeamStatus !== "succeeded" || getAllUsersStatus !== "succeeded") {
      return;
    }

    const pathArray = pathname.split("/");
    if (!allowedLocations.includes(pathArray[1])) {
      dispatch(clearHistoryArray());
      return;
    }

    dispatch(addBreadCrumb(pathname));
  }, [pathname, dispatch, user, getAllTeamStatus, getAllUsersStatus]);

  const getConfigCatFlags = useCallback(async () => {
    // If not idle, no need to fetch the flags again
    if (getConfigCatStatus !== "idle") {
      return;
    }
    dispatch(setConfigCatFlagStatus("loading"));
    await configCatClient.forceRefreshAsync();
    const subdomainLocaleInstancePrefix = IS_AU_INSTANCE ? "AU" : "US";
    const userAccountId = `${userInfo?.userAccountId ?? ""}`;

    const currUser: User = new User(
      userAccountId,
      userInfo?.emailAddress ?? "",
      undefined,
      {
        companyAccountId: userInfo?.companyAccountId?.toString() ?? "",
        companyAccountIdWithLocale: `${subdomainLocaleInstancePrefix}${userInfo?.companyAccountId}`,
        instance: subdomainLocaleInstancePrefix,
      }
    );
    getUserConfigCat(currUser, configCatClient, dispatch);
  }, [configCatClient, getConfigCatStatus, userInfo, dispatch]);

  useEffect(() => {
    if (!userInfo) return;
    getConfigCatFlags();
  }, [userInfo, getConfigCatFlags]);

  // if the user is logged in get the app cues information
  useEffect(() => {
    if (user && getAppCuesInformationStatus === "idle") {
      dispatch(getAppCuesInformation());
    }
  }, [dispatch, user, getAppCuesInformationStatus]);

  // if appCuesInformation is not empty, identify the user
  useEffect(() => {
    if (appCuesInformation && user) {
      window.Appcues.identify(
        getUserIdendifier(user.toString()),
        appCuesInformation
      );
    }
  }, [appCuesInformation, user]);

  // As of now only two public routes should be /accountSetup and /join/:joinType/:inviteCode
  const checkIfURLisPublic = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const [, param1, param2] = window.location.pathname.split("/");
    const validJoinTypes = ["companies", "teams"];
    return (
      (param1 === "join" && validJoinTypes.includes(param2)) ||
      (param1 === "accountSetup" && queryParams.has("token1"))
    );
  };

  const [, firstPathName] = pathname.split("/");

  // This constant is used to hide the navbar and chatbot button on certain pages.
  const hideUserComponents = useMemo(() => {
    if (!user) {
      return true;
    }
    switch (firstPathName) {
      case "accountSetup":
      case "join":
      case "survey":
      case "PasswordReset":
        return true;
    }
    return false;
  }, [firstPathName, user]);

  const navbar = useMemo(() => {
    if (hideUserComponents) {
      return null;
    }
    return <GlobalNavbar />;
  }, [hideUserComponents]);

  const chatbot = useMemo(() => {
    if (hideUserComponents) {
      return null;
    }

    return <FloatingChatbotButton />;
  }, [hideUserComponents]);

  const quickActions = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get("eventId");
    const inSampleTeamScan = eventId === "sample";
    if (hideUserComponents && !inSampleTeamScan) {
      return null;
    }

    return <QuickActionDrawer />;
  }, [hideUserComponents]);

  // If gerUsersStatus is loading then we need to show the loading screen.
  if (getUserStatus === "loading") {
    return <Loading />;
  }

  return (
    <>
      <Team360AssessmentModal />
      <CreateTeamModal />
      <ToastContainer />
      <OnboardingModal />
      <LaunchAssessmentModal />
      <SubscriptionModal />
      {isNewDirectReportModalOpen ? (
        <NewDirectReportModal
          isOpen
          onCloseModal={() => {
            dispatch(setIsNewDirectReportModalOpen(null));
          }}
          userAccountIds={isNewDirectReportModalOpen.userAccountIds}
          onDismissDirectReportNotifications={() => {
            onDismissDirectReportNotifications(
              dispatch,
              isNewDirectReportModalOpen.userAccountIds,
              allNewDirectReportNotifications
            );
          }}
        />
      ) : null}
      {!isDepartmentsHidden ? <EditDepartmentModal /> : null}
      <InviteTeamLeaderModal />
      {/* When taking the assessment then we need to hide the navbar.  */}
      {showSubscriptionModal ? null : (
        <>
          {navbar}
          {chatbot}
          {quickActions}
        </>
      )}

      <AllRoutes />
    </>
  );
}

export default App;
