import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { Form } from "react-bootstrap";
import ListModuleForm from "../Forms/ListModuleForm";
import FreeTextModuleForm from "../Forms/FreeTextModuleForm";
import {
  hideModal,
  selectAddModuleToUserGuideStatus,
  selectDeleteUserModuleByIdStatus,
  selectEditUserGuideModuleStatus,
  selectAddModuleToTeamGuideStatus,
  selectDeleteTeamModuleByIdStatus,
  selectEditTeamGuideModuleStatus,
  selectSelectedModule,
  selectGuideType,
  selectUserIdOrTeamIdOrCompanyId,
  selectAddModuleToCompanyGuideStatus,
  selectDeleteCompanyModuleByIdStatus,
  selectEditCompanyGuideModuleStatus,
} from "../slice";
import { isModuleFreeTextType, isModuleListType } from "../types";
import Button from "app/storybookComponents/Button";

// container imports preferably keep them contained to just api calls
import {
  selectUserModuleTemplates,
  addModuleToUserGuide,
  editUserGuideModule,
  deleteUserModuleById,
} from "app/containers/UserGuide/slice";
import {
  selectTeamModuleTemplates,
  editTeamGuideModule,
  deleteTeamModuleById,
  addModuleToTeamGuide,
} from "app/containers/TeamGuide/slice";
import {
  selectCompanyModuleTemplates,
  editCompanyGuideModule,
  deleteCompanyModuleById,
  addModuleToCompanyGuide,
} from "app/containers/CompanyGuide/slice";
import EditResourceModuleForm from "../Forms/EditResourceModuleForm";

export default function EditModuleModal() {
  const dispatch = useAppDispatch();
  const guideType = useAppSelector(selectGuideType);
  const userIdOrTeamIdOrCompanyId = useAppSelector(
    selectUserIdOrTeamIdOrCompanyId
  );
  const selectedModule = useAppSelector(selectSelectedModule);

  // User Selectors
  const userModuleTemplates = useAppSelector(selectUserModuleTemplates);
  const addUserModuleStatus = useAppSelector(selectAddModuleToUserGuideStatus);
  const deleteUserModuleStatus = useAppSelector(
    selectDeleteUserModuleByIdStatus
  );
  const editUserModuleStatus = useAppSelector(selectEditUserGuideModuleStatus);

  // Team Selectors
  const teamModuleTemplates = useAppSelector(selectTeamModuleTemplates);
  const addTeamModuleStatus = useAppSelector(selectAddModuleToTeamGuideStatus);
  const deleteTeamModuleStatus = useAppSelector(
    selectDeleteTeamModuleByIdStatus
  );
  const editTeamModuleStatus = useAppSelector(selectEditTeamGuideModuleStatus);

  // Company Selectors
  const companyModuleTemplates = useAppSelector(selectCompanyModuleTemplates);
  const addCompanyModuleStatus = useAppSelector(
    selectAddModuleToCompanyGuideStatus
  );
  const deleteCompanyModuleStatus = useAppSelector(
    selectDeleteCompanyModuleByIdStatus
  );
  const editCompanyModuleStatus = useAppSelector(
    selectEditCompanyGuideModuleStatus
  );

  const onSave = (
    section: string,
    title: string,
    moduleType: string,
    moduleContent: string,
    moduleTemplateId?: number,
    link: string = ""
  ) => {
    if (selectedModule?.talentInsightsModuleId) {
      onSaveExistingModule(title, moduleContent, link);
    } else {
      onSaveNewModule(
        section,
        title,
        moduleType,
        moduleContent,
        moduleTemplateId,
        link
      );
    }
  };

  const onSaveNewModule = (
    section: string,
    title: string,
    moduleType: string,
    moduleContent: string,
    moduleTemplateId?: number,
    link: string = ""
  ) => {
    if (userIdOrTeamIdOrCompanyId === null) return;
    if (selectedModule?.talentInsightsModuleId) return;
    const payload = {
      section,
      title,
      moduleType,
      moduleContent,
      link,
      moduleTemplateId,
    };

    switch (guideType) {
      case "team": {
        return dispatch(
          addModuleToTeamGuide({
            payload,
            teamId: userIdOrTeamIdOrCompanyId,
          })
        );
      }
      case "company": {
        return dispatch(
          addModuleToCompanyGuide({
            payload,
            companyAccountId: userIdOrTeamIdOrCompanyId,
          })
        );
      }
      case "user":
      default: {
        dispatch(
          addModuleToUserGuide({
            payload,
            userAccountId: userIdOrTeamIdOrCompanyId,
          })
        );
      }
    }
  };

  const onSaveExistingModule = (
    title: string,
    moduleContent: string,
    link: string = ""
  ) => {
    if (userIdOrTeamIdOrCompanyId === null) return;
    if (!selectedModule?.talentInsightsModuleId) return;

    switch (guideType) {
      case "team": {
        return dispatch(
          editTeamGuideModule({
            payload: {
              talentInsightsModuleId: selectedModule.talentInsightsModuleId,
              title,
              moduleContent,
              link,
            },
            teamId: userIdOrTeamIdOrCompanyId,
          })
        );
      }
      case "company": {
        return dispatch(
          editCompanyGuideModule({
            payload: {
              talentInsightsModuleId: selectedModule.talentInsightsModuleId,
              title,
              moduleContent,
              link,
            },
            companyAccountId: userIdOrTeamIdOrCompanyId,
          })
        );
      }
      case "user":
      default: {
        dispatch(
          editUserGuideModule({
            payload: {
              talentInsightsModuleId: selectedModule.talentInsightsModuleId,
              title,
              moduleContent,
              link,
            },
            userAccountId: userIdOrTeamIdOrCompanyId,
          })
        );
      }
    }
  };

  const onDelete = (talentInsightsModuleId: number) => {
    if (!userIdOrTeamIdOrCompanyId) return;

    switch (guideType) {
      case "team":
        return dispatch(
          deleteTeamModuleById({
            talentInsightsModuleId,
            teamId: userIdOrTeamIdOrCompanyId,
          })
        );
      case "company":
        return dispatch(
          deleteCompanyModuleById({
            talentInsightsModuleId,
            companyAccountId: userIdOrTeamIdOrCompanyId,
          })
        );
      case "user":
      default:
        return dispatch(
          deleteUserModuleById({
            talentInsightsModuleId,
          })
        );
    }
  };

  const getCurrentModuleTemplates = () => {
    switch (guideType) {
      case "team":
        return teamModuleTemplates;
      case "company":
        return companyModuleTemplates;
      case "user":
      default:
        return userModuleTemplates;
    }
  };

  const isLoading = () => {
    switch (guideType) {
      case "team":
        return (
          addTeamModuleStatus === "loading" ||
          deleteTeamModuleStatus === "loading" ||
          editTeamModuleStatus === "loading"
        );
      case "company":
        return (
          addCompanyModuleStatus === "loading" ||
          deleteCompanyModuleStatus === "loading" ||
          editCompanyModuleStatus === "loading"
        );
      case "user":
      default:
        return (
          addUserModuleStatus === "loading" ||
          deleteUserModuleStatus === "loading" ||
          editUserModuleStatus === "loading"
        );
    }
  };

  // Extracting this because it is being reused in every component.
  const getSubmitButton = (
    talentInsightsModuleId?: number,
    isDisabled?: boolean
  ) => (
    <Form.Group
      className={`d-flex justify-content-${
        talentInsightsModuleId ? "between" : "end"
      }`}
    >
      {talentInsightsModuleId ? (
        <Button
          variant="secondary-blue"
          onClick={() => {
            onDelete(talentInsightsModuleId);
          }}
          disabled={isLoading()}
        >
          Delete Module
        </Button>
      ) : null}
      <Button type="submit" disabled={isLoading() || isDisabled}>
        Save
      </Button>
    </Form.Group>
  );

  const getForm = () => {
    if (moduleTemplates === null || selectedModule === null) {
      return null;
    }
    const {
      moduleType,
      moduleTemplateId,
      moduleContent,
      moduleTitle,
      talentInsightsModuleId,
      link,
    } = selectedModule;

    // Since the moduleType of resource is a freeform no need to check the module template, that is why we are exiting early.
    if (moduleType === "Resource") {
      return (
        <EditResourceModuleForm
          onSave={onSave}
          moduleDescription={moduleContent}
          moduleTitle={moduleTitle}
          getSubmitButton={getSubmitButton}
          talentInsightsModuleId={talentInsightsModuleId}
          moduleLink={link}
        />
      );
    }

    const selectedModuleObj = moduleTemplates[moduleType][moduleTemplateId];
    switch (moduleType) {
      case "Free Text": {
        if (isModuleFreeTextType(selectedModuleObj)) {
          return (
            <FreeTextModuleForm
              modulePriorityOrder={selectedModuleObj.modulePriorityOrder}
              characterLimit={500}
              placeholder={selectedModuleObj.placeholder}
              shortDescription={selectedModuleObj.shortDescription}
              description={selectedModuleObj.description}
              linkAllowed={selectedModuleObj.linkAllowed}
              moduleTemplateId={selectedModuleObj.moduleTemplateId}
              section={selectedModuleObj.section}
              title={selectedModuleObj.title}
              onSave={onSave}
              moduleContent={moduleContent}
              talentInsightsModuleId={talentInsightsModuleId}
              getSubmitButton={getSubmitButton}
              link={link}
            />
          );
        }
        break;
      }
      case "List": {
        if (isModuleListType(selectedModuleObj)) {
          return (
            <ListModuleForm
              modulePriorityOrder={selectedModuleObj.modulePriorityOrder}
              perItemCharacterLimit={selectedModuleObj.perItemCharacterLimit}
              prefilled={selectedModuleObj.prefilled}
              shortDescription={selectedModuleObj.shortDescription}
              totalItemLimit={8}
              description={selectedModuleObj.description}
              linkAllowed={selectedModuleObj.linkAllowed}
              moduleTemplateId={selectedModuleObj.moduleTemplateId}
              section={selectedModuleObj.section}
              onSave={onSave}
              title={selectedModuleObj.title}
              moduleContent={moduleContent}
              talentInsightsModuleId={talentInsightsModuleId}
              getSubmitButton={getSubmitButton}
              link={link}
            />
          );
        }
        break;
      }
    }
    return null;
  };

  const getModuleTitle = () => {
    if (moduleTemplates === null || selectedModule === null) {
      return "";
    }

    if (selectedModule.moduleType === "Resource") {
      return "Key Resource";
    }

    return moduleTemplates[selectedModule.moduleType][
      selectedModule.moduleTemplateId
    ].title;
  };

  const moduleTemplates = getCurrentModuleTemplates();

  return (
    <>
      <div className="modal-title-row">
        <h2>{getModuleTitle()}</h2>
        <Button
          onClick={() => dispatch(hideModal())}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <div>{getForm()}</div>
    </>
  );
}
