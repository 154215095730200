import Button from "app/storybookComponents/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectDepartments,
  selectIsDepartmentsHidden,
} from "app/containers/AdminConsole/slice";
import SortableTable from "app/components/SortableTable";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import CheckInResponseSummaryModal from "../CheckInResponseSummaryModal";
import { Card, Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOutOfFiveColor } from "app/containers/Dashboard/helpers";
import { useNavigate } from "react-router-dom";
import { getSelectProps } from "utils/helperFunctions";
import {
  GroupWeeklyCheckInTableRowData,
  isWeeklyCheckInRowUser,
  SelectedCheckInBreakdownEntity,
  WeeklyCheckInTableRowData,
  AllWeeklyCheckInTableData,
} from "../types";
import ExportCSVButton from "app/storybookComponents/Button/ExportCSVButton";
import {
  getDateForSorting,
  getWeeklyCheckInTableRowEntityId,
  getTableDataFromSelectedRow,
} from "../helpers";
import EmptyCard from "app/storybookComponents/Cards/EmptyCard";
import Loading from "app/storybookComponents/Loading";
import {
  WeeklyCheckInInitialTimeInterval,
  WeeklyCheckInInitialEntityType,
  WeeklyCheckInTimeIntervals,
  WeeklyCheckInEntityTypes,
} from "app/containers/AdminConsole/constants";
import { trackCSVExport } from "utils/trackingFunctions";
import CheckinScorePopover from "app/components/Popovers/CheckinScorePopover";
import SimplePopover from "app/components/Popovers/SimplePopover";
import TableDropdownMenu from "app/components/Dropdowns/TableDropdownMenu";
import { responseStatus } from "utils/types";
import { regradeWeeklyCheckin } from "../slice";

interface Props {
  getWeeklyCheckInTableDataStatus: responseStatus;
  allWeeklyCheckInTableData: AllWeeklyCheckInTableData;
  regradingWeeklyCheckinStatus: responseStatus;
  fetchTableData?: (params: {
    entityType: string;
    timeInterval: string;
  }) => void;
  title?: string;
  headerDescription?: string;
  fallbackBackButton?: React.ReactNode;
  hideDepartmentDropdown?: boolean;
}

const CheckInActivityTable = ({
  getWeeklyCheckInTableDataStatus,
  allWeeklyCheckInTableData,
  regradingWeeklyCheckinStatus,
  fetchTableData,
  title = "Check-in Activity",
  headerDescription = "Showing activity in this entire organization",
  fallbackBackButton,
  hideDepartmentDropdown = false,
}: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const [checkInResponseModalState, setCheckInResponseModalState] = useState<{
    isOpen: boolean;
    threadId: string;
    userAccountId: number | null;
  }>({ isOpen: false, threadId: "", userAccountId: null });
  const [displayedInput, setDisplayedInput] = useState<string>("");
  const [entitySelected, setEntitySelected] = useState(
    WeeklyCheckInInitialEntityType
  );
  const [timeInterval, setTimeInterval] = useState(
    WeeklyCheckInInitialTimeInterval
  );
  const [breakdownRowSelectedArray, setBreakdownRowSelectedArray] = useState<
    SelectedCheckInBreakdownEntity[]
  >([]);

  const breakdownRowSelected = useMemo(
    () => breakdownRowSelectedArray[0] ?? null,
    [breakdownRowSelectedArray]
  ) as SelectedCheckInBreakdownEntity | null;

  useEffect(() => {
    if (fetchTableData) {
      fetchTableData({
        entityType: entitySelected,
        timeInterval,
      });
    }
  }, [fetchTableData, timeInterval, entitySelected]);

  const formatNumber = (num: number): string => {
    // Check if the number is a whole number
    if (num % 1 === 0) {
      return num.toString();
    } else {
      // If the number has a decimal part, truncate to one decimal place
      return num.toFixed(1);
    }
  };

  const getNoScorePopoverElement = (
    trait?: string,
    threadId?: string,
    errorGeneratingScores?: boolean | 0 | 1,
    gradingCount: number = 0
  ) => {
    if (!trait || !threadId) return null;
    const icon = errorGeneratingScores ? "exclamation-triangle" : "circle-info";
    const message = errorGeneratingScores
      ? `An error occurred while trying to capture a ${trait} score for this Check-In response.`
      : `This Check-In response was not long enough to capture a ${trait} score.`;

    const maxTriesMetElement =
      gradingCount > 3 ? (
        <div
          style={{
            color: "#550023",
            backgroundColor: "#F7CCDD",
            border: "1px solid #F2B3CD",
            padding: "12px 16px",
            borderRadius: "4px",
          }}
        >
          <div className="row-gap-16px align-items-center">
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              style={{ fontSize: "16px" }}
            />
            <p>This score can only be updated up to 3 times.</p>
          </div>
        </div>
      ) : null;

    return (
      <SimplePopover
        popoverClassName="checkin-score-popover"
        popoverContent={
          <div className="column-gap-16px">
            <p>{message}</p>
            {errorGeneratingScores ? (
              <div className="column-gap-8px">
                <div>
                  <Button
                    variant="secondary-blue"
                    onClick={() => {
                      dispatch(regradeWeeklyCheckin(threadId));
                    }}
                    disabled={
                      regradingWeeklyCheckinStatus === "loading" ||
                      !!maxTriesMetElement
                    }
                  >
                    Try again
                  </Button>
                </div>
                {maxTriesMetElement}
              </div>
            ) : null}
          </div>
        }
        trigger="hover"
        hideTitleAndCloseButton
      >
        <FontAwesomeIcon icon={["far", icon]} style={{ paddingLeft: "5px" }} />
      </SimplePopover>
    );
  };

  const getScoreCell = (
    score: number | null,
    traitName?: string,
    threadId?: string,
    errorGeneratingScores?: boolean | 0 | 1,
    gradingCount?: number
  ) => {
    const dotColor = getOutOfFiveColor(score ?? 0);
    const displayValue = score ? (
      <div className="row-gap-8px align-items-center">
        {formatNumber(score)}
        <div className={`status-dot ${dotColor}`} />
      </div>
    ) : (
      <div className="d-flex">
        <p>-</p>
        {getNoScorePopoverElement(
          traitName,
          threadId,
          errorGeneratingScores,
          gradingCount
        )}
      </div>
    );

    return {
      sortValue: score,
      displayValue,
    };
  };

  const getDropdownFromRecord = (
    record: Record<string, string>,
    selected: string,
    onSelect: (str: string | null) => void,
    isDisabled: boolean = false
  ) => {
    const recordEntries = Object.entries(record);
    return (
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle
          id="dropdown-basic"
          className="d-flex align-items-center justify-content-between"
          style={{ width: "200px" }}
          variant="light"
          disabled={isDisabled}
        >
          {record[selected]}
        </Dropdown.Toggle>
        <TableDropdownMenu>
          {recordEntries.map(([key, value], idx) => (
            <React.Fragment key={key}>
              <Dropdown.Item eventKey={key}>{value}</Dropdown.Item>
              {recordEntries.length - 1 !== idx && <Dropdown.Divider />}
            </React.Fragment>
          ))}
        </TableDropdownMenu>
      </Dropdown>
    );
  };

  const getEntityDisplayValue = (
    title: {
      label: string;
      onClick: () => void;
    },
    description?: string
  ) => (
    <div>
      <button className="button-link" onClick={title.onClick}>
        <p className="user-name-cell__name">{title.label}</p>
      </button>
      {description ? <p className="small-grey-text">{description}</p> : null}
    </div>
  );

  const getUserCell = (userAccountId: number, threadId: string) => {
    const user = usersById[userAccountId];
    if (!user) {
      return "";
    }
    const { firstName = "", lastName = "", jobTitle = "No job title" } = user;
    const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
    return {
      sortValue: fullName,
      displayValue: getEntityDisplayValue(
        {
          label: fullName,
          onClick: () => {
            setCheckInResponseModalState({
              isOpen: true,
              threadId,
              userAccountId,
            });
          },
        },
        jobTitle
      ),
    };
  };

  const getDepartmentCell = (cellData: GroupWeeklyCheckInTableRowData) => {
    const { entityId: departmentId, invitedUserAccountIds = {} } = cellData;
    const invitedCount = Object.values(invitedUserAccountIds).length;
    const department = departments[departmentId] ?? {};
    const { name = "" } = department;
    const memberString = `${invitedCount} invited`;

    return {
      sortValue: name,
      displayValue: getEntityDisplayValue(
        {
          label: name,
          onClick: () => onSetBreakdownState(cellData, "department"),
        },
        memberString
      ),
    };
  };

  const getTeamCell = (cellData: GroupWeeklyCheckInTableRowData) => {
    const { entityId: teamId, invitedUserAccountIds = {} } = cellData;
    const invitedCount = Object.values(invitedUserAccountIds).length;
    const team = allTeamsById[teamId];
    const name = team?.teamName ?? "";
    const memberString = `${invitedCount} invited`;
    return {
      sortValue: name,
      displayValue: getEntityDisplayValue(
        {
          label: name,
          onClick: () => onSetBreakdownState(cellData, "team"),
        },
        memberString
      ),
    };
  };

  const getCompletionRateCell = (
    invitedCount: number = 0,
    completedCount: number = 0
  ) => {
    const completionRate = invitedCount
      ? (completedCount / invitedCount) * 100
      : 0;
    const completionRateString = `${completionRate.toFixed()} % (${completedCount}/${invitedCount})`;
    const dotColor = getOutOfFiveColor(completionRate / 20);
    return {
      sortValue: completionRate,
      displayValue: (
        <div className="row-gap-8px align-items-center">
          <p style={{ minWidth: "79px" }}>{completionRateString}</p>
          <div className={`status-dot ${dotColor}`} />
        </div>
      ),
    };
  };
  const getDateCell = (startDate: string, endDate?: string) => {
    const startDateObj = new Date(startDate);
    const startDisplayValue = startDateObj.toLocaleDateString("en-US", {
      month: endDate ? "short" : "long",
      day: "numeric",
    });

    // Guard clause for single date
    if (!endDate) {
      const timeString = startDateObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });
      return {
        sortValue: startDateObj.getTime(),
        displayValue: (
          <div>
            <p>{startDisplayValue}</p>
            <p className="small-grey-text">{timeString}</p>
          </div>
        ),
      };
    }

    // If endDate is provided, format as date range
    const endDateObj = new Date(endDate);
    const endDateString = endDateObj.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const endYearString = endDateObj.getFullYear();
    return {
      sortValue: startDateObj.getTime(),
      displayValue: (
        <p>
          {startDisplayValue} - {endDateString}, {endYearString}
        </p>
      ),
    };
  };

  const getUserDropdownCell = (threadId: string, userAccountId: number) => ({
    displayValue: (
      <Dropdown
        onSelect={(e) => {
          if (e === "summary") {
            setCheckInResponseModalState({
              isOpen: true,
              threadId,
              userAccountId,
            });
          } else if (e === "profile") {
            navigate(`/UserGuide/${userAccountId}`);
          }
        }}
        style={{ marginLeft: "auto" }}
      >
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-basic"
          className="no-caret"
        >
          <FontAwesomeIcon icon="ellipsis" />
        </Dropdown.Toggle>
        <TableDropdownMenu>
          <Dropdown.Item eventKey="summary">
            View response summary
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="profile">View profile</Dropdown.Item>
        </TableDropdownMenu>
      </Dropdown>
    ),
    sortValue: 0,
  });

  const onSetBreakdownState = (
    data: GroupWeeklyCheckInTableRowData,
    entityType: string
  ) => {
    const { entityId } = data;

    if (entityType === "user") {
      return;
    }
    const startAndEndDates = {
      startDate: data.startDate,
      endDate: data.endDate,
    };

    if (entityType === "team") {
      const userAccountIds = Object.values(data.invitedUserAccountIds);
      setEntitySelected("user");
      return setBreakdownRowSelectedArray([
        {
          type: "team",
          teamId: entityId,
          threadIds: data.threadIds ?? [],
          userAccountIds,
          ...startAndEndDates,
        },
        ...breakdownRowSelectedArray,
      ]);
    }

    setEntitySelected("team");
    fetchTableData?.({
      entityType: "team",
      timeInterval,
    });
    const teamIds = departments[entityId]?.teams ?? [];

    setBreakdownRowSelectedArray([
      {
        type: "department",
        departmentId: entityId,
        teamIds,
        ...startAndEndDates,
      },
      ...breakdownRowSelectedArray,
    ]);
  };

  const getGroupDropdownCell = (data: GroupWeeklyCheckInTableRowData) => {
    const { entityId } = data;
    const onDropdownSelect = (e: string | null) => {
      if (e === "breakdown") {
        return onSetBreakdownState(data, entitySelected);
      }

      const basePath =
        entitySelected === "department" ? "/DepartmentGuide" : "/TeamGuide";
      navigate(`${basePath}/${entityId}`);
    };

    return {
      displayValue: (
        <Dropdown onSelect={onDropdownSelect} style={{ marginLeft: "auto" }}>
          <Dropdown.Toggle
            variant="outline-primary"
            id="dropdown-basic"
            className="no-caret"
          >
            <FontAwesomeIcon icon="ellipsis" />
          </Dropdown.Toggle>
          <TableDropdownMenu>
            <Dropdown.Item eventKey="breakdown">
              View activity breakdown
            </Dropdown.Item>
            <Dropdown.Item eventKey="profile">View profile</Dropdown.Item>
          </TableDropdownMenu>
        </Dropdown>
      ),
      sortValue: 0,
    };
  };

  const weeklyCheckInTableDataShowing = useMemo(() => {
    if (breakdownRowSelected) {
      return getTableDataFromSelectedRow(
        allWeeklyCheckInTableData,
        breakdownRowSelected,
        timeInterval,
        entitySelected
      );
    }

    return Object.values(
      allWeeklyCheckInTableData?.[timeInterval]?.[entitySelected] ?? {}
    );
  }, [
    breakdownRowSelected,
    allWeeklyCheckInTableData,
    entitySelected,
    timeInterval,
  ]);

  const getFilteredRows = useCallback((): WeeklyCheckInTableRowData[] => {
    // If there is no input, return all rows
    if (!displayedInput) {
      return weeklyCheckInTableDataShowing ?? [];
    }

    if (entitySelected === "team") {
      return (
        weeklyCheckInTableDataShowing?.filter((row) => {
          const team = allTeamsById[getWeeklyCheckInTableRowEntityId(row)];
          if (!team) {
            return false;
          }
          return team.teamName
            .toLowerCase()
            .includes(displayedInput.toLowerCase());
        }) ?? []
      );
    }

    if (entitySelected === "department") {
      return (
        weeklyCheckInTableDataShowing?.filter((row) => {
          const department = departments[getWeeklyCheckInTableRowEntityId(row)];
          if (!department) {
            return false;
          }
          return department.name
            ?.toLowerCase()
            .includes(displayedInput.toLowerCase());
        }) ?? []
      );
    }

    // Otherwise, filter the weeklyCheckInTableDataShowing by user name or email
    return (
      weeklyCheckInTableDataShowing?.filter((row) => {
        const user = usersById[getWeeklyCheckInTableRowEntityId(row)];
        if (!user) {
          return false;
        }
        const { firstName = "", lastName = "", emailAddress } = user;
        const fullName = `${firstName} ${lastName}`.trim();
        return (
          fullName.toLowerCase().includes(displayedInput.toLowerCase()) ||
          emailAddress.toLowerCase().includes(displayedInput.toLowerCase())
        );
      }) ?? []
    );
  }, [
    usersById,
    displayedInput,
    entitySelected,
    departments,
    allTeamsById,
    weeklyCheckInTableDataShowing,
  ]);

  const getOverallScore = (
    moodScore: number | null,
    progressScore: number | null,
    readinessScore: number | null
  ) => {
    const totalScore =
      (moodScore ?? 0) + (progressScore ?? 0) + (readinessScore ?? 0);
    const dotScore = totalScore / 3;
    const dotColor = getOutOfFiveColor(dotScore);

    const displayValue = dotScore ? (
      <div className="row-gap-8px align-items-center">
        {formatNumber(dotScore)}
        <div className={`status-dot ${dotColor}`} />
      </div>
    ) : (
      <div className="d-flex">
        <p>-</p>
        <FontAwesomeIcon
          icon={["far", "exclamation-triangle"]}
          style={{ paddingLeft: "5px" }}
        />
      </div>
    );

    return {
      sortValue: dotScore,
      displayValue: displayValue,
    };
  };

  const getTableRow = (data: WeeklyCheckInTableRowData) => {
    const overallScore = getOverallScore(
      data.moodScore,
      data.progressScore,
      data.readinessScore
    );
    if (isWeeklyCheckInRowUser(data)) {
      return {
        moodScore: getScoreCell(
          data.moodScore,
          "mood",
          data.threadId,
          data.errorGeneratingScores,
          data.gradingCount
        ),
        progressScore: getScoreCell(
          data.progressScore,
          "progress",
          data.threadId,
          data.errorGeneratingScores,
          data.gradingCount
        ),
        readinessScore: getScoreCell(
          data.readinessScore,
          "readiness",
          data.threadId,
          data.errorGeneratingScores,
          data.gradingCount
        ),
        user: getUserCell(data.userAccountId, data.threadId),
        date: getDateCell(data.completedDate),
        dropdown: getUserDropdownCell(data.threadId, data.userAccountId),
        overallScore,
      };
    }

    const invitedUserAccountIds =
      Object.values(data.invitedUserAccountIds) ?? [];

    const groupContent = {
      moodScore: getScoreCell(data.moodScore),
      progressScore: getScoreCell(data.progressScore),
      readinessScore: getScoreCell(data.readinessScore),
      date: getDateCell(data.startDate, data.endDate),
      completionRate: getCompletionRateCell(
        invitedUserAccountIds.length,
        data.completedUserAccountIds.length
      ),
      overallScore,
      dropdown: getGroupDropdownCell(data),
    };

    if (entitySelected === "department") {
      return {
        ...groupContent,
        department: getDepartmentCell(data),
      };
    }

    return {
      ...groupContent,
      team: getTeamCell(data),
    };
  };

  const getTableRows = () => getFilteredRows().map(getTableRow);

  const getSearchPlaceholder = () => {
    if (breakdownRowSelected) {
      return "Search for a user...";
    }
    switch (entitySelected) {
      case "user":
        return "Search for a user...";
      case "team":
        return "Search for a team...";
      case "department":
        return "Search for a department...";
    }
  };

  const getSearchInput = () => {
    const { selectStyles, components } = getSelectProps();

    return (
      <Form.Group>
        <Select
          placeholder={getSearchPlaceholder()}
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={displayedInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setDisplayedInput(e);
            }
          }}
        />
      </Form.Group>
    );
  };

  const getNameCSVCellValue = useCallback(
    (entityId: number, entitySelected: string) => {
      if (entitySelected === "department") {
        return departments[entityId]?.name ?? "";
      }

      if (entitySelected === "team") {
        return allTeamsById[entityId]?.teamName ?? "";
      }
      const user = usersById[entityId];
      const { firstName = "", lastName = "" } = user ?? {};
      return `${firstName} ${lastName}`.trim();
    },
    [usersById, departments, allTeamsById]
  );

  const getCSVExportRows = useCallback(
    () =>
      getFilteredRows().map((data) => {
        const entityId =
          "userAccountId" in data ? data.userAccountId : data.entityId;
        const totalScore =
          (data.moodScore ?? 0) +
          (data.progressScore ?? 0) +
          (data.readinessScore ?? 0);
        const overallScore = totalScore / 3;
        const baseRow = {
          [entitySelected]: getNameCSVCellValue(entityId, entitySelected),
          date: getDateForSorting(data).toDateString(),
          moodScore: data.moodScore ?? "N/A",
          progressScore: data.progressScore ?? "N/A",
          readinessScore: data.readinessScore ?? "N/A",
          overallScore: totalScore ? overallScore.toFixed(2) : "N/A",
        };

        if (!("threadId" in data)) {
          const invitedLength = Object.values(
            data.invitedUserAccountIds
          ).length;
          baseRow["completionRate"] = invitedLength
            ? (
                (data.completedUserAccountIds.length / invitedLength) *
                100
              ).toFixed(2)
            : 0;
        }
        trackCSVExport("Check-In Activity");

        return baseRow;
      }),
    [getFilteredRows, getNameCSVCellValue, entitySelected]
  );

  const getHeaders = () => {
    const nameHeaderLabel =
      entitySelected.charAt(0).toUpperCase() + entitySelected.slice(1);
    const mainHeaders = [
      { label: nameHeaderLabel, key: entitySelected },
      { label: "Date", key: "date" },
      {
        label: "Overall score",
        key: "overallScore",
        additionalContent: (
          <CheckinScorePopover type="overall" showColors={false} />
        ),
      },
      {
        label: "Mood score",
        key: "moodScore",
        additionalContent: <CheckinScorePopover type="mood" />,
      },
      {
        label: "Progress score",
        key: "progressScore",
        additionalContent: <CheckinScorePopover type="progress" />,
      },
      {
        label: "Readiness score",
        key: "readinessScore",
        additionalContent: <CheckinScorePopover type="readiness" />,
      },
      { label: "", key: "dropdown", disableSort: true },
    ];

    if (entitySelected !== "user") {
      mainHeaders.splice(1, 0, {
        label: "Completion Rate",
        key: "completionRate",
      });
    }

    return mainHeaders;
  };

  const getEmptyState = () => {
    if (getWeeklyCheckInTableDataStatus === "loading") {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if (rows.length !== 0) {
      return null;
    }

    if (displayedInput) {
      return (
        <EmptyCard
          title={`No ${entitySelected} found matching "${displayedInput}"`}
          bodyText="Try searching for a different name."
        />
      );
    }

    return (
      <EmptyCard
        title="No check-in activity found"
        bodyText="Once there is check-in activity, it will be shown here."
      />
    );
  };

  const getTitleSection = () => {
    if (!breakdownRowSelected) {
      return (
        <div className="column-gap-8px">
          <h2>{title}</h2>
          <p>{headerDescription}</p>
        </div>
      );
    }

    // Example: Aug 6 - Aug 12, 2021
    const dataRangeString = getDateCell(
      breakdownRowSelected.startDate,
      breakdownRowSelected.endDate
    ).displayValue;

    const entitySelectedText = getEntitySelectedText();
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="column-gap-8px">
          <h2>Completion Activity Breakdown</h2>
          <div className="row-gap-4px grey-text">
            <p>
              Check-In activity for {entitySelectedText} from {dataRangeString}
            </p>
          </div>
        </div>
        <ExportCSVButton
          headers={headers.filter((header) => header.key !== "dropdown")}
          getRows={getCSVExportRows}
        />
      </div>
    );
  };

  const getEntitySelectedText = () => {
    if (!breakdownRowSelected) {
      return "";
    }

    if (breakdownRowSelected.type === "department") {
      const departmentName =
        departments[breakdownRowSelected.departmentId]?.name ?? "";
      return departmentName.toLowerCase().includes("department")
        ? departmentName
        : `${departmentName} Department`;
    }

    const teamName = allTeamsById[breakdownRowSelected.teamId]?.teamName;
    return teamName.toLowerCase().includes("team")
      ? teamName
      : `${teamName} Team`;
  };
  const getDropdownSection = () => {
    if (breakdownRowSelected) {
      return null;
    }
    const CheckInEntityTypes = {
      ...WeeklyCheckInEntityTypes,
    };

    if (hideDepartmentDropdown || isDepartmentsHidden) {
      delete CheckInEntityTypes["department"];
    }

    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="row-gap-12px">
          {getDropdownFromRecord(
            WeeklyCheckInTimeIntervals,
            timeInterval,
            (e) => {
              setTimeInterval(e ?? "allTime");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
          {getDropdownFromRecord(
            CheckInEntityTypes,
            entitySelected,
            (e) => {
              setEntitySelected(e ?? "user");
              setDisplayedInput("");
            },
            isDropdownDisabled
          )}
        </div>
        <div>
          <ExportCSVButton
            headers={headers.filter((header) => header.key !== "dropdown")}
            getRows={getCSVExportRows}
          />
        </div>
      </div>
    );
  };

  const onBackNavigate = () => {
    const newBreakdownRowSelectedArray = [...breakdownRowSelectedArray];
    const removedSelectedRow = newBreakdownRowSelectedArray.shift();

    if (removedSelectedRow?.type) {
      setEntitySelected(removedSelectedRow?.type);
    }
    setBreakdownRowSelectedArray(newBreakdownRowSelectedArray);
  };

  const getBackButton = () => {
    if (!breakdownRowSelected) {
      return fallbackBackButton ?? null;
    }

    return (
      <Button
        variant="secondary-blue"
        style={{ border: "none", marginBottom: "20px" }}
        onClick={() => {
          onBackNavigate();
        }}
      >
        <FontAwesomeIcon icon="arrow-left" className="me-2" /> Back
      </Button>
    );
  };

  const rows = getTableRows();
  const headers = getHeaders();
  const isDropdownDisabled = getWeeklyCheckInTableDataStatus === "loading";

  return (
    <>
      <CheckInResponseSummaryModal
        show={checkInResponseModalState.isOpen}
        onHide={() => {
          setCheckInResponseModalState({
            isOpen: false,
            threadId: "",
            userAccountId: null,
          });
        }}
        threadId={checkInResponseModalState.threadId}
        userAccountId={checkInResponseModalState.userAccountId}
      />
      <div>{getBackButton()}</div>
      <Card className="weekly-checkin-activity-table-card">
        {getTitleSection()}
        {getSearchInput()}
        {getDropdownSection()}
        <SortableTable
          rows={rows}
          tableClassName="admin-console-table"
          columnHeaders={[...headers]}
        />
        {getEmptyState()}
      </Card>
    </>
  );
};

export default CheckInActivityTable;
