import { Card } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import InsightReportHeader from "app/components/TEAM360InsightsModules/InsightReportHeader";
import {
  selectCompanySettings,
  selectDepartments,
} from "../AdminConsole/slice";
import ResultsOverviewCard from "app/components/TEAM360InsightsModules/ResultsOverviewCard";
import Team360ResultsModal from "app/components/Team360Assessment/Team360ResultsModal/Team360ResultsModal";
import { TTeam360Factor } from "app/components/Team360Assessment/types";
import TeamSummaryCard from "app/components/TEAM360InsightsModules/TeamSummaryCard";
import {
  selectCurrentUserAccountId,
  selectIsCurrentUserAdmin,
  selectSampleTeamsByTeamId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import TeamReflectionCard from "app/components/TEAM360InsightsModules/TeamReflectionCard";
import ManagersVsTeamMembers from "app/components/TEAM360InsightsModules/ManagerVsTeamMembers";
import GenderInsightsCard from "app/components/TEAM360InsightsModules/GenderInsightsCard";
import WhatSkillsCanYourTeamWorkOn from "app/components/Team360Assessment/WhatSkillsCanYourTeamWorkOn";
import {
  getDimensionGuide,
  getSkillGuide,
  selectGetDimensionGuideStatus,
  selectGetSkillsGuideStatus,
} from "app/containers/SkillsGuide/slice";
import Team360WhoCanYouTalkTo from "app/components/Team360Assessment/Team360WhoCanYouTalkTo";
import DepartmentSummaryCard from "app/components/TEAM360InsightsModules/DepartmentSummaryCard";
import Team360CommentsByYourTeamModal from "app/components/Team360Assessment/Team360CommentsByYourTeamModal";
import Team360ScoreBreakdownModal from "app/components/Team360Assessment/Team360ScoreBreakdownModal";
import Team360ComparisonModal from "app/components/Team360Assessment/Team360ComparisonModal";
import Team360AgreementReportModal from "app/components/Team360Assessment/Team360AgreementReportModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTeam360Scores,
  selectAllTeam360Results,
  selectGettingAllTeam360AssessmentResultsStatus,
} from "app/components/SurveyDataInstances/slice";
import { getDepartmentLeadId } from "../AdminConsole/helpers";
import Loading from "app/storybookComponents/Loading";
import DateRangePicker from "app/components/InstancePicker/DateRangePicker";
import InstanceStickyFooter from "app/components/InstancePicker/InstanceStickyFooter";
import DepartmentTeam360WhatConversationsCanYouSuggest from "app/components/Team360Assessment/DepartmentTeam360WhatConversationsCanYouSuggest";
import EmptyTeam360ResultsCard from "app/components/Team360Assessment/EmptyTeam360ResultsCard";
import SampleBanner from "app/components/Team360Assessment/SampleBanner";
import FreeTextSummary from "../AdminConsole/FreeTextSummary";
import InstanceBanner from "./InstanceBanner";
import {
  getDepartmentInsightReport,
  getGenderInsights,
  selectDepartmentInsightReportById,
  selectGenderInsightsByDepartmentId,
  selectGetGenderInsightsStatus,
  selectGetDepartmentInsightReportStatus,
  selectDepartmentTeamCommentsByDepartmentId,
  clearStatusStates,
  selectTeam360InstancePicked,
  updateTeam360DateRange,
  selectUpdateTeam360DateRangeStatus,
  resetUpdateTeam360DateRangeStatus,
  getTeamAssessmentInstances,
  selectTeam360InstancesByDepartmentId,
  selectGetDepartmentConversationSuggestionsStatus,
  selectDepartmentSuggestedConversations,
  getConversationPartners,
  clearGetConversationStatus,
  selectTeam360CardsExpanded,
  setTeam360CardsExpanded,
} from "./slice";
import { setIsQuickActionDrawerOpen } from "app/components/QuickActions/slice";
import TeamScanActionChecklistDrawer from "app/components/Checklists/TeamScanActionChecklistDrawer";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "../Chatbot/slice";
import CustomChatbotButton from "../Chatbot/CustomChatbotButton";
import { DEPARTMENT_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE } from "../CoachBotPrompts/constants";
import VisibilityBanner from "app/storybookComponents/WarningBanner/VisibilityBanner";
import { prepareDepartmentInsightReportForCoachBo } from "./helpers";

export default function DepartmentInsightReport() {
  const { departmentId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // -------------------------------------- Selectors -------------------------------------- //
  const departments = useAppSelector(selectDepartments);
  const departmentInsightReport = useAppSelector(
    selectDepartmentInsightReportById(Number(departmentId))
  );
  const getDepartmentInsightReportStatus = useAppSelector(
    selectGetDepartmentInsightReportStatus
  );
  const getSkillsGuideStatus = useAppSelector(selectGetSkillsGuideStatus);
  const getDimensionGuideStatus = useAppSelector(selectGetDimensionGuideStatus);
  const genderInsights = useAppSelector(
    selectGenderInsightsByDepartmentId(Number(departmentId))
  );
  const getGenderInsightsStatus = useAppSelector(selectGetGenderInsightsStatus);
  const getTeamScoreStatus = useAppSelector(
    selectGettingAllTeam360AssessmentResultsStatus
  );
  const sampleTeams = useAppSelector(selectSampleTeamsByTeamId);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const departmentTeamComments = useAppSelector(
    selectDepartmentTeamCommentsByDepartmentId(
      departmentId ? Number(departmentId) : null
    )
  );
  const updatingTeam360DateRangeStatus = useAppSelector(
    selectUpdateTeam360DateRangeStatus
  );
  const team360IntervalPicked = useAppSelector(
    selectTeam360InstancePicked(departmentId ? Number(departmentId) : undefined)
  );
  const allTeam360Scores = useAppSelector(
    selectAllTeam360Results(team360IntervalPicked ?? undefined)
  );
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const availableSurveyInstances = useAppSelector(
    selectTeam360InstancesByDepartmentId(
      departmentId ? Number(departmentId) : null
    )
  );
  const getDepartmentSuggestedConversationsStatus = useAppSelector(
    selectGetDepartmentConversationSuggestionsStatus
  );
  const departmentSuggestedConversation = useAppSelector(
    selectDepartmentSuggestedConversations(
      departmentId ? Number(departmentId) : undefined
    )
  );
  const team360CardsExpanded = useAppSelector(
    selectTeam360CardsExpanded(Number(departmentId))
  );
  const companySettings = useAppSelector(selectCompanySettings);

  const teams = useMemo(
    () => ({ ...sampleTeams, ...allTeamsById }),
    [sampleTeams, allTeamsById]
  );

  // -------------------------------------- States -------------------------------------- //
  const [showTeam360ResultsModal, setShowTeam360ResultsModal] =
    useState<TTeam360Factor | null>(null);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showScoreBreakdownModal, setShowScoreBreakdownModal] = useState(false);
  const [showAgreementChart, setShowAgreementChart] = useState(false);
  const [showDateRangePickerModal, setShowDateRangePickerModal] =
    useState(false);
  const [showComparisonModal, setShowComparisonModal] = useState<
    "organization" | "department" | null
  >(null);
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState(false);

  // -------------------------------------- Effects -------------------------------------- //
  useEffect(() => {
    return () => {
      dispatch(clearStatusStates());
    };
  }, [dispatch]);

  useEffect(() => {
    if (getTeamScoreStatus === "idle") {
      dispatch(getTeam360Scores());
    }
  }, [dispatch, getTeamScoreStatus]);

  useEffect(() => {
    if (
      departmentId &&
      !departmentInsightReport &&
      getDepartmentInsightReportStatus !== "loading" &&
      getDepartmentInsightReportStatus !== "failed"
    ) {
      dispatch(
        getDepartmentInsightReport({ departmentId: Number(departmentId) })
      );
    }
  }, [
    dispatch,
    getDepartmentInsightReportStatus,
    departmentId,
    departmentInsightReport,
  ]);

  useEffect(() => {
    if (
      departmentId &&
      !genderInsights &&
      getGenderInsightsStatus !== "loading" &&
      getGenderInsightsStatus !== "failed"
    ) {
      dispatch(getGenderInsights({ departmentId: Number(departmentId) }));
    }
  }, [dispatch, getGenderInsightsStatus, departmentId, genderInsights]);

  useEffect(() => {
    if (getSkillsGuideStatus === "idle") {
      dispatch(getSkillGuide());
    }
  }, [dispatch, getSkillsGuideStatus]);

  useEffect(() => {
    if (getDimensionGuideStatus === "idle") {
      dispatch(getDimensionGuide());
    }
  }, [dispatch, getDimensionGuideStatus]);

  useEffect(() => {
    // -------showScoreBreakdownModal-------
    const showScoreBreakdownModalBool = searchParams.get(
      "showScoreBreakdownModal"
    );
    setShowScoreBreakdownModal(showScoreBreakdownModalBool === "true");
  }, [searchParams]);

  useEffect(() => {
    // Upon receiving a successful update of the date range we close the modal.
    if (updatingTeam360DateRangeStatus === "succeeded") {
      setShowDateRangePickerModal(false);
      dispatch(resetUpdateTeam360DateRangeStatus());
    }
  }, [updatingTeam360DateRangeStatus, dispatch]);

  useEffect(() => {
    // On component did unmount:
    return () => {
      dispatch(clearGetConversationStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    // If we currently are updating the date range that means that the state hasn't updated the value for team360IntervalPicked so return early.
    if (updatingTeam360DateRangeStatus === "loading") return;
    // Since every time we change the date range we will reset getTeamSuggestedConversationsStatus to be idle so once idle then we know to fetch again
    if (getDepartmentSuggestedConversationsStatus !== "idle") return;
    // If we already have the suggested conversations then we return early.
    if (departmentSuggestedConversation) return;
    // If we still don't have the teamInfo then we return early.
    if (!departmentId || !departments[Number(departmentId)]) return;
    const departmentLeaderId = getDepartmentLeadId(
      Number(departmentId),
      departments
    );

    // If user is not the department leader or is not an admin no need to get the conversation data.
    if (currentUserAccountId !== departmentLeaderId && !isAdmin) return;

    // If a department leader exist then we send conversationPartnerType as 'departmentLeader'
    if (departmentLeaderId) {
      dispatch(
        getConversationPartners({
          departmentId: Number(departmentId),
          userAccountId: departmentLeaderId,
          conversationPartnerType: "departmentLeader",
          startDate: team360IntervalPicked?.startDate,
          endDate: team360IntervalPicked?.endDate,
          instance: team360IntervalPicked?.instance ?? undefined,
        })
      );
      return;
    }

    const members = [...(departments[Number(departmentId)]?.teamMembers ?? [])];
    const firstId = departmentLeaderId ?? members?.shift();
    let secondMember = members?.shift();

    if (secondMember === departmentLeaderId) {
      secondMember = members?.shift();
    }

    // If for whatever reason there are no members in the department then we return early.
    if (!firstId || !secondMember) return;
    dispatch(
      getConversationPartners({
        departmentId: Number(departmentId),
        userAccountId: firstId,
        conversationPartner: secondMember,
        startDate: team360IntervalPicked?.startDate,
        endDate: team360IntervalPicked?.endDate,
        instance: team360IntervalPicked?.instance ?? undefined,
      })
    );
  }, [
    updatingTeam360DateRangeStatus,
    getDepartmentSuggestedConversationsStatus,
    departmentSuggestedConversation,
    departmentId,
    departments,
    currentUserAccountId,
    isAdmin,
    dispatch,
    team360IntervalPicked,
  ]);

  useEffect(() => {
    if (!departmentId || availableSurveyInstances) return;
    dispatch(getTeamAssessmentInstances(Number(departmentId)));
  }, [dispatch, departmentId, availableSurveyInstances]);

  // -------------------------------------- Helpers -------------------------------------- //
  const departmentLeaderId = getDepartmentLeadId(
    Number(departmentId),
    departments
  );
  const isSampleDepartment =
    departments[Number(departmentId)]?.companyAccountId === 0;
  const isUserDepartmentLeader = departmentLeaderId === currentUserAccountId;
  const departmentInsightScores =
    departmentInsightReport?.departmentScores ??
    allTeam360Scores?.departmentScores?.[Number(departmentId)];

  const isLatestInstance = useMemo(() => {
    if (!team360IntervalPicked) return true;
    if (!team360IntervalPicked.endDate && !team360IntervalPicked.startDate)
      return true;

    const totalInstances = availableSurveyInstances?.length ?? 0;

    return !!(
      totalInstances &&
      availableSurveyInstances?.[0]?.endDate ===
        team360IntervalPicked?.endDate &&
      availableSurveyInstances?.[0]?.startDate ===
        team360IntervalPicked?.startDate
    );
  }, [availableSurveyInstances, team360IntervalPicked]);

  const getResultsOverviewFooter = () => {
    const onSeeScoreBreakdownClick = () => {
      setSearchParams((searchParams) => {
        searchParams.set("showScoreBreakdownModal", "true");
        return searchParams;
      });
      setShowScoreBreakdownModal(true);
    };

    if (isUserDepartmentLeader || isSampleDepartment || isAdmin) {
      return (
        <>
          <Button
            onClick={() => {
              dispatch(setIsQuickActionDrawerOpen(false));
              setIsChecklistDrawerOpen(true);
            }}
          >
            See discussion checklist
          </Button>
          <Button variant="secondary-blue" onClick={onSeeScoreBreakdownClick}>
            See score breakdown
          </Button>
        </>
      );
    }

    return (
      <>
        <Button onClick={onSeeScoreBreakdownClick}>See score breakdown</Button>
        <Button
          variant="secondary-blue"
          onClick={() => setShowCommentModal(true)}
        >
          See comments
        </Button>
      </>
    );
  };

  const onCoachBotClick = () => {
    const {
      coachBotIntro,
      preSelectedPrompts: userPrompts,
      chatType,
    } = DEPARTMENT_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE;
    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt({
        coachBotIntro,
        userPrompts,
        chatType,
        chatTypeId: Number(departmentId),
        type: "teamScan",
        report: prepareDepartmentInsightReportForCoachBo(
          departmentInsightReport
        ),
        comments: departmentTeamComments,
      })
    );
    dispatch(setIsCoachbotOpen(true));
  };

  const getBottomRightElementForInstanceStickyFooter = () => {
    if (!departmentInsightReport) return null;
    if (!companySettings?.aiFeaturesEnabled) {
      return (
        <Button onClick={() => setShowDateRangePickerModal(true)}>
          Configure results
        </Button>
      );
    }
    return (
      <div>
        <CustomChatbotButton
          onCoachbotClick={onCoachBotClick}
          text="Want help with these results?"
          className="assistant-bubble floating"
          includeButton
        />
      </div>
    );
  };

  const getBody = () => {
    if (getDepartmentInsightReportStatus === "loading") return <Loading />;

    if (!departmentInsightReport?.overview) {
      return (
        <EmptyTeam360ResultsCard
          entityType="department"
          completionInfo={departmentInsightReport?.completionInfo}
          hasEditAccess={false}
        />
      );
    }

    const instanceBanner = (
      <InstanceBanner
        onConfigureResults={() => {
          setShowDateRangePickerModal(true);
        }}
        isLatestInstance={isLatestInstance}
        instanceShowing={team360IntervalPicked}
        hasEnoughResults={!!departmentInsightReport?.overview}
      />
    );

    const reportHeader = (
      <InsightReportHeader
        completionInfo={departmentInsightReport?.completionInfo}
      />
    );

    const resultsOverviewCard = (
      <ResultsOverviewCard
        title="How's Your Department Doing Overall?"
        overviewText={departmentInsightReport?.overview ?? ""}
        departmentScores={departmentInsightScores}
        setShowTeam360ResultsModal={setShowTeam360ResultsModal}
        type="department"
        footerButtons={getResultsOverviewFooter()}
        hideActionButtons
      />
    );

    const departmentSummaryCard =
      departmentInsightReport?.howYourDepartmentComparesInThisOrganization ? (
        <DepartmentSummaryCard
          showThisDepartmentKey
          cardDescriptions={[
            departmentInsightReport.howYourDepartmentComparesInThisOrganization
              ?.howYouCompareText.breakdown ?? "",
            departmentInsightReport.howYourDepartmentComparesInThisOrganization
              ?.howYouCompareText.description ?? "",
          ]}
          classificationArray={
            departmentInsightReport.howYourDepartmentComparesInThisOrganization
              ?.classificationArray
          }
          highestScoringDepartment={
            departmentInsightReport.howYourDepartmentComparesInThisOrganization
              ?.highestScoringDepartment
          }
          lowestScoringDepartment={
            departmentInsightReport.howYourDepartmentComparesInThisOrganization
              ?.lowestScoringDepartment
          }
          departmentClassification={
            departmentInsightReport.howYourDepartmentComparesInThisOrganization
              ?.departmentClassification
          }
          onSeeComparisonReport={() => setShowComparisonModal("organization")}
          isExpanded={team360CardsExpanded?.["DepartmentSummaryCard"]}
          setIsExpanded={(value) =>
            dispatch(
              setTeam360CardsExpanded({
                departmentId: Number(departmentId),
                cardTitle: "DepartmentSummaryCard",
                value,
              })
            )
          }
        />
      ) : null;
    const teamSummaryCard = (
      <TeamSummaryCard
        teamSummary={departmentInsightReport?.teamSummary ?? null}
        teams={teams}
        title="How Are Teams In Your Department Doing?"
        isExpanded={team360CardsExpanded?.["TeamSummaryCard"]}
        setIsExpanded={(value) =>
          dispatch(
            setTeam360CardsExpanded({
              departmentId: Number(departmentId),
              cardTitle: "TeamSummaryCard",
              value,
            })
          )
        }
        onSeeComparisonReport={() => setShowComparisonModal("department")}
      />
    );
    const managersVsTeamMembers = (
      <ManagersVsTeamMembers
        title="How Do Your Team Leaders Compare With Team Members?"
        varianceReport={departmentInsightReport?.varianceReport}
        memberScores={
          departmentInsightReport?.departmentManagerAndMemberAnalysis
            ?.departmentMemberScores
        }
        teamLeaderScores={
          departmentInsightReport?.departmentManagerAndMemberAnalysis
            ?.departmentTeamLeaderScores
        }
        onSeeAgreementReport={() => setShowAgreementChart(true)}
        isExpanded={team360CardsExpanded?.["ManagersVsTeamMembers"]}
        setIsExpanded={(value) =>
          dispatch(
            setTeam360CardsExpanded({
              departmentId: Number(departmentId),
              cardTitle: "ManagersVsTeamMembers",
              value,
            })
          )
        }
      />
    );
    const genderInsightsCard = (
      <GenderInsightsCard
        genderInsights={genderInsights}
        title="Does Gender Impact Teamwork?"
        isCardCollapseByDefault
      />
    );
    const teamReflectionCard = (
      <TeamReflectionCard
        teamReflectionSummary={
          departmentInsightReport?.teamReflectionSummary ?? null
        }
        entityType="department"
        isCollapseByDefault
        title="What Else Did Your Department Say?"
      />
    );

    if (isUserDepartmentLeader) {
      return (
        <Card className="team-360-full-page-card">
          <div className="analytics" style={{ gap: "40px" }}>
            <div className="team-360-full-page-card__section">
              {instanceBanner}
              {reportHeader}
              {resultsOverviewCard}
            </div>
            <div className="team-360-full-page-card__section">
              <h2 className="team-360-divider-title">What Can You Do Next?</h2>
              <WhatSkillsCanYourTeamWorkOn
                team360Scores={departmentInsightScores}
                title="What Skills Can Your Department Work On?"
                stepsText={[
                  "Select to focus on your department’s growth areas or build on your department’s strengths.",
                  "Choose 1-2 skills to focus on doing or discussing with your team over the next 2 weeks.",
                ]}
                isExpanded={
                  team360CardsExpanded?.["WhatSkillsCanYourTeamWorkOn"]
                }
                setIsExpanded={(value) =>
                  dispatch(
                    setTeam360CardsExpanded({
                      departmentId: Number(departmentId),
                      cardTitle: "WhatSkillsCanYourTeamWorkOn",
                      value,
                    })
                  )
                }
              />
              {departmentInsightReport?.conversationPartners &&
              (departmentInsightReport.conversationPartners.strengths ||
                departmentInsightReport.conversationPartners.weaknesses) ? (
                <Team360WhoCanYouTalkTo
                  strengths={
                    departmentInsightReport?.conversationPartners?.strengths
                  }
                  weaknesses={
                    departmentInsightReport?.conversationPartners?.weaknesses
                  }
                  stepsText={[
                    "Select to focus on your department's growth areas or build on your department's strengths.",
                    "Choose one or two people to converse with over the next two weeks.",
                    "Enjoy exploring issues, asking questions, and discussing best practices.",
                  ]}
                  isExpanded={team360CardsExpanded?.["Team360WhoCanYouTalkTo"]}
                  setIsExpanded={(value) =>
                    dispatch(
                      setTeam360CardsExpanded({
                        departmentId: Number(departmentId),
                        cardTitle: "Team360WhoCanYouTalkTo",
                        value,
                      })
                    )
                  }
                />
              ) : null}
            </div>
            <div className="team-360-full-page-card__section">
              <h2 className="team-360-divider-title">More Insights</h2>
              {departmentSummaryCard}
              {teamSummaryCard}
              {managersVsTeamMembers}
              {genderInsightsCard}
              {teamReflectionCard}
              <FreeTextSummary departmentId={Number(departmentId)} />
            </div>
          </div>
        </Card>
      );
    } else if (isAdmin || isSampleDepartment) {
      const thisIsWhatTeamLeaderSeesBanner = (
        <div className="warning-banner">
          <p>
            <b>Department Leader View:</b> This is the report that a leader of
            this department sees.
          </p>
        </div>
      );
      return (
        <div className="column-gap-20px">
          <Card className="team-360-full-page-card">
            <div className="team-360-full-page-card__section">
              {!isSampleDepartment && instanceBanner}
              {reportHeader}
              {!isSampleDepartment && thisIsWhatTeamLeaderSeesBanner}
              {resultsOverviewCard}
            </div>
          </Card>
          <Card className="team-360-full-page-card">
            <div className="team-360-full-page-card__section">
              {!isSampleDepartment && (
                <div className="warning-banner">
                  <p>
                    <b>Admin View:</b> This is what you can do to help this
                    department.
                  </p>
                </div>
              )}
              <h2 className="team-360-divider-title">
                How Can You Help This Department?
              </h2>
              <WhatSkillsCanYourTeamWorkOn
                team360Scores={departmentInsightScores}
                title="What Skills Can You Suggest?"
                stepsText={[
                  "Select either focus on growth areas or build on strengths to get ideas.",
                  "Choose 1-2 skills to help this department focus on doing or discussing over the next 2 weeks.",
                ]}
                isExpanded={
                  team360CardsExpanded?.["WhatSkillsCanYourTeamWorkOn"]
                }
                setIsExpanded={(value) =>
                  dispatch(
                    setTeam360CardsExpanded({
                      departmentId: Number(departmentId),
                      cardTitle: "WhatSkillsCanYourTeamWorkOn",
                      value,
                    })
                  )
                }
              />
              <DepartmentTeam360WhatConversationsCanYouSuggest
                comparedByOptionSelected={
                  departmentSuggestedConversation?.activeUserAccountId
                    ? {
                        userAccountId:
                          departmentSuggestedConversation?.activeUserAccountId,
                        teamId: departmentSuggestedConversation?.activeTeamId,
                      }
                    : null
                }
                comparedToOptionSelected={
                  departmentSuggestedConversation?.activeComparedTo
                    ? {
                        compareTo:
                          departmentSuggestedConversation?.activeComparedTo,
                        teamId:
                          departmentSuggestedConversation?.activeComparedToTeamId,
                      }
                    : null
                }
                storedConversationPartners={
                  departmentSuggestedConversation?.conversationsToBeHad
                }
                isLoading={
                  getDepartmentSuggestedConversationsStatus === "loading"
                }
                departmentId={Number(departmentId)}
                dateRangeInstance={{
                  startDate: team360IntervalPicked?.startDate,
                  endDate: team360IntervalPicked?.endDate,
                  instance: team360IntervalPicked?.instance ?? undefined,
                }}
                isCollapseByDefault
              />
            </div>
          </Card>
          <Card className="team-360-full-page-card">
            <div className="team-360-full-page-card__section">
              {!isSampleDepartment && thisIsWhatTeamLeaderSeesBanner}
              <h2 className="team-360-divider-title">More Insights</h2>
              {departmentSummaryCard}
              {teamSummaryCard}
              {managersVsTeamMembers}
              {genderInsightsCard}
              {teamReflectionCard}
              <FreeTextSummary departmentId={Number(departmentId)} />
            </div>
          </Card>
        </div>
      );
    }
    return null;
  };

  if (!departmentId) return null;

  const companyScores = isSampleDepartment
    ? allTeam360Scores?.sampleCompanyScores
    : allTeam360Scores?.companyScores;

  return (
    <div>
      {showTeam360ResultsModal ? (
        <Team360ResultsModal
          show={!!showTeam360ResultsModal}
          hideModal={() => setShowTeam360ResultsModal(null)}
          companyScores={companyScores}
          departmentScores={departmentInsightScores}
          activeFactor={showTeam360ResultsModal}
        />
      ) : null}
      <DateRangePicker
        show={showDateRangePickerModal}
        onHide={() => setShowDateRangePickerModal(false)}
        isLoading={updatingTeam360DateRangeStatus === "loading"}
        onSubmit={({ startDate, endDate, instanceType, instance }) => {
          dispatch(
            updateTeam360DateRange({
              departmentId: Number(departmentId),
              startDate,
              endDate,
              instanceType,
              instance,
              throwErrorIfNoOverview: true,
            })
          );
        }}
        defaultInstanceSelected={{
          startDate: team360IntervalPicked?.startDate,
          endDate: team360IntervalPicked?.endDate,
        }}
        availableInstances={availableSurveyInstances}
        entity="department"
        failedToUpdate={updatingTeam360DateRangeStatus === "failed"}
        onResetFailedToUpdate={() => {
          dispatch(resetUpdateTeam360DateRangeStatus());
        }}
      />
      <Team360CommentsByYourTeamModal
        show={showCommentModal}
        onHide={() => setShowCommentModal(false)}
        departmentId={Number(departmentId)}
        teamComments={departmentTeamComments}
        modalTitle="Comments by your department"
      />
      <Team360ScoreBreakdownModal
        show={showScoreBreakdownModal}
        onHide={() => {
          setShowScoreBreakdownModal(false);
          navigate(-2);
        }}
        departmentId={Number(departmentId)}
        modalSearchParam="showScoreBreakdownModal"
        instance={team360IntervalPicked?.instance}
        startDate={team360IntervalPicked?.startDate}
        endDate={team360IntervalPicked?.endDate}
      />
      <Team360ComparisonModal
        show={!!showComparisonModal}
        onHide={() => setShowComparisonModal(null)}
        departmentId={Number(departmentId)}
        preSelectedDepartmentOrOrg={showComparisonModal ?? undefined}
      />
      <Team360AgreementReportModal
        show={showAgreementChart}
        onHide={() => setShowAgreementChart(false)}
        usersScore={
          departmentInsightReport?.departmentManagerAndMemberAnalysis
            ?.departmentMemberScores
        }
        comparedUserScore={
          departmentInsightReport?.departmentManagerAndMemberAnalysis
            ?.departmentTeamLeaderScores
        }
        userCustomLabel="Average of team members"
        comparedUserCustomLabel="Average of team leaders"
        comparedUserAvatar={
          <div style={{ marginTop: "4px" }}>
            <FontAwesomeIcon icon="diamond" />
          </div>
        }
        differenceThreshold={8}
      />
      {isSampleDepartment && <SampleBanner entity="department" />}
      {departmentInsightReport?.overview && !isSampleDepartment ? (
        <VisibilityBanner>
          <p>
            This report is only visible to this department's leader and admins.
          </p>
        </VisibilityBanner>
      ) : null}
      {getBody()}
      <TeamScanActionChecklistDrawer
        isOpen={isChecklistDrawerOpen}
        setIsOpen={(incomingBool) => setIsChecklistDrawerOpen(incomingBool)}
        onSeeCommentsClick={() => setShowCommentModal(true)}
        isLeader={isUserDepartmentLeader}
        type="Department"
      />
      {departmentInsightReport?.overview ? (
        <InstanceStickyFooter
          bottomRightElement={getBottomRightElementForInstanceStickyFooter()}
        />
      ) : null}
    </div>
  );
}
