import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getCoachBoWeeklyCheckInActivityReport,
  getWeeklyCheckInLineChartData,
  getWeeklyCheckInModuleStatus,
  selectCoachBoWeeklyCheckInActivityReports,
  selectGetCoachBoWeeklyCheckInActivityReportStatus,
  selectGetWeeklyCheckInLineChartDataStatus,
  selectGetWeeklyCheckInModuleStatus,
  selectShowWeeklyCheckInActivityEmptyState,
  selectWeeklyCheckInLineChartData,
} from "../slice";
import { useCallback, useEffect, useState } from "react";
import { trackFeatureViewed } from "utils/trackingFunctions";
import WeeklyCheckInActivitySnapshot from "./WeeklyCheckInActivitySnapshot";

const AdminWeeklyCheckinSnapshot = () => {
  const dispatch = useAppDispatch();
  const coachBoWeeklyCheckInActivityReports = useAppSelector(
    selectCoachBoWeeklyCheckInActivityReports
  );
  const getCoachBoWeeklyCheckInActivityReportStatus = useAppSelector(
    selectGetCoachBoWeeklyCheckInActivityReportStatus
  );
  const getShowWeeklyCheckInActivityEmptyStateStatus = useAppSelector(
    selectGetWeeklyCheckInModuleStatus
  );
  const showWeeklyCheckInActivityEmptyState = useAppSelector(
    selectShowWeeklyCheckInActivityEmptyState
  );
  const weeklyCheckInLineChartData = useAppSelector(
    selectWeeklyCheckInLineChartData
  );
  const fetchingWeeklyCheckInLineChartDataStatus = useAppSelector(
    selectGetWeeklyCheckInLineChartDataStatus
  );

  const [
    weeklyCheckInActivityTimeInterval,
    setWeeklyCheckInActivityTimeInterval,
  ] = useState("Past 7 days");
  const [lineChartTimeInterval, setLineChartTimeInterval] =
    useState("Past 30 days");
  const [lineChartNavigationIndex, setLineChartNavigationIndex] = useState(1);

  useEffect(() => {
    trackFeatureViewed("Weekly Check-In Activity Dashboard Card (Admin)");
  }, []);

  useEffect(() => {
    dispatch(getCoachBoWeeklyCheckInActivityReport());
    dispatch(getWeeklyCheckInModuleStatus());
    dispatch(
      getWeeklyCheckInLineChartData({
        timeInterval: "last30Days",
        index: 1,
      })
    );
  }, [dispatch]);

  const onGetWeeklyCheckInLineChartData = useCallback(
    ({ timeInterval, index }: { timeInterval: string; index: number }) => {
      dispatch(
        getWeeklyCheckInLineChartData({
          timeInterval,
          index,
        })
      );
    },
    [dispatch]
  );

  return (
    <WeeklyCheckInActivitySnapshot
      coachBoWeeklyCheckInActivityReports={coachBoWeeklyCheckInActivityReports}
      getCoachBoWeeklyCheckInActivityReportStatus={
        getCoachBoWeeklyCheckInActivityReportStatus
      }
      showWeeklyCheckInActivityEmptyState={showWeeklyCheckInActivityEmptyState}
      weeklyCheckInLineChartData={weeklyCheckInLineChartData}
      fetchingWeeklyCheckInLineChartDataStatus={
        fetchingWeeklyCheckInLineChartDataStatus
      }
      getShowWeeklyCheckInActivityEmptyStateStatus={
        getShowWeeklyCheckInActivityEmptyStateStatus
      }
      viewActivityPath="/AdminConsole/Check-In Activity"
      weeklyCheckInActivityTimeInterval={weeklyCheckInActivityTimeInterval}
      setWeeklyCheckInActivityTimeInterval={
        setWeeklyCheckInActivityTimeInterval
      }
      lineChartTimeInterval={lineChartTimeInterval}
      setLineChartTimeInterval={setLineChartTimeInterval}
      lineChartNavigationIndex={lineChartNavigationIndex}
      setLineChartNavigationIndex={setLineChartNavigationIndex}
      onGetWeeklyCheckInLineChartData={onGetWeeklyCheckInLineChartData}
    />
  );
};

export default AdminWeeklyCheckinSnapshot;
