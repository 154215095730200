import Modal from "app/components/Modals/SimpleModal";
import Button from "app/storybookComponents/Button";

interface Props {
  hideModal: () => void;
  show: boolean;
  title: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  onActionClick: () => void;
  contentTitle?: string | JSX.Element;
  contentText?: string | JSX.Element;
}

export default function SimpleConfirmationModal({
  hideModal,
  onActionClick,
  show,
  title,
  actionButtonText = "Confirm Changes",
  cancelButtonText = "Cancel",
  contentTitle,
  contentText,
}: Readonly<Props>) {
  return (
    <Modal
      show={show}
      onHide={hideModal}
      className="simple-modal"
      size="lg"
      title={title}
    >
      <div style={{ textAlign: "center" }}>
        {typeof contentTitle === "string" ? (
          <h3>{contentTitle}</h3>
        ) : (
          contentTitle
        )}
        {typeof contentText === "string" ? (
          <p style={{ marginTop: "8px" }}>{contentText}</p>
        ) : (
          contentText
        )}
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Button onClick={hideModal} variant="secondary-blue">
          {cancelButtonText}
        </Button>
        <Button onClick={onActionClick}>{actionButtonText}</Button>
      </div>
    </Modal>
  );
}
