import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

interface Props {
  title?: string;
  text?: string;
  className?: string;
  customPopoverElm?: JSX.Element;
  trigger?: "click" | "focus" | "hover";
}
export default function HelpPopover({
  title,
  text,
  className,
  customPopoverElm,
  trigger,
}: Readonly<Props>) {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const getPopoverContent = () => {
    if (customPopoverElm) {
      return <div>{customPopoverElm}</div>;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        {title ? <strong>{title}</strong> : null}
        <div>{text}</div>
      </div>
    );
  };

  const getPopoverClassName = () => {
    if (customPopoverElm) {
      return "team-360-popover no-max-width";
    }
    return "team-360-popover";
  };

  const getPopover = () => (
    <Popover className={getPopoverClassName()}>{getPopoverContent()}</Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      placement="auto"
      overlay={getPopover()}
      show={show}
      onToggle={handleClick}
      trigger={trigger}
    >
      <FontAwesomeIcon icon={["far", "circle-info"]} className={className} />
    </OverlayTrigger>
  );
}
