import { Dropdown } from "react-bootstrap";

interface NonClickableDropdownItemProps {
  children: React.ReactNode;
  eventKey?: string;
}

const NonClickableDropdownItem = ({
  children,
  eventKey = "non-clickable",
}: Readonly<NonClickableDropdownItemProps>) => {
  return (
    <Dropdown.Item
      onClick={(e) => {
        e.preventDefault(); // Prevent default anchor action
        e.stopPropagation(); // Stop click event from closing the dropdown
      }}
      style={{ pointerEvents: "none" }}
      eventKey={eventKey}
    >
      {children}
    </Dropdown.Item>
  );
};

export default NonClickableDropdownItem;
