import { LINE_CHART_DEFAULT_COLORS } from "./constants";
import { getColorFromArray } from "./helpers";

interface Props {
  colors?: string[];
  labels: string[];
}
const ChartLegend = ({
  colors = LINE_CHART_DEFAULT_COLORS,
  labels,
}: Readonly<Props>) => {
  const getLegendItems = () =>
    labels.map((label, index) => {
      const color = getColorFromArray(colors, index);
      return (
        <div key={label} className="chart-legend-item">
          <div
            className="chart-legend-item-color"
            style={{ backgroundColor: color }}
          />
          <span>{label}</span>
        </div>
      );
    });
  return <div className="chart-legend">{getLegendItems()}</div>;
};

export default ChartLegend;
