import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useEffect } from "react";
import { RowContent, SortDirection, TableHeader } from "./types";
import { renderTableRows } from "./helpers";
import Paginator from "./Paginator";
import { OverlayTrigger, Popover } from "react-bootstrap";
import OverflowContainer from "app/storybookComponents/OverflowContainer/OverflowContainer";
import RowsPerPageSelector from "./RowsPerPageSelector";
import Loading from "app/storybookComponents/Loading";

interface Props {
  columnHeaders: TableHeader[];
  rows: RowContent[];
  tableClassName?: string;
  itemsPerPage?: number;
  hidePagination?: boolean;
  includeRowsPerPageSelector?: boolean;
  isLoading?: boolean;
}

// TODO: Down the line, refactor so that it only sorts the rows once when the user clicks on the header
// because right now it calls the sort function again when the user clicks on the next page
export default function SortableTable({
  columnHeaders = [],
  rows,
  tableClassName = "",
  itemsPerPage = 10,
  hidePagination,
  includeRowsPerPageSelector = true,
  isLoading = false,
}: Readonly<Props>) {
  const [currentPage, setCurrentPage] = useState(1);
  const [toggledSort, setToggledSort] = useState<{
    headerName: string;
    sortDirection: SortDirection;
    sortInverse?: boolean;
    isDate?: boolean;
  } | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);

  const tableRows = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return renderTableRows({
      columnHeaders,
      rows: rows,
      toggledSort,
      startIndex,
      endIndex,
    });
  }, [rows, toggledSort, columnHeaders, currentPage, rowsPerPage]);

  // Every time the length of the rows changes, reset the current page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [rows.length]);

  const onSort = (
    headerName: string,
    sortInverse?: boolean,
    isDate?: boolean
  ) => {
    if (
      !toggledSort ||
      (toggledSort && toggledSort.headerName !== headerName)
    ) {
      return setToggledSort({
        headerName,
        sortDirection: "up",
        sortInverse,
        isDate,
      });
    }

    if (toggledSort.sortDirection === "up") {
      return setToggledSort({
        ...toggledSort,
        sortDirection: "down",
        sortInverse,
      });
    }

    setToggledSort(null);
  };

  const getSortIconName = (headerName: string) => {
    if (
      !toggledSort ||
      (toggledSort && toggledSort.headerName !== headerName)
    ) {
      return "sort";
    }

    if (toggledSort.sortDirection === "up") {
      return "sort-up";
    }
    return "sort-down";
  };

  const getToolTipContent = (toolTipContent: string) => {
    const overlay = (
      <Popover className="team-360-popover">
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {toolTipContent}
        </div>
      </Popover>
    );
    return (
      <OverlayTrigger rootClose placement="auto" overlay={overlay}>
        <FontAwesomeIcon
          icon={["far", "circle-info"]}
          style={{ paddingLeft: "5px" }}
        />
      </OverlayTrigger>
    );
  };

  const getTableHeader = () =>
    columnHeaders.map(
      ({
        label,
        key,
        sortInverse,
        isDate,
        disableSort,
        className,
        infoTooltip,
        additionalContent,
      }) => (
        <th
          onClick={() => !disableSort && onSort(key, sortInverse, isDate)}
          role="button"
          key={key}
          className={className}
        >
          {label}
          {additionalContent}
          {label && !disableSort ? (
            <FontAwesomeIcon icon={getSortIconName(key)} className="ms-2" />
          ) : null}
          {label && infoTooltip ? getToolTipContent(infoTooltip) : null}
        </th>
      )
    );

  if (isLoading) return <Loading />;

  return (
    <>
      <OverflowContainer>
        <table className={`sortable-table ${tableClassName}`}>
          <thead>
            <tr>{getTableHeader()}</tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </OverflowContainer>
      {tableRows?.length && !hidePagination ? (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <Paginator
              pageRangeDisplayed={5}
              pageCount={Math.ceil(rows.length / rowsPerPage)}
              onPageChange={(selectedItem) => {
                setCurrentPage(selectedItem.selected + 1);
              }}
              forcePage={currentPage - 1}
            />
            <div className="row-gap-12px align-items-center">
              <p className="grey-text">
                Page <b>{currentPage}</b> of{" "}
                {Math.ceil(rows.length / rowsPerPage)}
              </p>
              <div className="page-selector-divider" />
              {includeRowsPerPageSelector ? (
                <RowsPerPageSelector
                  value={rowsPerPage}
                  onChange={setRowsPerPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
