import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { Card, Dropdown } from "react-bootstrap";
import {
  selectAllCompanyUsersById,
  selectCompanyInfo,
  selectConfigCatFlag,
} from "app/containers/Global/slice";
import { UserInfo } from "app/containers/Global/types";
import useScrollToAnchor from "utils/hooks/useScrollToAnchor";
import UploadPictureModal from "app/components/ImageEditor/UploadPictureModal";
import AdminConsoleSettingsModal from "./Modals/AdminConsoleSettingModal";
import {
  getTeamAndDepartmentLeads,
  selectCompanySettings,
  selectIsDepartmentsHidden,
  selectAiFeaturesEnabled,
  toggleDisableDepartments,
  toggleAIFeaturesEnabled,
  toggleHasCoachBoConversationHistoryEnabled,
  updateCompanySettings,
  selectHasCoachBoConversationHistoryEnabled,
  updateCompanySettingsAction,
  updateVisibilitySettings,
  selectUpdateVisibilitySettingsStatus,
  selectBulkUpdatePendingTeamMembershipStatus,
  getPendingTeamMemberships,
  clearPendingTeamMemberships,
  selectPendingTeamMemberships,
  selectPendingTeamMemberStatus,
} from "./slice";
import {
  CompanySettings,
  GenericAdminNumberOption,
  OneTwoThree,
} from "./types";
import Toggle from "app/components/Toggle";
import { useDebounceCallback } from "utils/hooks";
import { COACH_BO_STYLES, WHO_CAN_SETTINGS_MAP } from "./constants";
import WhoCanDoSomethingModal from "./Modals/WhoCanDoSomethingModal";
import Loading from "app/storybookComponents/Loading";
import VisibilitySettings from "./Cards/VisibilitySettings";
import { IS_AU_INSTANCE } from "utils/environmentVariables";
import { getNotifications } from "app/components/Notifications/slice";
import AddPendingTeamMembersToTeam from "./Modals/AddPendingTeamMembersToTeam";
import { onAcceptOrDeclineAllPendingTeamInvitationsClick } from "./helpers";
import SimpleConfirmationModal from "app/components/Modals/SimpleConfirmationModal";
import SimplePopover from "app/components/Popovers/SimplePopover";
import { CONFIG_CAT_FLAGS } from "app/components/GlobalNavbar/constants";

interface Props {
  users: Record<string | number, UserInfo>;
}

export default function AdminConsoleSettings({ users }: Readonly<Props>) {
  const dispatch = useAppDispatch();
  useScrollToAnchor();

  // ------------------------ Selectors ------------------------------------
  const companySettings = useAppSelector(selectCompanySettings);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const areDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const areAIFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const isConversationHistoryEnabled = useAppSelector(
    selectHasCoachBoConversationHistoryEnabled
  );
  const updatingVisibilityDefaultsStatus = useAppSelector(
    selectUpdateVisibilitySettingsStatus
  );
  const bulkUpdatePendingTeamMembershipStatus = useAppSelector(
    selectBulkUpdatePendingTeamMembershipStatus
  );
  const pendingTeamMembers = useAppSelector(selectPendingTeamMemberships);
  const pendingTeamMembersStatus = useAppSelector(
    selectPendingTeamMemberStatus
  );
  const weeklyCheckInConfigCatFlag = useAppSelector(
    selectConfigCatFlag(CONFIG_CAT_FLAGS.weeklyCheckIn.savedFlagName)
  );

  // ------------------------ States ------------------------------------
  const [showEditGlobalHeaderModal, setShowEditGlobalHeaderModal] =
    useState(false);
  const [showAddPendingTeamMemberModal, setShowAddPendingTeamMemberModal] =
    useState(false);
  const [modalShowing, setModalShowing] = useState<
    null | "Edit Domains" | "Who Can Invite" | "Who Can Create"
  >(null);
  const [whoCanJoinOrganization, setWhoCanJoinOrganization] = useState<
    OneTwoThree | undefined
  >(undefined);
  const [whoCanCreateTeams, setWhoCanCreateTeams] = useState<
    GenericAdminNumberOption | undefined
  >(undefined);
  const [whoCanInvite, setWhoCanInvite] = useState<
    GenericAdminNumberOption | undefined
  >(undefined);
  const [showConversationHistoryModal, setShowConversationHistoryModal] =
    useState(false);
  const [
    showCheckInWithCoachBoChangeModal,
    setShowCheckInWithCoachBoChangeModal,
  ] = useState(false);
  const [visibilitySetting, setVisibilitySetting] = useState(1);
  const [visibilityOverrideToggle, setVisibilityOverrideToggle] =
    useState(false);
  const [allowUsersToLinkPreviousResults, setAllowUsersToLinkPreviousResults] =
    useState(false);
  const [hasRequireTeamAcceptance, setHasRequireTeamAcceptance] =
    useState(false);
  const [coachBoStyle, setCoachBoStyle] = useState<string>("normal");

  // ------------------------ Use Effects ------------------------------------

  useEffect(() => {
    setWhoCanJoinOrganization(companySettings?.emailSetting);
  }, [companySettings?.emailSetting]);

  useEffect(() => {
    setWhoCanCreateTeams(companySettings?.teamCreationAccess);
  }, [companySettings?.teamCreationAccess]);

  useEffect(() => {
    setWhoCanInvite(companySettings?.invitationAccess);
  }, [companySettings?.invitationAccess]);

  useEffect(() => {
    setVisibilityOverrideToggle(
      !!companySettings?.allowVisibilityPermissionOverride
    );
  }, [companySettings?.allowVisibilityPermissionOverride]);

  useEffect(() => {
    setVisibilitySetting(companySettings?.visibilityPermissionDefaultId || 1);
  }, [companySettings?.visibilityPermissionDefaultId]);

  useEffect(() => {
    setAllowUsersToLinkPreviousResults(!!companySettings?.linkResultsAccess);
  }, [companySettings?.linkResultsAccess]);

  useEffect(() => {
    setCoachBoStyle(companySettings?.coachBoStyle ?? "normal");
  }, [companySettings?.coachBoStyle]);

  // Commenting this out for now, to reduce scope of DQP-41
  // useEffect(() => {
  //   setHasReflectionQuestions(!!companySettings?.hasReflectionQuestions);
  // }, [companySettings?.hasReflectionQuestions]);

  useEffect(() => {
    setHasRequireTeamAcceptance(!!companySettings?.requireTeamAcceptance);
  }, [companySettings?.requireTeamAcceptance]);

  // ------------------------ Get Functions ------------------------------------
  const getModals = () => {
    if (!companyInfo) {
      return null;
    }
    return (
      <>
        <UploadPictureModal
          modalShowing={showEditGlobalHeaderModal ? "coverPhoto" : null}
          closeModal={() => setShowEditGlobalHeaderModal(false)}
          picture={companySettings?.globalHeaderBase64}
          updateGuidePhoto={async (photo, imgSrc, imageName) => {
            if (!companyInfo?.companyAccountId) {
              return;
            }
            await dispatch(
              updateCompanySettings({
                payload: { globalHeaderImage: photo, globalHeader: imageName },
                companyAccountId: companyInfo?.companyAccountId,
              })
            );
            setShowEditGlobalHeaderModal(false);
          }}
          deleteGuidePhoto={async () => {
            if (!companyInfo?.companyAccountId) {
              return;
            }
            await dispatch(
              updateCompanySettings({
                payload: { deleteGlobalHeader: true },
                companyAccountId: companyInfo?.companyAccountId,
              })
            );
            setShowEditGlobalHeaderModal(false);
          }}
        />
        <AdminConsoleSettingsModal
          modalShowing={modalShowing === "Edit Domains" ? "Edit Domains" : null}
          onSave={onAdminConsoleSettingsModalSave}
          hideModal={() => setModalShowing(null)}
          users={users}
          companySettings={companySettings || undefined}
        />
        <SimpleConfirmationModal
          show={showConversationHistoryModal}
          hideModal={() => {
            setShowConversationHistoryModal(false);
          }}
          onActionClick={() => {
            updateConversationHistory();
            setShowConversationHistoryModal(false);
          }}
          title="Conversation History"
          contentText={
            <p
              style={{
                marginTop: "8px",
              }}
            >
              This will permanently delete all conversation history for everyone
              in your organization. <br />
              Note: Each individual can turn off conversation history in their
              user settings.
            </p>
          }
          contentTitle="Are you sure you want to turn off this setting for your entire organization?"
        />

        <SimpleConfirmationModal
          show={showCheckInWithCoachBoChangeModal}
          hideModal={() => {
            setShowCheckInWithCoachBoChangeModal(false);
          }}
          onActionClick={() => {
            onSaveToggle(
              !companySettings?.hasCoachBoWeeklyCheckInsEnabled,
              "hasCoachBoWeeklyCheckInsEnabled"
            );
            setShowCheckInWithCoachBoChangeModal(false);
          }}
          title="Enable Weekly Check-Ins"
          contentText="Everyone in your organization will receive an email from Coach Bo each week to check on work progress and report any successes or challenges."
          contentTitle="Are you sure you want to enable Weekly Check-Ins?"
        />

        <WhoCanDoSomethingModal
          show={modalShowing === "Who Can Create"}
          onClose={() => setModalShowing(null)}
          title="Who Can Create Teams"
          descriptionText="Enter members in your organization who can create teams"
          defaultUserAccountIds={companySettings?.teamCreationCustomList}
          onSave={(userAccountIds) => {
            dispatch(
              updateCompanySettings({
                payload: {
                  teamCreationCustomList: userAccountIds,
                  teamCreationAccess: 4,
                },
                companyAccountId: companyInfo?.companyAccountId,
              })
            );
          }}
        />
        <WhoCanDoSomethingModal
          show={modalShowing === "Who Can Invite"}
          onClose={() => setModalShowing(null)}
          title="Who Can Invite New Members"
          descriptionText="Select the users who can invite new members to your organization"
          defaultUserAccountIds={companySettings?.invitationCustomList}
          onSave={(userAccountIds) => {
            dispatch(
              updateCompanySettings({
                payload: {
                  invitationCustomList: userAccountIds,
                  invitationAccess: 4,
                },
                companyAccountId: companyInfo?.companyAccountId,
              })
            );
          }}
        />
        <AddPendingTeamMembersToTeam
          show={showAddPendingTeamMemberModal}
          onHide={() => {
            setShowAddPendingTeamMemberModal(false);
            dispatch(clearPendingTeamMemberships());
          }}
          onAccept={() => {
            onAcceptOrDeclineAllPendingTeamInvitationsClick(
              dispatch,
              Number(companyInfo?.companyAccountId),
              "accept"
            );
          }}
          onDecline={() => {
            onAcceptOrDeclineAllPendingTeamInvitationsClick(
              dispatch,
              Number(companyInfo?.companyAccountId),
              "decline"
            );
          }}
          isLoading={bulkUpdatePendingTeamMembershipStatus === "loading"}
          onRemoveRequiredAcceptance={() => {
            setHasRequireTeamAcceptance(false);
            dispatch(
              updateCompanySettings({
                payload: { requireTeamAcceptance: 0 },
                companyAccountId: Number(companyInfo?.companyAccountId),
              })
            );
            dispatch(getNotifications());
          }}
          pendingTeamMembers={pendingTeamMembers}
        />
      </>
    );
  };

  const getCustomListStringFromIds = (userIds: number[] = []) => {
    if (!userIds.length) return "Custom List (0)";
    const userNames = userIds
      ?.map(
        (userAccountId) =>
          `${usersInfoById[userAccountId]?.firstName} ${usersInfoById[userAccountId]?.lastName}`
      )
      .join(", ");

    return (
      <div>
        <span>Custom List ({userIds.length})</span>
        <span className="d-block">{userNames}</span>
      </div>
    );
  };

  const EDIT_KEYS = 8888;
  const getDropdownCheck = (selected: boolean) =>
    selected ? (
      <FontAwesomeIcon icon="check" className="me-2" />
    ) : (
      <div style={{ width: "20px", display: "inline-block" }} />
    );

  const getGenericDropdownItems = (
    itemOrder: number[],
    items: { [value: number]: string },
    selectedValue?: number,
    customUserList: number[] = []
  ) => {
    const dropdownItems = itemOrder.map((key) => {
      const value = items[key];
      if (value === "Custom List" && !customUserList.length) {
        return null;
      }
      return (
        <Dropdown.Item key={key} eventKey={key}>
          <div className="d-flex align-items-center">
            {getDropdownCheck(selectedValue === key)}
            {value === "Custom List"
              ? getCustomListStringFromIds(customUserList)
              : value}
          </div>
        </Dropdown.Item>
      );
    });

    return [
      ...dropdownItems,
      getEditOrCreateCustomListItem(customUserList.length),
    ];
  };

  const getGenericDropdown = ({
    items,
    onDropdownSelect,
    className,
    customUserList,
    selectedValue,
    itemOrder,
  }: {
    items: { [value: number]: string };
    itemOrder?: number[];
    onDropdownSelect: (payload: number) => void;
    className?: string;
    customUserList?: number[] | null;
    selectedValue?: number;
  }) => {
    if (selectedValue === undefined) {
      return null;
    }
    const orderOfItems = itemOrder || Object.keys(items).map(Number);
    const selectedDropdownOptionString = getSelectedValue(selectedValue);
    return (
      <Dropdown
        className={className}
        onSelect={(e) => {
          if (!e) return;
          onDropdownSelect(Number(e));
        }}
      >
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          {selectedDropdownOptionString}{" "}
          {selectedValue === 4 && `(${customUserList?.length})`}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {getGenericDropdownItems(
            orderOfItems,
            items,
            selectedValue,
            customUserList || []
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getSelectedValue = (storedValue?: number) => {
    if (storedValue === undefined) return "All Members";
    return WHO_CAN_SETTINGS_MAP[storedValue] ?? "All Members";
  };

  const getWhoCanJoinOrganizationValue = () => {
    switch (whoCanJoinOrganization) {
      case 2:
        return `Anyone with a ${
          companySettings?.originalDomain || "@dmpaper.com"
        } email`;
      case 3:
        return "Custom List";
      case 1:
        return "Anyone";
      default:
        return "Anyone";
    }
  };

  const getCheckOrDiv = (value: number) => {
    return whoCanJoinOrganization === value ? (
      <FontAwesomeIcon icon="check" className="me-2" />
    ) : (
      <div className="me-2" />
    );
  };

  const getEditOrCreateCustomListItem = (customDomainListLength: number) => {
    const icon = customDomainListLength ? "edit" : "plus";
    const editOrCreate = customDomainListLength
      ? "Edit Custom List"
      : "Create Custom List";
    return (
      <Dropdown.Item key={EDIT_KEYS} eventKey={EDIT_KEYS}>
        <FontAwesomeIcon icon={icon} className="me-2" />
        {editOrCreate}
      </Dropdown.Item>
    );
  };

  const getWhoCanJoinOrganizationDropdownItems = () => {
    const items = {
      1: "Anyone",
      2: `Anyone with an @${
        companySettings?.originalDomain || "@dmpaper.com"
      } email`,
      3: `Custom List`,
    };
    const customListString =
      companySettings?.customDomainList?.join(", ") ?? null;

    const customListItem = companySettings?.customDomainList?.length ? (
      <div>
        {getCheckOrDiv(3)}
        <span>Custom List ({companySettings?.customDomainList?.length})</span>
        <span className="d-block">({customListString})</span>
      </div>
    ) : null;

    const returnItems = Object.entries(items).map(([value, label]) => {
      if (value === "3" && !customListItem) return null;

      return (
        <Dropdown.Item key={value} eventKey={value}>
          {value === "3" ? (
            customListItem
          ) : (
            <div>
              {getCheckOrDiv(parseInt(value))}
              {label}
            </div>
          )}
        </Dropdown.Item>
      );
    });
    return [
      ...returnItems,
      getEditOrCreateCustomListItem(
        companySettings?.customDomainList?.length ?? 0
      ),
    ];
  };
  const getWhoCanJoinOrganizationDropdown = () => (
    <Dropdown
      onSelect={(e) => {
        if (!e) return;
        onWhoCanJoinOrganizationChange(parseInt(e));
      }}
    >
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        {getWhoCanJoinOrganizationValue()}{" "}
        {whoCanJoinOrganization === 3 &&
          `(${companySettings?.customDomainList?.length})`}
      </Dropdown.Toggle>
      <Dropdown.Menu>{getWhoCanJoinOrganizationDropdownItems()}</Dropdown.Menu>
    </Dropdown>
  );

  // ------------------------ On Change Functions ------------------------------------

  const onWhoCanJoinOrganizationChange = (value: number) => {
    if (!companyInfo?.companyAccountId) {
      return;
    }

    // Todo: for all cases we need to make sure that the user doesn't already have the value they are trying to set, if they do we don't need to make the call to the endpoint.
    switch (value) {
      case 1: {
        // If the value is already set to all, we don't need to make the call to the endpoint.
        if (whoCanJoinOrganization === 1) return;

        setWhoCanJoinOrganization(1);
        return dispatch(
          updateCompanySettings({
            payload: { emailSetting: 1 },
            companyAccountId: companyInfo?.companyAccountId,
          })
        );
      }
      case 2: {
        // If the value is already set to onlyCurrentDomain, we don't need to make the call to the endpoint.
        if (whoCanJoinOrganization === 2) return;

        setWhoCanJoinOrganization(2);
        return dispatch(
          updateCompanySettings({
            payload: { emailSetting: 2 },
            companyAccountId: companyInfo?.companyAccountId,
          })
        );
      }
      case 3: {
        // If the value is already set to customList, we don't need to make the call to the endpoint.
        setWhoCanJoinOrganization(3);
        if (companySettings?.emailSetting !== 3) {
          dispatch(
            updateCompanySettings({
              payload: { emailSetting: 3 },
              companyAccountId: companyInfo?.companyAccountId,
            })
          );
        }
        return;
      }
      case EDIT_KEYS:
        return setModalShowing("Edit Domains");
    }
  };

  // ------------------------ On Save Functions ------------------------------------
  const onAdminConsoleSettingsModalSave = (
    payload: Partial<CompanySettings>
  ) => {
    if (!companyInfo?.companyAccountId) {
      return;
    }
    return dispatch(
      updateCompanySettings({
        payload,
        companyAccountId: companyInfo?.companyAccountId,
      })
    );
  };

  // ------------------------ Debounce Functions ------------------------------------
  // The debounce function is used to prevent the API call from being made too frequently.
  const onSaveToggle = useDebounceCallback(
    (value: boolean, key: string, fetchLeads?: boolean) => {
      if (!companyInfo?.companyAccountId) {
        return;
      }

      dispatch(
        updateCompanySettings({
          payload: { [key]: Number(value) as 0 | 1 },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );

      if (fetchLeads) {
        dispatch(getTeamAndDepartmentLeads({ forceFetch: true }));
      }
    },
    1000
  );

  const onSaveSuggestedConversationsSetting = useDebounceCallback(
    (value: boolean) => {
      if (!companyInfo?.companyAccountId) {
        return;
      }

      dispatch(
        updateCompanySettings({
          payload: { hasSuggestedConversations: Number(value) as 0 | 1 },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    },
    1000
  );

  const onVisibilityOverrideToggle = useDebounceCallback((value: boolean) => {
    if (!companyInfo?.companyAccountId) {
      return;
    }

    dispatch(
      updateVisibilitySettings({
        payload: { allowVisibilityPermissionOverride: Number(value) as 0 | 1 },
      })
    );
  }, 1000);

  const onSaveWhoCanCreateTeams = useDebounceCallback(
    (value: GenericAdminNumberOption) => {
      if (!companyInfo?.companyAccountId) {
        return;
      }

      return dispatch(
        updateCompanySettings({
          payload: { teamCreationAccess: value },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    },
    1000
  );

  const onSaveWhoCanInvite = useDebounceCallback(
    (value: GenericAdminNumberOption) => {
      if (!companyInfo?.companyAccountId) {
        return;
      }

      return dispatch(
        updateCompanySettings({
          payload: { invitationAccess: value },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    },
    1000
  );

  const onSaveCompanySettings = useDebounceCallback(
    <
      K extends keyof Pick<
        CompanySettings,
        | "linkResultsAccess"
        // "hasReflectionQuestions"| // Commenting this out for now, to reduce scope of DQP-41
        | "requireTeamAcceptance"
        | "coachBoStyle"
      >
    >(
      key: K,
      value: CompanySettings[K]
    ) => {
      if (!companyInfo?.companyAccountId) {
        return;
      }

      dispatch(
        updateCompanySettings({
          payload: { [key]: value },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    },
    1000
  );

  const updateConversationHistory = () => {
    dispatch(
      toggleHasCoachBoConversationHistoryEnabled(!isConversationHistoryEnabled)
    );
    onSaveToggle(
      !isConversationHistoryEnabled,
      "hasCoachBoConversationHistoryEnabled"
    );
  };

  const handleConversationHistoryToggle = () => {
    if (isConversationHistoryEnabled) {
      setShowConversationHistoryModal(true);
    } else {
      updateConversationHistory();
    }
  };

  const getVisibilityHelpButton = () => {
    const onClick = () => {
      if (IS_AU_INSTANCE) {
        return window.open(
          "https://criteriacorp.helpdocs.io/article/scdk8461hx-visibility-defaults",
          "_blank"
        );
      }
      window.open(
        "https://criteria.helpdocs.io/article/3m8jldujd4-visibility-defaults",
        "_blank"
      );
    };

    return (
      <button className="no-style-button grey-text" onClick={onClick}>
        <div className="row-gap-8px align-items-center">
          <FontAwesomeIcon icon={["far", "question-circle"]} />
          <p>Need help?</p>
        </div>
      </button>
    );
  };

  const onCoachBoStyleChange = (value: string | null) => {
    if (!value) return;
    setCoachBoStyle(value);
    onSaveCompanySettings("coachBoStyle", value as "normal" | "conservative");
  };

  // ------------------------ (END) Debounce Functions ------------------------------------

  const checkIfValidValue = (
    value: number
  ): value is GenericAdminNumberOption => {
    return value === 1 || value === 2 || value === 3 || value === 4;
  };

  const getBody = () => {
    if (!companySettings) return <Loading />;
    return (
      <>
        <Card className="admin-console-settings-card">
          <h2>General Settings</h2>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Who Can Join Your Organization?</h3>
              <p>Specify allowable domains for joining your organization.</p>
            </div>
            <div className="setting-row-action">
              {getWhoCanJoinOrganizationDropdown()}
            </div>
          </div>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Who Can Create Teams?</h3>
              <p>Specify which users in your organization can create teams.</p>
            </div>
            <div className="setting-row-action">
              {getGenericDropdown({
                items: WHO_CAN_SETTINGS_MAP,
                itemOrder: [1, 3, 2, 4],
                onDropdownSelect: (e) => {
                  if (e === EDIT_KEYS) {
                    return setModalShowing("Who Can Create");
                  }

                  if (checkIfValidValue(e)) {
                    setWhoCanCreateTeams(e);
                    onSaveWhoCanCreateTeams(e);
                  }
                },
                selectedValue: whoCanCreateTeams,
                customUserList: companySettings?.teamCreationCustomList,
              })}
            </div>
          </div>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Who Can Add People To This Organization?</h3>
              <p>
                Specify which users can invite new users by email or using the
                company invite link.
              </p>
            </div>
            <div className="setting-row-action">
              {getGenericDropdown({
                items: WHO_CAN_SETTINGS_MAP,
                itemOrder: [1, 3, 2, 4],
                onDropdownSelect: (e) => {
                  if (e === EDIT_KEYS) {
                    return setModalShowing("Who Can Invite");
                  }
                  if (checkIfValidValue(e)) {
                    setWhoCanInvite(e);
                    onSaveWhoCanInvite(e);
                  }
                },
                selectedValue: whoCanInvite,
                customUserList: companySettings?.invitationCustomList,
              })}
            </div>
          </div>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>
                Allow Admins to Add Users to Teams without Users Accepting
                Invites?
              </h3>
              <p>
                When this is on, admins can add users to teams without users
                accepting invites. When it's off, people have to accept invites
                to be added to a team.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={!hasRequireTeamAcceptance}
                loading={
                  hasRequireTeamAcceptance &&
                  pendingTeamMembersStatus === "loading"
                }
                handleToggle={async () => {
                  // When turning this setting on, no need to check if there are pending team members
                  if (!hasRequireTeamAcceptance) {
                    setHasRequireTeamAcceptance(true);
                    dispatch(
                      updateCompanySettings({
                        payload: { requireTeamAcceptance: 1 },
                        companyAccountId: Number(companyInfo?.companyAccountId),
                      })
                    );
                    return;
                  }

                  // When turning this setting off, check if there are pending team members
                  const { payload } = (await dispatch(
                    getPendingTeamMemberships()
                  )) as { payload: any[] };

                  // If there are pending team members, show the modal to add them to the team or decline them
                  if (payload?.length) {
                    return setShowAddPendingTeamMemberModal(true);
                  }

                  // If there are no pending team members, turn off the setting
                  setHasRequireTeamAcceptance(false);
                  dispatch(
                    updateCompanySettings({
                      payload: { requireTeamAcceptance: 0 },
                      companyAccountId: Number(companyInfo?.companyAccountId),
                    })
                  );
                  dispatch(getNotifications());
                }}
              />
            </div>
          </div>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Allow Users to Link Previous Results</h3>
              <p>
                Any user can link results that they've previously completed with
                their organization to their employee profile.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={allowUsersToLinkPreviousResults}
                handleToggle={() => {
                  setAllowUsersToLinkPreviousResults(
                    !allowUsersToLinkPreviousResults
                  );
                  onSaveCompanySettings(
                    "linkResultsAccess",
                    allowUsersToLinkPreviousResults ? 0 : 1
                  );
                }}
              />
            </div>
          </div>
        </Card>
        <Card className="admin-console-settings-card">
          <h2>Features</h2>

          <h3 className="admin-console-settings-card-subheader">General</h3>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Enable Departments</h3>
              <p>
                Enable admins to create departments and invite department
                leaders. Department leaders can view TEAMscan results for their
                department.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={!areDepartmentsHidden}
                handleToggle={() => {
                  dispatch(toggleDisableDepartments(!areDepartmentsHidden));
                  onSaveToggle(
                    !areDepartmentsHidden,
                    "areDepartmentsHidden",
                    true
                  );
                }}
              />
            </div>
          </div>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Enable All AI Features</h3>
              <p>
                Enable AI functionality for Develop. This includes Coach Bo (our
                Coach Bot) as well as AI generated free text summaries for
                organization and department leaders.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={areAIFeaturesEnabled}
                handleToggle={() => {
                  dispatch(toggleAIFeaturesEnabled(!areAIFeaturesEnabled));
                  onSaveToggle(!areAIFeaturesEnabled, "aiFeaturesEnabled");
                }}
              />
            </div>
          </div>
          <hr className="m-0" />
          <h3 className="admin-console-settings-card-subheader">
            TEAMscan Report
          </h3>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Enable Suggested Conversations</h3>
              <p>
                Allow team members to view suggested conversations with other
                members in the organization based on lowest and highest scoring
                teamwork areas.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={companySettings.hasSuggestedConversations === 1}
                handleToggle={() => {
                  const updatedValue =
                    companySettings.hasSuggestedConversations === 1 ? 0 : 1;
                  dispatch(
                    updateCompanySettingsAction({
                      hasSuggestedConversations: updatedValue,
                    })
                  );
                  onSaveSuggestedConversationsSetting(!!updatedValue);
                }}
              />
            </div>
          </div>
          <hr className="m-0" />
          {/*  Commenting this out for now, to reduce scope of DQP-41 */}
          {/* <h3 className="admin-console-settings-card-subheader">
            TEAMscan Survey
          </h3>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Enable Reflection (Additional) Questions </h3>
              <p>
                Team members will have the option to rate the overall
                performance of their team, indicate their likelihood to leave
                that team, their working style on that team, and any additional
                comments for that team.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={hasReflectionQuestions}
                handleToggle={() => {
                  setHasReflectionQuestions(!hasReflectionQuestions);
                  onSaveCompanySettings(
                    "hasReflectionQuestions",
                    hasReflectionQuestions ? 0 : 1
                  );
                }}
              />
            </div>
          </div> */}
          <h3 className="admin-console-settings-card-subheader">Coach Bo</h3>

          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Enable Conversation History</h3>
              <p>
                All conversations with Coach Bo in your organization will be
                saved by default, but individual users can turn off their
                conversation history. Only individual users can see their
                conversation history.
              </p>
            </div>
            <div className="setting-row-action">
              <Toggle
                isOn={isConversationHistoryEnabled}
                handleToggle={handleConversationHistoryToggle}
              />
            </div>
          </div>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Coach Bo Style</h3>
              <p>
                Specify the style of responses Coach Bo should reply with. In{" "}
                <u>normal</u> mode, Coach Bo will help users with a wide range
                of requests and enable more discussions around personal issues.
                In <u>conservative</u> mode, Coach Bo will redirect
                conversations to focus on work-related issues and refer users to
                organizational resources for topics outside of work.
              </p>
            </div>
            <div className="setting-row-action">
              <Dropdown onSelect={onCoachBoStyleChange}>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  className="row-gap-16px align-items-center"
                >
                  {COACH_BO_STYLES[coachBoStyle]}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="normal">
                    {getDropdownCheck(coachBoStyle === "normal")}
                    {COACH_BO_STYLES.normal}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="conservative">
                    {getDropdownCheck(coachBoStyle === "conservative")}
                    {COACH_BO_STYLES.conservative}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {getCheckInWithCoachBoSetting()}
        </Card>
        <Card className="admin-console-settings-card">
          <div className="d-flex justify-content-between align-items-center">
            <h2 id="visibility-settings">Visibility Defaults</h2>
            {getVisibilityHelpButton()}
          </div>
          <VisibilitySettings
            visibilitySetting={visibilitySetting}
            visibilityOverrideToggle={visibilityOverrideToggle}
            setVisibilityOverrideToggle={(payload) => {
              onVisibilityOverrideToggle(payload);
              setVisibilityOverrideToggle(payload);
            }}
            onSaveVisibilityDefaults={async ({
              reconfigureVisibilityPermissions,
              visibilityPermissionDefaultId,
            }) => {
              if (!companyInfo?.companyAccountId) {
                return;
              }
              await dispatch(
                updateVisibilitySettings({
                  payload: {
                    reconfigureVisibilityPermissions,
                    visibilityPermissionDefaultId,
                  },
                })
              );
            }}
            isChangingDefaultsLoading={
              updatingVisibilityDefaultsStatus === "loading"
            }
          />
        </Card>
        <Card className="admin-console-settings-card">
          <h2>Style Settings</h2>
          <div className="setting-row">
            <div className="setting-row-description">
              <h3>Default Global Header Image</h3>
              <p>
                This image will be the default header image for all user and
                team profiles in your organization.
              </p>
            </div>
            <div className="setting-row-action">
              <Button
                variant="secondary-blue"
                onClick={() => setShowEditGlobalHeaderModal(true)}
              >
                <FontAwesomeIcon icon="upload" className="me-2" /> Upload
              </Button>
            </div>
          </div>
        </Card>
      </>
    );
  };

  const getCheckInWithCoachBoSetting = () => {
    if (!areAIFeaturesEnabled || !weeklyCheckInConfigCatFlag) return null;
    const isSettingEnabled = !!companySettings?.hasCoachBoWeeklyCheckInsEnabled;
    return (
      <>
        <hr className="m-0" />
        <h3 className="admin-console-settings-card-subheader">Check-Ins</h3>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Enable Weekly Check-Ins</h3>
            <p>
              Everyone in this organization will receive an email from Coach Bo
              to check on work progress, share how they’re feeling, and report
              any successes or challenges at the end of each week. Leaders and
              managers will receive an email with key patterns, recommended
              actions, and a completion analysis based on weekly check-ins
              completed by members on their team(s) or in their reporting
              structure. This can be enabled or disabled for a team or
              department from individual team/department settings.{" "}
              <SimplePopover
                popoverContent={
                  <div>
                    <p>
                      If this is your first time trying out Weekly Check-Ins,
                      try enabling this feature for a single team or department
                      to see how Check-Ins work. To turn this on for a single
                      team, go to a team profile, click on the team settings
                      tab, and enable Weekly Check-Ins. Once you’re comfortable,
                      you can turn on Weekly Check-Ins for everyone in this
                      organization by toggling this setting on.
                    </p>
                  </div>
                }
                hideTitleAndCloseButton
                popoverClassName="popover-max-width-400px"
              >
                <span className="button-link">
                  This is how we suggest you implement this feature.
                </span>
              </SimplePopover>
            </p>
          </div>
          <div className="setting-row-action">
            <Toggle
              isOn={isSettingEnabled}
              handleToggle={() => {
                if (isSettingEnabled) {
                  setShowCheckInWithCoachBoChangeModal(true);
                } else {
                  onSaveToggle(
                    !isSettingEnabled,
                    "hasCoachBoWeeklyCheckInsEnabled"
                  );
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="column-gap-20px">
      {getModals()}
      {getBody()}
    </div>
  );
}
