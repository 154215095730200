import { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { updateTeam } from "app/containers/Global/slice";
import { Team } from "app/containers/Global/types";
import {
  getAssessmentInformation,
  selectAssessmentInformation,
  selectDepartments,
  selectIsDepartmentsHidden,
} from "app/containers/AdminConsole/slice";
import Select from "react-select";
import ToggleBox from "app/storybookComponents/Forms/ToggleBox";

interface Props {
  team: Team;
  modalShowing: boolean;
  hideModal: () => void;
}

export default function EditInformationModal({
  hideModal,
  modalShowing,
  team,
}: Props) {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);

  const [teamName, setTeamName] = useState(team?.teamName ?? "");
  const [departmentId, setDepartmentId] = useState(
    team?.departmentId ?? undefined
  );
  const [
    sendDepartmentTEAMScanInvitations,
    setSendDepartmentTEAMScanInvitations,
  ] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (modalShowing) {
      dispatch(getAssessmentInformation());
    } else {
      setTeamName(team?.teamName ?? "");
      setDepartmentId(team?.departmentId);
    }
  }, [dispatch, modalShowing, team]);

  useEffect(() => {
    setTeamName(team?.teamName ?? "");
    setDepartmentId(team?.departmentId ?? undefined);
  }, [team]);

  useEffect(() => {
    if (!assessmentInformation || !departmentId || !team) {
      return;
    }
    const newDepartmentAssessmentInformation =
      assessmentInformation?.departments?.[departmentId];
    if (!newDepartmentAssessmentInformation?.activeAssessment) {
      // If the department has no active assessment, we don't need to show the toggle box
      return setSendDepartmentTEAMScanInvitations(undefined);
    }

    setSendDepartmentTEAMScanInvitations(
      !!newDepartmentAssessmentInformation?.emailSentOnInitialInvite
    );
  }, [team, departmentId, assessmentInformation]);

  if (!departments) {
    return null;
  }

  const departmentOptions = Object.entries(departments).map(([key, val]) => ({
    label: val.name,
    value: Number(key),
  }));

  const onHideModal = () => {
    setTeamName(team?.teamName ?? "");
    setDepartmentId(team?.departmentId ?? undefined);
    hideModal();
  };

  const getDepartmentChangeToggleBox = () => {
    // If the department is hidden, the department did not change we don't need to show the toggle box
    if (
      isDepartmentsHidden ||
      team?.departmentId === departmentId ||
      !departmentId
    ) {
      return null;
    }

    const newDepartmentAssessmentInformation =
      assessmentInformation?.departments?.[departmentId];

    if (newDepartmentAssessmentInformation?.activeAssessment) {
      return (
        <ToggleBox
          setToggle={() => {
            setSendDepartmentTEAMScanInvitations(
              !sendDepartmentTEAMScanInvitations
            );
          }}
          toggleLabel={
            <p>
              <b>This department currently has an active TEAMscan.</b> Send
              invitation emails to this team to complete the TEAMscan?
            </p>
          }
          toggleMessage={
            sendDepartmentTEAMScanInvitations
              ? "No invitations emails will be sent"
              : "An invitation email will be sent requesting team members to complete the TEAMscan survey."
          }
          toggleValue={!!sendDepartmentTEAMScanInvitations}
        />
      );
    }

    return null;
  };

  return (
    <Modal
      show={modalShowing}
      className="invite-modal simple-modal"
      size="lg"
      onHide={hideModal}
    >
      <div className="modal-title-row">
        <h2>Edit Team Details</h2>
        <Button
          variant={"secondary-blue"}
          onClick={onHideModal}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            updateTeam({
              teamId: team.teamId,
              payload: {
                teamName,
                departmentId: departmentId || team.departmentId,
                sendDepartmentTEAMScanInvitations,
              },
            })
          );
          onHideModal();
        }}
      >
        <Form.Group className="form-group">
          <Form.Label>Team Name</Form.Label>
          <Form.Control
            placeholder="e.g. Marketing"
            name="teamName"
            onChange={(e) => setTeamName(e.target.value)}
            value={teamName}
          />
        </Form.Group>
        {!isDepartmentsHidden ? (
          <Form.Group className="form-group">
            <Form.Label>Department</Form.Label>
            <Select
              options={departmentOptions}
              onChange={(e) => {
                setDepartmentId(e?.value);
              }}
              value={departmentOptions.find(
                (option) => option.value === departmentId
              )}
            />
          </Form.Group>
        ) : null}
        {getDepartmentChangeToggleBox()}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </Modal>
  );
}
