import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  selectIsSubscriptionModalOpen,
  selectSubscriptionStatus,
  selectGetSubscriptionStatus,
  getSubscriptionInfo,
} from "./slice";
import startTestIcon from "resources/images/start_test.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { selectCurrentUserAccountId } from "app/containers/Global/slice";
import { navigateToPreHire } from "utils/helperFunctions";

const SubscriptionModal = () => {
  const dispatch = useAppDispatch();
  const subscriptionStatus = useAppSelector(selectSubscriptionStatus);
  const show = useAppSelector(selectIsSubscriptionModalOpen);
  const getSubscriptionStatus = useAppSelector(selectGetSubscriptionStatus);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);

  useEffect(() => {
    if (getSubscriptionStatus === "idle" && loggedInUserAccountId) {
      dispatch(getSubscriptionInfo());
    }
  }, [getSubscriptionStatus, dispatch, loggedInUserAccountId]);

  if (!subscriptionStatus) {
    return null;
  }

  const companyContact = subscriptionStatus.companyContact;
  const emailLink = `mailto:${companyContact.emailAddress}`;
  const title = subscriptionStatus.freeTrial
    ? "Free Trial Has Ended"
    : "Subscription Has Ended";
  const subTitle = subscriptionStatus.freeTrial
    ? "Thank you for your interest in Develop by Criteria"
    : "Thank you for using Develop by Criteria";
  const contactRole = subscriptionStatus.freeTrial
    ? "Sales Executive"
    : "Customer Success Manager";

  return (
    <Modal show={show} size="lg">
      <div className="subscription-modal-body">
        <h2>{title}</h2>
        <div className="subscription-modal-upper-box">
          <div>
            <img src={startTestIcon} alt="start test icon" />
          </div>
          <div className="subscription-modal-upper-box-text">
            <h3 style={{ color: "black" }}>{subTitle}</h3>
            <p>
              Develop is a leading management and teamwork development platform
              for building higher performing teams and organizations, reducing
              unwanted turnover, and improving culture and engagement.
            </p>
          </div>
        </div>
        <div className="subscription-modal-lower-box">
          <div className="subscription-modal-please-call">
            Please contact {companyContact.firstName} to receive a price quote.
            Your {contactRole} is:
          </div>
          <div className="subscription-modal-sales-representative">
            <div className="sales-representative-contact-div">
              <img
                src={`https://s3.amazonaws.com/criteria-static-web-assets/employees/${companyContact.firstName.toLowerCase()}_${companyContact.lastName.toLowerCase()}.jpg`}
                alt={`${companyContact.firstName} ${companyContact.lastName}`}
                className="sales-representative-image"
              />
              <div>
                <h3>
                  {companyContact.firstName} {companyContact.lastName}
                </h3>
                <p>{contactRole}</p>
              </div>
            </div>
            <div className="sales-representative-contact-div">
              <div className="contact-button" role="button">
                <a href={emailLink} style={{ color: "white" }}>
                  <FontAwesomeIcon icon="envelope" />
                </a>
              </div>
              <a href={emailLink}>{companyContact.emailAddress}</a>
            </div>

            {companyContact.telephoneNumber && (
              <div className="sales-representative-contact-div">
                <div className="contact-button" role="button">
                  <FontAwesomeIcon icon="phone" />
                </div>
                <div>Phone Number: {companyContact.telephoneNumber}</div>
              </div>
            )}
          </div>
        </div>
        <div className="subscription-button-row">
          <Button
            variant="secondary-blue"
            className="go-to-prehire"
            style={{ border: "none" }}
            onClick={() => {
              navigateToPreHire();
            }}
          >
            Go to pre-hire platform
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
