import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectGetAllTeamsStatus,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  selectGettingSingleUserManagerAndDirectReportsStatus,
  selectManagerAndDirectReportsByUserAccountId,
} from "app/containers/AdvancedCsvUpload/slice";
import Loading from "app/storybookComponents/Loading";
import {
  getSubordinatesWeeklyCheckInActivityReport,
  getSubordinatesWeeklyCheckInLineChartData,
  getWeeklyCheckInModuleStatus,
  selectSubordinatesWeeklyCheckInActivityReports,
  selectSubordinatesWeeklyCheckInLineChartData,
  selectGetSubordinatesWeeklyCheckInLineChartDataStatus,
} from "../slice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { trackFeatureViewed } from "utils/trackingFunctions";
import WeeklyCheckInActivitySnapshot from "./WeeklyCheckInActivitySnapshot";
import SimplePopover from "app/components/Popovers/SimplePopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubordinatesWeeklyCheckinSnapshot = () => {
  const dispatch = useAppDispatch();
  const teams = useAppSelector(selectTeamsByTeamId);
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const users = useAppSelector(selectAllCompanyUsersById);
  const getTeamsStatus = useAppSelector(selectGetAllTeamsStatus);
  const managerAndDirectReports = useAppSelector(
    selectManagerAndDirectReportsByUserAccountId
  );
  const getManagerAndDirectReportsStatus = useAppSelector(
    selectGettingSingleUserManagerAndDirectReportsStatus
  );
  const subordinatesWeeklyCheckInActivityReports = useAppSelector(
    selectSubordinatesWeeklyCheckInActivityReports
  );
  const subordinatesLineChartData = useAppSelector(
    selectSubordinatesWeeklyCheckInLineChartData
  );
  const fetchingWeeklyCheckInLineChartDataStatus = useAppSelector(
    selectGetSubordinatesWeeklyCheckInLineChartDataStatus
  );

  const [
    weeklyCheckInActivityTimeInterval,
    setWeeklyCheckInActivityTimeInterval,
  ] = useState("Past 7 days");
  const [lineChartTimeInterval, setLineChartTimeInterval] =
    useState("Past 30 days");
  const [lineChartNavigationIndex, setLineChartNavigationIndex] = useState(1);
  const [hasFeatureBeenViewed, setHasFeatureBeenViewed] = useState(false);

  const canUserViewFeature = useMemo(() => {
    if (!currentUserAccountId) return false;
    const userInfo = users[currentUserAccountId];
    const directReportsForLoggedInUser =
      managerAndDirectReports[currentUserAccountId]?.directReportRelationships;

    const userIsLeaderOfTeam = userInfo?.teamIds?.some((teamId) => {
      const team = teams[teamId];
      const isLeader =
        team?.teamLeadUserAccountIds?.includes(currentUserAccountId);
      return isLeader;
    });

    return userIsLeaderOfTeam || directReportsForLoggedInUser?.length;
  }, [currentUserAccountId, users, teams, managerAndDirectReports]);

  useEffect(() => {
    if (!canUserViewFeature || hasFeatureBeenViewed) return;
    trackFeatureViewed(
      "Weekly Check-In Activity Dashboard Card (Direct Report)"
    );
    setHasFeatureBeenViewed(true);
  }, [canUserViewFeature, hasFeatureBeenViewed]);

  useEffect(() => {
    dispatch(getSubordinatesWeeklyCheckInActivityReport());
    dispatch(getWeeklyCheckInModuleStatus());
    dispatch(
      getSubordinatesWeeklyCheckInLineChartData({
        timeInterval: "last30Days",
        index: 1,
      })
    );
  }, [dispatch]);

  const onGetWeeklyCheckInLineChartData = useCallback(
    ({ timeInterval, index }: { timeInterval: string; index: number }) => {
      dispatch(
        getSubordinatesWeeklyCheckInLineChartData({
          timeInterval,
          index,
        })
      );
    },
    [dispatch]
  );

  const getHeaderCornerContent = () => (
    <SimplePopover
      popoverContent={
        <p>
          These metrics reflect check-in activity for everyone in your reporting
          structure, including people who report to you and teams you lead.
        </p>
      }
      trigger="hover"
      hideTitleAndCloseButton
    >
      <div className="info-hover-over-element">
        <FontAwesomeIcon icon={["far", "info-circle"]} className="info-icon" />
        Who's included in this?
      </div>
    </SimplePopover>
  );

  const getBodyWithLoading = () => {
    if (
      getTeamsStatus === "loading" ||
      getManagerAndDirectReportsStatus === "loading" ||
      !currentUserAccountId
    ) {
      return <Loading />;
    }

    if (!canUserViewFeature) {
      return null;
    }

    return (
      <WeeklyCheckInActivitySnapshot
        coachBoWeeklyCheckInActivityReports={
          subordinatesWeeklyCheckInActivityReports
        }
        getCoachBoWeeklyCheckInActivityReportStatus={"succeeded"}
        showWeeklyCheckInActivityEmptyState={false}
        weeklyCheckInLineChartData={subordinatesLineChartData}
        fetchingWeeklyCheckInLineChartDataStatus={
          fetchingWeeklyCheckInLineChartDataStatus
        }
        getShowWeeklyCheckInActivityEmptyStateStatus={"succeeded"}
        viewActivityPath="/DirectReportsWeeklyCheckin"
        weeklyCheckInActivityTimeInterval={weeklyCheckInActivityTimeInterval}
        setWeeklyCheckInActivityTimeInterval={
          setWeeklyCheckInActivityTimeInterval
        }
        lineChartTimeInterval={lineChartTimeInterval}
        setLineChartTimeInterval={setLineChartTimeInterval}
        lineChartNavigationIndex={lineChartNavigationIndex}
        setLineChartNavigationIndex={setLineChartNavigationIndex}
        onGetWeeklyCheckInLineChartData={onGetWeeklyCheckInLineChartData}
        headerCornerContent={getHeaderCornerContent()}
      />
    );
  };

  return getBodyWithLoading();
};

export default SubordinatesWeeklyCheckinSnapshot;
