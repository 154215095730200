import Button from "app/storybookComponents/Button";
import { Card, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DepartmentSurveys from "app/containers/DepartmentGuide/DepartmentSurveys";
import DepartmentSettings from "./DepartmentSettings";

interface Props {
  departmentId: number;
  isLeader?: boolean;
  pageShowing?: string;
}
export default function DepartmentSettingsPage({
  departmentId,
  isLeader,
  pageShowing,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const getContent = () => {
    if (pageShowing === "Department Settings") {
      return <DepartmentSettings departmentId={departmentId} />;
    }
    return (
      <DepartmentSurveys departmentId={departmentId} isLeader={isLeader} />
    );
  };

  return (
    <div className="column-gap-20px">
      <Card className="padding-20px column-gap-20px">
        <div className="d-flex justify-content-between">
          <h1>Department Settings</h1>
          <Button
            variant="secondary-blue"
            onClick={() => {
              navigate(`/DepartmentGuide/${departmentId}?tab=Dashboard`);
            }}
          >
            Go to department dashboard
          </Button>
        </div>
        <Nav
          className="simple-nav"
          activeKey={pageShowing ?? ""}
          onSelect={(e) => {
            navigate(`/DepartmentGuide/${departmentId}?tab=${e}`);
          }}
          style={{
            marginBottom: "-20px",
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="Manage Surveys">Surveys</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Department Settings">Settings</Nav.Link>
          </Nav.Item>
        </Nav>
      </Card>
      <Card className="padding-20px column-gap-20px">{getContent()}</Card>
    </div>
  );
}
