export const CONFIG_CAT_FLAGS = {
  activityMonitoring: {
    rawFlagName: "develop_analyticsdashboard",
    savedFlagName: "activityMonitoring",
    defaultValue: false,
  },
  weeklyCheckIn: {
    rawFlagName: "develop_weeklycheckin",
    savedFlagName: "weeklyCheckIn",
    defaultValue: false,
  },
} as const;
