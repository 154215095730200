import { UserInfo } from "app/containers/Global/types";
import Loading from "app/storybookComponents/Loading";
import { useState, ReactElement, useMemo, useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "../Button";
import InviteUserForm from "./InviteUserForm";
import {
  CheckSingleEmailResponse,
  Department,
} from "app/containers/AdminConsole/types";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreatableSingleSelect from "../SearchableInput/CreatableSingleSelect";
import { getUserOptionValue } from "../SearchableInput/helpers";
import { getOrganizationDepartments } from "app/containers/AdminConsole/helpers";
import { INVITE_PEOPLE_TEXT } from "app/components/Modals/constants";
import ToggleBox from "./ToggleBox";

interface Props {
  onCreateTeam: (payload: {
    teamName: string;
    departmentId: number;
    teamDescription: string;
    teamMemberEmails: string[];
    teamLeaderEmail?: string;
  }) => void;
  form: {
    teamName: string;
    departmentId: number;
    teamDescription: string;
    teamMemberEmails: string[];
  };
  setForm: (payload: {
    teamName: string;
    departmentId: number;
    teamDescription: string;
    teamMemberEmails: string[];
  }) => void;
  onClose?: () => void;
  isLoading?: boolean;
  createdTeamId?: number | null;
  usersInfoById: { [userId: number]: UserInfo };
  inviteUserByEmail: (emailAddresses: string[]) => void;
  userAccountId: number;
  inviteLink?: string;
  allowedDomains?: string[] | "ALL";
  departments?: { [departmentId: number]: Department };
  onCreateDepartment?: () => void;
  isAdmin?: boolean;
  invalidInvitedStrings?: string[];
  defaultTeamLeaderId?: number | null;
  loggedInUserId?: number | null;
  canUserInviteNewMembers?: boolean;
  checkedEmails?: {
    [email: string]: CheckSingleEmailResponse;
  };
  onCheckEmail?: (email: string) => void;
  resetInvalidInvitedStrings: () => void;
  setSendEmailSettingProps?: {
    value: boolean;
    setter: (value: boolean) => void;
  };
  hideInviteViaLinkOption?: boolean;
}

export default function CreateTeamForm({
  onCreateTeam,
  form,
  setForm,
  onClose,
  isLoading,
  createdTeamId,
  usersInfoById,
  inviteUserByEmail,
  inviteLink,
  allowedDomains,
  departments = {},
  onCreateDepartment,
  isAdmin,
  invalidInvitedStrings,
  defaultTeamLeaderId,
  loggedInUserId,
  canUserInviteNewMembers,
  onCheckEmail,
  checkedEmails,
  resetInvalidInvitedStrings,
  setSendEmailSettingProps,
  hideInviteViaLinkOption,
}: Props) {
  const MAX_STEPS = 3;
  const TEAM_NAME_CHAR_LIMIT = 50;
  const TEAM_DESCRIPTION_CHAR_LIMIT = 250;
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTeamLeaderId, setSelectedTeamLeaderId] = useState<
    null | number
  >(null);
  const [createdTeamLeaderEmail, setCreatedTeamLeaderEmail] = useState("");
  const filteredDepartments = getOrganizationDepartments(departments);

  useEffect(() => {
    setSelectedTeamLeaderId(defaultTeamLeaderId ?? null);
  }, [defaultTeamLeaderId]);

  const onFormChange = (formField: string, value: string) => {
    setForm({ ...form, [formField]: value });
  };

  const onFormSubmit = () => {
    if (!createdTeamLeaderEmail && !selectedTeamLeaderId) {
      return onCreateTeam(form);
    }

    let teamLeaderEmail: string = "";

    // If the team leader Id is set then we use that email address.
    if (selectedTeamLeaderId && usersInfoById[selectedTeamLeaderId]) {
      teamLeaderEmail = usersInfoById[selectedTeamLeaderId].emailAddress;
    } else if (createdTeamLeaderEmail) {
      teamLeaderEmail = createdTeamLeaderEmail;
    }
    onCreateTeam({ ...form, teamLeaderEmail });
  };

  const departmentOptions = Object.entries(filteredDepartments).map(
    ([key, val]) => ({
      label: val.name,
      value: Number(key),
    })
  );
  const userOptions = useMemo(
    () =>
      Object.entries(usersInfoById).map(([key, val]) => ({
        label: val.firstName
          ? `${val.firstName ?? ""} ${val.lastName ?? ""}`
          : val.emailAddress,
        value: Number(key),
      })),
    [usersInfoById]
  );

  const getDepartmentDropdown = () => {
    if (isAdmin) {
      return (
        <CreatableSelect
          noOptionsMessage={() => null}
          options={departmentOptions}
          onChange={(e) => {
            if (e?.value) {
              setForm({ ...form, departmentId: e.value });
            } else {
              setForm({ ...form, departmentId: 0 });
            }
          }}
          formatCreateLabel={() => (
            <div>
              <FontAwesomeIcon icon="plus" /> Add a new department
            </div>
          )}
          onCreateOption={() => {
            onCreateDepartment?.();
          }}
          value={departmentOptions.find(
            (option) => option.value === form.departmentId
          )}
          isValidNewOption={() => true}
          isClearable
        />
      );
    }

    return (
      <Select
        options={departmentOptions}
        onChange={(e) => {
          if (e?.value) {
            setForm({ ...form, departmentId: e.value });
          }
        }}
        value={departmentOptions.find(
          (option) => option.value === form.departmentId
        )}
      />
    );
  };

  const getWarningBannerElm = (stringValue: string, key: string) => (
    <div
      key={key}
      className="warning-banner light-red row-gap-12px align-items-center"
    >
      <FontAwesomeIcon icon="triangle-exclamation" />
      <p>{stringValue}</p>
    </div>
  );

  const getTeamLeaderValue = () => {
    if (createdTeamLeaderEmail) {
      return {
        label: createdTeamLeaderEmail,
        value: createdTeamLeaderEmail,
        emailAddress: createdTeamLeaderEmail,
        avatarCircle: null,
      };
    }

    if (!selectedTeamLeaderId) return null;
    return getUserOptionValue(usersInfoById[selectedTeamLeaderId]) ?? null;
  };

  const isNextForStep1Disabled = () => {
    // If no team name is set then we disable the next button.
    if (!form.teamName) {
      return true;
    }

    // If we have a selected team leader by Id then the next button is enabled.
    if (selectedTeamLeaderId) {
      return false;
    }

    // Given that the team leaderId is not set we check if the email is valid.
    if (
      createdTeamLeaderEmail &&
      checkedEmails?.[createdTeamLeaderEmail]?.error
    ) {
      return true;
    }

    // If none of the above conditions are met then we enable the next button.
    return false;
  };

  const getStep1TeamLeaderErrorBanner = () => {
    // if its a new email check if the email is valid.
    const checkedEmail = checkedEmails?.[createdTeamLeaderEmail];

    if (!selectedTeamLeaderId && checkedEmail?.error) {
      return getWarningBannerElm(
        checkedEmail.error,
        `${createdTeamLeaderEmail}-invalid-email`
      );
    }
    // if all fails then we return null.
    return null;
  };

  const getStep1TeamLeaderSnapshotMessage = () => {
    // if the logged in user is the selected team leader then we don't show the snapshot message.
    if (loggedInUserId === selectedTeamLeaderId || !setSendEmailSettingProps) {
      return null;
    }

    const toggleLabel = "Send invite email to team leader?";
    let message = "";
    if (setSendEmailSettingProps.value) {
      message =
        "An invitation email will be sent to these users to be a leader of this team. If the user is has not joined this organization, they will receive an invite to join both this organization and team.";
    } else if (isAdmin) {
      message =
        "No invitation will be sent. You can send the invitation later from the Teams tab in the Admin Console.";
    } else {
      message =
        "No invitation email will be sent. You can send invitation emails later from this teams's settings page.";
    }

    return (
      <ToggleBox
        setToggle={setSendEmailSettingProps.setter}
        toggleLabel={toggleLabel}
        toggleMessage={message}
        toggleValue={setSendEmailSettingProps.value}
      />
    );
  };

  const { modalDescription, inviteMemberInfoMessage, addMemberInfoMessage } =
    INVITE_PEOPLE_TEXT.Team;

  const teamLeaderSelected = getTeamLeaderValue();
  const steps: { [stepNumber: number]: ReactElement } = {
    1: (
      <div className="column-gap-18px">
        <h2>Create a Team</h2>
        <p>
          Teams should only be created by an admin, manager, or team leader.
          Within Develop by Criteria, teams are working groups that are fairly
          stable and likely to together for months or years.
        </p>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setCurrentStep(2);
          }}
        >
          <Form.Group className="form-group" controlId="teamSetupFormTeamName">
            <Form.Label className="simple-form-label">Team Name</Form.Label>
            <Form.Control
              placeholder="Type your team name here..."
              name="teamName"
              onChange={(e) => {
                onFormChange(
                  e.target.name,
                  e.target.value.slice(0, TEAM_NAME_CHAR_LIMIT)
                );
              }}
              value={form.teamName}
              required
            />
            <Form.Text id="teamNameLimit" muted>
              {form.teamName.length}/{TEAM_NAME_CHAR_LIMIT} Characters
            </Form.Text>
          </Form.Group>
          {departmentOptions?.length ? (
            <Form.Group className="form-group" controlId="teamDepartment">
              <Form.Label className="simple-form-label">
                Department (Optional)
              </Form.Label>
              {getDepartmentDropdown()}
            </Form.Group>
          ) : null}
          <Form.Group className="form-group" controlId="teamLeader">
            <Form.Label className="simple-form-label">
              Add/Invite Team Leader
            </Form.Label>

            <CreatableSingleSelect
              options={userOptions}
              onChange={(e) => {
                if (e?.value) {
                  setSelectedTeamLeaderId(Number(e.value));
                } else {
                  setSelectedTeamLeaderId(null);
                  setCreatedTeamLeaderEmail("");
                }
              }}
              value={teamLeaderSelected}
              onCaptureInputValue={(val) => {
                if (!canUserInviteNewMembers) {
                  return;
                }
                if (val) {
                  onCheckEmail?.(val);
                }
                setCreatedTeamLeaderEmail(val);
              }}
              placeHolder="Search by name or email"
              isNotCreatable={!canUserInviteNewMembers}
            />
          </Form.Group>
          {getStep1TeamLeaderSnapshotMessage()}
          {getStep1TeamLeaderErrorBanner()}
          <div className="ms-auto">
            <Button
              variant="primary"
              type="submit"
              disabled={isNextForStep1Disabled()}
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    ),
    2: (
      <div className="column-gap-18px">
        <h2>Short Team Description</h2>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onFormSubmit();
            setCurrentStep(3);
          }}
        >
          <Form.Group className="form-group" controlId="accountSetupWhatIDo">
            <Form.Label>What does this team do?</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type your team description here..."
              name="teamDescription"
              onChange={(e) => {
                onFormChange(
                  e.target.name,
                  e.target.value.slice(0, TEAM_DESCRIPTION_CHAR_LIMIT)
                );
              }}
              value={form.teamDescription}
            />
            <Form.Text id="teamDescriptionLimit" muted>
              {form.teamDescription.length}/{TEAM_DESCRIPTION_CHAR_LIMIT}{" "}
              Characters
            </Form.Text>
          </Form.Group>

          <div className="ms-auto">
            <Button variant="primary" type="submit">
              Next
            </Button>
          </div>
        </Form>
      </div>
    ),
    3: (
      <div className="column-gap-18px">
        <h2>
          {setSendEmailSettingProps?.value ? "Invite" : "Add"} Team Members to{" "}
          {form.teamName}
        </h2>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InviteUserForm
            onInviteViaEmail={inviteUserByEmail}
            teamId={createdTeamId ?? undefined}
            inviteLink={inviteLink}
            isLoading={isLoading}
            teamMembers={Object.values(usersInfoById)}
            allowedDomains={allowedDomains}
            invalidInvitedStrings={invalidInvitedStrings ?? []}
            modalDescription={
              <p>
                <b>Why add team members?</b> {modalDescription}
              </p>
            }
            onCSVUploadSuccess={() => {
              onClose?.();
            }}
            hideCsvTab
            hideInviteViaLinkTab={
              !canUserInviteNewMembers || hideInviteViaLinkOption
            }
            inviteMemberInfoMessage={inviteMemberInfoMessage}
            hiddenUserAccounts={
              selectedTeamLeaderId ? [selectedTeamLeaderId] : []
            }
            addMemberInfoMessage={addMemberInfoMessage}
            resetInvalidInvitedStrings={resetInvalidInvitedStrings}
            setSendEmailSettingProps={setSendEmailSettingProps}
          />
        </Form>
      </div>
    ),
  };

  return (
    <>
      {onClose ? (
        <Button
          onClick={() => onClose()}
          variant={"secondary-blue"}
          style={{
            border: "none",
            width: "auto",
            position: "absolute",
            right: "16px",
            top: "16px",
          }}
          xIcon
        />
      ) : null}
      <div>
        <p className="mb-0">
          Step {currentStep} of {MAX_STEPS}
        </p>
        {isLoading ? <Loading /> : steps[currentStep]}
      </div>
    </>
  );
}
