import {
  ModuleTemplates,
  Guide,
  UsedModuleTemplateIds,
  isModuleType,
  ModuleType,
  BaseModuleTemplate,
} from "app/components/Modules/types";

import { TeamAnalyticsText } from "app/containers/TeamGuide/types";
import { Team } from "app/containers/Global/types";
import { PendingAssessmentNotification } from "app/components/Notifications/types";
import ModuleCard from "app/components/Modules/Cards/ModuleCard";
import MemberCardWithNoButton from "app/components/MemberCard/MemberCardWithNoButton";
import { TeamMostRecentInstanceInfo } from "../Assessment/types";
import { Dictionary } from "@reduxjs/toolkit";
import { OverallActivitySnapshot, TEAMscanActivitySnapshot } from "./types";
import { ActivityMonitorBoxProps } from "app/components/ActivityMonitor/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimplePopover from "app/components/Popovers/SimplePopover";
import { INDIVIDUAL_LOGIN_COUNT_START_DATE } from "./constants";
import { NA_IMPORTANT_COMPANY_ACCOUNTS } from "../AdminConsole/constants";
import { IS_AU_INSTANCE } from "utils/environmentVariables";

// Will take in teamId, teamGuideModuleTemplates, and Team guide
// We will then return back two module for sections that the team has not answered.
// If the team has answered modules for each section then we can show a module for an already answered section
// Will take in userAccountId, userGuideModuleTemplates, and User guide
// We will then return back two module for sections that the user has not answered.
// If the user has answered modules for each section then we can show a module for an already answered section
export const getModulesToAdd = (payload: {
  moduleTemplates: ModuleTemplates | null;
  guide: Guide;
  usedModules: { [id: number]: UsedModuleTemplateIds };
  onModuleAdd: (moduleTemplateId: number, moduleType: ModuleType) => void;
}) => {
  const { moduleTemplates, usedModules, onModuleAdd } = payload;
  if (!moduleTemplates) return;
  const unusedSections = getUnusedModuleTemplates(moduleTemplates, usedModules);
  return unusedSections.map((moduleTemplate) => (
    <ModuleCard
      key={`${moduleTemplate.moduleTemplateId}_${moduleTemplate.moduleType}`}
      moduleTemplateId={moduleTemplate.moduleTemplateId}
      moduleType={moduleTemplate.moduleType}
      title={moduleTemplate.title}
      shortDescription={moduleTemplate.shortDescription}
      iconName={moduleTemplate.iconName}
      onAddModule={() =>
        onModuleAdd(moduleTemplate.moduleTemplateId, moduleTemplate.moduleType)
      }
    />
  ));
};

export const getUnusedModuleTemplates = (
  moduleTemplates: ModuleTemplates,
  usedModules: Partial<UsedModuleTemplateIds> = {}
): (BaseModuleTemplate & {
  moduleType: ModuleType;
})[] => {
  // First we will make a copy of the Module Templates
  const copyModuleTemplates = JSON.parse(
    JSON.stringify(moduleTemplates)
  ) as ModuleTemplates;

  // Then remove the usedModules from the Module templates
  Object.entries(usedModules).forEach(([key, valueArr]) => {
    if (!isModuleType(key)) return;
    valueArr.forEach((id) => {
      if (copyModuleTemplates[key][id]) {
        // We remove the already added modules from the copy so that we don't show it again to add.
        delete copyModuleTemplates[key][id];
      }
    });
  });

  // When removing the usedModules we will keep track of which section have already been used
  const returnArr: (BaseModuleTemplate & {
    moduleType: ModuleType;
  })[] = [];

  // We will find the max for the module templates
  const maxLength = Object.values(copyModuleTemplates).reduce(
    (acc, curr) => Math.max(acc, Object.keys(curr).length),
    0
  ) as number;

  let idx = 0;
  while (idx < maxLength && returnArr.length < 2) {
    Object.entries(copyModuleTemplates).forEach(
      // eslint-disable-next-line no-loop-func
      ([moduleType, moduleTemplateMap]) => {
        const temp = Object.values(moduleTemplateMap)[
          idx
        ] as BaseModuleTemplate;
        if (!temp) return;
        returnArr.push({ ...temp, moduleType: moduleType as ModuleType });
      }
    );

    idx += 1;
  }

  return returnArr;
};

// Should return only the top 4 members
export const getMembersFromTeam = (team: Team | null) => {
  if (!team) return null;
  const members =
    team.teamMemberIds?.map((userAccountId) => {
      return (
        <MemberCardWithNoButton
          userAccountId={userAccountId}
          key={userAccountId}
        />
      );
    }) ?? [];
  const pendingMembers =
    team.pendingTeamMemberIds?.map((userAccountId) => {
      return (
        <MemberCardWithNoButton
          key={userAccountId}
          userAccountId={userAccountId}
          isPendingForTeam
          customPendingMessage="Pending team invitation"
        />
      );
    }) ?? [];

  const totalMembers = [...members, ...pendingMembers].splice(0, 4);

  return <div className="four-team-member-holder">{totalMembers}</div>;
};

export const getTeamTotalText = (
  teamTotal: number = 0,
  totalPending: number = 0
) => {
  const acceptedTeams = teamTotal - totalPending;
  if (totalPending === 0 && acceptedTeams === 0) {
    return "";
  } else if (acceptedTeams === 0 && totalPending !== 0) {
    return `${totalPending} pending invite${totalPending === 1 ? "" : "s"}`;
  } else if (acceptedTeams !== 0 && totalPending === 0) {
    return `${acceptedTeams} team${acceptedTeams === 1 ? "" : "s"} joined`;
  } else {
    return `${acceptedTeams} team${
      acceptedTeams === 1 ? "" : "s"
    } joined, ${totalPending} pending invite${totalPending === 1 ? "" : "s"}`;
  }
};

export const getTeamMemberTotalText = (
  acceptedMemberCount: number = 0,
  pendingMemberCount: number = 0
): string => {
  if (pendingMemberCount === 0 && acceptedMemberCount === 0) {
    return "";
  } else if (acceptedMemberCount === 0 && pendingMemberCount !== 0) {
    return `${pendingMemberCount} member${
      pendingMemberCount === 1 ? "" : "s"
    } invited`;
  } else if (acceptedMemberCount !== 0 && pendingMemberCount === 0) {
    return `${acceptedMemberCount} total member${
      acceptedMemberCount === 1 ? "" : "s"
    }`;
  } else {
    return `${acceptedMemberCount} total member${
      acceptedMemberCount === 1 ? "" : "s"
    }, ${pendingMemberCount} pending invite${
      pendingMemberCount === 1 ? "" : "s"
    }`;
  }
};

// Takes in notifications as param and return back an object with the teamId being the key and the value being the pending assessment notification
export const getNotificationTeamMap = (
  pendingAssessmentNotifications?: PendingAssessmentNotification[]
): {
  [teamId: number]: PendingAssessmentNotification;
} => {
  if (!pendingAssessmentNotifications) return {};
  const notificationMap: {
    [teamId: number]: PendingAssessmentNotification;
  } = {};
  pendingAssessmentNotifications?.forEach((notification) => {
    if (!notification.teamId) return;
    if (!notificationMap[notification.teamId]) {
      notificationMap[notification.teamId] = notification;
    }
  });
  return notificationMap;
};

export const getHasAnyTeam360Results = (
  allMostRecentAssessments: Dictionary<TeamMostRecentInstanceInfo>,
  teamIds?: number[]
) =>
  !!teamIds?.some((teamId) => allMostRecentAssessments[teamId]?.totalInvited);

export const getHowManyTeamsHaveTeam360Results = (
  allTeamAnalyticText: { [teamId: number]: TeamAnalyticsText },
  teamIds?: number[]
): number =>
  teamIds?.reduce(
    (acc, teamId) =>
      allTeamAnalyticText[teamId]?.completionInfo?.totalInvited ? acc + 1 : acc,
    0
  ) ?? 0;

export const getLabelColor = (label: string) => {
  if (label === "above") {
    return "green";
  } else if (label === "below") {
    return "red";
  }
  return "yellow";
};

export const getArrowValue = (label: string): "up" | "down" | null => {
  if (label === "above") {
    return "up";
  } else if (label === "below") {
    return "down";
  }
  return null;
};

export const getWeekFromEndDateOrFromNow = (endDate?: string) => {
  if (!endDate) return "";
  const endDatePlus7Days = new Date(endDate);
  endDatePlus7Days.setDate(endDatePlus7Days.getDate() + 7);
  const now = new Date();
  if (endDatePlus7Days < now) {
    now.setDate(now.getDate() + 7);
    return now.toISOString();
  }
  return endDatePlus7Days.toISOString();
};

export const getLoginCountWarningMessageText = () => {
  const loginStartTrackingDate = new Date(INDIVIDUAL_LOGIN_COUNT_START_DATE);

  const loginStartTrackingDateText = loginStartTrackingDate.toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  const loginStartTrackingDateTextWithoutYear =
    loginStartTrackingDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });

  return `Note: There is no login data prior to ${loginStartTrackingDateText}. The login count shown here is the total count starting ${loginStartTrackingDateTextWithoutYear}.`;
};

export const getMonitorPropsFromOverallActivitySnapshot = (
  overallActivitySnapshot?: OverallActivitySnapshot | null,
  timeInterval: string = "week",
  userCount: number = 1,
  companyAccountId?: number
): ActivityMonitorBoxProps[] => {
  const activityMonitorBoxProps: ActivityMonitorBoxProps[] = [];
  if (!overallActivitySnapshot) return activityMonitorBoxProps;
  const {
    chatCount,
    peopleWithMoreThanOneChatCount,
    loginCount,
    TEAMscanCount,
  } = overallActivitySnapshot;

  const isAllianz =
    !IS_AU_INSTANCE &&
    NA_IMPORTANT_COMPANY_ACCOUNTS.Allianz === companyAccountId;

  activityMonitorBoxProps.push({
    title: "Logins",
    mainValue: `${loginCount.totalCount}`,
    changeSinceLast: getChangeSinceLast(
      loginCount.trendAmount,
      loginCount.trendDirection
    ),
    timeInterval,
    popoverText: `This is the total number of logins into Develop within the past ${timeInterval}.`,
    dotColor: null,
    additionalDescriptionContent: !isAllianz ? (
      <div>
        <SimplePopover
          trigger="hover"
          popoverContent={<p>{getLoginCountWarningMessageText()}</p>}
          hideTitleAndCloseButton
        >
          <FontAwesomeIcon icon={["far", "exclamation-triangle"]} />
        </SimplePopover>
      </div>
    ) : null,
  });

  activityMonitorBoxProps.push({
    title: "Coach Bo Chats",
    mainValue: `${chatCount.totalCount}`,
    changeSinceLast: getChangeSinceLast(
      chatCount.trendAmount,
      chatCount.trendDirection
    ),
    timeInterval,
    popoverText: `This is the total number of Coach Bo Chats completed within the past ${timeInterval}.`,
    dotColor: null,
  });

  const peopleWithMoreThanOneChatCountPercentage = Math.round(
    (peopleWithMoreThanOneChatCount.totalCount / userCount) * 100
  );

  activityMonitorBoxProps.push({
    title: "People With 1+ Coach Bo Chats",
    mainValue: peopleWithMoreThanOneChatCount.totalCount,
    changeSinceLast: getChangeSinceLast(
      getFixedPercentage(peopleWithMoreThanOneChatCount.trendAmount),
      peopleWithMoreThanOneChatCount.trendDirection
    ),
    dotColor: getOutOfFiveColor(peopleWithMoreThanOneChatCountPercentage / 20),
    descriptionText: `out of ${userCount}`,
    timeInterval,
    popoverText: `This is the number of users who have completed at least one Coach Bo chat within the past ${timeInterval}.`,
  });

  activityMonitorBoxProps.push({
    title: "TEAMscans",
    mainValue: `${TEAMscanCount.totalCompletedCount}`,
    changeSinceLast: getChangeSinceLast(
      TEAMscanCount.trendAmount,
      TEAMscanCount.trendDirection
    ),
    descriptionText: `out of ${TEAMscanCount.totalCount}`,
    timeInterval,
    popoverText: `This is the total number of TEAMscan events completed within the past ${timeInterval}.`,
    dotColor: null,
  });

  return activityMonitorBoxProps;
};

export const getTEAMscanActivityReportToActivityMonitorBoxProps = (
  snapshot?: TEAMscanActivitySnapshot | null,
  timeInterval: string = "week"
): ActivityMonitorBoxProps[] => {
  const activityMonitorBoxProps: ActivityMonitorBoxProps[] = [];

  if (!snapshot) return activityMonitorBoxProps;

  const totalPeopleOnTeams = snapshot.userCount.current ?? 1;
  activityMonitorBoxProps.push({
    title: "Organization TEAMscan Score",
    mainValue: snapshot.TEAMscanScore.current ?? "-",
    changeSinceLast: {
      totalChange: Math.abs(snapshot.TEAMscanScore.change ?? 0),
      isPositive: (snapshot.TEAMscanScore.change ?? 0) > 0,
    },
    timeInterval,
    popoverText: `This is the average of all scores from TEAMscans launched in the past ${timeInterval}.`,
  });

  const peopleOnAverageAndBelowTeamsPercentage =
    snapshot.peopleOnAverageOrBelowTeams.current !== null &&
    snapshot.peopleOnAverageOrBelowTeams.current !== undefined
      ? Math.round(
          (snapshot.peopleOnAverageOrBelowTeams.current / totalPeopleOnTeams) *
            100
        )
      : null;

  activityMonitorBoxProps.push({
    title: "# People On Avg. And Below Teams",
    mainValue: snapshot.peopleOnAverageOrBelowTeams.current ?? "-",
    changeSinceLast: {
      totalChange: Math.abs(snapshot.peopleOnAverageOrBelowTeams.change ?? 0),
      isPositive: (snapshot.peopleOnAverageOrBelowTeams.change ?? 0) > 0,
      isColorInverted: true,
    },
    dotColor: percentageColor(peopleOnAverageAndBelowTeamsPercentage, true),
    descriptionText:
      peopleOnAverageAndBelowTeamsPercentage !== null
        ? `out of ${totalPeopleOnTeams} (${peopleOnAverageAndBelowTeamsPercentage}%)`
        : "",
    timeInterval,
    popoverText: `This is the total number of responses with “Acceptable”, “Poor”, or “Very Poor” indicated for the question “How would you rate the overall performance of this team?” on TEAMscans launched in the past ${timeInterval}.`,
  });

  const peopleNotLikelyToStayOnTeamPercentage =
    snapshot.peopleNotLikelyToStayOnTeam.current !== null &&
    snapshot.peopleNotLikelyToStayOnTeam.current !== undefined
      ? Math.round(
          (snapshot.peopleNotLikelyToStayOnTeam.current / totalPeopleOnTeams) *
            100
        )
      : null;

  activityMonitorBoxProps.push({
    title: "# People Not Likely To Stay On Team",
    mainValue: snapshot.peopleNotLikelyToStayOnTeam.current ?? "-",
    changeSinceLast: {
      totalChange: Math.abs(snapshot.peopleNotLikelyToStayOnTeam.change ?? 0),
      isPositive: (snapshot.peopleNotLikelyToStayOnTeam.change ?? 0) > 0,
      isColorInverted: true,
    },
    dotColor: percentageColor(peopleNotLikelyToStayOnTeamPercentage, true),
    descriptionText:
      peopleNotLikelyToStayOnTeamPercentage !== null
        ? `out of ${totalPeopleOnTeams} (${peopleNotLikelyToStayOnTeamPercentage}%)`
        : "",
    timeInterval,
    popoverText: `This is the total number of responses with “Likely” or “Very Likely” indicated for the question “How likely are you to look for chances to leave this team in the next 6-12 months?” on all TEAMscans launched in the past ${timeInterval}.`,
  });

  return activityMonitorBoxProps;
};
const getFixedPercentage = (percentage: number | null) => {
  if (!percentage) {
    return null;
  }
  const percentNum = Number(percentage);
  return Number(percentNum.toFixed());
};

export const getChangeSinceLast = (
  trendAmount: number | null,
  trendDirection: string | null,
  addPercentage?: boolean
) => {
  if (trendAmount === null) {
    return null;
  }
  return {
    isPositive: trendDirection === "up",
    totalChange: addPercentage ? `${trendAmount}%` : trendAmount,
  };
};

export const getOutOfFiveColor = (
  score?: number | null,
  inverted?: boolean
) => {
  if (score === null || score === undefined) return null;
  const trueScore = inverted ? 5 - score : score;
  if (trueScore > 4.5) {
    return "green";
  }
  if (trueScore > 3) {
    return "light-green";
  }
  if (trueScore > 2) {
    return "yellow";
  }
  if (trueScore > 1) {
    return "poppy";
  }
  return "red";
};

export const percentageColor = (score?: number | null, inverse?: boolean) => {
  if (score === null || score === undefined) return null;
  const trueScore = inverse ? 100 - score : score;
  if (trueScore > 70) {
    return "green";
  }
  if (trueScore > 50) {
    return "yellow";
  }
  return "red";
};

export const getTimeIntervalKey = (selectedKey?: string) => {
  switch (selectedKey) {
    case "Past 30 days":
      return "last30Days";
    case "Past 365 days":
      return "last365Days";
    default:
      return null;
  }
};
