import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import DepartmentForm from "app/storybookComponents/Forms/DepartmentForm";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectDepartmentTypes,
  selectDepartments,
  selectCreateDepartmentStatus,
  selectUpdateDepartmentStatus,
  updateDepartment,
  createDepartment,
  selectCreatedDepartmentId,
  clearCreatedDepartmentId,
  selectUserIsAbleToInviteTeamLeaders,
  selectNewCheckedEmails,
  selectCheckSingleEmailStatus,
  checkNewEmail,
} from "app/containers/AdminConsole/slice";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
} from "app/containers/Global/slice";
import TeamAverageIllustration from "resources/images/illustration-team-average.png";
import {
  selectEditingDepartmentId,
  selectEditDepartmentModalIsOpen,
  closeEditDepartmentModal,
  openInviteTeamLeaderModal,
} from "./slice";
import { PLATFORM_NAME } from "utils/constants";
import { getDepartmentLeadId } from "app/containers/AdminConsole/helpers";

const EMPTY_FORM_INPUT = {
  name: "",
  departmentTypeId: null,
  isLeaderToggled: false,
  leader: null,
  departmentLeaderEmail: undefined,
};
export default function EditDepartmentModal() {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const departmentMap = useAppSelector(selectDepartmentTypes); // Department Types is different in that we use department types for analytics while departments are what the user sees.
  const users = useAppSelector(selectAllCompanyUsersById);
  const createDepartmentStatus = useAppSelector(selectCreateDepartmentStatus);
  const updateDepartmentStatus = useAppSelector(selectUpdateDepartmentStatus);
  const editingDepartmentId = useAppSelector(selectEditingDepartmentId);
  const createdDepartmentId = useAppSelector(selectCreatedDepartmentId);
  const showing = useAppSelector(selectEditDepartmentModalIsOpen);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const isUserAbleToInviteTeamLeader = useAppSelector(
    selectUserIsAbleToInviteTeamLeaders
  );
  const checkedEmails = useAppSelector(selectNewCheckedEmails);
  const checkEmailStatus = useAppSelector(selectCheckSingleEmailStatus);

  const isLoading =
    createDepartmentStatus === "loading" ||
    updateDepartmentStatus === "loading";

  const [departmentFormInput, setDepartmentFormInput] = useState<{
    name: string;
    leader: number | null;
    departmentTypeId: number | null;
    departmentLeaderEmail?: string;
    isLeaderToggled?: boolean;
  }>(EMPTY_FORM_INPUT);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  useEffect(() => {
    if (!editingDepartmentId || !departments[editingDepartmentId]) {
      return;
    }
    const editingDepartment = departments[editingDepartmentId];
    const getInitialLeader = () => {
      if (typeof editingDepartment?.leader === "number") {
        return editingDepartment.leader;
      }
      return editingDepartment?.leader?.userAccountId || null;
    };
    const initialLeader = getInitialLeader();

    setDepartmentFormInput({
      name: editingDepartment?.name || "",
      leader: initialLeader,
      isLeaderToggled: !!initialLeader,
      departmentTypeId: editingDepartment?.departmentTypeId || null,
    });
  }, [editingDepartmentId, departments]);

  useEffect(() => {
    if (!showing) setDepartmentFormInput(EMPTY_FORM_INPUT);
  }, [showing]);

  const hideModal = () => {
    dispatch(closeEditDepartmentModal());
    dispatch(clearCreatedDepartmentId());
    setShowSuccessScreen(false);
  };

  const onOpenInviteTeamLeaderModal = () => {
    hideModal();
    dispatch(openInviteTeamLeaderModal());
  };

  const getSuccessScreenText = () => {
    const createdDepartment = createdDepartmentId
      ? departments[createdDepartmentId]
      : null;
    const appendedText =
      "Department leaders will be able to invite team leaders to this department and launch TEAMscan.";

    if (createdDepartment?.leader && createdDepartmentId) {
      const departmentLeaderId = getDepartmentLeadId(
        createdDepartmentId,
        departments
      );

      if (loggedInUserAccountId === departmentLeaderId) {
        return (
          <p className="simple-modal-text">
            You have assigned yourself as the leader of this department.{" "}
            {appendedText}
          </p>
        );
      }

      const departmentLeader = departmentLeaderId
        ? users[departmentLeaderId]
        : null;

      if (departmentLeader) {
        return (
          <p className="simple-modal-text">
            <b>
              {departmentLeader?.firstName} {departmentLeader?.lastName}
            </b>{" "}
            has been invited to be the team leader of this department.{" "}
            {appendedText}
          </p>
        );
      }
    }

    if (createdDepartment?.departmentLeaderEmail) {
      return (
        <p className="simple-modal-text">
          An invitation email has been sent to{" "}
          <b>{createdDepartment.departmentLeaderEmail}</b> to be the leader of
          this department.
          {appendedText}
        </p>
      );
    }

    return (
      <p className="simple-modal-text">
        No leader has been assigned to this department. Department leaders are
        able to invite team leaders to this department and launch the TEAMscan.
        To add a department leader, find this department in the Admin Console,
        click on the overflow menu, and add a department leader.
      </p>
    );
  };

  const getSuccessScreen = () => {
    const createdDepartment = createdDepartmentId
      ? departments[createdDepartmentId]
      : null;

    // We need to show the number of total members in the team
    return (
      <div>
        <Button
          onClick={() => hideModal()}
          variant={"secondary-blue"}
          style={{
            border: "none",
            width: "auto",
            position: "absolute",
            right: "16px",
            top: "16px",
          }}
          xIcon
        />
        <div className="column-gap-20px">
          <div className="column-gap-12px">
            <p className="mb-0">Done</p>
            <h2>Department Successfully Created</h2>
          </div>
          <div className="column-gap-16px text-align-center align-items-center">
            <div>
              <img src={TeamAverageIllustration} alt="Teams" />
            </div>
            <p
              style={{
                fontSize: "16px",
              }}
            >
              <b>"{createdDepartment?.name}" has been successfully created</b>
            </p>
            {getSuccessScreenText()}
          </div>
          <div className="row-gap-8px justify-content-center my-2">
            {isUserAbleToInviteTeamLeader ? (
              <Button onClick={onOpenInviteTeamLeaderModal}>
                Invite team leaders to this department
              </Button>
            ) : null}
            <Button
              variant="secondary-blue"
              onClick={() => {
                hideModal();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const onCheckIfCreatedEmailIsValid = (email: string) => {
    dispatch(checkNewEmail(email));
  };

  const invalidCreatedEmailMessage = () => {
    if (!departmentFormInput.departmentLeaderEmail) return null;
    return checkedEmails[departmentFormInput.departmentLeaderEmail]?.error;
  };

  const onSaveDepartment = async ({ sendEmail }: { sendEmail?: boolean }) => {
    const formInputPayload = {
      name: departmentFormInput.name,
      leader: departmentFormInput?.departmentLeaderEmail
        ? undefined
        : departmentFormInput.leader || null,
      departmentTypeId: departmentFormInput.departmentTypeId || undefined,
      departmentLeaderEmail:
        departmentFormInput?.departmentLeaderEmail || undefined,
      sendDepartmentLeaderInvitationEmail: sendEmail,
    };

    // If no editingDepartmentId, then we are creating a new department
    if (!editingDepartmentId) {
      const response = (await dispatch(
        createDepartment(formInputPayload)
      )) as any;
      if (response.error) return;
      setShowSuccessScreen(true);
      return;
    }

    const response = dispatch(
      updateDepartment({
        ...formInputPayload,
        departmentId: editingDepartmentId,
      })
    ) as any;
    if (response.error) return;
    hideModal();
  };

  const getModalContent = () => {
    if (showSuccessScreen) {
      return getSuccessScreen();
    }
    const selectedDepartment = editingDepartmentId
      ? departments[editingDepartmentId]
      : null;

    return (
      <>
        <div className="modal-title-row">
          <h2>
            {editingDepartmentId ? "Edit Department" : "Create a Department"}
          </h2>
          <Button
            onClick={() => hideModal()}
            variant={"secondary-blue"}
            style={{ border: "none", width: "auto" }}
            xIcon
          />
        </div>
        <p
          style={{
            marginTop: "-10px",
          }}
        >
          Within {PLATFORM_NAME}, departments represent groups of teams (e.g.,
          marketing, finance, operations). They can be useful when looking at
          TEAMscan reports. You can turn departments off if you don't want to
          use them in the "Admin Console" under the "Settings" tab.
        </p>
        <DepartmentForm
          users={users}
          departmentMap={departmentMap}
          formInput={departmentFormInput}
          departmentInformation={selectedDepartment}
          setFormInput={(val) => setDepartmentFormInput(val)}
          onSave={onSaveDepartment}
          isLoading={isLoading}
          loggedInUserId={loggedInUserAccountId}
          checkIfCreatedEmailIsValid={onCheckIfCreatedEmailIsValid}
          invalidCreatedEmailMessage={invalidCreatedEmailMessage()}
          checkIfCreatedEmailIsValidStatus={checkEmailStatus}
        />
      </>
    );
  };

  return (
    <Modal show={showing} onHide={hideModal} size="lg" className="simple-modal">
      {getModalContent()}
    </Modal>
  );
}
