import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortableTable from "app/components/SortableTable";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Button from "app/storybookComponents/Button";
import {
  selectAllCompanyUsersById,
  selectCreatedButNotInvitedUsersIds,
  selectGetAllUserStatus,
  selectTeamsByTeamId,
  selectUpdateUserByIdStatus,
  updateUserById,
  getAllTeamsByCompanyAccountId,
  selectCompanyInfo,
} from "app/containers/Global/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import {
  getAllDeactivatedUsers,
  inviteCreatedUser,
  inviteCreatedUsers,
  linkTestTaker,
  reactivateUser,
  reInviteUser,
  selectDeactivatedUsers,
  resetEditedCheckedEmails,
  selectInviteCreatedUsersStatus,
  updateUserEmail,
} from "../slice";
import DeactivateUserModal from "../Modals/DeactivateUserModal";
import { RowContent } from "app/components/SortableTable/types";
import { capitalizeWord } from "utils/helperFunctions";
import { UserInfo } from "app/containers/Global/types";
import CircularTabs from "app/storybookComponents/Tabs/CircularTabs";
import SimplePopover from "app/components/Popovers/SimplePopover";
import AddSingleUserModal from "app/containers/AdvancedCsvUpload/People/AddSingleUserModal";
import CopyText from "app/components/CopyButton/CopyText";
import {
  getAllManagerAndDirectReports,
  removeManager,
  selectLinkedTestTakerByUserAccountId,
  selectManagerAndDirectReportsByUserAccountId,
  updateManager,
} from "app/containers/AdvancedCsvUpload/slice";
import { ManagerRelationShip } from "app/containers/AdvancedCsvUpload/types";
import UpdatingMembersInProcessBanner from "app/containers/AdvancedCsvUpload/UpdatingMembersInProcessBanner";
import { unlinkTestTaker } from "app/components/WorkplaceInsightsReport/slice";
import TableDropdownMenu from "app/components/Dropdowns/TableDropdownMenu";

interface Props {
  filteredUserAccountIds?: number[];
  searchTerm?: string;
  isLoading?: boolean;
  onInvitePeople?: () => void;
}

type PeopleFilterTab = "All" | "Active" | "Invited" | "Created" | "Deactivated";

export default function PeopleTable({
  filteredUserAccountIds,
  searchTerm,
  isLoading,
  onInvitePeople,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ------------------------- App selectors ----------------------------------//
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const updateUserInfoStatus = useAppSelector(selectUpdateUserByIdStatus);
  const gettingAllUsersStatus = useAppSelector(selectGetAllUserStatus);
  const createdButNotInvitedUserIds = useAppSelector(
    selectCreatedButNotInvitedUsersIds
  );
  const inviteCreatedUsersStatus = useAppSelector(
    selectInviteCreatedUsersStatus
  );
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const managersAndDirectReportsByUserAccountId = useAppSelector(
    selectManagerAndDirectReportsByUserAccountId
  );
  const linkedTestTakersByUserAccountId = useAppSelector(
    selectLinkedTestTakerByUserAccountId
  );
  const companyInformation = useAppSelector(selectCompanyInfo);
  const allDeactivatedUsers = useAppSelector(selectDeactivatedUsers);

  // ------------------------- States ----------------------------------//
  const [showDeactivateUserModalUserId, setShowDeactivateUserModalUserId] =
    useState<null | number>(null);
  const [updateUserTo, setUpdateUserTo] = useState<null | {
    userAccountId: number;
    type: "Admin" | "User";
  }>(null);
  const [userToEditAccountId, setUserToEditAccountId] = useState(0);
  const [showInviteNotInvitedBanner, setShowInviteNotInvitedBanner] =
    useState(true);
  const [tabFilterSelected, setTabFilterSelected] =
    useState<PeopleFilterTab>("All");

  // ------------------------- Effects ----------------------------------//
  useEffect(() => {
    dispatch(getAllManagerAndDirectReports());
    dispatch(getAllDeactivatedUsers());
  }, [dispatch]);

  // ------------------------- Local variables ----------------------------------//

  const filteredDeactivatedUsers = useMemo(() => {
    if (!searchTerm || tabFilterSelected !== "Deactivated") {
      return allDeactivatedUsers;
    }

    const filteredUsers: Record<string, UserInfo> = {};
    Object.entries(allDeactivatedUsers).forEach(([userAccountId, user]) => {
      const { firstName, lastName, emailAddress } = user;
      const regexString = searchTerm
        .toLowerCase()
        .replace(/\\/g, "\\\\")
        .replace("+", "\\+");
      const pattern = new RegExp(regexString);
      const name = `${firstName} ${lastName}`.toLowerCase().trim();
      if (pattern.exec(name) || pattern.exec(emailAddress.toLowerCase())) {
        filteredUsers[userAccountId] = user;
      }
    });
    return filteredUsers;
  }, [allDeactivatedUsers, tabFilterSelected, searchTerm]);

  const tabsObj = useMemo(() => {
    type PeopleTab = {
      label: string;
      userAccountIds: number[];
      isDisabled: boolean;
    };
    const activeTab: PeopleTab = {
      label: "Active",
      userAccountIds: [],
      isDisabled: false,
    };
    const invitedTab: PeopleTab = {
      label: "Invited",
      userAccountIds: [],
      isDisabled: false,
    };
    const createdTab: PeopleTab = {
      label: "Created",
      userAccountIds: [],
      isDisabled: false,
    };
    const allTab: PeopleTab = {
      label: "All",
      userAccountIds: [],
      isDisabled: false,
    };
    const deactivatedTab: PeopleTab = {
      label: "Deactivated",
      userAccountIds: Object.keys(filteredDeactivatedUsers).map(Number),
      isDisabled: false,
    };

    const userIds = filteredUserAccountIds ?? Object.keys(usersInfoById);

    userIds.forEach((userId) => {
      const userAccountId = Number(userId);
      const userObject = usersInfoById[userAccountId];
      if (!userObject) return;
      const { firstTeamsLogin, tmgInvited } = userObject;
      const isActive = !!firstTeamsLogin;

      allTab.userAccountIds.push(userAccountId);
      if (isActive) {
        return activeTab.userAccountIds.push(userAccountId);
      }
      if (tmgInvited) {
        return invitedTab.userAccountIds.push(userAccountId);
      }
      createdTab.userAccountIds.push(userAccountId);
    });

    return { allTab, activeTab, invitedTab, createdTab, deactivatedTab };
  }, [filteredUserAccountIds, usersInfoById, filteredDeactivatedUsers]);

  const userIds = useMemo(() => {
    switch (tabFilterSelected) {
      case "Active":
        return tabsObj.activeTab.userAccountIds;
      case "Invited":
        return tabsObj.invitedTab.userAccountIds;
      case "Created":
        return tabsObj.createdTab.userAccountIds;
      case "All":
        return tabsObj.allTab.userAccountIds;
    }
    return [];
  }, [tabFilterSelected, tabsObj]);
  const totalPeopleInOrganization = Object.keys(usersInfoById).length;

  // ------------------------- Functions ----------------------------------//

  const getActiveUserDropdown = useCallback(
    (userAccountId: number, isActive?: boolean, tmgInvited?: boolean) => {
      const onUserTableDropdownSelect = (e: string | null, id: number) => {
        switch (e) {
          case "deactivateUser": {
            return setShowDeactivateUserModalUserId(id);
          }
          case "resendInvitation": {
            return dispatch(reInviteUser(id));
          }
          case "sendInvitation": {
            return dispatch(inviteCreatedUser(id));
          }
          case "seeUserGuide": {
            return navigate(`/UserGuide/${id}`);
          }
          case "editUserInfo": {
            return setUserToEditAccountId(id);
          }
        }
      };

      return (
        <Dropdown
          onSelect={(e) => onUserTableDropdownSelect(e, userAccountId)}
          style={{ marginLeft: "auto" }}
          key={userAccountId}
        >
          <Dropdown.Toggle
            variant="outline-primary"
            id="dropdown-basic"
            className="no-caret"
          >
            <FontAwesomeIcon icon="ellipsis" />
          </Dropdown.Toggle>

          <TableDropdownMenu>
            {isActive ? (
              <Dropdown.Item eventKey="seeUserGuide">
                Go to user profile
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                eventKey={tmgInvited ? "resendInvitation" : "sendInvitation"}
              >
                {tmgInvited ? "Resend" : "Send"} invitation
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="editUserInfo">
              Edit user information
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="deactivateUser" className="danger">
              Deactivate user
            </Dropdown.Item>
          </TableDropdownMenu>
        </Dropdown>
      );
    },
    [dispatch, navigate]
  );

  const getDeactivatedUserDropdown = useCallback(
    (userAccountId: number) => {
      const onUserTableDropdownSelect = (e: string | null) => {
        if (e === "reactivate") {
          dispatch(reactivateUser(userAccountId));
        }
      };

      return (
        <Dropdown
          onSelect={(e) => onUserTableDropdownSelect(e)}
          style={{ marginLeft: "auto" }}
        >
          <Dropdown.Toggle
            variant="outline-primary"
            id="dropdown-basic"
            className="no-caret"
          >
            <FontAwesomeIcon icon="ellipsis" />
          </Dropdown.Toggle>
          <TableDropdownMenu>
            <Dropdown.Item eventKey="reactivate">Reactivate</Dropdown.Item>
          </TableDropdownMenu>
        </Dropdown>
      );
    },
    [dispatch]
  );

  const getInvitedWarningIcon = useCallback(
    (userAccountId: number) => {
      // we will need to update the text if the user was invited but not yet joined.
      // this shall be popover with a button to allow the user to remind member to log into the platform
      return (
        <SimplePopover
          popoverContent={
            <div className="column-gap-16px">
              <p>
                This user has been invited but has not joined this organization
                yet. Send an invitation now?
              </p>
              <div>
                <Button
                  onClick={() => {
                    dispatch(reInviteUser(userAccountId));
                  }}
                >
                  Send reminder
                </Button>
              </div>
            </div>
          }
          trigger="hover"
          popoverTitle="Invited But Not Joined Yet"
        >
          <FontAwesomeIcon
            icon={["far", "exclamation-circle"]}
            color="#CC8200"
          />
        </SimplePopover>
      );
    },
    [dispatch]
  );

  const getCreatedWarningIcon = useCallback(
    (userAccountId: number) => {
      // we will need to update the text if the user was invited but not yet joined.
      // this shall be popover with a button to allow the user to remind member to log into the platform
      return (
        <SimplePopover
          popoverContent={
            <div className="column-gap-16px">
              <p>
                This user has been added but has not been invited to join this
                organization yet. Send an invitation now?
              </p>
              <div>
                <Button
                  onClick={() => {
                    dispatch(inviteCreatedUser(userAccountId));
                  }}
                >
                  Send invite
                </Button>
              </div>
            </div>
          }
          trigger="hover"
          popoverTitle="Created But Not Invited Yet"
        >
          <FontAwesomeIcon
            icon={["far", "exclamation-circle"]}
            color="#CC8200"
          />
        </SimplePopover>
      );
    },
    [dispatch]
  );

  const getName = useCallback(
    (
      userInfo: UserInfo,
      hideWarningIcon?: boolean
    ): {
      displayValue: JSX.Element;
      sortValue: string | null;
    } => {
      const {
        firstName,
        lastName,
        emailAddress,
        jobTitle,
        firstTeamsLogin,
        tmgInvited,
        userAccountId,
      } = userInfo;
      const name = `${firstName ?? ""} ${lastName ?? ""}`.trim();
      let warningIcon: null | JSX.Element = null;
      if (!firstTeamsLogin && !hideWarningIcon) {
        warningIcon = tmgInvited
          ? getInvitedWarningIcon(userAccountId)
          : getCreatedWarningIcon(userAccountId);
      }
      return {
        displayValue: (
          <div className="row-gap-6px">
            <div className="user-name-cell">
              <p className="user-name-cell__name">{name}</p>
              <p className="user-name-cell__job-title">{jobTitle}</p>
            </div>
            {warningIcon}
          </div>
        ),
        sortValue: name ?? emailAddress,
      };
    },
    [getInvitedWarningIcon, getCreatedWarningIcon]
  );

  const getTeamPopoverElement = useCallback(
    (teamIds: number[]) => {
      const teamElms: JSX.Element[] = [];
      teamIds.forEach((teamId) => {
        const team = teamsById[teamId];
        if (!team) return;
        const { teamName } = team;
        teamElms.push(
          <div>
            <Link to={`/TeamGuide/${teamId}`}>{teamName}</Link>
          </div>
        );
      });

      return <div className="column-gap-16px">{teamElms}</div>;
    },
    [teamsById]
  );

  const getTeamCount = useCallback(
    (
      userInfo: UserInfo
    ): {
      displayValue: JSX.Element;
      sortValue: string | number | null;
    } => {
      const { teamIds } = userInfo;
      const teamCount = teamIds?.length ?? 0;

      if (teamCount === 0) {
        return {
          displayValue: <div>0</div>,
          sortValue: 0,
        };
      }

      // the display value should show a underline text with the number of teams
      // when the user hovers over the text we should show all of the teams the user is a part of
      return {
        displayValue: (
          <SimplePopover
            popoverContent={getTeamPopoverElement(teamIds)}
            trigger="click"
            popoverTitle="All user's teams"
          >
            <button className="button-link">
              <p>{teamCount}</p>
            </button>
          </SimplePopover>
        ),
        sortValue: teamCount,
      };
    },
    [getTeamPopoverElement]
  );

  const getAccessRole = (
    userInfo: UserInfo
  ): {
    displayValue: JSX.Element;
    sortValue: string | null;
  } => {
    const { tmgRoleId, firstTeamsLogin, tmgInvited } = userInfo;
    const hasLoggedInForTheFirstTime = !!firstTeamsLogin;

    let role = tmgRoleId === 1 ? "Admin" : "User";
    if (hasLoggedInForTheFirstTime) {
      role = tmgRoleId === 1 ? "Admin" : "User";
    } else if (tmgInvited) {
      role = "Invited";
    } else {
      role = "Created";
    }
    return {
      displayValue: <div>{role}</div>,
      sortValue: tmgRoleId?.toString() ?? null,
    };
  };

  const getDirectReports = useCallback(
    (directReports?: ManagerRelationShip[]) => {
      const directReportsCount = directReports?.length ?? 0;
      if (directReportsCount === 0) {
        return 0;
      }

      const getPopoverElement = () => {
        const directReportsElms: JSX.Element[] = [];
        directReports?.forEach((report) => {
          const { employeeUserAccountId } = report;
          const userInfo = usersInfoById[employeeUserAccountId];
          if (!userInfo) return;
          const { firstName, lastName } = userInfo;
          directReportsElms.push(
            <div>
              <Link to={`/UserGuide/${employeeUserAccountId}`}>
                {`${firstName} ${lastName}`}
              </Link>
            </div>
          );
        });
        return <div className="column-gap-16px">{directReportsElms}</div>;
      };

      return (
        <SimplePopover
          popoverContent={getPopoverElement()}
          trigger="click"
          popoverTitle="All user's direct reports"
        >
          <button className="button-link">
            <p>{directReportsCount}</p>
          </button>
        </SimplePopover>
      );
    },
    [usersInfoById]
  );

  const getManager = useCallback(
    (managerRelationship?: ManagerRelationShip | null) => {
      if (!managerRelationship) return "-";
      const managerId = managerRelationship.managerUserAccountId;
      const managerName = `${usersInfoById[managerId]?.firstName ?? ""} ${
        usersInfoById[managerId]?.lastName ?? ""
      }`;
      return {
        displayValue: <Link to={`/UserGuide/${managerId}`}>{managerName}</Link>,
        sortValue: managerName,
      };
    },
    [usersInfoById]
  );

  const getActiveUserRows = useCallback(() => {
    const rows: RowContent[] = [];
    userIds.forEach((userId) => {
      const userObject = usersInfoById[Number(userId)];
      if (!userObject) return;
      const {
        jobTitle,
        emailAddress,
        userAccountId,
        firstTeamsLogin,
        gender: genderText,
        tmgInvited,
      } = userObject;

      const isActive = !!firstTeamsLogin;
      const gender =
        genderText === "do-not-disclose" || !genderText
          ? "Not disclosed"
          : capitalizeWord(genderText);
      rows.push({
        className: !isActive ? "deactivated" : undefined,
        name: getName(userObject),
        gender,
        jobTitle,
        emailAddress: {
          displayValue: (
            <div style={{ position: "relative" }}>
              <CopyText text={emailAddress} className="copy-email-cell" />
            </div>
          ),
          sortValue: emailAddress,
        },
        teamCount: getTeamCount(userObject),
        manager: getManager(
          managersAndDirectReportsByUserAccountId[userAccountId]
            ?.managerRelationship
        ),
        tmgRoleId: getAccessRole(userObject),
        directReports: {
          displayValue: (
            <div className="row-gap-12px align-items-center">
              <div>
                {getDirectReports(
                  managersAndDirectReportsByUserAccountId[userAccountId]
                    ?.directReportRelationships
                )}
              </div>
              {getActiveUserDropdown(userAccountId, isActive, !!tmgInvited)}
            </div>
          ),
          sortValue:
            managersAndDirectReportsByUserAccountId[userAccountId]
              ?.directReportRelationships?.length ?? 0,
        },
      });
    });
    return rows;
  }, [
    userIds,
    usersInfoById,
    getName,
    getActiveUserDropdown,
    getTeamCount,
    managersAndDirectReportsByUserAccountId,
    getDirectReports,
    getManager,
  ]);

  const getDeactivatedUserRows = useCallback(
    () =>
      Object.entries(filteredDeactivatedUsers).map(
        ([userAccountId, userObject]) => {
          const { jobTitle, emailAddress, gender } = userObject;
          return {
            name: getName(userObject, true),
            gender,
            jobTitle,
            emailAddress: {
              displayValue: (
                <div style={{ position: "relative" }}>
                  <CopyText text={emailAddress} className="copy-email-cell" />
                </div>
              ),
              sortValue: emailAddress,
            },
            teamCount: "-",
            manager: "-",
            tmgRoleId: getAccessRole(userObject),
            directReports: {
              displayValue: (
                <div className="row-gap-12px align-items-center">
                  <div>-</div>
                  {getDeactivatedUserDropdown(Number(userAccountId))}
                </div>
              ),
              sortValue: "-",
            },
            key: userAccountId,
          };
        }
      ),
    [getName, filteredDeactivatedUsers, getDeactivatedUserDropdown]
  );

  const rows = useMemo(() => {
    if (tabFilterSelected === "Deactivated") {
      return getDeactivatedUserRows();
    }
    return getActiveUserRows();
  }, [tabFilterSelected, getActiveUserRows, getDeactivatedUserRows]);

  const getWarningModalMessage = () => {
    if (updateUserTo === null) return "";

    if (updateUserTo.type === "User") {
      return "This user will no longer be able to access admin settings, change access permissions, and configure any global organization settings.";
    } else {
      return "Admins have the ability to create/delete teams, invite/remove members, change access permissions, and configure any global organization settings.";
    }
  };

  const onWarningModalConfirm = async () => {
    if (!updateUserTo) return;
    const tmgRoleId = updateUserTo.type === "Admin" ? 1 : 2;
    await dispatch(
      updateUserById({
        userAccountId: updateUserTo.userAccountId,
        payload: {
          tmgRoleId,
        },
      })
    );
    setUpdateUserTo(null);
  };

  const getWarningTitle = () => {
    if (!updateUserTo) return "";
    const memberInfo = usersInfoById[updateUserTo.userAccountId];
    const name =
      memberInfo?.firstName || memberInfo?.lastName
        ? `${memberInfo?.firstName ?? ""} ${memberInfo?.lastName ?? ""}`
        : memberInfo?.emailAddress;

    return `Are you sure you want to switch ${name} to "${
      updateUserTo?.type ?? ""
    }" ?`;
  };
  const getEmptyCard = () => {
    if (isTableLoading) {
      return null;
    }
    if (totalPeopleInOrganization === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px align-items-center">
            <span>
              <b>No teams have been created yet.</b>
            </span>
            <p>Teams created will be shown here</p>
            <Button onClick={() => onInvitePeople?.()}>
              <FontAwesomeIcon icon="plus" className="me-2" /> Create a team
            </Button>
          </div>
        </div>
      );
    }

    // if the length of rows is 0 then return the empty card
    if (rows.length === 0) {
      return (
        <div className="empty-card">
          <div className="column-gap-12px">
            <span>
              <b>{`No people found matching "${searchTerm}"`}</b>
            </span>
            <p>Try searching by name or email</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const getCreatedButNotInvitedBanner = () => {
    if (!showInviteNotInvitedBanner || !createdButNotInvitedUserIds.length) {
      return null;
    }
    return (
      <div className="warning-banner blue d-flex align-items-center justify-content-between">
        <p>
          <FontAwesomeIcon icon={["far", "lightbulb-on"]} className="me-2" />
          You haven’t sent invitations to {
            createdButNotInvitedUserIds.length
          }{" "}
          {createdButNotInvitedUserIds.length === 1 ? "person" : "people"} in
          your organization yet. Would you like to do this now?
        </p>

        <div className="d-flex gap-2">
          <Button
            variant="secondary-blue"
            onClick={() => setShowInviteNotInvitedBanner(false)}
          >
            Dismiss
          </Button>
          <Button
            onClick={() => {
              dispatch(inviteCreatedUsers());
            }}
            disabled={inviteCreatedUsersStatus === "loading"}
          >
            Send Invitations
          </Button>
        </div>
      </div>
    );
  };

  const onSaveUserEdit = async (payload: {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    gender?: string;
    jobTitle?: string;
    tmgRoleId?: 1 | 2;
    linkedTestTakerId?: number;
    managerUserAccountId?: number;
    sendInvitations?: boolean;
  }) => {
    if (!userToEditAccountId) return;
    dispatch(
      updateUserById({
        userAccountId: userToEditAccountId,
        payload: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          jobTitle: payload.jobTitle,
          gender: payload.gender?.toLowerCase(),
          tmgRoleId: payload.tmgRoleId,
        },
      })
    );
    saveUserEmail(userToEditAccountId, payload.emailAddress);
    saveLinkedTestTaker(userToEditAccountId, payload.linkedTestTakerId);
    await saveManager(userToEditAccountId, payload.managerUserAccountId);
  };

  const saveUserEmail = (userAccountId: number, emailAddress?: string) => {
    // if the email address is the same as the existing email address, return to avoid making an unnecessary API call
    const user = usersInfoById[userAccountId];
    if (!emailAddress || user?.emailAddress === emailAddress) {
      return;
    }
    dispatch(updateUserEmail({ userAccountId, emailAddress }));
  };

  const saveLinkedTestTaker = async (
    userAccountId: number,
    linkedTestTakerId?: number
  ) => {
    const existingTestTakerId =
      linkedTestTakersByUserAccountId[userAccountId]?.testTakerId;

    // If the test taker is the same as the existing test taker, return to avoid making an unnecessary API call
    if (existingTestTakerId === linkedTestTakerId) {
      return;
    }

    // If theres a new test taker selected, link the test taker to the user
    if (linkedTestTakerId) {
      dispatch(
        linkTestTaker({
          userAccountId: userToEditAccountId,
          testTakerId: linkedTestTakerId,
        })
      );
      return;
    }

    // if test taker already linked and no new test taker is selected, unlink the current test taker
    if (existingTestTakerId) {
      dispatch(unlinkTestTaker(userToEditAccountId));
    }
  };

  const saveManager = async (
    userAccountId: number,
    managerUserAccountId?: number
  ) => {
    const existingManagerId =
      managersAndDirectReportsByUserAccountId[userAccountId]
        ?.managerRelationship?.managerUserAccountId;

    // If the manager is the same as the existing manager, return to avoid making an unnecessary API call
    if (existingManagerId === managerUserAccountId) {
      return;
    }

    // If a new manager is selected, update the manager
    if (managerUserAccountId) {
      await dispatch(
        updateManager({
          userAccountId: userToEditAccountId,
          managerUserAccountId: managerUserAccountId,
        })
      );
      // if no new manager is selected and there is an existing manager, remove the manager
    } else if (existingManagerId) {
      await dispatch(removeManager(userToEditAccountId));
    }

    // If the manager is different from the existing manager, refresh the teams
    if (existingManagerId !== managerUserAccountId) {
      dispatch(
        getAllTeamsByCompanyAccountId({
          companyAccountId: companyInformation?.companyAccountId ?? 0,
          refreshTeams: true,
        })
      );
    }
  };

  const tabArray = useMemo(() => {
    const tabs = [
      tabsObj.allTab,
      tabsObj.activeTab,
      tabsObj.invitedTab,
      tabsObj.createdTab,
      tabsObj.deactivatedTab,
    ];
    return tabs.map((tab) => ({
      label: tab.label,
      count: tab.userAccountIds.length,
      isDisabled: tab.userAccountIds.length === 0,
    }));
  }, [tabsObj]);

  const isTableLoading = isLoading || gettingAllUsersStatus === "loading";

  return (
    <>
      {/* Special modal to make sure to reassign teams and departments before deleting the chose user if the user has any. */}
      <DeactivateUserModal
        show={showDeactivateUserModalUserId !== null}
        onHide={() => setShowDeactivateUserModalUserId(null)}
        userAccountId={showDeactivateUserModalUserId}
      />
      <AddSingleUserModal
        isOpen={userToEditAccountId !== 0}
        onClose={() => {
          setUserToEditAccountId(0);
          dispatch(resetEditedCheckedEmails());
        }}
        onSaveUserDataRow={onSaveUserEdit}
        userAccountId={userToEditAccountId}
        showConnectTestTaker
        saveButtonText="Save"
        title="Edit User Information"
        hideSendInvitationToggle={true}
      />
      <WarningModal
        modalTitle="Change permissions"
        warningTitle={getWarningTitle()}
        warningMessage={getWarningModalMessage()}
        isOpen={!!updateUserTo}
        onConfirmClick={onWarningModalConfirm}
        hideModal={() => setUpdateUserTo(null)}
        isDanger={false}
        customButtonText="Confirm Changes"
        isInProgress={updateUserInfoStatus === "loading"}
      />
      <CircularTabs
        selectedTab={tabFilterSelected}
        onTabSelected={(tab) => {
          setTabFilterSelected(tab as PeopleFilterTab);
        }}
        tabs={tabArray}
      />
      {getCreatedButNotInvitedBanner()}
      <UpdatingMembersInProcessBanner appendedClassName="mb-3" />
      <div className="admin-console-table-container">
        <SortableTable
          rows={rows}
          tableClassName="admin-console-table"
          columnHeaders={[
            {
              key: "name",
              label: "Name",
            },
            {
              key: "emailAddress",
              label: "Email",
            },
            {
              key: "gender",
              label: "Gender",
            },
            {
              key: "tmgRoleId",
              label: "Access Role",
            },
            {
              key: "manager",
              label: "Manager",
            },
            {
              key: "teamCount",
              label: "Teams",
              sortInverse: true,
            },
            {
              key: "directReports",
              label: "Direct Reports",
              sortInverse: true,
            },
          ]}
          isLoading={isTableLoading}
        />
      </div>
      {getEmptyCard()}
    </>
  );
}
