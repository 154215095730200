export const MOOD_SCORE_DESCRIPTIONS: string[] = [
  "Energized & Optimistic - Feeling highly motivated and positive about work. Ready to take on challenges.",
  "Generally Positive - Maintaining good energy and engagement. Some minor challenges but overall confident.",
  "Neutral/Mixed - Neither particularly positive nor negative. May have competing factors affecting mood.",
  "Somewhat Challenging - Experiencing notable difficulties. Energy and motivation lower than usual.",
  "Significant Strain - Facing serious challenges. May need immediate support or intervention.",
];

export const PROGRESS_SCORE_DESCRIPTIONS: string[] = [
  "Exceptional Progress - Exceeded expectations with multiple significant achievements.",
  "Strong Progress - Clear forward momentum with goals being met and some notable successes.",
  "Steady Progress - Moving forward at an expected pace. No major setbacks or gains.",
  "Limited Progress - Some movement forward but falling short of expectations.",
  "Blocked/Stalled - Little to no forward progress. Significant obstacles preventing advancement.",
];

export const READINESS_SCORE_DESCRIPTIONS: string[] = [
  "Clear Path Forward - No significant blockers. Well-equipped to handle any minor challenges.",
  "Minor Obstacles - Some small challenges present but manageable with current resources.",
  "Moderate Blockers - Notable obstacles requiring attention but not causing critical delays.",
  "Significant Blockers - Major obstacles impacting progress. May need external support.",
  "Critical Blockers - Severe obstacles preventing progress. Immediate intervention needed.",
];
